import localForge from 'localforage';
import { COUNTRY } from 'constants/index';
import logError from './logError';

const getStoreDbName = () => {
  switch (COUNTRY) {
    case 'ae': {
      return 'stylistore_ae';
    }
    case 'kw': {
      return 'stylistore_kw';
    }
    case 'bh': {
      return 'stylistore_bh';
    }
    case 'qa': {
      return 'stylistore_qa';
    }
    default: {
      return 'stylistore';
    }
  }
};

localForge.config({
  driver: window.localStorage ? localForge.LOCALSTORAGE : localForge.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'styli',
  version: 1.1,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: getStoreDbName(), // Should be alphanumeric, with underscores.
  description: 'Styli Offline Store'
});

// const userDb = localForge.createInstance({
//   // driver: localForge., // Force WebSQL; same as using setDriver()
//   name: 'styli',
//   version: 1.0,
//   size: 4980736, // Size of database, in bytes. WebSQL-only for now.
//   storeName: 'user', // Should be alphanumeric, with underscores.
//   description: 'Styli Offline User'
// });

const { getItem, setItem, removeItem } = localForge;

export const getStorage = async key => {
  const result = await getItem(key);
  return result;
};
export const setStorage = async (key, value) => {
  const result = await setItem(key, value);
  return result;
};
export const removeStorage = async key => {
  const result = await removeItem(key);
  return result;
};

// export const getUserStorage = async key => {
//   const result = await userDb.getItem(key);
//   return result;
// };
// export const setUserStorage = async (key, value) => {
//   const result = await userDb.setItem(key, value);
//   return result;
// };
// export const removeUserStorage = async key => {
//   const result = await userDb.removeItem(key);
//   return result;
// };

export const logoutStorage = async () => {
  try {
    //  await userDb.clear();
    await localForge.clear();
    await localForge.dropInstance({
      name: 'styli',
      storeName: 'stylistore_ae'
    });
    await localForge.dropInstance({ name: 'styli', storeName: 'stylistore' });
    // await localForge.dropInstance({ name: 'styli', storeName: 'user' });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (e) {
    logError(e);
  }
};
