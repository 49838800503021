import React from 'react';
import './index.scss';
import FreeShipping from 'assets/icons/free-shipping-o.svg';
import { translate } from 'constants/language';
import { useHistory } from 'react-router-dom';
import { CURRENT_LANG_PREFIX } from '../../../../constants';

export default ({ widgetData, bottomSpace }) => {
  const history = useHistory();
  const doOnClick = () => {
    if (widgetData.link) {
      history.push(`/${CURRENT_LANG_PREFIX}/${widgetData.link}`);
      window.scrollTo(0, 0);
    }
  };
  return (
    <div
      className="flex middle-xs"
      onClick={doOnClick}
      onKeyPress={doOnClick}
      role="link"
      style={{ marginBottom: bottomSpace }}
    >
      <FreeShipping height="25px" className="rtl-flip" />
      <div className="ml-15 mr-15">
        <div className="c-dark-gray fs-14 fw-6 mb-2">{widgetData.title}</div>
        <div className="c-brown-gray fs-11">
          <span>{translate('On all your orders')}&nbsp;</span>
          <span className="c-brand">{widgetData.subTitle}</span>
          {translate('. T&C apply*')}
        </div>
      </div>
    </div>
  );
};
