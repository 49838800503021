import React, { useEffect, useState } from 'react';
import { getNavigationBasePath } from 'utils';
import classnames from 'classnames';

import flashIcon from 'assets/icons/flash-icon.png';
import ArrowRight from 'assets/icons/arrow-right-brand.svg';
import { translate } from 'constants/language';
import TimerWithTiles from './TimerWithTiles';

export default ({
  title = '',
  flashSaleActive,
  flashSaleData = {},
  tillDate,
  viewMoreObj,
  link = '',
  onWidgetClick = () => {},
  hasBG = true,
  pageType = 'HOME',
  id = '',
  flashImg = {},
  flashText = '',
  isSmallerTimer = false
}) => {
  const tillDateObj = new Date(tillDate).getTime();
  const [daysVal, setDaysVal] = useState(0);
  const [hoursVal, setHoursVal] = useState(0);
  const [minutesVal, setMinutesVal] = useState(0);
  const [secondsVal, setSecondsVal] = useState(0);
  const timeGLobal = tillDateObj - new Date().getTime();

  const flashSaleTimer = `flashSaleTimer_${id}`;

  useEffect(() => {
    clearInterval(window[flashSaleTimer]);
    if (timeGLobal > 0) {
      window[flashSaleTimer] = setInterval(() => {
        const now = new Date().getTime();
        const timeleft = tillDateObj - now;
        if (timeleft >= 0) {
          setDaysVal(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
          setHoursVal(
            Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          );
          setMinutesVal(
            Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
          );
          setSecondsVal(Math.floor((timeleft % (1000 * 60)) / 1000));
        }

        if (timeleft < 0) {
          clearInterval(window[flashSaleTimer]);
          window.location.reload();
        }
      }, 1000);
    }

    return () => {
      clearInterval(window[flashSaleTimer]);
    };
  }, [tillDate]);

  const onParentClick = event => {
    if (pageType === 'HOME') {
      onWidgetClick({ event, widget: { ...viewMoreObj } });
    }
  };
  const doOnClick = event => {
    onWidgetClick({ event, widget: { ...viewMoreObj } });
  };

  return (
    <If condition={timeGLobal > 0}>
      <div
        className={classnames({
          'pt-8 pb-8 flex center-xs': hasBG
        })}
        onClick={onParentClick}
        onKeyPress={onParentClick}
        role="button"
        style={flashSaleData.color ? { background: flashSaleData.color } : {}}
      >
        <div
          className={classnames(
            'flex middle-xs',
            hasBG
              ? 'pt-2 pb-2 pl-10 pr-10 center-xs'
              : 'pr-5 pl-5 pb-2 between-xs mt-4'
          )}
          //   style={flashSaleData.color ? { background: flashSaleData.color } : {}}
        >
          <If condition={flashImg && flashImg.url && !isSmallerTimer}>
            <div className="flex middle-xs">
              <div
                style={{ overflow: 'hidden' }}
                className="h-30 flex center-xs "
              >
                <img src={flashImg.url} alt="" className="h100p" />
              </div>
              <If condition={pageType === 'HOME'}>
                <div className="fs-16 lh-20 ls-048 fw-6 c-dark-gray">
                  {title}
                </div>
              </If>
              <If
                condition={
                  pageType === 'PDP' ||
                  pageType === 'BAG' ||
                  flashSaleActive === false
                }
              >
                <div className="fs-12 lh-16 ls-072 fw-6 c-dark-gray ml-n pl-r-5">
                  {translate('Flash Sale')}
                </div>
              </If>
            </div>
          </If>
          <If condition={flashText && isSmallerTimer}>
            <div className="flex middle-xs">
              <If condition={pageType === 'HOME'}>
                <div className="fs-16 lh-20 ls-048 fw-6 c-dark-gray">
                  {flashText}
                </div>
              </If>
              <If
                condition={
                  pageType === 'PDP' ||
                  pageType === 'BAG' ||
                  flashSaleActive === false
                }
              >
                <div className="fs-12 lh-16 ls-072 fw-6 c-dark-gray ml-n pl-r-5">
                  {flashText}
                </div>
              </If>
            </div>
          </If>
          <div className="flex middle-xs ml-auto">
            <div
              className={classnames(
                'fs-10 lh-14 ls-032 c-dark-gray pl-5 pr-5',
                {
                  flashSaleActive: ''
                }
              )}
            >
              <Choose>
                <When condition={pageType === 'PLP'}>
                  {flashSaleActive === false
                    ? translate('has expired')
                    : translate('This sale will end in')}
                </When>
                <Otherwise>
                  <span className={classnames({ low: pageType === 'PDP' })}>
                    {translate('Ends in')}
                  </span>
                </Otherwise>
              </Choose>
            </div>
            <If condition={flashSaleActive !== false}>
              <TimerWithTiles
                daysVal={daysVal}
                hoursVal={hoursVal}
                minutesVal={minutesVal}
                secondsVal={secondsVal}
                isSmallerTimer={isSmallerTimer}
              />
            </If>
            <If condition={link && link.length !== 0}>
              <div className="w-20 h-20 flex middle-xs center-xs">
                <a
                  href={`${getNavigationBasePath()}/${link}`}
                  onClick={doOnClick}
                  onKeyPress={doOnClick}
                >
                  <ArrowRight height="12px" className="rtl-r" />
                </a>
              </div>
            </If>
            <If condition={pageType === 'PDP' || pageType === 'BAG'}>
              <div className="h-20 w-20 flex center-xs">
                <img src={flashIcon} alt="" className="h100p" />
              </div>
            </If>
            <If condition={pageType === 'PLP'}>
              <If condition={flashSaleActive !== false}>
                <div className="w-5 h-5" />
              </If>
              <div className="h-20 w-20 flex center-xs">
                <img src={flashIcon} alt="" className="h100p" />
              </div>
            </If>
          </div>
        </div>
      </div>
    </If>
  );
};
