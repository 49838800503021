import React from 'react';

export default function ({
  items = [],
  itemHeight = "32px",
  itemWidth = "auto",
  ...props
}) {
  return (
    <div className="flex" {...props}>
      {items && items.map(item => (
        <a href={item.href} target="_blank" rel="noopener noreferrer" className="pr-r-15">
          <img src={item.src} alt={item.alt} height={itemHeight} width={itemWidth} />
        </a>
      ))}
    </div>
  );
}
