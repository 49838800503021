import { COUNTRY, IS_EVENT_LOGGING } from 'constants/index';
// import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import en from '../language/en.json';
import ar from '../language/ar.json';
import isArabic from '../constants/isArabic';
import { getCustomerId } from './user';

export function cleverTapProcess({
  eventName,
  additionalData,
  cleverTapData = {}
}) {
  if (IS_EVENT_LOGGING) {
    const currentLanguage = isArabic ? ar : en;
    if (eventName === 'login' || eventName === 'sign_up_form_success') {
      const profile = {
        'First Name': additionalData?.firstName,
        'Last Name': additionalData?.lastName,
        Name: `${additionalData?.firstName}${additionalData?.lastName}`,
        Email: additionalData?.email,
        Phone: additionalData.mobileNumber
          ? additionalData.mobileNumber
          : additionalData.addressMobile,
        UserID: additionalData?.customerId,
        Identity: additionalData?.customerId,
        Gender: '1',
        SelectedCountry: COUNTRY,
        SelectedLanguage: currentLanguage,
        GuestId: ''
      };
      const profileUpdated = {
        Site: profile
      };
      try {
        if (profile.UserID) {
          if (eventName === 'login') {
            window.clevertap.event.push('login', {
              UserID: additionalData?.customerId,
              login_type: additionalData.loginType
                ? additionalData.loginType
                : 'password',
              status: 'success',
              screen_name: 'Account_User_Identification_Bottomsheet',
              platform: isMobile ? 'msite' : 'web'
            });
          } else {
            window.clevertap.profile.push(profileUpdated);
            window.clevertap.event.push('signup', {
              UserID: additionalData?.customerId,
              login_type: additionalData.isotpRegister ? 'otp' : 'password',
              status: 'success',
              screen_name: 'Account_User_Identification_Bottomsheet',
              platform: isMobile ? 'msite' : 'web'
            });
          }
        }
      } catch (e) {
        console.log(e, 'errorcatch');
      }
    } else {
      let sourceScreenName = '';
      // let cleverTapEventName = '';
      let cleverTapEventName = eventName;
      switch (eventName) {
        case 'view_item':
          sourceScreenName = 'Category_Listing';
          break;
        case 'add_to_wishlist':
          sourceScreenName = 'Category_Listing';
          break;
        case 'add_to_cart':
          sourceScreenName = 'Product_Details';
          break;
        case 'view_cart':
          sourceScreenName = 'cart_page';
          break;
        case 'product_listing':
          sourceScreenName = 'product_listing';
          break;
        case 'couponcode_success':
          sourceScreenName = 'Bag_Home';
          break;
        case 'couponcode_failed':
          sourceScreenName = 'Bag_Home';
          break;
        case 'order_cancel':
          sourceScreenName = 'OrderCancel';
          break;
        case 'card_added':
          sourceScreenName = 'checkout_page';
          break;
        case 'checkout':
          sourceScreenName = 'Bag_Home';
          break;
        case 'order_failed':
          sourceScreenName = 'check_out';
          break;

        default:
          sourceScreenName = '';
      }

      switch (eventName) {
        case 'couponcode_success':
          cleverTapEventName = 'coupon_success';
          break;
        case 'couponcode_failed':
          cleverTapEventName = 'coupon_failed';
          break;
        default:
          cleverTapEventName = eventName;
      }

      const cleverTapLog = {
        ...cleverTapData,
        source_screen_name: sourceScreenName,
        user_id: getCustomerId(),
        platform: isMobile ? 'msite' : 'web'
      };
      window.clevertap.event.push(cleverTapEventName, cleverTapLog);
    }
  }
}
export default cleverTapProcess;
