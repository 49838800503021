import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import SearchBar from 'components/SearchBar';
import { getNavigationBasePath } from 'utils';

import BackIcon from 'assets/icons/arrow-back-blk.svg';
import './index.scss';

// import ArabicLogo from './arabicLogo.svg';
// import EnglishLogo from './englishLogo.svg';
import LogoMix from 'assets/logo.svg';
import LogoEN from 'assets/logo-en.svg';
import SearchIcon from './magnifying-glass.svg';

import { COUNTRY, isIOS } from '../../constants';
import { translate } from '../../constants/language';

const Logo = COUNTRY.toLowerCase() === 'in' ? LogoEN : LogoMix;
// const logos = { en: EnglishLogo, ar: ArabicLogo };

export default function({
  HeaderRight = <div className="w100">&nbsp;</div>,
  title = '',
  backIconWidth = '14px',
  subTitle = '',
  backAction = () => {},
  titleClassName = '',
  showLogo = true,
  iconLeft = (
    <a onClick={backAction} onKeyPress={backAction} role="link">
      <BackIcon
        width={backIconWidth}
        // icon={faArrowLeft}
        className="c-black-light rtl-reverse v-m"
      />
    </a>
  ),
  type = '',
  wraperClasses = '',
  openSearchBar = false,
  handleSearchClick,
  widthClass,
  style: wraperStyle = {}
  // autoFocusOnSearchField
}) {
  const [showSearchBar, setSearchBarVisible] = useState(openSearchBar);
  const handleSearchBar = () => {
    if (!showSearchBar && isIOS) {
      window.scrollTo(0, 0); // iOS Issue
    }
    setSearchBarVisible(!showSearchBar);
    if (handleSearchClick) handleSearchClick();
  };
  // const Logo = logos[CURRENT_LANG_PREFIX];
  const listner = () => {
    if (window.scrollY > 35) {
      document.getElementsByTagName('BODY')[0].classList.add('_scrolled');
    } else {
      document.getElementsByTagName('BODY')[0].classList.remove('_scrolled');
    }
  };
  useEffect(() => {
    if (type === 'centered') {
      window.addEventListener('scroll', listner);
    }
    return () => {
      window.removeEventListener('scroll', listner);
      document.getElementsByTagName('BODY')[0].classList.remove('_scrolled');
    };
  }, []);

  useEffect(() => {
    if (showSearchBar) {
      document.querySelector('html').classList.add('_no-scroll');
    } else {
      document.querySelector('html').classList.remove('_no-scroll');
    }
    return () => document.querySelector('html').classList.remove('_no-scroll');
  }, [showSearchBar]);
  useEffect(() => {
    if (openSearchBar) {
      setSearchBarVisible(true);
    } else {
      setSearchBarVisible(false);
    }
  }, [openSearchBar]);

  return (
    <>
      <If condition={showSearchBar}>
        <SearchBar onBackClick={handleSearchBar} isExpand={true} />
      </If>
      <Choose>
        <When condition={type === 'centered'}>
          <header
            style={wraperStyle}
            className={classnames(
              'header _large header _large bg-white top-fixed z-4 ta-c',
              wraperClasses,
              {
                'pl-25': !(wraperClasses.indexOf('pl-') > -1),
                'pr-25': !(wraperClasses.indexOf('pr-') > -1),
                'pt-16': !(wraperClasses.indexOf('pt-') > -1),
                'pb-8': !(wraperClasses.indexOf('pb-') > -1)
              }
            )}
          >
            <If condition={showLogo}>
              <a
                className="d-il-block"
                href={`${getNavigationBasePath()}/`}
                title={translate('Styli')}
              >
                <Logo
                  title="styli logo"
                  width="70px"
                  height="32px"
                  className="d-block"
                />
              </a>
            </If>
            <div
              id="searchButton"
              className="flex middle-xs pos-abs end-0 top-0 mr-20 ml-20 mt-20 p-5"
              onClick={handleSearchBar}
              onKeyPress={handleSearchBar}
              role="button"
            >
              <SearchIcon height="20px" width="20px" className="d-block" />
            </div>
          </header>
        </When>
        <Otherwise>
          <header
            style={wraperStyle}
            className={classnames(
              'between-xs header flex bg-white top-fixed z-1 h-50 b-box transition-0',
              wraperClasses,
              {
                'pl-15': !(wraperClasses.indexOf('pl-') >= 0),
                'pr-15': !(wraperClasses.indexOf('pr-') >= 0),
                'bb-light-gray': !(wraperClasses.indexOf('bb-') >= 0)
              }
            )}
          >
            <div className="flex middle-xs">
              {iconLeft}
              <If condition={showLogo}>
                <a
                  href={`${getNavigationBasePath()}/`}
                  title={translate('Styli')}
                >
                  <Logo
                    title="styli logo"
                    width="43"
                    height="24"
                    className="mr-15 ml-15"
                  />
                </a>
              </If>
            </div>
            <h1
              className={classnames(
                'flex-grow-1 m-0 ml-14 mr-14 ta-l fw-6 flex col center-xs',
                widthClass || 'w100',
                titleClassName,
                {
                  'fs-16': !(titleClassName.indexOf('fs-') > -1),
                  'c-header-title': !(titleClassName.indexOf('c-') > -1)
                }
              )}
            >
              <div className="flex">
                <div className="start-xs w100p">
                  <h1 className="txt-overflow-ellipsis ls-048">{title}</h1>
                  <If condition={subTitle}>
                    <div className="fs-11 fw-5 c-brown-gray txt-overflow-ellipsis ls-12">
                      {subTitle}
                    </div>
                  </If>
                </div>
              </div>
            </h1>
            {HeaderRight}
          </header>
        </Otherwise>
      </Choose>
    </>
  );
}
