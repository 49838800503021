import values from 'object.values';
import { getStorage, setStorage } from 'utils/storage';
import { path } from '.';
import { COUNTRY } from '../constants';

export function getSelectedCityData({ selectedAddress, addressData }) {
  const selectedProvince = path(
    [COUNTRY.toUpperCase(), selectedAddress.regionId],
    addressData
  );
  const cities = (selectedProvince && values(selectedProvince.cities)) || [];
  const selectedCityDetails =
    (cities.length &&
      cities.find(city => city.name_en === selectedAddress.city)) ||
    {};
  return selectedCityDetails;
}

export async function getDeliverySlaCityId() {
  const cityId = await getStorage('pdc');
  return cityId;
}

export async function setDeliverySlaCityId(cityId) {
  await setStorage('pdc', cityId);
}
export async function getDeliverySlaArea() {
  const area = await getStorage('pda');
  return area;
}
export async function setDeliverySlaArea(area) {
  await setStorage('pda', area);
}
