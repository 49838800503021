import React from 'react';
import ImgList from 'components/libs/ImgList';
import { translate } from 'constants/language';
import ApplePayIcon from 'assets/icons/pay-method/apple-pay.png';
import VisaIcon from 'assets/icons/pay-method/visa.png';
import MasterCardIcon from 'assets/icons/pay-method/mastercard.png';
import MadaIcon from 'assets/icons/pay-method/mada.png';
import { COUNTRY } from 'constants/index';
import FooterTitle from './FooterTitle';

export default function ({ icon, label, value, className, ...props }) {

  const payMethods = [
    {
      src: ApplePayIcon,
      link: "#",
    },
    {
      src: VisaIcon,
      link: "#",
    },
    {
      src: MasterCardIcon,
      link: "#",
    }
  ];

  const madaLogo = {
    src: MadaIcon,
    link: "#",
  }
  return (
    <div {...props}>
      <FooterTitle>{translate('We Accept')}</FooterTitle>
      <ImgList items={[COUNTRY === "sa" ? madaLogo : {}, ...payMethods]} />
    </div>

  );
}