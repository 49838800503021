import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import { isBrowser } from 'react-device-detect';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'utils/analytics';

// import urlParse from 'url-parse';
// import { translate } from 'constants/language';
import { resetProductDetails } from 'containers/ProductDetail/actions';
import { useStateValue } from 'context/app';
import {
  CURRENT_LANG_PREFIX
  // , COUNTRY
} from '../../../constants';

export default function({
  hits,
  dataType,
  onBackClick,
  currentRefinement
  // refine
}) {
  const history = useHistory();
  const [results, setResults] = useState(hits);
  const [suggestions, setSuggestions] = useState([]);
  const {
    productDetail: [, dispatch]
  } = useStateValue();

  const handleCategoryClick = ({ url, actualText: query }) => {
    const lang = CURRENT_LANG_PREFIX;
    onBackClick();
    resetProductDetails({}, dispatch);

    if (query) {
      history.push(`/${lang}/catalogsearch/result?q=${query}`);
    }

    if (url) {
      logEvent({
        eventName: 'search',
        data: {
          searchTerm: currentRefinement
        },
        skipGtm: true,
        screenName: 'Search_Listing'
      });
      history.push(`/${lang}/${url}`);
    }
  };

  useEffect(() => {
    setResults(hits);
    if (isEmpty(suggestions)) {
      setSuggestions(hits);
    }
  }, [hits]);

  const stilize = text => {
    const txtAry = text.split('${');
    try {
      if (txtAry.length > 1) {
        const stylObj = JSON.parse(txtAry[1].slice(0, -2).replaceAll("'", '"'));
        const hText = `<span style="font-weight:${stylObj.type}; color:${stylObj.color}">${stylObj.text}</span>`;
        return `${txtAry[0]}${hText}`;
      }
    } catch (e) {
      console.error(e);
    }
    return txtAry[0];
  };
  return (
    <>
      <If
        condition={
          currentRefinement.length &&
          results.length &&
          dataType !== 'suggestions'
        }
      >
        <ul className="srch-list m-0 p-0">
          {results.map(hit => {
            return (
              <li
                key={hit.objectID}
                className="bb-light pl-10 pr-10 pt-5 pb-5 block"
              >
                <div className="flex middle-xs td-none">
                  <a
                    role="link"
                    onClick={() => handleCategoryClick(hit)}
                    onKeyPress={() => {
                      handleCategoryClick(hit);
                    }}
                    className="w100p-m25"
                  >
                    <div
                      className={classnames(
                        'c-dark-gray txt-ovewrflow-ellipsis',
                        { 'fs-14': isBrowser, 'fs-12': !isBrowser }
                      )}
                      dangerouslySetInnerHTML={{
                        __html: stilize(hit.display)
                      }}
                    />
                    <div className="fs-10 c-label-hint txt-ovewrflow-ellipsis">
                      {hit.path ||
                        (hit.categories_without_path || []).map(category => (
                          <> {category}/</>
                        ))}
                    </div>
                  </a>
                  {/* <a
                    role="link"
                    onClick={() => refine(hit.name)}
                    onKeyPress={() => refine(hit.name)}
                    className="end-xs w-25 fs-14 c-black-light"
                  ></a> */}
                </div>
              </li>
            );
          })}
        </ul>
      </If>
    </>
  );
}
