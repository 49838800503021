import React, { useState, useRef, useEffect } from 'react';
import MuteIcon from 'assets/icons/mute.png';
import UnmuteIcon from 'assets/icons/unmute.png';
import PlayIcon from 'assets/icons/play.png';
import { BrowserView, MobileView } from 'react-device-detect';
import { getNavigationBasePath } from 'utils/index';

export default ({ widgetData: widgetDataAll, onWidgetClick, rowType }) => {
  const [isClickAbleVdoBanner, setIsClickAbleVdoBanner] = useState(false);
  const videoRef = useRef();
  const [mute, setMute] = useState(true);
  const [showPlay, setShowPlay] = useState(true);

  const [widgetData = {}] = widgetDataAll;

  const handleMute = () => {
    if (mute) setMute(false);
    else setMute(true);
  };

  const handlePlay = () => {
    setShowPlay(false);
    if (videoRef && videoRef.current) videoRef.current.click();
  };

  const doWidgetClick = (event, slide) => {
    event.preventDefault();
    onWidgetClick({ event, widget: slide, widgetIndex: 0 });
  };

  const doCtrlClick = event => {
    if (event.target.paused) {
      event.target.play();
    } else {
      event.target.pause();
      setShowPlay(true);
    }
  };

  useEffect(() => {
    if (widgetData.widgetType === 'UploadedVideo' && rowType === 'VideoGrid') {
      setIsClickAbleVdoBanner(true);
      setShowPlay(false);
    }
  }, [widgetData, rowType]);

  return (
    <If condition={widgetData.videos && widgetData.videos.length > 0}>
      <div className="pos-relt">
        <a
          role="button"
          onKeyDown={event => {
            if (isClickAbleVdoBanner) {
              doWidgetClick(event, widgetData);
            } else {
              doCtrlClick(event);
            }
          }}
          onClick={event => {
            if (isClickAbleVdoBanner) {
              doWidgetClick(event, widgetData);
            } else {
              doCtrlClick(event);
            }
          }}
          href={`${getNavigationBasePath()}/${widgetData.link}`}
        >
          <video
            ref={videoRef}
            playsinline
            style={{
              width: '100%',
              height: '100%'
            }}
            autoPlay={isClickAbleVdoBanner ?? true}
            muted={mute}
            loop
            src={widgetData.videos[0].url}
          >
            <track src="" kind="captions" srcLang="" label="" />
          </video>
        </a>
        <MobileView>
          <div
            className="pos-abs end-0"
            style={{ zIndex: '1', bottom: '24px' }}
          >
            <a role="button" onClick={handleMute} onKeyPress={handleMute}>
              <If condition={mute}>
                <img src={MuteIcon} alt="" className="w-25 mr-10 r-5" />
              </If>
              <If condition={!mute}>
                <img src={UnmuteIcon} alt="" className="w-25 mr-10 r-5" />
              </If>
            </a>
          </div>

          <If condition={showPlay}>
            <div
              className="pos-abs end-0 flex middle-xs"
              style={{
                zIndex: '1',
                bottom: '24px',
                right: '152px',
                top: '0px'
              }}
            >
              <a role="button" onClick={handlePlay} onKeyPress={handlePlay}>
                <img src={PlayIcon} alt="" className="w-60 mr-10 r-5" />
              </a>
            </div>
          </If>
        </MobileView>
        <BrowserView>
          <div
            className="pos-abs end-0"
            style={{ zIndex: '1', bottom: '81px', right: '-45px' }}
          >
            <a role="button" onClick={handleMute} onKeyPress={handleMute}>
              <If condition={mute}>
                <img src={MuteIcon} alt="" className="w-25 mr-40 pr-20 r-5" />
              </If>
              <If condition={!mute}>
                <img src={UnmuteIcon} alt="" className="w-25 mr-40 pr-20 r-5" />
              </If>
            </a>
          </div>
        </BrowserView>
      </div>
    </If>
  );
};
