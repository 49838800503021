import React, { useEffect, useState } from 'react';
import './index.scss';
import classnames from 'classnames';
import { path } from 'utils/';
import { useSwipeable } from 'react-swipeable';
import { isBrowser } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CURRENT_LANG_PREFIX } from '../../../../constants';

export default ({ widgetData, bottomSpace, onWidgetClick = () => {} }) => {
  const [fWidgetData, setfWidgetData] = useState([]);
  const [focuedSlide, setFocuedSlide] = useState(isBrowser ? 3 : 2);
  const [noAnim, setNoAnim] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  useEffect(() => {
    if (widgetData && widgetData.length > 0) {
      const newWData = Object.create(widgetData);
      newWData.unshift(widgetData[widgetData.length - 1]);
      newWData.unshift(widgetData[widgetData.length - 2]);
      newWData.push(widgetData[0]);
      newWData.push(widgetData[1]);
      setfWidgetData(newWData);
    }
  }, [widgetData]);

  const [sliderWidth, setSliderWidth] = useState();
  // const [sliderHeight, setSliderHeight] = useState();
  const [slideWidth, setSlideWidth] = useState();
  const [sideSlideWidth, setSideSlideWidth] = useState();
  const [focusSlideWidth, setFocusSlideWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      const wFactor = isBrowser ? ref.clientWidth / 9 : ref.clientWidth / 4;
      setSlideWidth(wFactor);
      setSideSlideWidth(wFactor * 2);
      setFocusSlideWidth(isBrowser ? wFactor * 3 : wFactor * 2);
      setSliderWidth(slideWidth * fWidgetData.length + slideWidth * 4);
      // setSliderHeight(ref.clientHeight || '');
    }
  };

  const [sliderShift, setSliderShift] = useState();
  const doOnSwipe = targetDirection => {
    if (inProgress) return;
    setInProgress(true);
    let targetIndex = 0;
    if (targetDirection === 'NEXT') {
      targetIndex = focuedSlide + 1;
    } else if (targetDirection === 'PREV') {
      targetIndex = focuedSlide - 1;
    }
    const slideShiftFactor = isBrowser ? 2 : 1;
    setFocuedSlide(targetIndex);
    setSliderShift((targetIndex - slideShiftFactor) * slideWidth * -1);
    setTimeout(function() {
      if (targetIndex === 1) {
        setNoAnim(true);
        targetIndex = fWidgetData.length - 3;
        setFocuedSlide(targetIndex);
        setSliderShift((targetIndex - slideShiftFactor) * slideWidth * -1);
        setNoAnim(false);
      } else if (targetIndex === fWidgetData.length - 2) {
        setNoAnim(true);
        targetIndex = 2;
        setFocuedSlide(targetIndex);
        setSliderShift((targetIndex - slideShiftFactor) * slideWidth * -1);
        setNoAnim(false);
      }

      setInProgress(false);
    }, 300);
  };

  const doOnClick = (event, slide, widgetIndex) => {
    event.preventDefault();
    if (widgetIndex === focuedSlide)
      onWidgetClick({ event, widget: slide, widgetIndex });
    else if (widgetIndex < focuedSlide) doOnSwipe('PREV');
    else if (widgetIndex > focuedSlide) doOnSwipe('NEXT');
  };

  useEffect(() => {
    setSliderShift(slideWidth * -1);
  }, [fWidgetData]);

  const handlers = useSwipeable({
    onSwipedLeft: () => doOnSwipe('NEXT'),
    onSwipedRight: () => doOnSwipe('PREV'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const {
    imageMedia: [
      { dimensions: { width: iImgWidth, height: iImgHeight } = {} } = {}
    ] = []
  } =
    fWidgetData && fWidgetData.length && typeof fWidgetData[0] === 'undefined';

  return (
    <div className="" style={{ paddingBottom: bottomSpace }} {...handlers}>
      <div className={classnames('focus-slider', { _anim: !noAnim })}>
        <div ref={handelRef}>
          <div
            className="_slides"
            style={{
              width: sliderWidth,
              marginLeft: sliderShift
              // height: sliderHeight
            }}
          >
            <If condition={fWidgetData.length > 3}>
              {fWidgetData
                .filter(slide => path(['imageMedia', [0], 'url'], slide))
                .map((slide, widgetIndex) => {
                  const { imageMedia: [{ url: imgURL } = {}] = [] } = slide;
                  let slideImgWidth = slideWidth;
                  const iSlideImgHeight =
                    iImgHeight &&
                    focusSlideWidth &&
                    (iImgHeight / iImgWidth) * focusSlideWidth;
                  let slideImgHeight = isBrowser
                    ? iSlideImgHeight / 2
                    : iSlideImgHeight;
                  if (focuedSlide === widgetIndex) {
                    slideImgWidth = focusSlideWidth;
                    slideImgHeight = iSlideImgHeight;
                  } else if (
                    isBrowser &&
                    (focuedSlide - 1 === widgetIndex ||
                      focuedSlide + 1 === widgetIndex)
                  ) {
                    slideImgWidth = sideSlideWidth;
                    slideImgHeight = iSlideImgHeight;
                  }
                  return (
                    <If condition={imgURL}>
                      <div
                        className={classnames('_slide', {
                          _focused: focuedSlide === widgetIndex
                        })}
                        style={{ width: slideImgWidth }}
                      >
                        <a
                          href={`/${CURRENT_LANG_PREFIX}/${slide.mlink ||
                            slide.link}`}
                          onClick={event =>
                            doOnClick(event, slide, widgetIndex)
                          }
                          style={{
                            height: slideImgHeight,
                            padding:
                              !isBrowser && focuedSlide !== widgetIndex
                                ? '30% 0'
                                : ''
                          }}
                        >
                          <LazyLoadImage
                            src={imgURL}
                            alt={slide.name}
                            className={classnames(
                              'w100p d-block',
                              isBrowser &&
                                (focuedSlide === widgetIndex ||
                                  focuedSlide - 1 === widgetIndex ||
                                  focuedSlide + 1 === widgetIndex)
                                ? 'odj-fit-contain'
                                : 'odj-fit-cover',
                              { 'pl-10 pr-10 b-box': isBrowser }
                            )}
                          />
                        </a>
                      </div>
                    </If>
                  );
                })}
            </If>
          </div>
        </div>
        <ul className="pagination-ctrl">
          {fWidgetData
            .filter(slide => path(['imageMedia', [0], 'url'], slide))
            .map((slide, widgetIndex) => {
              return (
                <If
                  condition={
                    widgetIndex > 1 && widgetIndex < fWidgetData.length - 2
                  }
                >
                  <li
                    className={classnames('_slide', {
                      _active: focuedSlide === widgetIndex
                    })}
                  >
                    {widgetIndex}
                  </li>
                </If>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
