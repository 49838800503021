import React from 'react';
import { useStateValue } from 'context/app';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import TopBarIcon1 from 'assets/icons/top-bar-icon-1.svg';
import TopBarIcon2 from 'assets/icons/top-bar-icon-2.svg';
import TopBarIcon3 from 'assets/icons/top-bar-icon-3.svg';
import PhWhite from 'assets/icons/ph-white.svg';
import { COUNTRY, PHONE_CODE } from 'constants/index';
import { translate } from 'constants/language';
import LangSwitch from 'components/libs/LangSwitch';
import CountrySwitch from 'components/libs/CountrySwitch';
import classNames from 'classnames';

export default function({ ...props }) {
  const {
    account: [accountState]
  } = useStateValue();
  const { config = {} } = accountState;
  const countryCode = PHONE_CODE[COUNTRY.toUpperCase()];

  return (
    <div className="bg-black-light c-white mh-44" {...props}>
      <ContainerDesktop>
        <div className="flex middle-xs">
          <div className="ml-0 mr-auto flex middle-xs pt-10 pb-10">
            <div className="flex middle-xs">
              <TopBarIcon1 className="h-20" />
              <div className="fs-14 lh-18 ls-064 pl-r-10">
                {translate("100's of new styles everyday")}
              </div>
            </div>
            <div className="flex middle-xs ml-20 mr-20">
              <TopBarIcon2 className="h-20" />
              <div className="fs-14 lh-18 ls-064 pl-r-10">
                {translate('Express shipping')}
              </div>
            </div>
            <div className="flex middle-xs">
              <TopBarIcon3 className="h-20" />
              <div className="fs-14 lh-18 ls-064 pl-r-10">
                {translate('Free and easy returns')}
              </div>
            </div>
          </div>
          <div className="mr-0 ml-auto flex middle-xs pt-10 pb-10">
            <If
              condition={
                config &&
                config.contactUs &&
                config.contactUs[COUNTRY] &&
                config.contactUs[COUNTRY].phone
              }
            >
              <a
                className="flex middle-xs c-white"
                href={`tel:${config.contactUs[COUNTRY].phone ||
                  config.contactUs.phone}`}
                rel="noopener noreferrer"
              >
                <PhWhite className="h-24" />
                <div className="fs-14 lh-18 ls-064 pl-r-10 en-font forced-ltr">
                  {countryCode}{' '}
                  {config.contactUs[COUNTRY].phone || config.contactUs.phone}
                </div>
              </a>
            </If>
            <CountrySwitch
              isDropDown={true}
              classes={classNames({
                'ml-30 mr-20': COUNTRY.toLowerCase() !== 'in'
              })}
            />
            <If condition={COUNTRY.toLowerCase() !== 'in'}>
              <div className="fs-14 lh-18 ls-064 h-24">
                <LangSwitch />
              </div>
            </If>
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
