import React from 'react';
import classnames from 'classnames';
import Modal from 'react-modal';
import { isBrowser, isMobile } from 'react-device-detect';
import CloseIcon from 'assets/icons/icon-cancel-black.svg';
import './index.scss';
import { IS_ARABIC } from 'constants/index';

const CLOSE_TIMEOUT = 500;

export default function ReactSlidingPane({
  isOpen = false,
  title,
  timeOut = CLOSE_TIMEOUT,
  // subtitle,
  onRequestClose,
  onAfterOpen,
  children,
  className = '',
  overlayClassName,
  // closeIcon,
  from = 'right',
  width,
  height,
  style = {},
  contentClassName = '',
  childrenWrapClassname = '',
  setContainerRef,
  showCloseButton,
  // hasHeader = true,
  isCenter = false,
  fullHeight = false,
  headerTitle,
  isLogin = false
}) {
  const directionClass = `slide-pane_from_${
    !isCenter && isBrowser ? 'right' : from
  }`;

  const handleSetPageRef = ref => {
    if (ref && setContainerRef) {
      setContainerRef(ref);
    }
  };
  const browserClass =
    !isCenter && isBrowser ? { margin: '0 0 0 auto', maxHeight: '100%' } : {};
  return (
    <Modal
      className={classnames('slide-pane', directionClass, className, {
        'br-6': className.indexOf('br-') === -1 && isBrowser,
        'br-t-6': className.indexOf('br-') === -1 && isMobile
      })}
      style={{
        content: {
          width: width || '80%',
          height: height || 'auto',
          ...browserClass,
          ...style
        }
      }}
      overlayClassName={`slide-pane__overlay ${overlayClassName || ''} ${
        height && !isLogin ? '' : 'align-modal-center'
      } ${isLogin ? 'login-align-modal-center' : ''}`}
      closeTimeoutMS={timeOut}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      contentLabel={`Modal "${title || ''}"`}
    >
      {/* <div className="slide-pane__header">
        <div className="slide-pane__close" onKeyPress={onRequestClose} onClick={onRequestClose}>
          {closeIcon ? (
            closeIcon
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 22">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4 11l8 8c.6.5.6 1.5 0 2-.5.6-1.5.6-2 0l-9-9c-.6-.5-.6-1.5 0-2l9-9c.5-.6 1.5-.6 2 0 .6.5.6 1.5 0 2l-8 8z"
              />
            </svg>
          )}
        </div>
        <div className="slide-pane__title-wrapper">
          <h2 className="slide-pane__title">{title}</h2>
          <div className="slide-pane__subtitle">{subtitle}</div>
        </div>
      </div> */}
      <div
        className={classnames(contentClassName, 'slide-pane__content', {
          'p-56': isBrowser && !isCenter && !fullHeight
        })}
        ref={handleSetPageRef}
      >
        <If condition={showCloseButton}>
          <div className="flex middle-xs pos-relt bb-white-badge pb-16 pt-16 pl-15 pr-15">
            <div
              className={classnames(
                'fs-16 ls-05 w100p c-main-black fw-6 flex',
                {
                  'center-xs': isCenter
                }
              )}
            >
              {headerTitle}
            </div>

            <a
              className={classnames('mr-0 ml-auto pos-abs top-0 p-10', {
                'r-0': !IS_ARABIC,
                'l-0': IS_ARABIC
              })}
              role="button"
              onClick={onRequestClose}
              onKeyDown={onRequestClose}
            >
              <CloseIcon className="d-block" />
            </a>
          </div>
        </If>
        <div
          className={classnames(childrenWrapClassname, 'pos-relt z-1', {
            'h100vh b-box pt-56 pl-56 pr-56 pb-32': fullHeight
          })}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
}
