import { isBrowser } from 'react-device-detect';
import axios from 'axios';

// const email = getEmail();
const axiosOptions = {
  timeout: 120000,
  headers: {
    'X-Source': isBrowser ? 'msite' : 'msite',
    'X-Client-Version':
      process.env.TAG_NAME || process.env.BRANCH || 'development'
  }
};
const axiosMobileInstance = axios.create(axiosOptions);

export default axiosMobileInstance;
