import React from 'react';
import { translate } from 'constants/language';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import { COUNTRY, CURRENT_LANG_PREFIX } from 'constants/index';

export default function({ vatInfo = {}, vatCountryInfo = {} }) {
  return (
    <div className="bg-light-gray ">
      <ContainerDesktop isUserPage={true}>
        <div className="pt-16 pb-16 flex middle-xs">
          <div className="ml-0 mr-auto flex middle-xs">
            <div className="fs-12 lh-16 ls-072 c-brown-gray ml-30 mr-30 flex middle-xs">
              <If
                condition={
                  vatInfo.enable && vatCountryInfo && vatCountryInfo.enable
                }
              >
                <div className="en-font pr-r-10">
                  {vatCountryInfo &&
                    vatCountryInfo.texts &&
                    vatCountryInfo?.texts?.map((vatcntry, index) => (
                      <a
                        className="c-soft-blue fw-5"
                        href={
                          vatcntry[CURRENT_LANG_PREFIX] &&
                          vatcntry[CURRENT_LANG_PREFIX].url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        {vatcntry[CURRENT_LANG_PREFIX] &&
                          vatcntry[CURRENT_LANG_PREFIX].label}{' '}
                        <If
                          condition={index !== vatCountryInfo?.texts.length - 1}
                        >
                          <span className="c-brown-gray fw-5">|</span>
                        </If>
                      </a>
                    ))}
                </div>
              </If>
              <div className="en-font">
                {translate('©2021 Retail World Limited.')}
              </div>
              <div>&nbsp;</div>
              <div>{translate('All rights reserved')}</div>
            </div>
          </div>
          <div className="mr-0 ml-auto flex middle-xs fs-14 lh-18 ls-064">
            <a
              className="ml-15 c-dark-gray"
              role="button"
              href={`/${COUNTRY}/${CURRENT_LANG_PREFIX}/account/faq`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('FAQs')}
            </a>
            <a
              className="ml-15 c-dark-gray"
              role="button"
              href={`/${COUNTRY}/${CURRENT_LANG_PREFIX}/account/terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('Terms and Conditions')}
            </a>
            <a
              className="ml-15 c-dark-gray"
              role="button"
              href={`/${COUNTRY}/${CURRENT_LANG_PREFIX}/account/privacy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('Privacy Policy')}
            </a>
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
