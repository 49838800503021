import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useStateValue } from 'context/app';
import { getNavigationBasePath } from 'utils';
import { intervalToDuration } from 'date-fns';
import Img from 'components/libs/Img';
import { getHomePage } from '../../actions';
import DigitBlock from './digitBlock';

export default ({
  saleTimer,
  isTimeUp,
  now,
  doOnClick = () => {},
  widgetData = []
}) => {
  const endDate = saleTimer && saleTimer.endTime && new Date(saleTimer.endTime);
  const {
    home: [, dispatch]
  } = useStateValue();

  useEffect(() => {
    if (isTimeUp) {
      getHomePage({}, dispatch);
    }
  }, [isTimeUp]);

  const { days, hours, minutes, seconds } = intervalToDuration({
    start: now,
    end: endDate || now
  });
  return (
    <If condition={!isTimeUp && saleTimer}>
      <div
        className="center-xs"
        style={{ background: `${saleTimer.widgetBackgroundColor}` }}
      >
        <a
          href={`${getNavigationBasePath()}/${saleTimer.link}`}
          onClick={event =>
            doOnClick(event, {
              link: `${saleTimer.link}`,
              target: `${saleTimer.target}`
            })
          }
          onKeyPress={event =>
            doOnClick(event, {
              link: `${saleTimer.link}`,
              target: `${saleTimer.target}`
            })
          }
          className="flex middle-xs pt-5 pb-5 pl-15 pr-15"
        >
          <If
            condition={
              widgetData &&
              widgetData.imageMedia &&
              widgetData.imageMedia[0] &&
              widgetData.imageMedia[0].url
            }
          >
            <div className="ml-0 mr-auto">
              <Img
                src={widgetData.imageMedia[0].url}
                className="d-block mx-h-40 w-auto"
              />
            </div>
          </If>
          <div className="flex middle-xs mr-0 ml-auto">
            <If condition={saleTimer.title}>
              <div
                className={classnames(
                  `flex center-xs pl-4 pr-4 fs-10 fw-6 ls-04 cap br-2 mr-r-10 br-1`
                )}
                style={{
                  color: `${saleTimer.titleColor}`,
                  background: `${saleTimer.titleBackground}`
                }}
              >
                {saleTimer.title}
              </div>
            </If>
            <div className="flex middle-xs forced-ltr en-font">
              <If condition={days > 0}>
                <DigitBlock
                  saleTimer={saleTimer}
                  label="DAYS"
                  digitSet={days}
                  showColons={true}
                />
              </If>
              <DigitBlock
                saleTimer={saleTimer}
                label="HR"
                digitSet={hours}
                showColons={true}
              />
              <DigitBlock
                saleTimer={saleTimer}
                label="MIN"
                digitSet={minutes}
                showColons={days <= 0}
              />
              <If condition={days <= 0}>
                <DigitBlock
                  saleTimer={saleTimer}
                  label="SEC"
                  digitSet={seconds}
                />
              </If>
            </div>
          </div>
        </a>
      </div>
    </If>
  );
};
