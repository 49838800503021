import React from 'react';
import classnames from 'classnames';

export default function({ icon, label, value, className, ...props }) {
  return (
    <div className={classnames('flex middle-xs', className)} {...props}>
      <div className="pr-r-15">{icon}</div>
      <div>
        <div className="fs-12 lh-16 ls-072 c-dark-gray">{label}</div>
        <div className="fs-16 lh-20 ls-072 c-dark-gray mt-4 en-font">
          {value}
        </div>
      </div>
    </div>
  );
}
