import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { isBefore } from 'date-fns';
// import LazyLoadComponent from 'react-intersection-observer-lazy-load';
// import { TransitionGroup } from 'react-transition-group';
import './index.scss';
import classnames from 'classnames';
import CountDownTimer from 'components/libs/CountDownTimer';
import trim from 'lodash.trim';
import { logEvent } from 'utils/analytics';
import {
  BANNER_CAROUSEL,
  GRID,
  WIDGET_FREE_SHIPPING,
  WIDGET_IMAGE_TILE,
  PRODUCT_GRID,
  CATEGORY_CAROUSEL,
  WIDGET_VIDEO,
  PRODUCT_CAROUSEL,
  FOCUS_CAROUSEL,
  IMAGE_CAROUSEL,
  RECENTLY_VIEWED,
  VIDEO_GRID,
  MULTI_TAB_PRODUCT,
  AUTO_CAROUSEL,
  FOCUS_CAROUSEL_NEW
} from 'constants/home';

import { resetProductState } from 'containers/ProductList/actions';
import { useStateValue } from 'context/app';
import { path } from 'utils/';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import Ticker from '../Ticker';
import BannerCarousel from '../BannerCarousel';
import BannerGridCarousel from '../BannerGridCarousel';
import RowTitle from '../RowTitle';
import ViewMoreBar from '../ViewMoreBar';
import FreeShipping from '../FreeShipping';
import ImageTileRow from '../ImageTileRow';
import CategoryCarousel from '../CategoryCarousel';
import ProdCarousel from '../ProdCarousel';
import ImageCarousel from '../ImageCarousel';
import RecentlyViewed from '../RecentlyViewed';
import Video from '../Video';
import { CURRENT_LANG_PREFIX } from '../../../../constants';
import FocusCarousel from '../FocusCarousel';
import { setPromoNameInHomeState } from '../../actions';
import VideoGrid from '../VideoGrid';
import MultiTabProductWidget from '../MultiTabProductWidget';

export default ({
  widgetRef,
  rowType = '',
  rowTitle = '',
  iWidgetData = [],
  products = [],
  viewMore = {},
  analytics: analyticsData = {},
  borderBottom = false,
  columnCountMob = 1,
  columnCountTab = 1,
  columnCountDesktop = 1,
  cloumnStyling,
  horizontalSpacing = 8,
  bottomSpacing = 8,
  titleCenterAlign = true,
  itemTextCenterAlign = false,
  slideShowTime = 0,
  scheduleInfo = {},
  saleTimer = {},
  isPLPBanner = false,
  selectedTabTextColor = '',
  selectedTabBorderColor = '',
  nonSelectedTabBorderColor = '',
  selectedTabBackgroundColor = '',
  showItemCount = false,
  id = ''
}) => {
  const history = useHistory();
  const {
    products: [, productDispatch],
    account: [accountState],
    home: [, homeDispatch]
  } = useStateValue();

  const recentlyViewedConfig = path(
    ['config', 'recommendSys', 'components', 'recentlyViewed'],
    accountState
  );

  // const recentlyViewedTitle =
  //   rowTitle ||
  //   path(['title', [CURRENT_LANG_PREFIX]], recentlyViewedConfig || {});

  const BGColor = path(['backgroundColor'], cloumnStyling);
  const sideSpace =
    cloumnStyling &&
    cloumnStyling.horizontalSpacing !== null &&
    cloumnStyling.horizontalSpacing >= 0
      ? cloumnStyling.horizontalSpacing
      : horizontalSpacing;
  const bottomSpace =
    cloumnStyling &&
    cloumnStyling.bottomSpacing !== null &&
    cloumnStyling.bottomSpacing >= 0
      ? cloumnStyling.bottomSpacing
      : bottomSpacing;
  const aspectRatio = path(['aspectRatio'], cloumnStyling);

  const handleWidgetClick = ({ event, widget, widgetIndex = 0 }) => {
    const { link, mlink, contextName, contextLink, target } = widget;

    event.preventDefault();
    if ((link || contextName) && window.location.href.indexOf(link) === -1) {
      if (widget.categoryInfo) {
        resetProductState(
          {
            category: {
              ...widget.categoryInfo,
              name: widget.targetTitle,
              magentoPathUrl: mlink || link
            }
          },
          productDispatch
        );
      }

      if (analyticsData) {
        logEvent({
          eventName: 'select_content',
          data: {
            promotions: [
              {
                id: analyticsData._id,
                name: analyticsData.promoName,
                creative_name: widget.name,
                creative_slot: widgetIndex
              }
            ]
          },
          screenName: 'home'
        });
        // REQ3: Select Promotion
        logEvent({
          eventName: 'select_promotion',
          data: {
            creative_name: widget.name,
            creative_slot: widgetIndex,
            promotion_id: analyticsData._id,
            promotion_name: analyticsData.promoName,
            deduplication_id: null
          },
          screenName: 'home'
        });
        setPromoNameInHomeState(analyticsData.promoName, homeDispatch);
      }

      if (target && target === 'App_Deeplink') {
        try {
          const appLink = link && trim(link).split('|');
          window.location.href =
            appLink && appLink.length > 1 ? appLink[0] : '';
        } catch (e) {
          console.error(e);
        }
      } else {
        history.push(`/${CURRENT_LANG_PREFIX}/${mlink || contextLink || link}`);
        window.scrollTo(0, 0);
      }
    }
  };
  const [widgetData, setWidgetData] = useState(iWidgetData);
  useEffect(() => {
    if (isBrowser && iWidgetData) {
      const newWidgetData = [];
      iWidgetData.forEach(widgetItem => {
        const newWidgetItem = widgetItem;
        if (
          widgetItem.imageMediaDesktop &&
          widgetItem.imageMediaDesktop.length
        ) {
          newWidgetItem.imageMedia = widgetItem.imageMediaDesktop;
        }
        newWidgetData.push(newWidgetItem);
      });
      setWidgetData(newWidgetData);
    } else {
      setWidgetData(iWidgetData);
    }
  }, [iWidgetData]);

  const [now, setNow] = useState(new Date());
  const [isTimeUp, setIsTimeUp] = useState(false);
  const endDate = saleTimer && saleTimer.endTime && new Date(saleTimer.endTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    if (endDate) {
      const timeup = isBefore(endDate - 59000, now);
      setIsTimeUp(timeup);
    }
    return () => {
      clearInterval(interval);
    };
  }, [saleTimer && endDate]);

  return (
    <div
      style={{
        paddingLeft:
          rowType !== CATEGORY_CAROUSEL &&
          rowType !== BANNER_CAROUSEL &&
          rowType !== MULTI_TAB_PRODUCT
            ? sideSpace
            : '',
        paddingRight:
          rowType !== CATEGORY_CAROUSEL &&
          rowType !== BANNER_CAROUSEL &&
          rowType !== MULTI_TAB_PRODUCT
            ? sideSpace
            : '',
        backgroundColor: BGColor,
        paddingTop: BGColor && rowType !== CATEGORY_CAROUSEL ? bottomSpace : '',
        marginBottom: BGColor ? bottomSpace : '',
        display: `${
          scheduleInfo && scheduleInfo.scheduledTill < new Date().toISOString()
            ? 'none'
            : 'block'
        }`
      }}
      ref={widgetRef}
      className={classnames({
        'bg-white-three': rowType === CATEGORY_CAROUSEL,
        'overflow-hidden': rowType === GRID
      })}
    >
      <ContainerDesktop>
        <Choose>
          <When
            condition={
              scheduleInfo &&
              scheduleInfo.countDown &&
              scheduleInfo.countDown === true
            }
          >
            <CountDownTimer
              tillDate={scheduleInfo.scheduledTill}
              viewMoreObj={viewMore}
              onWidgetClick={handleWidgetClick}
              link={viewMore && (viewMore.contextLink || viewMore.link)}
              hasBG={false}
              pageType="HOME"
              id={id}
              flashImg={scheduleInfo.flashImg ? scheduleInfo.flashImg : {}}
              flashText={scheduleInfo.flashText}
              isSmallerTimer={scheduleInfo.smallerTimer}
            />
          </When>
          <Otherwise>
            <If
              condition={
                rowTitle.length > 0 &&
                rowType !== CATEGORY_CAROUSEL &&
                rowType !== RECENTLY_VIEWED
              }
            >
              <RowTitle
                title={rowTitle}
                txtColor={
                  rowType === PRODUCT_CAROUSEL && cloumnStyling
                    ? cloumnStyling.mainTitleColor
                    : ''
                }
                titleCenterAlign={titleCenterAlign}
              />
            </If>
          </Otherwise>
        </Choose>
        {/* <LazyLoadComponent> 
      <TransitionGroup
        key="1"
        transitionName="fade"
        transitionAppear
        transitionAppearTimeout={1000}
        transitionEnter={false}
        transitionLeave={false}
      > */}
        <Choose>
          <When condition={saleTimer && !isTimeUp}>
            <If
              condition={
                saleTimer.startTime &&
                isBefore(new Date(saleTimer.startTime), now) &&
                !isTimeUp
              }
            >
              <Ticker
                saleTimer={saleTimer}
                isTimeUp={isTimeUp}
                now={now}
                doOnClick={handleWidgetClick}
                widgetData={widgetData && widgetData.length && widgetData[0]}
              />
            </If>
          </When>
          <Otherwise>
            <Choose>
              <When
                condition={rowType === BANNER_CAROUSEL && widgetData.length}
              >
                <BannerCarousel
                  onWidgetClick={handleWidgetClick}
                  widgetData={widgetData}
                  analyticsData={analyticsData || {}}
                  aspectRatio={
                    (aspectRatio && aspectRatio.replace('AR_', '')) || '16x9'
                  }
                  bottomSpace={bottomSpace}
                  slideShowTime={slideShowTime}
                />
              </When>
              <When condition={rowType === AUTO_CAROUSEL && widgetData.length}>
                <BannerGridCarousel
                  onWidgetClick={handleWidgetClick}
                  cloumnStyling={cloumnStyling}
                  widgetData={widgetData}
                  analyticsData={analyticsData || {}}
                  aspectRatio={
                    (aspectRatio && aspectRatio.replace('AR_', '')) || '16x9'
                  }
                  bottomSpace={bottomSpace}
                  slideShowTime={slideShowTime || 0}
                />
              </When>
              <When
                condition={
                  (rowType === FOCUS_CAROUSEL ||
                    rowType === FOCUS_CAROUSEL_NEW) &&
                  widgetData.length
                }
              >
                <FocusCarousel
                  onWidgetClick={handleWidgetClick}
                  widgetData={widgetData}
                  analyticsData={analyticsData || {}}
                  aspectRatio={
                    (aspectRatio && aspectRatio.replace('AR_', '')) || '16x9'
                  }
                  bottomSpace={bottomSpace}
                />
              </When>
              <When condition={rowType === CATEGORY_CAROUSEL}>
                <CategoryCarousel
                  onWidgetClick={handleWidgetClick}
                  analyticsData={analyticsData || {}}
                  widgetData={widgetData}
                  rowTitle={rowTitle}
                  borderBottom={borderBottom}
                  bottomSpace={bottomSpace}
                />
              </When>
              <When condition={rowType === PRODUCT_CAROUSEL}>
                <ProdCarousel
                  analyticsData={analyticsData || {}}
                  widgetData={widgetData}
                  products={products}
                  rowType={rowType}
                  cloumnStyling={cloumnStyling}
                  bottomSpace={bottomSpace}
                  itemTextCenterAlign={itemTextCenterAlign}
                />
              </When>

              <When condition={rowType === IMAGE_CAROUSEL}>
                <ImageCarousel
                  onWidgetClick={handleWidgetClick}
                  analyticsData={analyticsData || {}}
                  widgetData={widgetData}
                  rowTitle={rowTitle}
                  borderBottom={borderBottom}
                  bottomSpace={bottomSpace}
                />
              </When>
              <When condition={rowType === RECENTLY_VIEWED}>
                <RecentlyViewed
                  recentlyViewedConfig={recentlyViewedConfig}
                  homeDispatch={homeDispatch}
                  recentlyViewedTitle={rowTitle}
                  widgetData={widgetData}
                />
              </When>
              <When condition={rowType === PRODUCT_GRID}>
                <ImageTileRow
                  onWidgetClick={handleWidgetClick}
                  analyticsData={analyticsData || {}}
                  products={products}
                  columnCountMob={columnCountMob}
                  columnCountTab={columnCountTab}
                  columnCountDesktop={columnCountDesktop}
                  cloumnStyling={cloumnStyling}
                  rowType={rowType}
                  bottomSpace={bottomSpace}
                  hasBGColor={!!BGColor}
                  itemTextCenterAlign={itemTextCenterAlign}
                />
              </When>
              <When condition={rowType === MULTI_TAB_PRODUCT}>
                <MultiTabProductWidget
                  analyticsData={analyticsData || {}}
                  widgetData={widgetData}
                  rowType={rowType}
                  columnCountMob={columnCountMob}
                  columnCountTab={columnCountTab}
                  columnCountDesktop={columnCountDesktop}
                  cloumnStyling={cloumnStyling}
                  bottomSpace={bottomSpace}
                  viewMore={viewMore}
                  itemTextCenterAlign={itemTextCenterAlign}
                  selectedTabTextColor={selectedTabTextColor}
                  nonSelectedTabBorderColor={nonSelectedTabBorderColor}
                  selectedTabBorderColor={selectedTabBorderColor}
                  selectedTabBackgroundColor={selectedTabBackgroundColor}
                  showItemCount={showItemCount}
                />
              </When>
              <When condition={!rowType || rowType === GRID}>
                <If condition={widgetData && widgetData.length}>
                  <If condition={widgetData[0].widgetType === WIDGET_VIDEO}>
                    <Video
                      widgetData={widgetData}
                      analyticsData={analyticsData || {}}
                      bottomSpace={bottomSpace}
                    />
                  </If>
                  <If
                    condition={
                      widgetData[0].widgetType === WIDGET_FREE_SHIPPING
                    }
                  >
                    <FreeShipping
                      widgetData={widgetData[0]}
                      analyticsData={analyticsData || {}}
                      bottomSpace={bottomSpace}
                    />
                  </If>

                  <If
                    condition={
                      !widgetData[0].widgetType ||
                      widgetData[0].widgetType === WIDGET_IMAGE_TILE
                    }
                  >
                    <ImageTileRow
                      onWidgetClick={handleWidgetClick}
                      analyticsData={analyticsData || {}}
                      widgetData={widgetData}
                      columnCountMob={columnCountMob}
                      columnCountTab={columnCountTab}
                      columnCountDesktop={columnCountDesktop}
                      cloumnStyling={cloumnStyling}
                      rowType={rowType}
                      bottomSpace={bottomSpace}
                      itemTextCenterAlign={itemTextCenterAlign}
                    />
                  </If>
                </If>
              </When>
              <When condition={rowType === VIDEO_GRID && widgetData.length}>
                <VideoGrid
                  rowType={rowType}
                  onWidgetClick={handleWidgetClick}
                  widgetData={widgetData}
                  analyticsData={analyticsData || {}}
                />
              </When>
            </Choose>
          </Otherwise>
        </Choose>

        {/*  </TransitionGroup>
     </LazyLoadComponent> */}
        <If
          condition={viewMore && !isPLPBanner && rowType !== MULTI_TAB_PRODUCT}
        >
          <ViewMoreBar
            viewMoreObj={viewMore}
            onWidgetClick={handleWidgetClick}
            text={viewMore.text}
            link={viewMore.contextLink || viewMore.link}
            analyticsData={analyticsData}
            bottomSpace={bottomSpace}
            txtColor={
              rowType === PRODUCT_CAROUSEL && cloumnStyling
                ? cloumnStyling.subTitleColor
                : ''
            }
          />
        </If>
        <If condition={borderBottom && rowType !== CATEGORY_CAROUSEL}>
          <hr
            className="m-0"
            style={{ marginBottom: !BGColor ? bottomSpace : '' }}
          />
        </If>
      </ContainerDesktop>
    </div>
  );
};
