import {
  ACTION_GET_PRODUCT_DETAILS,
  ACTION_GET_PRODUCT_DETAILS_FAILURE,
  ACTION_GET_PRODUCT_DETAILS_LOADING,
  ACTION_GET_BEAUTY_PRODUCT_INFO,
  ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE,
  ACTION_GET_BEAUTY_PRODUCT_INFO_LOADING,
  ACTION_GET_PROMOS,
  ACTION_GET_PROMOS_FAILURE,
  ACTION_GET_PROMOS_LOADING,
  ACTION_RESET_PRODUCT_DETAILS,
  ACTION_SET_PRODUCT_CAT_INFO,
  ACTION_FETCH_ALSO_BOUGHT_LOADING,
  ACTION_FETCH_ALSO_BOUGHT_LOADED,
  ACTION_FETCH_ALSO_BOUGHT_ERROR,
  ACTION_FETCH_YOU_MAY_LIKE_LOADING,
  ACTION_FETCH_YOU_MAY_LIKE_LOADED,
  ACTION_FETCH_YOU_MAY_LIKE_ERROR,
  ACTION_SIZECHART_LOADING,
  ACTION_SIZECHART_ERROR,
  ACTION_SIZECHART_FETCHED,
  ACTION_FETCH_ALSO_BOUGHT_MIX_LOADING,
  ACTION_FETCH_ALSO_BOUGHT_MIX_LOADED,
  ACTION_FETCH_ALSO_BOUGHT_MIX_ERROR,
  ACTION_FETCH_RECOM_PROD_LOADING,
  ACTION_FETCH_RECOM_PROD_LOADED,
  ACTION_FETCH_RECOM_PROD_ERROR,
  ACTION_GET_MODEL_INFO_LOADING,
  ACTION_GET_MODEL_INFO_LOADED,
  ACTION_GET_MODEL_INFO_ERROR,
  ACTION_GET_PDP_RATINGS_LOADING,
  ACTION_GET_PDP_RATINGS_LOADED,
  ACTION_GET_PDP_RATINGS_ERROR,
  ACTION_FETCH_YOU_MAY_LIKE_COUNT,
  ACTION_FETCH_YOU_MAY_LIKE_COUNT_ERROR,
  ACTION_FETCH_YOU_MAY_LIKE_COUNT_LOADING,
  ACTION_GET_FIT_INSIGHT_RATINGS_LOADED,
  ACTION_GET_FIT_INSIGHT_RATINGS_ERROR,
  ACTION_GET_FIT_INSIGHT_RATINGS_LOADING
} from './actions';

export const initialState = { isLoading: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_PRODUCT_DETAILS: {
      const {
        configProducts = [],
        partial,
        contextRuleId,
        query,
        caSource,
        caSourceType,
        caSourceValue,
        caBannerPromoName
      } = action.data || {};
      const isOutOfStock =
        !partial &&
        (!configProducts.length ||
          !configProducts.filter(product => Number(product.quantity) > 0)
            .length);
      return {
        ...state,
        isLoading: false,
        isOutOfStock,
        productDetail: action.data,
        loadMagentoPage: false,
        sizeChartData: undefined,
        caSource: caSource || state.caSource,
        caSourceType: caSourceType || state.caSourceType,
        caSourceValue:
          caSourceValue || contextRuleId || query || state.caSourceValue,
        caBannerPromoName: caBannerPromoName || state.caBannerPromoName
      };
    }
    case ACTION_RESET_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetail: undefined,
        caSource: undefined,
        caSourceType: undefined,
        caSourceValue: undefined,
        getPDPRatingsError: undefined,
        getPDPRatingsData: undefined
      };
    }

    case ACTION_SET_PRODUCT_CAT_INFO: {
      return {
        ...state,
        productCatInfo: action.data
      };
    }

    case ACTION_GET_PRODUCT_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        productDetailFailure: true,
        loadMagentoPage: action.data.loadMagentoPage
      };
    }
    case ACTION_GET_PRODUCT_DETAILS_LOADING: {
      return {
        ...state,
        loadMagentoPage: false,
        isLoading: true,
        productDetailFailure: false,
        getPDPRatingsError: undefined,
        getPDPRatingsData: undefined
      };
    }
    case ACTION_GET_BEAUTY_PRODUCT_INFO_LOADING: {
      return {
        ...state,
        isBeautyProdInfoLoading: true,
        beautyProdInfoFailure: false,
        beautyProdInfo: undefined
      };
    }
    case ACTION_GET_BEAUTY_PRODUCT_INFO: {
      const { productInfos = {} } = action.data || {};
      return {
        ...state,
        isBeautyProdInfoLoading: true,
        beautyProdInfoFailure: false,
        beautyProdInfo: productInfos
      };
    }
    case ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE: {
      return {
        ...state,
        isBeautyProdInfoLoading: false,
        beautyProdInfoFailure: true,
        beautyProdInfo: undefined
      };
    }
    case ACTION_GET_PROMOS: {
      return {
        ...state,
        ispromoLoading: false,
        promoData: action.data.list
      };
    }
    case ACTION_GET_PROMOS_FAILURE: {
      return {
        ...state,
        ispromoLoading: false,
        promoDataFailure: true,
        promoData: []
      };
    }
    case ACTION_GET_PROMOS_LOADING: {
      return {
        ...state,
        ispromoLoading: true,
        promoDataFailure: false
      };
    }

    case ACTION_FETCH_ALSO_BOUGHT_LOADING: {
      return {
        ...state,
        alsoViewedLoading: true,
        alsoViewedError: undefined,
        alsoViewedData: undefined
      };
    }
    case ACTION_FETCH_ALSO_BOUGHT_LOADED: {
      return {
        ...state,
        alsoViewedLoading: false,
        alsoViewedError: false,
        alsoViewedData: action.data
      };
    }
    case ACTION_FETCH_ALSO_BOUGHT_ERROR: {
      return {
        ...state,
        alsoViewedLoading: false,
        alsoViewedError: true,
        alsoViewedData: undefined
      };
    }

    case ACTION_FETCH_YOU_MAY_LIKE_LOADING: {
      return {
        ...state,
        youMayLikeLoading: true,
        youMayLikeError: undefined,
        youMayLikeData: undefined
      };
    }
    case ACTION_FETCH_YOU_MAY_LIKE_LOADED: {
      return {
        ...state,
        youMayLikeLoading: false,
        youMayLikeError: false,
        youMayLikeData: action.data
      };
    }
    case ACTION_FETCH_YOU_MAY_LIKE_COUNT: {
      const { recomCountData = {} } = state;
      const { recomCount, productSku } = action.data;
      recomCountData[productSku] = recomCount;
      return {
        ...state,
        youMayLikeCountLoading: false,
        recomCountData
      };
    }
    case ACTION_FETCH_YOU_MAY_LIKE_COUNT_LOADING: {
      return {
        ...state,
        youMayLikeCountLoading: true,
        youMayLikeCountError: false,
        recommendationCount: 0
      };
    }
    case ACTION_FETCH_YOU_MAY_LIKE_COUNT_ERROR: {
      return {
        ...state,
        youMayLikeCountLoading: false,
        youMayLikeCountError: true,
        recommendationCount: 0
      };
    }
    case ACTION_FETCH_YOU_MAY_LIKE_ERROR: {
      return {
        ...state,
        youMayLikeLoading: false,
        youMayLikeError: true,
        youMayLikeData: undefined
      };
    }

    case ACTION_FETCH_RECOM_PROD_LOADING: {
      const newRecomProdList = state.recomProdList || {};
      newRecomProdList[action.data.type] = {
        loading: true,
        error: undefined,
        productList: undefined,
        ...action.data
      };
      return {
        ...state,
        recomProdList: { ...newRecomProdList }
      };
    }
    case ACTION_FETCH_RECOM_PROD_LOADED: {
      const newRecomProdList = state.recomProdList || {};
      newRecomProdList[action.data.type] = {
        loading: false,
        error: false,
        ...action.data
      };
      return {
        ...state,
        recomProdList: { ...newRecomProdList }
      };
    }
    case ACTION_FETCH_RECOM_PROD_ERROR: {
      const newRecomProdList = state.recomProdList || {};
      newRecomProdList[action.data.type] = {
        loading: false,
        error: true,
        productList: undefined,
        ...action.data
      };
      return {
        ...state,
        recomProdList: { ...newRecomProdList }
      };
    }

    case ACTION_SIZECHART_LOADING: {
      return {
        ...state,
        sizeChartLoading: true,
        sizeChartData: undefined
      };
    }

    case ACTION_SIZECHART_ERROR: {
      return {
        ...state,
        sizeChartLoading: false,
        sizeChartData: undefined
      };
    }

    case ACTION_SIZECHART_FETCHED: {
      return {
        ...state,
        sizeChartLoading: false,
        sizeChartData: action.data
      };
    }

    case ACTION_FETCH_ALSO_BOUGHT_MIX_LOADING: {
      return {
        ...state,
        alsoViewedMixLoading: true,
        alsoViewedMixError: undefined,
        alsoViewedMixData: undefined
      };
    }
    case ACTION_FETCH_ALSO_BOUGHT_MIX_LOADED: {
      return {
        ...state,
        alsoViewedMixLoading: false,
        alsoViewedMixError: false,
        alsoViewedMixData: action.data
      };
    }
    case ACTION_FETCH_ALSO_BOUGHT_MIX_ERROR: {
      return {
        ...state,
        alsoViewedMixLoading: false,
        alsoViewedMixError: true,
        alsoViewedMixData: undefined
      };
    }
    case ACTION_GET_MODEL_INFO_LOADING: {
      return {
        ...state,
        getModelInfoLoading: true,
        getModelInfoError: undefined,
        getModelInfoData: undefined
      };
    }
    case ACTION_GET_MODEL_INFO_LOADED: {
      return {
        ...state,
        getModelInfoLoading: false,
        getModelInfoError: false,
        getModelInfoData: action.data
      };
    }
    case ACTION_GET_MODEL_INFO_ERROR: {
      return {
        ...state,
        getModelInfoLoading: false,
        getModelInfoError: true,
        getModelInfoData: undefined
      };
    }
    case ACTION_GET_PDP_RATINGS_LOADING: {
      return {
        ...state,
        getPDPRatingsLoading: true,
        getPDPRatingsError: undefined,
        getPDPRatingsData: undefined
      };
    }
    case ACTION_GET_PDP_RATINGS_LOADED: {
      return {
        ...state,
        getPDPRatingsLoading: false,
        getPDPRatingsError: false,
        getPDPRatingsData: action.data
      };
    }
    case ACTION_GET_PDP_RATINGS_ERROR: {
      return {
        ...state,
        getPDPRatingsLoading: false,
        getPDPRatingsError: true,
        getPDPRatingsData: undefined
      };
    }
    case ACTION_GET_FIT_INSIGHT_RATINGS_LOADING: {
      return {
        ...state,
        fitInsightRatingsLoading: true,
        fitInsightRatingsError: undefined,
        fitInsightRatingsData: undefined
      };
    }
    case ACTION_GET_FIT_INSIGHT_RATINGS_LOADED: {
      return {
        ...state,
        fitInsightRatingsLoading: false,
        fitInsightRatingsError: false,
        fitInsightRatingsData: action.data
      };
    }
    case ACTION_GET_FIT_INSIGHT_RATINGS_ERROR: {
      return {
        ...state,
        fitInsightRatingsLoading: false,
        fitInsightRatingsError: true,
        fitInsightRatingsData: undefined
      };
    }

    default:
      return state;
  }
};
