import React from 'react';
import classnames from 'classnames';
import { BASE_PATH, CURRENT_LANG_PREFIX, IS_ARABIC } from 'constants/index';
import { setDefaultLanguage } from 'utils/';

export default function LangSwitch({ ...props }) {
  const handleLanguageChange = () => {
    const lang = CURRENT_LANG_PREFIX === 'ar' ? 'en' : 'ar';
    setDefaultLanguage(lang, {});
    window.location.href = `${BASE_PATH}/${lang}/`;
  };
  return (
    <div
      onClick={handleLanguageChange}
      onKeyPress={handleLanguageChange}
      role="button"
      {...props}
      className={classnames(IS_ARABIC ? "en-font" : "ar-font")}
    >
      {IS_ARABIC ? 'English' : 'العربية'}
    </div>
  );
}
