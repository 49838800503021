import { IN_ENGLISH_STORE_ID } from 'constants/index';

export const isValidCreditCard = (str = '') => {
  const regexp = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
  return regexp.test(str.replace(/\s/g, ''));
};

export const isValidExpiryDate = (exp = '') => {
  const [month, year] = exp.split('/');
  return new Date(`20${year}-${month}-28`) > new Date();
};

export const isValidName = (name = '') => {
  const correct = /^[a-zA-Z ا-ي أ-ش أ-غ]{2,50}$/.test(name);
  return (correct && name.split(' ').length >= 2) || false;
};

export const isValidCvv = (cvv = '', cardType) => {
  return (
    Number(cvv) > 0 &&
    ((cardType === 'amex' && cvv.length === 4) ||
      (cardType !== 'amex' && cvv.length === 3))
  );
};

export const isValidPhone = (phone = '', storeId) => {
  const phoneNumber = String(Number(phone));
  if ([13, 12, 15, 17, 19, 21, IN_ENGLISH_STORE_ID].indexOf(storeId) > -1) {
    if (
      (storeId !== IN_ENGLISH_STORE_ID && phoneNumber.length > 9) ||
      phoneNumber < 1000000
    )
      return false;
    let regexp = /(^(?:[0]{1})?([0-9]{8})$)/;
    if (phoneNumber.substr(0, 1) === '0') {
      regexp = /(^(?:[0]{2})?([0-9]{9})$)/;
    }
    if (storeId === IN_ENGLISH_STORE_ID) {
      regexp = /(^(?:[0]{1})?([0-9]{10})$)/;
    }
    return regexp.test(phoneNumber);
  }
  if (phoneNumber.length > 10 || phoneNumber < 10000000) return false;
  let regexp = /(^(?:[0]{1})?([0-9]{9})$)/;
  if (phoneNumber.substr(0, 1) === '0') {
    regexp = /(^(?:[0]{2})?([0-9]{10})$)/;
  }
  return regexp.test(phoneNumber);
};

export const isValidEmail = (email = '') => {
  if (email.indexOf('—') > -1) return false;
  const regexp = /^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/; //eslint-disable-line
  const regexpApi = /^([a-zA-Z0-9_\-\.]+)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/; //eslint-disable-line
  return (
    regexp.test(String(email).toLowerCase()) &&
    regexpApi.test(String(email).toLowerCase())
  );
};

export const isValidPassword = (password = '') => {
  return password.length >= 6;
};

export const isValidFirstOrLastName = (name = '') => {
  const correct = /^[a-zA-Z0-9 ا-ي أ-ش أ-غ ]{0,50}$/.test(name);
  return correct;
};
