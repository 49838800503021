import React, { useEffect, useState } from 'react';

export default function TimerWithTiles({
  daysVal = 0,
  hoursVal = 0,
  minutesVal = 0,
  secondsVal = 0,
  isSmallerTimer = false
}) {
  const [hourArray, setHourArray] = useState([]);
  const [minuteArray, setMinuteArray] = useState([]);
  const [secondArray, setSecondArray] = useState([]);
  useEffect(() => {
    const hourVal =
      hoursVal + daysVal * 24 < 10
        ? String(hoursVal + daysVal * 24).padStart(2, '0')
        : hoursVal + daysVal * 24;
    const minuteVal = String(minutesVal).padStart(2, '0');
    const secondVal = String(secondsVal).padStart(2, '0');
    setHourArray(hourVal.toString().split(''));
    setMinuteArray(minuteVal.toString().split(''));
    setSecondArray(secondVal.toString().split(''));
  }, [daysVal, hoursVal, minutesVal, secondsVal]);

  const Tile = ({ children }) => {
    return (
      <div
        className={`w-18 h-18 br-4 c-white ta-c ${
          isSmallerTimer ? 'p-2' : 'p-3'
        } bg-black m-2 fs-14 fw-bold`}
      >
        {children}
      </div>
    );
  };

  return (
    <div className="flex c-black fw-5 middle-xs">
      {hourArray &&
        hourArray.length &&
        hourArray.map(item => <Tile>{item}</Tile>)}{' '}
      :{' '}
      {minuteArray &&
        minuteArray.length &&
        minuteArray.map(item => <Tile>{item}</Tile>)}{' '}
      :{' '}
      {secondArray &&
        secondArray.length &&
        secondArray.map(item => <Tile>{item}</Tile>)}
    </div>
  );
}
