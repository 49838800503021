import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import { useHistory } from 'react-router-dom';
import { getCurrentLanguage, getNavigationBasePath } from 'utils';
import { translate } from 'constants/language';
import SearchBar from 'components/SearchBar';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import LogoMix from 'assets/logo.svg';
import LogoEN from 'assets/logo-en.svg';
import BagIcon from 'assets/icons/bag-blk.svg';
import ProfileIcon from 'assets/icons/profile-blk.svg';
import HeartLine from 'assets/icons/wishlist-blk.svg';
import SearchIcon from 'assets/icons/search-icon.svg';
import LeftMenuDesktop from 'containers/Account/views/LeftMenuDesktop';
import { ARABIC_LANG, COUNTRY, CURRENT_LANG_PREFIX } from '../../constants';

const Logo = COUNTRY.toLowerCase() === 'in' ? LogoEN : LogoMix;

export default function({
  tabs = [],
  openSearchBar = false,
  categoryTabs = [],
  setActiveCategoryIndex = () => {},
  updateActiveTab = () => {},
  activeTab = 0,
  bagCount = 0,
  wishListCount = 0
}) {
  const [showSearchBar, setSearchBarVisible] = useState(openSearchBar);
  const handleSearchClick = () => {
    setSearchBarVisible(!showSearchBar);
  };
  const handleSearchBar = () => {
    setSearchBarVisible(!showSearchBar);
    if (handleSearchClick) handleSearchClick();
  };
  useEffect(() => {
    if (openSearchBar) {
      setSearchBarVisible(true);
    } else {
      setSearchBarVisible(false);
    }
  }, [openSearchBar]);
  const history = useHistory();
  const handleClick = (nativeEvent, href) => {
    nativeEvent.preventDefault();
    history.push(href);
    window.scrollTo(0, 0);
  };
  const isRTL = getCurrentLanguage() === ARABIC_LANG;
  return (
    <div className="header-main">
      <div className="bg-thumb-gray">
        <ContainerDesktop>
          <div className="flex middle-xs" style={{ minHeight: '56px' }}>
            <div className="mr-auto ml-0 flex middle-xs">
              <a
                className=""
                href={`${getNavigationBasePath()}/`}
                title={translate('Styli')}
              >
                <Logo
                  title="styli logo"
                  width="70px"
                  height="40px"
                  className="d-block"
                />
              </a>
              <div className="fs-16 lh-20 ls-048 c-dark-gray flex middle-xs ml-42 mr-42 fw-6 cap">
                {tabs.map((tab, index) => {
                  return (
                    <a
                      onClick={event => updateActiveTab(index, event)}
                      onKeyPress={event => updateActiveTab(index, event)}
                      role="button"
                      className={classnames('pl-15 pt-18 pr-15 pb-18', {
                        'bg-light-gray': activeTab === index
                      })}
                    >
                      <span>{translate(tab.title)}</span>
                      <If condition={!isEmpty(tab.tag)}>
                        <span
                          className="pl-3 pr-3 br-3 capitalize fw-5 mt-n-5 ml-r-4 lh-14 pull-right-r"
                          style={{
                            backgroundColor:
                              tab.tag.backgroundColor || '#ed6c90',
                            color: tab.tag.textColor || '#fdfcfc',
                            fontSize: tab.tag.textSize || '10px'
                          }}
                        >
                          {isRTL ? tab.tag.textAR : tab.tag.textEN}
                        </span>
                      </If>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="pos-relt ml-30 mr-30">
              <If condition={showSearchBar}>
                <SearchBar onBackClick={handleSearchBar} isExpand={true} />
                <div
                  onClick={handleSearchBar}
                  onKeyPress={handleSearchBar}
                  role="button"
                  className="screen-fixed bg-dark-gray z-100 o-35"
                >
                  &nbsp;
                </div>
              </If>
              <div
                className="flex middle-xs w-530 bg-white b-white-two"
                onClick={handleSearchBar}
                onKeyPress={handleSearchBar}
                role="button"
              >
                <div className="ml-r-15 mt-8 mb-8 h-24 w-24 flex middle-xs center-xs">
                  <SearchIcon className="d-block " />
                </div>
                <div className="fs-14 lh-18 ls-064 c-dark-gray ml-10 mr-10">
                  {translate('What are you looking for?')}
                </div>
              </div>
            </div>
            <div className="ml-0 mr-0 flex middle-xs">
              <div className="pos-relt hover-dropdown trans-3">
                <a>
                  <ProfileIcon className="h-30 w-30 d-block" />
                </a>
                <div
                  className={classnames(
                    'pos-abs top-0 z-1 pt-40 _child',
                    CURRENT_LANG_PREFIX === 'en' ? 'r-0 mr-n-8' : 'l-0 ml-n-8'
                  )}
                >
                  <div
                    className={classnames(
                      'caret pos-abs top-0 mt-n-12 mt-28',
                      CURRENT_LANG_PREFIX === 'en' ? 'r-0 mr-17' : 'l-0 ml-17'
                    )}
                  />
                  <LeftMenuDesktop isTopMenu={true} />
                </div>
              </div>
              <a
                href={`/${COUNTRY}/${CURRENT_LANG_PREFIX}/wishlist`}
                onClick={e =>
                  handleClick(e, `/${CURRENT_LANG_PREFIX}/wishlist`)
                }
                className="ml-30 mr-30 pos-relt"
              >
                <HeartLine className="h-30 w-30 d-block" />
                <If condition={wishListCount > 0}>
                  <span
                    className={classnames(
                      'en-font bg-pastel-red-two fs-10 fw-6 c-white br-50p h-16 w-16 ta-c pos-abs _badge top-0 bag-badge',
                      isRTL ? 'l-0' : 'r-0'
                    )}
                  >
                    {wishListCount}
                  </span>
                </If>
              </a>
              <a
                className="pos-relt"
                href={`/${COUNTRY}/${CURRENT_LANG_PREFIX}/checkout/cart`}
                onClick={e =>
                  handleClick(e, `/${CURRENT_LANG_PREFIX}/checkout/cart`)
                }
              >
                <BagIcon className="d-block" />
                <If condition={bagCount > 0}>
                  <span
                    className={classnames(
                      'en-font bg-pastel-red-two fs-10 fw-6 c-white br-50p h-16 w-16 ta-c pos-abs _badge top-0 bag-badge',
                      isRTL ? 'l-0' : 'r-0'
                    )}
                  >
                    {bagCount}
                  </span>
                </If>
              </a>
            </div>
          </div>
        </ContainerDesktop>
      </div>
      <div className="bg-light-gray bb-white-two" style={{ minHeight: '51px' }}>
        <ContainerDesktop>
          <div className="flex middle-xs overflow-auto-no-bar">
            {categoryTabs.map((tab, index) => {
              return (
                <div
                  className={classnames(
                    'fs-14 c-dark-gray ls-064 lh-18 pt-16 pb-16 pr-r-10 flex middle-xs',
                    { 'pl-r-10': index !== 0 }
                  )}
                  onClick={() => setActiveCategoryIndex(index)}
                  onKeyPress={() => setActiveCategoryIndex(index)}
                  role="button"
                >
                  {tab.displayName}
                  <If condition={tab.tagTextNew}>
                    <span
                      className="pl-3 pr-3 br-3 capitalize fw-5 ml-r-5 pull-right-r"
                      style={{
                        backgroundColor: tab.tagBackgroundColor || '#ed6c90',
                        color: tab.tagTextColor || '#fdfcfc',
                        fontSize: tab.tagTextSize || '10px'
                      }}
                    >
                      {tab.tagTextNew}
                    </span>
                  </If>
                </div>
              );
            })}
          </div>
        </ContainerDesktop>
      </div>
    </div>
  );
}
