import React from 'react';
import classnames from 'classnames';
import { CURRENT_LANG_PREFIX } from 'constants/index';
import { useHistory } from 'react-router-dom';

export default function ({ items, className, ...props }) {
  const history = useHistory();
  return (
    <div className={classnames("", className)} {...props}>
      {items && items.map(item => (
        <div
          className="fs-14 lh-24 ls-064 c-main-gray"
          role="button"
          onClick={() => history.push(`/${CURRENT_LANG_PREFIX}/${item.link}`)}
          onKeyPress={() => history.push(`/${CURRENT_LANG_PREFIX}/${item.link}`)}
          href={`/${CURRENT_LANG_PREFIX}/${item.link}`}
        >
          {item.title && item.title[CURRENT_LANG_PREFIX] || ""}
        </div>
      ))
      }
    </div>
  );
}