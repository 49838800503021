import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import animationData from 'assets/lottie/heart-animation.json';
import HeartFilled from 'assets/icons/heart-filled.svg';
import HeartLine from 'assets/icons/heart-o.svg';

export default ({
  width = 45,
  height = 7,
  doPlay = '',
  wishListItemId = -1,
  isPLP = false,
  wrapperClass = ''
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [animDir, sdetAnimDir] = useState(1);
  const [isFavL, setIsFavL] = useState(-1);
  const [animState, setAnimState] = useState({
    isStopped: true,
    isPaused: true
  });

  useEffect(() => {
    if (isFavL === -1) setIsFavL(wishListItemId);
  }, [wishListItemId]);

  useEffect(() => {
    if (doPlay === 'add' || doPlay === 'remove') {
      setAnimState({ isStopped: true, isPaused: true });
      setIsAnimating(true);

      if (doPlay === 'add') {
        setIsFavL(false);
        sdetAnimDir(1);
      } else if (doPlay === 'remove') {
        setIsFavL(true);
        sdetAnimDir(-1);
      }

      setAnimState({ isStopped: false, isPaused: false });
      setTimeout(() => {
        setIsFavL(!isFavL);
      }, 2000);
    }
  }, [doPlay]);

  const defaultOptions = {
    loop: true,
    renderer: 'svg',
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const eventListeners = [
    {
      eventName: 'loopComplete',
      callback: () => {
        setAnimState({ isStopped: true, isPaused: true });
        setIsAnimating(false);
      }
    }
  ];
  return (
    <div className={classnames(wrapperClass, 'pos-relt w-100 h-100')}>
      <div
        className={classnames(
          'h-100 w-100 flex middle-xs center-xs pos-abs l-0 t-0',
          {
            'o-0': isAnimating
          }
        )}
      >
        <Choose>
          <When condition={isFavL > 0}>
            <HeartFilled
              className=""
              width={isPLP ? '12px' : '20px'}
              height={isPLP ? '12px' : '20px'}
            />
          </When>
          <Otherwise>
            <HeartLine
              width={isPLP ? '12px' : '20px'}
              height={isPLP ? '12px' : '20px'}
            />
          </Otherwise>
        </Choose>
      </div>
      <div className={classnames({ 'o-0': !isAnimating })}>
        <Lottie
          options={defaultOptions}
          width={width}
          height={height}
          eventListeners={eventListeners}
          isStopped={animState.isStopped}
          isPaused={animState.isPaused}
          direction={animDir}
        />
      </div>
    </div>
  );
};
