import React from 'react';
import classnames from 'classnames';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import Img from 'components/libs/Img';
import ProdPrice from '../ProdPrice';

export default ({ productDetail, prodPrices, prodDiscount }) => {
  return (
    <div>
      <MobileView>
        <div className="z-2 pt-15 mt-37">
          <div className="row pr-8 pl-8 pt-10 bt-light">
            <div className="col-xs-10 pr-2 pl-2">
              <div className="flex between-xs middle-xs">
                <div className="fs-9 cap c-soft-blue fw-5">
                  <h2>{productDetail.brand}</h2>
                </div>
              </div>
              <div className="flex middle-xs mb-0">
                <div>
                  <div className="flex fs-14 lh-1_5">
                    <h1>{productDetail.name}</h1>
                  </div>
                </div>
                <div className="h-20 ml-auto" />
              </div>
              <div className="flex middle-xs mb-0">
                <div className="fs-18 lh-24 ls-032 mt-8 fw-6">
                  <ProdPrice
                    prodPrices={prodPrices}
                    productDetail={productDetail}
                    prodDiscount={prodDiscount}
                    isInLine={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-2 pl-16 pt-10">
              <Img
                className={classnames('d-block h-auto br-4 p-1')}
                src={productDetail?.imageUrl || ''}
                alt={productDetail.name}
                width="40px"
                height="auto"
              />
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div className="w-380 pt-16 mt-20">
          <div className="row pr-8 pl-8 pt-10 bt-light">
            <div className="col-xs-10 pr-2 pl-2">
              <div className="fs-11 lh-14 ls-08 cap c-soft-blue fw-5">
                <h2>{productDetail.brand}</h2>
              </div>

              <div className="fs-16 lh-20 ls-073 c-dark-gray mt-8">
                <h1>{productDetail.name}</h1>
              </div>
              <div className="fs-18 lh-24 ls-032 mt-8 fw-6">
                <ProdPrice
                  prodPrices={prodPrices}
                  productDetail={productDetail}
                  prodDiscount={prodDiscount}
                  isInLine={true}
                />
              </div>
            </div>
            <div className="col-xs-2 pl-16 pt-10">
              <Img
                className={classnames('d-block h-auto br-4 p-1')}
                src={productDetail?.imageUrl || ''}
                alt={productDetail.name}
                width={isBrowser ? '56px' : '40px'}
                height="auto"
              />
            </div>
            <hr className="mt-0 mb-2 h-2 ml-n-15 mr-n-15 c-dark-gray" />
          </div>
        </div>
      </BrowserView>
    </div>
  );
};
