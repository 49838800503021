import React from 'react';
import classnames from 'classnames';
import { logEvent } from 'utils/analytics';

import { isWhiteSpace, getGenderPreference } from 'utils/';
import { useHistory } from 'react-router-dom';
import CloseIcon from 'assets/icons/close.svg';
import BackIcon from 'assets/icons/arrow-back-blk.svg';
import SearchIcon from 'components/Header/magnifying-glass.svg';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { useStateValue } from 'context/app';
import { resetProductDetails } from 'containers/ProductDetail/actions';
import WishList from 'containers/ProductList/views/WishList';
import { CURRENT_LANG_PREFIX } from '../../../constants';
import { translate } from '../../../constants/language';
import SearchHits from '../VmSearchHits/index';

export default ({
  currentRefinement = '',
  searchDataLoading,
  refine,
  finalData,
  popularSearch = [],
  showPreSearch = true,
  popularSearchTitle = '',
  onBackClick = () => {}
}) => {
  const clearSrch = () => {
    refine('');
  };
  const history = useHistory();
  const handleSubmit = ({ nativeEvent }) => {
    const newRefinement = currentRefinement.trim();
    nativeEvent.preventDefault();
    if (!isWhiteSpace(currentRefinement)) {
      logEvent({
        eventName: 'search',
        data: {
          searchTerm: currentRefinement
        },
        skipGtm: true,
        screenName: 'Search_Listing'
      });
      history.push(
        `/${CURRENT_LANG_PREFIX}/catalogsearch/result?q=${newRefinement}&wildcard=1`
      );
      onBackClick();
    }
  };
  const lang = CURRENT_LANG_PREFIX;

  const {
    productDetail: [, dispatch]
  } = useStateValue();

  const handlePopularClick = context => {
    onBackClick();
    resetProductDetails({}, dispatch);

    if (context) {
      logEvent({
        eventName: 'search',
        data: {
          searchTerm: context
        },
        skipGtm: true,
        screenName: 'Search_Listing'
      });
      history.push(`/${lang}/list/${getGenderPreference()}/context/${context}`);
    }
  };
  return (
    <>
      <div className="search-bar flex pt-5 pb-5 pl-15 pr-15 middle-xs bg-white">
        <MobileView>
          <a
            className="flex flex-start"
            onClick={onBackClick}
            role="button"
            onKeyPress={onBackClick}
          >
            <BackIcon width="14px" className="c-black-light rtl-reverse v-m" />
          </a>
        </MobileView>
        <BrowserView>
          <If condition={currentRefinement.length}>
            <div
              className="flex flex-end w40 center-xs"
              onClick={clearSrch}
              onKeyPress={clearSrch}
              role="button"
            >
              <CloseIcon className="d-block" />
            </div>
          </If>
          <If condition={!currentRefinement.length}>
            <a
              onClick={clearSrch}
              onKeyPress={clearSrch}
              role="button"
              className="c-main-gray flex middle-xs search-link"
            >
              <SearchIcon />
            </a>
          </If>
        </BrowserView>
        <div className="w100p pl-10 pr-10">
          <form
            action={`/${lang}/catalogsearch/result`}
            onSubmit={handleSubmit}
          >
            <input
              className={classnames(
                { 'en-font': currentRefinement },
                'fs-14 w100p h-40 p-0 b-none outline-none'
              )}
              name="q"
              type="text"
              autoComplete="off"
              value={currentRefinement}
              onChange={event => refine(event.currentTarget.value)}
              placeholder={translate('What are you looking for?')}
              ref={input => input && input.focus()}
            />
          </form>
        </div>
        <MobileView>
          <If condition={currentRefinement.length}>
            <div
              className="flex flex-end w20 center-xs"
              onClick={clearSrch}
              onKeyPress={clearSrch}
              role="button"
            >
              <CloseIcon className="d-block" />
            </div>
          </If>
          <If condition={!currentRefinement.length}>
            <a
              onClick={clearSrch}
              onKeyPress={clearSrch}
              role="button"
              className="c-main-gray flex middle-xs search-link"
            >
              <SearchIcon />
            </a>
          </If>
        </MobileView>
      </div>
      <div className="search-result bg-white overflow-auto bt-light">
        <Choose>
          <When
            condition={
              showPreSearch ||
              !currentRefinement.length ||
              (currentRefinement.length && !finalData.length)
            }
          >
            <div className="pl-15 pt-15 pr-15 pos-abs l-0 top-0 r-0 z-0">
              <If condition={popularSearch && popularSearch.length}>
                <MobileView>
                  <div
                    id="popularSearchTitle"
                    className="mb-8 fw-6 fs-14 lh-18 ls-064"
                  >
                    {popularSearchTitle}
                  </div>
                </MobileView>
                {popularSearch.map(item => {
                  return (
                    <a
                      onClick={() => handlePopularClick(item.context)}
                      onKeyPress={() => handlePopularClick(item.context)}
                      role="button"
                      className={classnames(
                        'd-il-block mr-r-10 mb-8 pl-8 pr-8 pt-10 pb-10 b-white-four lh-18 ls-064',
                        { 'fs-14': isBrowser, 'fs-12': !isBrowser }
                      )}
                    >
                      {item.icon.t || ''} {item.display}
                    </a>
                  );
                })}
              </If>
              <div>
                <WishList inSideSearch={true} />
              </div>
            </div>
          </When>
          <Otherwise>
            <If condition={currentRefinement.length}>
              <Choose>
                <When condition={finalData && finalData.length}>
                  <div className="pos-relt z-1 bg-white h100p">
                    <SearchHits
                      hits={finalData || []}
                      dataType="product/cat"
                      onBackClick={onBackClick}
                      currentRefinement={currentRefinement}
                      refine={refine}
                    />
                  </div>
                </When>
                <Otherwise>
                  <If
                    condition={
                      searchDataLoading === false &&
                      finalData &&
                      finalData.length === 0
                    }
                  >
                    <div className="pos-relt z-1 bg-white h100p">
                      <div className="c-header-sub-title fs-15 ls-040 p-20 ta-c">
                        {translate('No results found')}
                      </div>
                    </div>
                  </If>
                </Otherwise>
              </Choose>
            </If>
          </Otherwise>
        </Choose>
      </div>
    </>
  );
};
