import React from 'react';
import classnames from 'classnames';
import './index.scss';

export default ({
  title,
  noMB = false,
  mTop = false,
  txtColor = null,
  titleCenterAlign = true
}) => {
  return (
    <div
      className={classnames(' c-dark-gray fs-14 fw-5 lh-24', {
        'mb-8': !noMB,
        'mt-8': mTop,
        'ta-c': titleCenterAlign
      })}
      style={{ color: txtColor }}
    >
      {title}
    </div>
  );
};
