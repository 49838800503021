import React from 'react';
import ScaleIcon from 'assets/icons/scale.svg';
import { CURRENT_LANG_PREFIX, IS_ARABIC } from 'constants/index';
import classnames from 'classnames';

export default ({ sizeChartData = {}, size }) => {
  const {
    [`size_label_${CURRENT_LANG_PREFIX}`]: sizeLabel,
    [`scale_${CURRENT_LANG_PREFIX}`]: scale,
    [`pdp_subtext_${CURRENT_LANG_PREFIX}`]: pdpSubText,
    sizes = [],
    is_allowed_on_pdp: isAllowedOnPdp
  } = sizeChartData;

  const selectedSize =
    (sizes && sizes.find(s => s[`key_${CURRENT_LANG_PREFIX}`] === size)) || {};

  const showOnPdpSizes =
    (selectedSize.value && selectedSize.value.filter(s => s.show_on_pdp)) || [];

  const showOnTop =
    (showOnPdpSizes.length && showOnPdpSizes.filter(s => s.show_on_top)) || [];

  const showOnDown =
    (showOnPdpSizes.length && showOnPdpSizes.filter(s => !s.show_on_top)) || [];

  // const sizeLabelText = `${sizeLabel}: ${
  //   selectedSize[`key_${CURRENT_LANG_PREFIX}`]
  // }`;

  // const sizeLabelTextAr = `${
  //   selectedSize[`key_${CURRENT_LANG_PREFIX}`]
  // } :${sizeLabel}`;

  return (
    <div className="mt-10">
      <If condition={sizeChartData._id && isAllowedOnPdp && selectedSize.value}>
        <div
          className={classnames('flex', {
            'middle-xs': !showOnDown.length && !pdpSubText
          })}
        >
          <ScaleIcon className="rtl-flip" />
          <div className="fs-12 ml-10 mr-10 ls-038 w100p c-dark-gray">
            <div className="flex ws-nowrap fw-5 en-font middle-xs">
              <div
                className={classnames('en-font flex', {
                  'en-font-bold': IS_ARABIC
                })}
              >
                <div>{sizeLabel}</div>
                <div>:&nbsp;</div>
                <div>{selectedSize[`key_${CURRENT_LANG_PREFIX}`]}</div>
                {/* {IS_ARABIC ? sizeLabelTextAr : sizeLabelTextEn} */}
                {/* {sizeLabelText} */}
              </div>
              <If condition={showOnTop.length}>
                &nbsp;/&nbsp;
                {(showOnTop.length &&
                  showOnTop.map((v, index) => {
                    return (
                      <div className="flex middle-xs">
                        <div className="">
                          {v[`${CURRENT_LANG_PREFIX}_label`]}
                        </div>
                        :&nbsp;
                        <div className="en-font">{v.value}</div>
                        &nbsp;
                        <div> {v.append_scale ? `${scale}` : ''}</div>
                        <If condition={showOnTop.length - index > 1}>
                          &nbsp;/&nbsp;
                        </If>
                      </div>
                    );
                  })) ||
                  ``}
              </If>
            </div>
            <If condition={pdpSubText}>
              <div className="fs-10 ls-032">{pdpSubText}</div>
            </If>
            <If condition={showOnDown.length}>
              <div className="mt-8">
                {showOnDown.length &&
                  showOnDown.map(s => {
                    return (
                      <div className="pb-4 mr-l-10 d-il-block">
                        <div className="flex middle-xs">
                          <div className="c-brown-gray">
                            {`${s[`${CURRENT_LANG_PREFIX}_label`]}:`}
                          </div>
                          <div>&nbsp;</div>
                          <div className="en-font">{s.value}</div>
                          <div>
                            &nbsp;
                            {s.append_scale ? `${scale}` : ''}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </If>
          </div>
        </div>
      </If>
    </div>
  );
};
