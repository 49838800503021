import axios from 'utils/axios';
import { timingVisualComplete } from 'utils/analytics';
import logError from 'utils/logError';

import { CURRENT_LANG_PREFIX, COUNTRY, COUNTRY_KSA } from '../../constants';

export const ACTION_GET_STATIC_PAGE_LOADING = 'landing/GET_STATIC_PAGE_LOADING';
export const ACTION_GET_STATIC_PAGE = 'landing/GET_STATIC_PAGE';
export const ACTION_GET_STATIC_PAGE_ERROR = 'landing/GET_STATIC_PAGE_ERROR';

export const getStaticPage = async (option, dispatch) => {
  dispatch({ type: ACTION_GET_STATIC_PAGE_LOADING, data: {} });
  try{
    const startTime = new Date().getTime();
    const result = await axios.get(
      `/strapi_pages/processed/${option.pageName}/${COUNTRY !== COUNTRY_KSA ? `${COUNTRY}_` : ''
      }${CURRENT_LANG_PREFIX}.json`
    );
    dispatch({ type: ACTION_GET_STATIC_PAGE, data: result.data });
    const endTime = new Date().getTime();
    timingVisualComplete(endTime - startTime);
  } catch (e) {
    dispatch({ type: ACTION_GET_STATIC_PAGE_ERROR, data: {} });
    logError(e.message);
  }
};
