import React from 'react';
import { translate } from 'constants/language';
import { path } from 'utils/';
import { COUNTRY } from 'constants/index';
import { useStateValue } from 'context/app';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import CallIcon from 'assets/icons/call-o.svg';
import EmailIcon from 'assets/icons/email-o.svg';
import WAIcon from 'assets/icons/whats-app-o.png';
import FooterUSP from './FooterUSP';

export default function() {
  const {
    account: [accountState]
  } = useStateValue();
  const { config = {} } = accountState;
  const defaultContactDetails = {
    phone: '8001111090',
    email: 'hello.ksa@stylishop.com',
    whatsapp: '+966115208333'
  };
  const openWhatsAppClick = () => {
    const href = `https://wa.me/
                          ${
                            config.contactUs
                              ? path([COUNTRY, 'whatsapp'], config.contactUs) ||
                                defaultContactDetails.whatsapp
                              : defaultContactDetails.whatsapp
                          }`;
    window.location.href = href;
  };
  return (
    <div className="bg-thumb-gray pt-24 pb-24">
      <ContainerDesktop>
        <div className="flex middle-xs">
          <div className="ml-0 mr-auto">
            <div className="fs-18 fw-5 lh-24 ls-032 c-dark-gray">
              {translate("We're Always Here To Help")}
            </div>
            <div className="fs-12 lh-16 ls-072 c-dark-gray mt-8">
              {translate(
                'Reach out to us through any of these support channels'
              )}
            </div>
          </div>
          <div className="mr-0 ml-auto flex middle-xs">
            <If
              condition={
                config &&
                config.contactUs &&
                config.contactUs[COUNTRY] &&
                config.contactUs[COUNTRY].whatsapp
              }
            >
              <FooterUSP
                doClick={openWhatsAppClick}
                icon={
                  <img
                    src={WAIcon}
                    alt=""
                    className="d-block"
                    height="50px"
                    width="50px"
                  />
                }
                label={translate('WhatsApp Us')}
                value={
                  (config &&
                    config.contactUs &&
                    config.contactUs[COUNTRY] &&
                    config.contactUs[COUNTRY].whatsapp) ||
                  defaultContactDetails.whatsapp
                }
                className="pr-r-30"
              />
            </If>
            <If
              condition={
                config &&
                config.contactUs &&
                config.contactUs[COUNTRY] &&
                config.contactUs[COUNTRY].phone &&
                config.contactUs[COUNTRY].show_contact_us_account
              }
            >
              <FooterUSP
                icon={
                  <CallIcon className="d-block" height="50px" width="50px" />
                }
                label={translate('Call Us')}
                value={
                  config &&
                  config.contactUs &&
                  config.contactUs[COUNTRY] &&
                  config.contactUs[COUNTRY].phone
                }
                className="pr-r-30"
              />
            </If>
            <FooterUSP
              href={`mailto:
              ${
                config.contactUs
                  ? path([COUNTRY, 'email'], config.contactUs) ||
                    defaultContactDetails.email
                  : defaultContactDetails.email
              }`}
              target="_blank"
              rel="noopener noreferrer"
              icon={
                <EmailIcon className="d-block" height="50px" width="50px" />
              }
              label={translate('Email Us')}
              value={
                (config &&
                  config.contactUs &&
                  config.contactUs[COUNTRY] &&
                  config.contactUs[COUNTRY].email) ||
                defaultContactDetails.email
              }
              className="pr-r-30"
            />
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
