import axios from 'utils/axios';
// import isEmpty from 'lodash.isempty';

import { getJWT, getStoreId } from 'utils';
import logError from 'utils/logError';
import { GET_ORDER_DETAILS, GET_SELECTED_ADDRESS } from 'constants/api';

import { getStorage, removeStorage } from 'utils/storage';
import { getCustomerId, getXHeaderToken } from 'utils/user';

export const ACTION_SELECTED_TAB = 'payment/SELECTED_TAB';
export const ACTION_MAKE_PAYMENT_LOADING = 'payment/MAKE_PAYMENT_LOADING';
export const ACTION_MAKE_PAYMENT = 'payment/MAKE_PAYMENT';
export const ACTION_MAKE_PAYMENT_SUCCESS = 'payment/MAKE_PAYMENT_SUCCESS';
export const ACTION_MAKE_PAYMENT_FAILED = 'payment/MAKE_PAYMENT_FAILED';
export const ACTION_SET_ERRORS = 'payment/SET_ERRORS';
export const ACTION_SET_ORDER_DETAILS = 'payment/SET_ORDER_DETAILS';
export const ACTION_SET_ORDER_DETAILS_LOADING = 'payment/_SET_ADDRESS';
export const ACTION_SET_ADDRESS = 'confirmation/SET_ADDRESS';

export async function getSelectedAddressDetails({ addressId }, dispatch) {
  try {
    const customerId = getCustomerId()();
    dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
    const result = await axios.get(
      GET_SELECTED_ADDRESS({ addressId, customerId }),
      {
        headers: {
          Token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    logError(result.data.response);
    dispatch({
      type: ACTION_SET_ADDRESS,
      data: result.data.response.address
    });
  } catch (e) {
    logError(e);
  }
  // return response;
}

export async function resetQuote() {
  await removeStorage('quoteId');
  await removeStorage('cartItems');
  await removeStorage('currentQuote');
  await removeStorage('nudgeShowTimes');
}
export async function getOrderDetails({ orderId = '' }, dispatch) {
  try {
    const storedData = await getStorage('orderResponse')
    const storeId = getStoreId() || storedData?.storeId;
    const customerId = getCustomerId() || storedData?.customerId;

    dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
    const result = await axios.post(
      GET_ORDER_DETAILS(),
      {
        customerId,
        storeId,
        orderId
      },
      {
        headers: {
          Token: getJWT(),

          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );

    const orderResponse = result.data.response;
    if (orderResponse.orderId) {
      dispatch({ type: ACTION_SET_ORDER_DETAILS, data: orderResponse });
    }
  } catch (error) {
    logError(error);
  }
}
