import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './index.scss';
import { isBrowser } from 'react-device-detect';
import SuccessIcon from 'assets/icons/toast-success.svg';
import ErrorIcon from 'assets/icons/toast-error.svg';
import { translate } from 'constants/language';
import { CURRENT_LANG_PREFIX } from 'constants/index';
import { useHistory } from 'react-router-dom';

export default ({
  children,
  type = '',
  timeOut = 2500,
  showBar = false,
  ctaAction
}) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const animFactor = isBrowser ? 300 : 0;
  useEffect(() => {
    setShowSnackBar(showBar);
    setTimeout(() => {
      setShowSnackBar(false);
    }, timeOut - animFactor);
  }, [showBar]);
  const history = useHistory();
  return (
    <div className="flex center-xs" style={{ marginTop: '0' }}>
      <div
        className={classnames('toast flex middle-xs between-xs', {
          _show: showSnackBar,
          _desktop: isBrowser,
          _mobile: !isBrowser
          // 'error-bg': type === 'error',
          // success: type === 'success'
        })}
      >
        <If condition={isBrowser}>
          <div className="mr-r-12">
            <Choose>
              <When condition={type === 'error'}>
                <ErrorIcon className="d-block" />
              </When>
              <When condition={type === 'success'}>
                <SuccessIcon className="d-block" />
              </When>
            </Choose>
          </div>
        </If>
        <span>{children}</span>
        <If condition={ctaAction}>
          <a
            role="link"
            onClick={() => history.push(`/${CURRENT_LANG_PREFIX}/${ctaAction}`)}
            onKeyPress={() =>
              history.push(`/${CURRENT_LANG_PREFIX}/${ctaAction}`)
            }
            className="end-xs bb-app-bk ml-r-5"
          >
            {translate('View Profile')}
          </a>
        </If>
      </div>
    </div>
  );
};
