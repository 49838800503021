/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
import axios from 'utils/axios';
// import pick from 'lodash.pick';
import { forEach } from 'lodash';
import logError from 'utils/logError';
// import { API_GET_QUOTE_DETAILS } from '../../constants/api';
import { getStoreId, path, getJWT } from 'utils';
import { logEvent, timingVisualComplete } from 'utils/analytics';
import {
  API_PRODUCT_LISTING,
  API_PRODUCT_LISTING_NODE,
  TAGALYSIS_ENABLED,
  GET_CATEGORY_URL_PATH,
  GET_WISHLIST,
  GET_WISHLIST_IDS,
  UPDATE_WISHLIST
} from 'constants/api';

import { formatProduct } from 'utils/product';
import { getCustomerId, getXHeaderToken } from 'utils/user';
import { getStorage } from 'utils/storage';
import { showToast } from 'containers/Account/actions';
import { translate, UNKNOWN_ERROR } from 'constants/language';
import {
  VALUE_SEPARATOR,
  CURRENT_LANG_PREFIX,
  USE_MICROSERVICE
} from '../../constants';

export const ACTION_PRODUCT_LIST = 'productList/PRODUCT_LIST';
export const ACTION_PRODUCT_STATE = 'productList/ACTION_PRODUCT_STATE';
export const ACTION_FILTERS_LOADING = 'productList/ACTION_FILTERS_LOADING';
export const ACTION_SET_CART_COUNT = 'productList/ACTION_SET_CART_COUNT';
export const ACTION_PRODUCT_LIST_LOADING = 'productList/PRODUCT_LIST_LOADING';
export const ACTION_PRODUCT_LIST_SET_APPLIED_FILTERS =
  'productList/ACTION_PRODUCT_LIST_SET_APPLIED_FILTERS';
export const ACTION_PRODUCT_LIST_SET_FILTERS =
  'productList/PRODUCT_LIST_SET_FILTERS';

export const ACTION_PRODUCT_WISHLIST = 'productList/ACTION_PRODUCT_WISHLIST';
export const ACTION_GET_WISHLIST_FAILURE = 'productList/GET_WISHLIST_FAILURE';
export const ACTION_GET_WISHLIST_LOADING = 'productList/GET_WISHLIST_LOADING';

export const ACTION_GET_WISHLIST_IDS_LOADING =
  'productList/GET_WISHLIST_IDS_LOADING';
export const ACTION_PRODUCT_WISHLIST_IDS =
  'productList/ACTION_PRODUCT_WISHLIST_IDS';
export const ACTION_UPDATE_WISHLIST_COMPLETE =
  'productList/UPDATE_WISHLIST_COMPLETE';
export const ACTION_UPDATE_WISHLIST_LOADING =
  'productList/UPDATE_WISHLIST_LOADING';
export const ACTION_UPDATE_WISHLIST_ERROR = 'productList/UPDATE_WISHLIST_ERROR';
export const ACTION_WISHLIST_PRODUCT_REMOVED =
  'productList/WISHLIST_PRODUCT_REMOVED';

export const ACTION_SET_CATEGORY_INFO = 'productList/SET_CATEGORY_INFO';

export const ACTION_CROSS_SELL_COMPLETE = 'productList/CROSS_SELL_COMPLETE';
export const ACTION_CROSS_SELL_LOADING = 'productList/CROSS_SELL_LOADING';
export const ACTION_CROSS_SELL_FAILURE = 'productList/CROSS_SELL_FAILURE';

export async function getCategoryPathInfo(category) {
  try {
    let urlApiPathResult = {};
    urlApiPathResult = await axios.post(GET_CATEGORY_URL_PATH(), {
      categoryPath: `list/${category}`,
      langCode: CURRENT_LANG_PREFIX
    });
    const categoryId = path(['data', 'categoryId'], urlApiPathResult);
    const name = path(['data', 'name'], urlApiPathResult);
    if (categoryId) {
      urlApiPathResult = { id: categoryId, name };
    }

    return { ...urlApiPathResult };
  } catch (e) {
    return {};
  }
}

function formatFilterTobeSend(filters) {
  const ret = {};
  const ifilters = JSON.parse(JSON.stringify(filters));
  Object.keys(ifilters).forEach(facetLabel => {
    const facetValue = ifilters[facetLabel];
    if (
      facetValue &&
      facetLabel !== 'categoryIds' &&
      facetLabel !== 'category'
    ) {
      facetValue.forEach(value => {
        const [facet, facetVal] = String(value).split(VALUE_SEPARATOR);
        if (!ret[facet]) {
          ret[facet] = [];
        }
        ret[facet].push(facetVal);
      });
    } else {
      ret[facetLabel] = facetValue;
    }
  });
  return ret;
}
function sortAndClean({ filtersData, filterOrder }) {
  let output = {};
  const mainCatFilter = {};
  const moreFilter = {};

  if (Object.keys(filtersData).length > 0) {
    if (filterOrder != null) {
      for (const mainFilterOrder of filterOrder) {
        if (
          mainFilterOrder.value === 'price' ||
          mainFilterOrder.value === 'Price'
        ) {
          let added = false;
          for (const [key, value] of Object.entries(filtersData)) {
            if (
              value &&
              value[0] &&
              value[0].attributeCode === mainFilterOrder.value
            ) {
              value.isMainFilter = true;
              mainCatFilter[key] = value;
              added = true;
            }
          }
        } else if (
          ['discount_percentage', 'discount'].includes(mainFilterOrder.value)
        ) {
          let added = false;
          for (const [key, value] of Object.entries(filtersData)) {
            if (
              value &&
              value[0] &&
              value[0].attributeCode === mainFilterOrder.value
            ) {
              value.isMainFilter = true;
              mainCatFilter[key] = value;
              added = true;
            }
          }
        }
        if (mainFilterOrder.isCore === true) {
          let added = false;
          for (const [key, value] of Object.entries(filtersData)) {
            if (
              value &&
              value[0] &&
              value[0].attributeCode.includes(mainFilterOrder.value)
            ) {
              value.isMainFilter = true;
              mainCatFilter[key] = value;
              added = true;
            }
          }
        } else {
          // / More Filter sorting
          let added = false;
          for (const [key, value] of Object.entries(filtersData)) {
            if (!(key in mainCatFilter)) {
              if (
                value &&
                value[0] &&
                value[0].attributeCode.includes(mainFilterOrder.value)
              ) {
                value.isMainFilter = false;
                moreFilter[key] = value;
                added = true;
              }
            } else {
              added = true;
            }
          }
        }
      }
      output = { ...mainCatFilter, ...moreFilter };
    } else {
      output = filtersData;
      output.sort((a, b) => a.key.compare(b.key));
    }
  }
  return output;
}

export async function fetchProducts({
  query = '',
  searchEvent = '',
  gender,
  categoryIds,
  filters = {},
  sortInfo: { sortKey = undefined, sortOrder = undefined } = {},
  pageOffset = 1,
  categoryLevel,
  pageSize = 20,
  sendOnlyLastFacet,
  selectedFacet,
  contextRuleId,
  collectionData = false,
  searchId,
  vmUrlConfigValues = {}
}) {
  const storeId = getStoreId();
  let appliedFilters = {
    // category: [''],
    ...filters
  };
  const {
    vm_url_enabled: vmUrlEnabled,
    new_url: newUrl,
    old_url: oldUrl
  } = vmUrlConfigValues;

  if (!TAGALYSIS_ENABLED) {
    appliedFilters = { ...appliedFilters, categoryIds };
  }
  const numericFilters = [];
  if (filters.Price && filters.Price[0]) {
    if (
      !sendOnlyLastFacet ||
      (sendOnlyLastFacet && selectedFacet !== 'Price')
    ) {
      numericFilters.push(
        {
          type: 'price',
          value: filters.Price[0],
          operator: 'GREATER_THAN_AND_EQUALS'
        },
        {
          type: 'price',
          value: filters.Price[1],
          operator: 'LESS_THAN_AND_EQUALS'
        }
      );
    }
  }
  if (filters.discount_percentage && filters.discount_percentage[0]) {
    if (
      !sendOnlyLastFacet ||
      (sendOnlyLastFacet && selectedFacet !== 'discount_percentage')
    ) {
      numericFilters.push({
        type: 'discount_percentage',
        value: filters.discount_percentage[0],
        operator: 'GREATER_THAN_AND_EQUALS'
      });
    }
  }
  const filterPayload = { ...appliedFilters };
  // if (sendOnlyLastFacet && lastSelectedFacet !== selectedFacet) {
  //   delete filterPayload[selectedFacet];
  // }
  delete filterPayload.discount_percentage;
  delete filterPayload.Price;
  if (filterPayload.categoryIds && filterPayload.categoryIds.length < 1) {
    delete filterPayload.categoryIds;
  }
  const filterToSend = formatFilterTobeSend({ ...filterPayload });

  // code: 1 is needed to fetch latest configuration of filters
  const optional = { code: 1 };

  if (categoryLevel) {
    optional.categoryLevel = categoryLevel;
  }

  let requestObj = {
    filters: filterToSend,
    numericFilters,
    pageOffset: pageOffset - 1,
    pageSize,
    storeId,
    sortKey,
    sortOrder,
    collectionData,
    ...optional
  };
  if (contextRuleId) {
    requestObj.contextRuleId = contextRuleId;
    requestObj.categoryLevel = 3;
  }
  if (TAGALYSIS_ENABLED) {
    if (categoryIds && categoryIds.length === 1 && !query) {
      requestObj = { categoryId: categoryIds[0], ...requestObj };
    } else {
      requestObj = { query, ...requestObj };
    }
  } else {
    requestObj = { query, ...requestObj };
  }
  if (searchId) {
    requestObj.searchId = searchId;
  }
  if (searchEvent !== '') {
    requestObj.searchEvent = searchEvent;
    if (gender) {
      requestObj.gender = gender === 1 ? 'M' : 'W';
    }
  }
  requestObj.code = 1;

  // const result = await axios.post(
  //   API_PRODUCT_LISTING({ contextRuleId }),
  //   {
  //     ...requestObj
  //   },
  //   {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-Header-Token': getXHeaderToken()
  //     }
  //   }
  // );
  const reqUrl = vmUrlEnabled ? newUrl : oldUrl;
  const result = await axios.post(
    API_PRODUCT_LISTING_NODE({ reqUrl }),
    {
      ...requestObj
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Header-Token': getXHeaderToken()
      }
    }
  );
  const response = (result.data && result.data.response) || result.data; // slight confustion with the api
  if (
    response &&
    response.filters &&
    response.arrangedCategoryFilter &&
    response.arrangedCategoryFilter.length
  )
    response.filters.Categories = response.arrangedCategoryFilter;

  const iFilters = sortAndClean({
    filtersData: response.filters,
    filterOrder: response.filterOrder
  });

  response.filters = iFilters;
  if (contextRuleId) {
    logEvent({
      eventName: 'product_listing',
      cleverTapData: {
        user_id: getCustomerId(),
        cat_name: contextRuleId,
        cat_id: contextRuleId,
        result_count: response?.totalCount,
        search_type: 'autoComplete',
        screen_name: 'category_navigation'
      },
      isOnlyCleverTap: true,
      screenName: 'Category_Listing'
    });
  } else {
    logEvent({
      eventName: 'search',
      cleverTapData: {
        user_id: getCustomerId(),
        search_term: query,
        result_count: response?.totalCount,
        search_type: 'autoComplete',
        screen_name: 'product_search'
      },
      isOnlyCleverTap: true,
      screenName: 'Category_Listing'
    });
  }
  // const finalResponse = { response, filters: iFilters };
  return {
    response,
    numericFilters,
    appliedFilters
  };
}

export async function getWishList({ noLoading = false }, dispatch) {
  const customerId = getCustomerId();
  const startTime = new Date().getTime();

  if (customerId) {
    try {
      const storeId = getStoreId();
      if (!storeId) {
        return;
      }
      if (!noLoading) {
        dispatch({ type: ACTION_GET_WISHLIST_LOADING });
      }
      const result = USE_MICROSERVICE
        ? await axios.post(
            GET_WISHLIST({ customerId, storeId }),
            { customerId, storeId },
            {
              headers: {
                'X-Header-Token': getXHeaderToken(),
                Token: getJWT(),
                'Content-Type': 'application/json'
              }
            }
          )
        : await axios.get(GET_WISHLIST({ customerId, storeId }), {
            headers: {
              'X-Header-Token': getXHeaderToken(),
              Token: getJWT(),
              'Content-Type': 'application/json'
            }
          });
      const response = (result.data && result.data.response) || result.data; // slight confustion with the api
      dispatch({ type: ACTION_PRODUCT_WISHLIST, data: { ...response } });
      const endTime = new Date().getTime();
      timingVisualComplete(endTime - startTime);
    } catch (e) {
      dispatch({ type: ACTION_GET_WISHLIST_FAILURE });
      logError(e);
    }
  } else {
    dispatch({
      type: ACTION_GET_WISHLIST_FAILURE,
      data: { loginRequired: true }
    });
  }
}

export async function getCSProdList(dispatch) {
  const customerId = getCustomerId();
  if (customerId) {
    try {
      const storeId = getStoreId();
      if (!storeId) {
        return;
      }

      const skuResult = await axios.post(
        `${process.env.STYLI_APP_CLICK_STREAM_RECOMM_URL}/api/v1/clickstream/user_recommendation`,
        { user_id: customerId, store_id: storeId },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );
      const result = await axios.post(
        API_PRODUCT_LISTING({}),
        {
          filters: { sku: skuResult.data.recommended_item_list },
          // filters: { sku: ['7001430907', '7001520607'] },
          categoryLevel: 3,
          collectionData: false,
          contextRuleId: 'ramadan2022-w',
          numericFilters: [],
          pageOffset: 0,
          pageSize: 50,
          query: '',
          // code: 1,
          storeId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Header-Token': getXHeaderToken()
          }
        }
      );
      const response = (result.data && result.data.response) || result.data; // slight confustion with the api
      dispatch({ type: ACTION_PRODUCT_WISHLIST, data: { ...response } });
    } catch (e) {
      dispatch({ type: ACTION_GET_WISHLIST_FAILURE });
      logError(e);
    }
  } else {
    dispatch({
      type: ACTION_GET_WISHLIST_FAILURE,
      data: { loginRequired: true }
    });
  }
}

export async function getWishListIds(options, dispatch) {
  const customerId = getCustomerId();
  const storeId = getStoreId();
  if (customerId && storeId) {
    dispatch({ type: ACTION_GET_WISHLIST_IDS_LOADING });
    const result = await axios.get(GET_WISHLIST_IDS({ customerId, storeId }), {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });
    const { response, statusCode } = result.data;
    if (statusCode === '200') {
      dispatch({ type: ACTION_PRODUCT_WISHLIST_IDS, data: response });
    }
  }
}

export async function getProductList(
  {
    selectedGenderValue,
    category,
    gender,
    categoryIds: ids,
    filters = {},
    sortInfo: { sortKey = undefined, sortOrder = undefined } = {},
    pageOffset = 1,
    updateFilterData = false,
    lastSelectedFacet,
    categoryLevel,
    query,
    productListPathName,
    contextRuleId,
    updateUrlPath,
    discountFilters,
    filterOptChangd = false,
    isCrossSell = false,
    searchId,
    searchIdContextIdMap,
    pageSize,
    vmUrlConfigValues = {},
    currentGenderFilter,
    searchEvent = ''
  },
  dispatch
) {
  try {
    const startTime = new Date().getTime();
    if (pageOffset === 1) {
      // dispatch({ type: ACTION_PRODUCT_LIST_LOADING, data: { loading: true } });
    }
    if (isCrossSell) {
      dispatch({
        type: ACTION_CROSS_SELL_LOADING,
        data: { loading: true }
      });
    }
    if (filterOptChangd) {
      if (isCrossSell) {
        dispatch({
          type: ACTION_CROSS_SELL_LOADING,
          data: { loading: true }
        });
      } else {
        dispatch({
          type: ACTION_PRODUCT_LIST_LOADING,
          data: { loading: true }
        });
      }
    }
    let categoryInfo = {};
    let categoryIds = ids;
    try {
      if (
        !contextRuleId &&
        (!ids || updateUrlPath === true) &&
        category &&
        !(category.indexOf('/context/') > -1)
      ) {
        categoryInfo = await getCategoryPathInfo(category);
        if (categoryInfo.id) {
          categoryIds = [categoryInfo.id];
        }
      }
    } catch (e) {
      logError(e);
    }

    const inputObject = {
      query,
      searchEvent,
      gender,
      category,
      categoryIds,
      categoryLevel:
        (categoryLevel > 0 && categoryLevel) ||
        (categoryInfo.level > 0 && categoryInfo.level - 1) ||
        0,
      filters,
      sortInfo: { sortKey, sortOrder },
      pageOffset,
      searchId:
        searchIdContextIdMap === `${searchId}_${contextRuleId || query}`
          ? searchId
          : undefined
    };

    if (category && category.indexOf('/context/') > -1) {
      const [, , ruleName] = category.split('/');
      inputObject.contextRuleId = ruleName;
      delete inputObject.categoryIds;
    }
    if (isCrossSell && contextRuleId) {
      inputObject.contextRuleId = contextRuleId;
      inputObject.collectionData = true;
      inputObject.pageSize = pageSize;
    }
    inputObject.vmUrlConfigValues = vmUrlConfigValues;
    const { response, numericFilters, appliedFilters } = await fetchProducts(
      inputObject
    );

    const genderFilter = [];
    if (response.filters !== undefined && pageOffset === 1) {
      // for gender mock
      // const mockGenders = [];
      // mockGenders.push({
      //   value: "Boys",
      //   count: "1",
      //   attributeCode: "kids_gender"
      // });
      // mockGenders.push({
      //   value: "Girls",
      //   count: "1",
      //   attributeCode: "kids_gender"
      // });
      // response.filters.Gender = mockGenders;
      const filterValueList = Object.values(response.filters);
      filterValueList.map(function(value) {
        if (
          value &&
          value.length > 0 &&
          value[0].attributeCode !== undefined &&
          value[0].attributeCode === 'kids_gender'
        ) {
          genderFilter.push(...value);
        }
        return value;
      });
    } else {
      genderFilter.push(...(currentGenderFilter ?? []));
    }

    if (
      category &&
      category.indexOf('/context/') > -1 &&
      (!categoryInfo || !categoryInfo.name)
    ) {
      categoryInfo.name = response.ruleInfo && response.ruleInfo.name;
    }

    if (response && response.products) {
      const { minPrice, maxPrice } = response;
      if (isCrossSell) {
        dispatch({
          type: ACTION_CROSS_SELL_COMPLETE,
          data: {
            productList: response.products,
            collectionData: response.collectionData,
            contextRuleId
          }
        });
      } else {
        dispatch({
          type: ACTION_PRODUCT_LIST,
          data: {
            productListPathName,
            categoryInfo,
            filterOrder: response.filterOrder,
            productList: response.products,
            productFilter: response.filters,
            genderFilter,
            selectedGenderValue,
            filterTabProductFilter: response.filters,
            appliedFilters,
            appliedNumericFilters: numericFilters,
            query,
            category,
            categoryIds,
            flashSale: response.flashSale,
            pageOffset,
            totalPages: Number(response.totalPages),
            page: Number(response.page),
            totalCount: Number(response.totalCount),
            facetLabels: response.facetLabels,
            sortInfo: { sortKey, sortOrder },
            minPrice,
            maxPrice,
            contextRuleId: contextRuleId || inputObject.contextRuleId,
            productListLoading: updateFilterData,
            searchId: response.searchId,
            widgetData:
              (response.ruleInfo &&
                response.ruleInfo.storeFront &&
                response.ruleInfo.storeFront.widgetData) ||
              '',
            seoMetadata:
              (response.ruleInfo &&
                response.ruleInfo.storeFront &&
                response.ruleInfo.storeFront.seoMetadata) ||
              {},
            arrangedCategoryFilter: response.arrangedCategoryFilter,
            ruleName: response.ruleName,
            ruleNameAR: response.ruleNameArabic
          }
        });
        if (updateFilterData) {
          dispatch({
            type: ACTION_PRODUCT_LIST_SET_FILTERS,
            data: {
              filterTabProductFilter: response.filters,
              facetLabels: response.facetLabels,
              filterOrder: response.filterOrder,
              appliedFilters,
              lastSelectedFacet,
              minPrice,
              maxPrice,
              setAppliedFilterTab: true,
              productListLoading: false,
              totalCountFilter: response.totalCount,
              discountFilters,
              arrangedCategoryFilter: response.arrangedCategoryFilter
            }
          });
        }
      }
    }
    const endTime = new Date().getTime();
    timingVisualComplete(endTime - startTime);
  } catch (error) {
    logError(error);
    if (isCrossSell) {
      dispatch({
        type: ACTION_CROSS_SELL_FAILURE,
        data: { error }
      });
    } else {
      dispatch({
        type: ACTION_PRODUCT_LIST_LOADING,
        data: { loading: false, totalCount: 0, listingError: true }
      });
      setTimeout(() => {
        dispatch({
          type: ACTION_PRODUCT_LIST_LOADING,
          data: { loading: false, totalCount: 0, listingError: false }
        });
      }, 2000);
    }
  }
  return {};
}

export async function applyGender(
  {
    selectedGenderValue,
    sortInfo,
    category,
    query,
    filters = {},
    filterTabProductFilter,
    categoryIds,
    categoryLevel = 0,
    searchId,
    searchIdContextIdMap,
    vmUrlConfigValues = {},
    currentGenderFilter = []
  },
  dispatch
) {
  /* eslint-disable no-param-reassign */

  if (selectedGenderValue && selectedGenderValue.attributeCode) {
    const genderFilter = /* filters[selectedGenderValue.attributeCode] || */ [];
    if (!genderFilter.includes(selectedGenderValue.value)) {
      genderFilter.push(
        `${selectedGenderValue.attributeCode}${VALUE_SEPARATOR}${selectedGenderValue.value}`
      );
    }
    let addedToFilter = false;
    forEach(filterTabProductFilter ?? {}, (value, key) => {
      // console.log('filterTabProductFilter[]:');
      // console.log(key);
      // console.log(value);
      if (value instanceof Array) {
        forEach(value, v => {
          if (
            v.attributeCode === selectedGenderValue.attributeCode &&
            v.value === selectedGenderValue.value
          ) {
            filters[key] = genderFilter;
            addedToFilter = true;
          }
          return !addedToFilter;
        });
      }
      return !addedToFilter;
    });

    if (!addedToFilter)
      filters[selectedGenderValue.attributeCode] = genderFilter;
  }
  getProductList(
    {
      category,
      categoryIds,
      selectedGenderValue,
      sortInfo,
      filters,
      query,
      categoryLevel,
      searchId,
      searchIdContextIdMap,
      vmUrlConfigValues,
      currentGenderFilter: currentGenderFilter ?? []
    },
    dispatch
  );

  /* eslint-enable no-param-reassign */
}

export async function applySort(
  {
    sortOrder,
    sortKey,
    selectedGenderValue,
    category,
    query,
    filters = {},
    categoryIds,
    categoryLevel = 0,
    searchId,
    searchIdContextIdMap,
    vmUrlConfigValues = {},
    currentGenderFilter = []
  },
  dispatch
) {
  getProductList(
    {
      category,
      categoryIds,
      selectedGenderValue,
      sortInfo: { sortOrder, sortKey },
      filters,
      query,
      categoryLevel,
      searchId,
      searchIdContextIdMap,
      vmUrlConfigValues,
      currentGenderFilter: currentGenderFilter ?? []
    },
    dispatch
  );
}

export async function getFilters(
  {
    categoryIds,
    query,
    filters = {},
    vmUrlConfigValues = {},
    lastSelectedFacet,
    categoryLevel,
    selectedFacet,
    contextRuleId,
    searchId,
    selectedGenderValue
  },
  dispatch
) {
  try {
    dispatch({ type: ACTION_FILTERS_LOADING, data: { loading: true } });
    const { response, appliedFilters } = await fetchProducts({
      categoryIds,
      filters,
      query,
      categoryLevel,
      lastSelectedFacet,
      sendOnlyLastFacet: true,
      vmUrlConfigValues,
      selectedFacet,
      pageSize: 1,
      contextRuleId,
      searchId
    });
    if (response && response.products) {
      dispatch({
        type: ACTION_PRODUCT_LIST_SET_FILTERS,
        data: {
          filterTabProductFilter: response.filters,
          facetLabels: response.facetLabels,
          filterOrder: response.filterOrder,
          appliedFilters,
          lastSelectedFacet,
          minPrice: response.minPrice,
          maxPrice: response.maxPrice,
          totalCountFilter: response.totalCount,
          arrangedCategoryFilter: response.arrangedCategoryFilter,
          selectedGenderValue:
            response.selectedGenderValue ?? selectedGenderValue
        }
      });
    }
  } catch (error) {
    logError(error);
    dispatch({ type: ACTION_PRODUCT_LIST_LOADING, data: { loading: false } });
  }
}

export async function resetFilter(
  { filters, discountFilters, filterOrder },
  dispatch
) {
  dispatch({
    type: ACTION_PRODUCT_LIST_SET_FILTERS,
    data: {
      filterTabProductFilter: filters,
      discountFilters,
      filterOrder
    }
  });
}

export async function resetToAppliedFilterTab({ filters }, dispatch) {
  dispatch({
    type: ACTION_PRODUCT_LIST_SET_APPLIED_FILTERS,
    data: {
      filters
    }
  });
}

export async function getCartCount(dispatch) {
  const cartItems = await getStorage('cartItems');

  dispatch({
    type: ACTION_SET_CART_COUNT,
    data: { cartCount: Object.keys(cartItems || {}).length }
  });
}

export async function toggleWishList(
  {
    productId,
    wishListItemId = 0,
    mode,
    isWishlist,
    isPdp,
    disableBag,
    pdpDownMsg,
    productDetail,
    productCatInfo,
    wishlistProductsSkuArray,
    noLoading = false
  },
  dispatch,
  accountDispatch
) {
  try {
    dispatch({ type: ACTION_UPDATE_WISHLIST_LOADING });

    let canAddToWishList = false;
    let customerId;
    try {
      customerId = getCustomerId();
    } catch (e) {
      canAddToWishList = !!customerId;
    }
    if (!canAddToWishList) {
      canAddToWishList = !!customerId;
    }
    if (canAddToWishList) {
      const storeId = getStoreId();
      if (!storeId) {
        return;
      }
      const payload = {
        customerId,
        storeId,
        wishList: [
          {
            parentProductId: productId,
            prodcutId: productId,
            comments: '',
            quantity: 1,
            wishListItemId
          }
        ]
      };
      const headers = {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      };
      if (mode === 'post') {
        await axios.post(UPDATE_WISHLIST(), payload, {
          headers
        });

        if (isPdp && disableBag) {
          showToast(
            {
              type: 'success',
              content: pdpDownMsg,
              timeOut: 3000
            },
            accountDispatch
          );
        }

        formatProduct({ productDetail, productCatInfo }).then(formattedData => {
          let cleverTapData = {};
          if (productDetail) {
            cleverTapData = {
              product_name: productDetail.name,
              sku_id: productDetail.sku,
              cat_name: productDetail?.categories?.level3[0],
              cat_id: productDetail.categoryIds
                ? productDetail?.categoryIds[3]
                : '',
              parent_cat_name: productDetail?.categories?.level2[0],
              parent_cat_id: productDetail.categoryIds
                ? productDetail?.categoryIds[2]
                : '',
              super_cat_name: productDetail?.categories?.level1[0],
              super_cat_id: productDetail.categoryIds
                ? productDetail?.categoryIds[1]
                : ''
            };
          }
          //
          logEvent({
            eventName: 'add_to_wishlist',
            data: { items: formattedData },
            cleverTapData,
            skipGtm: true,
            screenName: 'My_Favorites'
          });
        });
      } else {
        dispatch({ type: ACTION_GET_WISHLIST_LOADING });
        const deleteResponse = await axios.delete(UPDATE_WISHLIST(), {
          headers,
          data: payload
        });
        const statusCode = path(['data', 'statusCode'], deleteResponse);

        if (statusCode === '200') {
          dispatch({
            type: ACTION_WISHLIST_PRODUCT_REMOVED,
            data: { productId, wishlistProductsSkuArray }
          });
          if (accountDispatch && isWishlist) {
            showToast(
              {
                type: 'success',
                content: translate('Removed from favorites')
              },
              accountDispatch
            );
            // getWishListCount({}, accountDispatch);
          }
        }
      }
      getWishList({ noLoading }, dispatch);
      dispatch({ type: ACTION_UPDATE_WISHLIST_COMPLETE });

      if (!isWishlist) getWishListIds({}, dispatch);
    }
  } catch (e) {
    dispatch({ type: ACTION_UPDATE_WISHLIST_ERROR });

    showToast(
      {
        type: 'error',
        content: UNKNOWN_ERROR
      },
      accountDispatch
    );
    logError(e);
  }
}

export function resetProductState({ category, fromPdp }, dispatch) {
  dispatch({ type: ACTION_PRODUCT_STATE, data: { category, fromPdp } });
}

export function setCategoryInfo({ category }, dispatch) {
  dispatch({ type: ACTION_SET_CATEGORY_INFO, data: { category } });
}
