import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import './index.scss';

export default function Button({
  children,
  IconLeft = '',
  showLoader = false,
  className = '',
  loaderClass = '',
  ...props
}) {
  const [butnStyle, setButnStyle] = useState('primary');
  useEffect(() => {
    if (className.indexOf('primary') >= 0) {
      setButnStyle('primary');
    } else if (className.indexOf('secondary') >= 0) {
      setButnStyle('secondary');
    } else if (className.indexOf('normal') >= 0) {
      setButnStyle('normal');
    } else if (className.indexOf('black') >= 0) {
      setButnStyle('black');
    }
  }, [className]);
  return (
    <button
      className={classnames(
        'Button',
        {
          'fw-6 fs-14 cap b-box br-3': butnStyle !== 'normal',
          'b-none': butnStyle === 'normal' && className.indexOf('b-') < 0,
          'bg-none': butnStyle === 'normal' && className.indexOf('bg-') < 0,
          'b-green bg-green b-none c-white': butnStyle === 'primary',
          'b-Green-300 bg-white c-green': butnStyle === 'secondary',
          'b-black bg-black b-none c-white': butnStyle === 'black',
          'p-10': butnStyle !== 'normal' && className.indexOf('p-') < 0
        },
        className
      )}
      type="button"
      {...props}
    >
      {IconLeft}
      <If condition={showLoader}>
        <div className="flex center-xs w100p">
          <div className={`loader ${loaderClass}`} />
        </div>
      </If>
      <span className="Action">{children}</span>
    </button>
  );
}
