/* eslint-disable camelcase */
import React, { useState } from 'react';
import useParse from 'url-parse';
import { logEvent } from 'utils/analytics';
import classnames from 'classnames';
import './index.scss';

import {
  formatPrice,
  // getProductGalleryImg,
  getNavigationBasePath,
  getPLPImg
} from 'utils/';
import { PRODUCT_CAROUSEL } from 'constants/home';
import { formatProduct } from 'utils/product';

import { useHistory } from 'react-router-dom';
import { translate } from 'constants/language';
import { useStateValue } from 'context/app';
import { setPartialProductDetails } from 'containers/ProductDetail/actions';
import { isBrowser } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import lazyLoaderImageEnglish from './imageLazyLoader.png';
import lazyLoaderImageArabic from './imageLazyLoaderArabic.png';
import {
  CURRENT_LANG_PREFIX,
  CURRENCY_FRACTION,
  COUNTRY
} from '../../../../constants';

const lang = CURRENT_LANG_PREFIX;

export default ({
  analyticsData,
  productIndex,
  product,
  wrapperClass,
  cloumnStyling,
  rowType,
  isLast = false,
  itemTextCenterAlign = false,
  hasBGColor,
  slideWidth,
  caSource = 'home',
  caSourceType = '',
  caSourceValue = '',
  contextRuleId = '',
  caBannerPromoName = '',
  query = ''
}) => {
  const isArabic = lang === 'ar';
  const {
    name,
    images: { image: imageURL } = {},
    prices: { price, specialPrice },
    discount
    // brand: by
  } = product;

  const history = useHistory();
  const {
    productDetail: [, dispatch],
    account: [accountState]
  } = useStateValue();
  const { config: { cdnOptimizedImageConfig = {}, plp } = {} } = accountState;
  const { home: iscompress = false } = cdnOptimizedImageConfig;

  const urlObj = product.url
    ? useParse(product.url)
    : useParse(
        `https://${
          window.location.host
        }/product-${product.name.toLowerCase().replace(/ /g, '-')}-${
          product.sku
        }`
      );
  const pathname = urlObj.pathname
    .replace('/sa_en/', '/')
    .replace('/sa_ar/', '/')
    .replace('/ae_ar/', '/')
    .replace('/ae_en/', '/')
    .replace('/kw_en/', '/')
    .replace('/kw_ar/', '/')
    .replace(`/${COUNTRY}/`, '/')
    .replace(`/${CURRENT_LANG_PREFIX}/`, '/');

  const doOnClick = event => {
    event.preventDefault();
    if (urlObj) {
      setPartialProductDetails(
        {
          product,
          contextRuleId,
          query,
          caSource,
          caSourceType,
          caSourceValue,
          caBannerPromoName
        },
        dispatch
      );

      formatProduct({
        productDetail: product,
        listName: analyticsData.promoName,
        listPosition: productIndex
      }).then(response => {
        logEvent({
          eventName: 'select_item',
          screenName: 'home',
          data: {
            value: Number(response.price),
            items: [
              {
                item_id: response.id,
                item_name: response.name,
                item_brand: response.brand,
                item_category: response.category?.split('/')[0],
                item_category2: response.category?.split('/')[1],
                item_category3: response.category?.split('/')[2],
                item_category4: response.category?.split('/')[3],
                item_list_name: response.list_name,
                price: response.price,
                quantity: response.quantity
              }
            ]
          }
        });
      });

      history.push(`/${CURRENT_LANG_PREFIX}${pathname}`);
      window.scrollTo(0, 0);
    }
  };
  const colSpacingFallback = rowType === PRODUCT_CAROUSEL ? 8 : 4;
  const cloumnSpacing =
    cloumnStyling && cloumnStyling.spacing && cloumnStyling.spacing >= 0
      ? cloumnStyling.spacing
      : colSpacingFallback;
  let colSpacing = isBrowser ? cloumnSpacing * 2.5 : cloumnSpacing;
  if (rowType === PRODUCT_CAROUSEL) {
    colSpacing = 0;
  }

  const [columnWidth, setColumnWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setColumnWidth(ref.clientWidth - colSpacing);
    }
  };
  const colStyle = {
    marginLeft: !isLast && isArabic ? colSpacing : '',
    marginRight: !isLast && !isArabic ? colSpacing : '',
    width: isBrowser ? slideWidth - colSpacing : ''
  };
  const colStyle1 = {
    paddingLeft: colSpacing / 2,
    paddingRight: colSpacing / 2
  };

  const discountThreshold = plp?.bogo_ui_change?.threshold_value[COUNTRY] || 0;
  const discountTextColor = plp?.discount_text_color[COUNTRY] || '#d64045';
  return (
    <div
      ref={handelRef}
      style={rowType === PRODUCT_CAROUSEL ? colStyle : colStyle1}
      className={wrapperClass}
    >
      <a
        className={classnames('d-block product-item bg-white', {
          'ta-c': itemTextCenterAlign
        })}
        onClick={doOnClick}
        onKeyPress={doOnClick}
        href={`${getNavigationBasePath()}${pathname}`}
        title={name}
      >
        <div
          className="bg-thumb-gray mb-8 br-3 overflow-hidden"
          data-columnWidth={columnWidth}
          style={{ height: columnWidth && (1200 / 900) * columnWidth }}
        >
          <LazyLoadImage
            className="d-block odj-fit-cover"
            src={
              getPLPImg({
                imgURL: imageURL,
                reqWidth: 370,
                isBrowser,
                iscompress,
                page: 'home'
              }) ||
              (lang === 'ar' ? lazyLoaderImageArabic : lazyLoaderImageEnglish)
            }
            alt={name}
            width="100%"
            height={columnWidth && (1200 / 900) * columnWidth}
          />
        </div>
        <If condition={rowType === PRODUCT_CAROUSEL}>
          <div className="fs-10 c-dark-gray lh-14 ws-normal txt-overflow-ellipsis">
            {product.name}
          </div>
        </If>
        <div
          className={classnames('fs-12 fw-6 c-dark-gray mb-2 en-font', {
            'pl-5 pr-5': hasBGColor
          })}
        >
          {formatPrice(specialPrice || price, null, {
            maximumFractionDigits: CURRENCY_FRACTION,
            minimumFractionDigits: CURRENCY_FRACTION
          })}
        </div>
        <If condition={specialPrice && discount > discountThreshold}>
          <div
            className={classnames('fs-10 c-brown-gray mb-2', {
              'pl-5 pr-5': hasBGColor
            })}
          >
            <span className="c-very-light-pink-two strike en-font">
              {formatPrice(price, null, {
                maximumFractionDigits: CURRENCY_FRACTION,
                minimumFractionDigits: CURRENCY_FRACTION
              })}
            </span>
            &nbsp;
            <div
              className="fs-10 cap d-il-block ls-032"
              style={{ color: discountTextColor }}
            >
              <div className="d-il-block en-font">
                {Number(Math.round(discount))}%
              </div>
              {/* <div className="d-il-block">%</div> */}
              <span className="caps">&nbsp;{translate('off')}&nbsp;</span>
            </div>
          </div>
        </If>
        <If condition={!specialPrice}>
          <div
            className={classnames('fs-10 c-brown-gray mb-2', {
              'pl-5 pr-5': hasBGColor
            })}
          >
            &nbsp;
          </div>
        </If>
        {/* <div className="fs-9 c-brown-gray">
          {translate('by')} {by}
        </div> */}
      </a>
    </div>
  );
};
