import digest from 'subtle-digest';
import axios from 'utils/axios';

import hex from 'u8a/to-hex';
import u8a from 'u8a/from-string';
import {
  CURRENT_LANG_PREFIX,
  WEBSITE_DOMAIN,
  // DEFAULT_CURRENCY,
  CARD_PAYMENT_INDEX,
  COD_PAYMENT_INDEX,
  APPLE_PAYMENT_INDEX,
  PAYMENT_CODES,
  PAYMENT_CODE_VAULT,
  CASHFREE_PAYMENT_INDEX
} from 'constants/index';
import { getUtcTimeStamp, sortArrayOfObj } from '.';
import logError from './logError';

export async function createHash(string) {
  const digestVal = await new Promise(resolve => {
    digest('sha-256', u8a(string), function(err, buf) {
      resolve({ hash: hex(new Uint8Array(buf)) });
    });
  });
  return digestVal;
}

function compare(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export async function generateSignature(passphrase, object) {
  let signatureText = '';
  const keys = Object.keys(object);
  keys.sort(compare);

  keys.forEach(key => {
    signatureText = `${signatureText}${key}=${object[key]}`;
  });

  // console.log('signature text ', passphrase + signatureText + passphrase);

  const signature = await createHash(passphrase + signatureText + passphrase);

  return signature.hash;
}

export async function getToken({
  paymentRequest,
  payfortCredentials,
  isDelete = false,
  paymentConfigs = {}
}) {
  const [month, year] = paymentRequest.expiryDate.split('/');

  const expiryDate = `${year}${month}`;
  // const { host = '' } = window.location;
  // const returnUrl = `https://${host === 'qa01.stylifashion.com' ? 'qa01.stylifashion.com' : WEBSITE_DOMAIN
  //   }/cardToken`;
  const returnUrl = `https://${WEBSITE_DOMAIN}/cardToken`;
  const merchantReferenceId = getUtcTimeStamp();

  const data = {
    access_code: paymentConfigs.card.access_code,
    // card_holder_name,
    card_number: paymentRequest.cardNumber,
    expiry_date: expiryDate,
    language: CURRENT_LANG_PREFIX,
    merchant_identifier: paymentConfigs.card.merchant_identifier,
    merchant_reference: merchantReferenceId,
    remember_me: paymentRequest.saveCard ? 'YES' : 'NO',
    return_url: returnUrl,
    service_command: isDelete ? 'UPDATE_TOKEN' : 'TOKENIZATION',
    card_security_code: paymentRequest.cvv
    // device_fingerprint
  };

  const tokenSignatureData = {
    access_code: paymentConfigs.card.access_code,
    language: CURRENT_LANG_PREFIX,
    merchant_identifier: paymentConfigs.card.merchant_identifier,
    merchant_reference: merchantReferenceId,
    return_url: returnUrl,
    service_command: 'TOKENIZATION'
    // device_fingerprint
  };

  data.signature = await generateSignature(
    paymentConfigs.card.request_sha_phrase,
    tokenSignatureData
  );

  const formKeys = Object.keys(data);
  const iFrame = document.getElementById('tokenization');
  iFrame.src = payfortCredentials.tokenizationUrl;

  const iDocument = iFrame.contentWindow.document;

  const form = iDocument.createElement('form');
  formKeys.forEach(formKey => {
    const input = iDocument.createElement('input');
    input.type = 'hidden';
    input.name = formKey;
    input.value = data[formKey];
    form.append(input);
  });
  form.method = 'Post';
  form.action = payfortCredentials.tokenizationUrl;
  form.target = 'tokenization';

  iFrame.appendChild(form);

  form.submit();
  const tokenPromise = await new Promise(resolve => {
    function receiveMessage(event) {
      // if (
      //   event.origin !==
      //   `https://${host === 'qa01.stylifashion.com'
      //     ? 'qa01.stylifashion.com'
      //     : WEBSITE_DOMAIN
      //   }`
      // )
      if (event.origin !== `https://${WEBSITE_DOMAIN}`) return;
      if (typeof event.data === 'string') {
        resolve({ cardToken: event.data });
      }
    }

    window.addEventListener('message', receiveMessage, true);
  });

  return tokenPromise;
}

export async function isPaymentCompatible() {
  return '';
}

export async function deleteCard({
  cardDetails,
  payfortCredentials,
  paymentConfigs
}) {
  // console.log(cardDetails, payfortCredentials);

  const data = {
    access_code: paymentConfigs.card.access_code,
    language: CURRENT_LANG_PREFIX,
    merchant_identifier: paymentConfigs.card.merchant_identifier,
    merchant_reference: getUtcTimeStamp().toString(),
    service_command: 'UPDATE_TOKEN',
    token_name: cardDetails.cardToken,
    token_status: 'INACTIVE'
  };

  data.signature = await generateSignature(
    paymentConfigs.card.request_sha_phrase,
    data
  );
  // console.log('data', JSON.stringify(data.signature));
  try {
    const payfortResponse = await axios.post(
      payfortCredentials.purchaseUrl,
      {
        ...data
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return {
      ...payfortResponse
    };
  } catch (e) {
    logError(e);
  }
  return '';
}

export function getBankOfferData({ bankOffers = [], cardBin }) {
  let bankOffer =
    bankOffers &&
    bankOffers.filter(offer => offer.cardbinList.indexOf(Number(cardBin)) > -1);
  if (bankOffer) bankOffer = sortArrayOfObj(bankOffer, 'priority', 'asc');
  return bankOffer[0] || {};
}

export function getPaymentEnum(paymentMethod) {
  let paymentEnum = '';

  switch (paymentMethod) {
    case PAYMENT_CODES[CARD_PAYMENT_INDEX]:
    case PAYMENT_CODE_VAULT:
      paymentEnum = 'CARD';
      break;
    case PAYMENT_CODES[COD_PAYMENT_INDEX]:
      paymentEnum = 'CASH';
      break;
    case PAYMENT_CODES[APPLE_PAYMENT_INDEX]:
      paymentEnum = 'APPLE';
      break;
    case 'free':
      paymentEnum = 'WALLET';
      break;
    case 'tabby_installments':
      paymentEnum = 'TABBY INSTALLMENT';
      break;
    case 'tamara_installments_3':
      paymentEnum = 'TAMARA INSTALLMENT 3';
      break;
    case 'tamara_installments_6':
      paymentEnum = 'TAMARA INSTALLMENT 6';
      break;
    case 'tabby_paylater':
      paymentEnum = 'TABBY PAY LATER';
      break;
    case 'cashfree':
      paymentEnum = 'CASHFREE PAYMENT';
      break;
    default:
  }
  // console.log({ paymentMethod, paymentEnum })
  return paymentEnum;
}

export function getEnabledPayments(availableMethods = []) {
  let applePayEnabled = false;
  let cardEnabled = false;
  let walletEnabled = false;
  let codEnabled = false;
  let tabbyInstallEnabled = false;
  let tamaraInstall3Enabled = false;
  let tamaraInstall6Enabled = false;
  let tabbyPayLaterEnabled = false;
  let cashFreeEnabled = false;

  availableMethods.forEach(paymentMethod => {
    switch (paymentMethod) {
      case PAYMENT_CODES[CARD_PAYMENT_INDEX]:
      case PAYMENT_CODE_VAULT:
        cardEnabled = true;
        break;
      case PAYMENT_CODES[COD_PAYMENT_INDEX]:
        codEnabled = true;
        break;
      case PAYMENT_CODES[APPLE_PAYMENT_INDEX]:
        applePayEnabled = true;
        break;
      case 'free':
        walletEnabled = true;
        break;
      case 'tabby_installments':
        tabbyInstallEnabled = true;
        break;
      case 'tamara_installments_3':
        tamaraInstall3Enabled = true;
        break;
      case 'tamara_installments_6':
        tamaraInstall6Enabled = true;
        break;
      case 'tabby_paylater':
        tabbyPayLaterEnabled = true;
        break;
      case PAYMENT_CODES[CASHFREE_PAYMENT_INDEX]:
        cashFreeEnabled = true;
        break;

      default:
    }
  });

  return {
    applePayEnabled,
    cardEnabled,
    walletEnabled,
    codEnabled,
    tabbyInstallEnabled,
    tamaraInstall3Enabled,
    tamaraInstall6Enabled,
    tabbyPayLaterEnabled,
    cashFreeEnabled
  };
  // console.log({ paymentMethod, paymentEnum })
}
