import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SlidePane from 'components/libs/SlidePane';
import Title from 'components/libs/Heading/Title';
// import SubTitle from 'components/libs/Heading/SubTitle';
import {
  resetRegistration,
  register,
  sendOTP
} from 'containers/Account/actions';
import { logEvent } from 'utils/analytics';

import ModalHeader from 'components/ModalHeader';
import Input from 'components/libs/Input';
import Button from 'components/libs/Buttons';
import { useStateValue } from 'context/app';
import { PHONE_CODE, COUNTRY, ARABIC_LANG } from 'constants/index';
import { getCurrentLanguage, getNavigationBasePath, getStoreId } from 'utils/';

import aeFlag from 'assets/images/uaeFlag.png';
import saFlag from 'assets/images/saudiFlag.png';
import kwFlag from 'assets/images/kuwaitFlag.png';
import QaFlag from 'assets/images/qatFlag.png';
import BhFlag from 'assets/images/bahFlag.png';
import inFlag from 'assets/images/indFlag.png';
import BackPopup from 'assets/icons/back-popup.svg';
import CloseIcon from 'assets/icons/close-popup.svg';

import {
  isValidPhone,
  isValidEmail,
  isValidPassword,
  isValidFirstOrLastName
} from 'utils/validators';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import VerifyPhone from 'components/VerifyPhone';

import { translate } from '../../constants/language';
import { isIOS } from '../../constants';
import Password from '../Login/password';

export const isRTL = getCurrentLanguage() === ARABIC_LANG;

export default ({
  showRegistrationDialog = false,
  onBack = () => {},
  eventAction = ''
}) => {
  const storeId = getStoreId();

  const countryCode = PHONE_CODE[COUNTRY.toUpperCase()];
  const flagObj = {
    ae: aeFlag,
    sa: saFlag,
    kw: kwFlag,
    bh: BhFlag,
    qa: QaFlag,
    in: inFlag
  };
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [type, setType] = useState('password');
  const [suffixIconText, setSuffixIconText] = useState(translate('SHOW'));
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [isMobileDisabled, setIsMobileDisabled] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [modalContainerRef, setModalContainerRef] = useState();

  const {
    account: [accountState, dispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();
  const {
    isRegistrationProcessing,
    config: { otpVerfication } = {}
  } = accountState;
  const phoneMandatoryRegisterForm = otpVerfication?.phoneMandatoryRegisterForm?.toString();
  const phoneVerifyRegister = otpVerfication?.phoneVerifyRegister?.toString();

  const [pageScrolled, setPageScrolled] = useState(false);

  const listner = () => {
    if (modalContainerRef.scrollTop > 37) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  };

  useEffect(() => {
    if (modalContainerRef && !pageScrolled)
      modalContainerRef.addEventListener('scroll', listner);
  }, [modalContainerRef]);

  useEffect(() => {
    if (accountState.loginType === 'MOBILE_LOGIN') {
      setMobile(Number(accountState.useridentifier));
      setIsMobileDisabled(true);
      setEmail('');
      setIsEmailDisabled(false);
    } else {
      setEmail(accountState.useridentifier);
      setIsEmailDisabled(true);
      setMobile('');
      setIsMobileDisabled(false);
    }
  }, [accountState.useridentifier]);

  const registerFailureLogEvent = reason => {
    const eventData = {
      event_category: 'login_signup',
      reason
    };
    logEvent({
      eventName: 'sign_up_form_failure',
      data: eventData,
      screenName: 'User_Registration'
    });
  };

  const handleError = () => {
    let hasError = false;
    if (!firstName) {
      hasError = true;
      setFirstNameError(translate('Please enter a valid value'));
    }
    if (firstName && !isValidFirstOrLastName(firstName)) {
      hasError = true;
      setFirstNameError(translate('No Special Characters allowed'));
      registerFailureLogEvent('Invalid Name');
    }

    if (!lastName) {
      hasError = true;
      setLastNameError(translate('Please enter a valid value'));
    }
    if (lastName && !isValidFirstOrLastName(lastName)) {
      hasError = true;
      setLastNameError(translate('No Special Characters allowed'));
      registerFailureLogEvent('Invalid Name');
    }

    if (!email) {
      hasError = true;
      setEmailError(translate('Please enter a valid value'));
    }

    if (email && !isValidEmail(email)) {
      hasError = true;
      setEmailError(translate('Invalid email. Can you check the format?'));
      registerFailureLogEvent('Invalid Email');
    }
    if (
      !mobile &&
      otpVerfication &&
      phoneMandatoryRegisterForm &&
      phoneMandatoryRegisterForm === 'true'
    ) {
      hasError = true;
      setMobileError(translate(translate('Please enter a valid value')));
    }
    if (mobile && !isValidPhone(mobile, storeId)) {
      hasError = true;
      setMobileError(
        [13, 12, 14, 16, 18, 20].indexOf(storeId) > -1
          ? translate(
              `Please use a valid mobile number${
                COUNTRY.toLowerCase() === 'in' ? '' : ' (eg. 50123456)'
              }`
            )
          : translate(
              `Please use a valid mobile number${
                COUNTRY.toLowerCase() === 'in' ? '' : ' (eg. 501234567)'
              }`
            )
      );
      registerFailureLogEvent('Invalid Phone');
    }

    if (!password) {
      hasError = true;
      setPasswordError(translate('Please enter a valid value'));
    }
    if (password && !isValidPassword(password)) {
      hasError = true;
      setPasswordError(translate('Min. 6 characters'));
    }
    return hasError;
  };
  const [registerData, setRegisterData] = useState({});
  const clickHandler = () => {
    if (handleError()) {
      return false;
    }
    const phone = mobile ? `${countryCode} ${mobile}` : '';

    const rData = {
      firstName,
      lastName,
      countryCode,
      mobile,
      email,
      password,
      quote: shippingState.quote,
      eventActionFrom: eventAction
    };
    setRegisterData(rData);
    if (
      mobile &&
      otpVerfication &&
      phoneVerifyRegister &&
      phoneVerifyRegister === 'true'
    ) {
      sendOTP({ phone, isRegistration: true }, dispatch);
      return true;
    }
    return register(rData, dispatch, shippingDispatch);
  };
  const closeHandler = () => {
    resetRegistration({}, dispatch);
    onBack();
  };

  const handleFocus = input => {
    if (input && showRegistrationDialog) {
      // input.focus();
    } else if (input) {
      input.blur();
    }
  };

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
    if (event.target.value && isValidFirstOrLastName(event.target.value))
      setFirstNameError(false);
  };

  const handleLastNameChange = event => {
    setLastName(event.target.value);
    if (event.target.value && isValidFirstOrLastName(event.target.value))
      setLastNameError(false);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
    if (event.target.value && isValidEmail(event.target.value))
      setEmailError(false);
  };

  const handleMobileChange = event => {
    setMobile(event.target.value);
    if (event.target.value && isValidPhone(event.target.value, storeId))
      setMobileError(false);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
    if (event.target.value && isValidPassword(event.target.value))
      setPasswordError(false);
  };

  const showPasswordHandler = () => {
    if (type === 'password') {
      setType('text');
      setSuffixIconText(translate('HIDE'));
    } else {
      setType('password');
      setSuffixIconText(translate('SHOW'));
    }
  };

  return (
    <>
      <SlidePane
        isOpen={showRegistrationDialog}
        from="bottom"
        width="100%"
        style={{ 'box-sizing': 'border-box' }}
        overlayClassName={showRegistrationDialog && 'z-100'}
        height="100%"
        onRequestClose={closeHandler}
        className={classnames('top-0', {
          'top-0': !isIOS,
          '_pt-for-header': !isBrowser
        })}
        setContainerRef={setModalContainerRef}
        isCenter={true}
      >
        <MobileView>
          <ModalHeader
            onBackButtonPress={closeHandler}
            isBackButton={true}
            backAction={onBack}
            noBorder={true}
            title={translate("Let's create your account")}
            titleClassName={classnames('fs-18 mt-12', {
              'o-1 transition-03': pageScrolled,
              'o-0 transition-03': !pageScrolled
            })}
          />
        </MobileView>

        <div
          className={classnames(
            isBrowser ? 'ml-30 mr-30 mt-32 mb-32' : 'ml-25 mr-25 mt-15'
          )}
        >
          <BrowserView>
            <div className="flex middl-xs mb-16">
              <div
                className="flex middle-xs"
                role="button"
                onClick={onBack}
                onKeyPress={onBack}
              >
                <BackPopup className="rtl-flip" />
                <div className="fs-12 lh-16 ls-072 c-dark-gray ml-10 mr-10 fw-6">
                  {translate('Back')}
                </div>
              </div>
              <div
                className="mr-0 ml-auto"
                role="button"
                onClick={onBack}
                onKeyPress={onBack}
              >
                <CloseIcon />
              </div>
            </div>
          </BrowserView>
          <Title>
            <span className="fs-21 fw-5">
              {translate("Let's create your account")}
            </span>
          </Title>
          {/* <SubTitle>
            {translate('Your personal assistant for fashion')}
          </SubTitle> */}
          <div className="row mt-15">
            <Input
              label={`*${translate('First Name')}`}
              id="registerFirstName"
              labelClass="block ls-072 fs-12"
              inputRef={handleFocus}
              value={firstName}
              onChange={handleFirstNameChange}
              error={firstNameError}
              wrapClassNames={classnames('col-xs-12', {})}
              placeholder={translate(
                COUNTRY.toLowerCase() === 'in' ? 'John' : 'Abdul'
              )}
            />
          </div>
          <div className="row mt-15 ">
            <Input
              id="registerLastName"
              label={translate('*Last Name')}
              labelClass="block ls-072 fs-12"
              inputRef={handleFocus}
              value={lastName}
              onChange={handleLastNameChange}
              error={lastNameError}
              wrapClassNames={classnames('col-xs-12', {})}
              placeholder={translate(
                COUNTRY.toLowerCase() === 'in' ? 'Doe' : 'Rahman'
              )}
            />
          </div>
          <div className="row mt-15 ">
            <Input
              id="registerEmail"
              label={translate('*Your Email Address')}
              labelClass="block ls-072 fs-12"
              className="r-ta-l"
              inputRef={handleFocus}
              value={email}
              dir="ltr"
              type="email"
              onChange={handleEmailChange}
              error={emailError}
              wrapClassNames={classnames('col-xs-12', {})}
              placeholder={translate('youremail@example.com')}
              disabled={isEmailDisabled}
            />
          </div>
          <div className="row mt-15">
            <Input
              label={translate('Country code')}
              labelClass="block ls-072 fs-12"
              inputRef={handleFocus}
              value={countryCode}
              className={classnames('r-ta-r w90p en-font', {})}
              prefix={
                <img
                  width={24}
                  className="r-ml-15 mt-14 pos-abs h-24"
                  src={flagObj[COUNTRY]}
                  alt="flag"
                />
              }
              wrapClassNames={classnames('col-xs-4 r-pr-10', {})}
              disabled={true}
            />
            <Input
              id="registerMobile"
              label={
                phoneMandatoryRegisterForm === 'true'
                  ? translate('*Mobile Number')
                  : translate('Mobile Number')
              }
              labelClass="block ls-072 fs-12"
              inputRef={handleFocus}
              value={mobile}
              type="number"
              dir="ltr"
              onChange={handleMobileChange}
              error={mobileError}
              className={classnames('en-font r-ta-l no-spin', {})}
              wrapClassNames={classnames('col-xs-8 r-pl-10', {})}
              placeholder={
                COUNTRY.toLowerCase() === 'in' ? '' : 'Ex: 522626548'
              }
              disabled={isMobileDisabled}
            />
          </div>
          <div className="row mt-15">
            <Input
              id="registerPassword"
              label={translate('*Create Password')}
              labelClass="block ls-072 fs-13"
              inputRef={handleFocus}
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
              type={type}
              // className={classnames({ 'fs-50': type === 'password' })} // causing issues on real device
              wrapClassNames={classnames('col-xs-12', {})}
              suffixIcon={
                <span
                  role="button"
                  className={classnames(
                    isRTL ? 'mt-27' : 'mt-25',
                    'end fs-14 fw-6  pl-10 pr-10 lh-46'
                  )}
                  onClick={showPasswordHandler}
                  onKeyPress={showPasswordHandler}
                >
                  {suffixIconText}
                </span>
              }
            />
          </div>
          <Button
            className="w100p primary cap fs-16 fw-6 mt-15"
            buttonType="stretched"
            loadingOnDisabled={false}
            disabled={isRegistrationProcessing}
            onClick={clickHandler}
            id="registerUser"
          >
            {translate('Create My Account')}
          </Button>
          <div className="mt-40 ta-c">
            <span className="c-brown-gray fs-12">
              {translate('By signing up, you agree to Styli’s')}
            </span>
          </div>
          <div className="ta-c">
            <a
              href={`${getNavigationBasePath()}/account/terms`}
              target="_blank"
              rel="noopener noreferrer"
              className="c-black-light"
            >
              <span className="fs-12 bb-brand-dashed pb-2">
                {translate('Terms and Conditions')}
              </span>
            </a>
          </div>
        </div>
        <Password showPasswordDialog={accountState.otp} noBorder={true} />
        <VerifyPhone
          showVerifyPhoneDialog={accountState.showVerifyPhoneRegistr}
          phone={`${countryCode} ${mobile}`}
          isLoginFlow={false}
          isRegistration={true}
          registerData={registerData}
        />
      </SlidePane>
    </>
  );
};
