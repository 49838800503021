import {
  ACTION_SET_ORDER_DETAILS,
  ACTION_SET_ORDER_DETAILS_LOADING,
  ACTION_SET_ADDRESS
} from './actions';

export const initialState = { selectedTabIndex: 0, orderSummary: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_ORDER_DETAILS: {
      return {
        ...state,
        orderSummary: action.data,
        orderDetailsLoading: false
      };
    }
    case ACTION_SET_ORDER_DETAILS_LOADING: {
      return {
        ...state,
        orderDetailsLoading: true
      };
    }
    case ACTION_SET_ADDRESS: {
      return {
        ...state,
        selectedAddress: action.data
      };
    }

    default:
      return state;
  }
};
