import React from 'react';
import { IS_ARABIC } from 'constants/index';
import classnames from 'classnames';

export default ({
  wrapperClass,
  multipleEng = false,
  engHolder = '',
  engText = '',
  engTxtClass = '',
  text = '',
  engTextColor = '#3b3b3b'
}) => {
  const textArray = (engHolder && text.split(engHolder)) || [];

  return (
    <span className={wrapperClass}>
      <Choose>
        <When condition={multipleEng}>{}</When>
        <Otherwise>
          {textArray[0]}
          <span
            // className={`en-font ${engTxtClass} `}
            className={classnames(
              IS_ARABIC ? `ar-font ${engTxtClass} ` : `en-font ${engTxtClass} `
            )}
            style={{ color: engTextColor }}
          >
            {engText}
          </span>
          {textArray[1]}
        </Otherwise>
      </Choose>
    </span>
  );
};
