import React, { useEffect, useState } from 'react';
import values from 'object.values';
import isEmpty from 'lodash.isempty';
import Helmet from 'react-helmet';
import { translate } from 'constants/language';
import './index.scss';
import { useStateValue } from 'context/app';
import { logPageview } from 'utils/analytics';
import { getSkuLevelPrice } from 'utils/product';

import { getWishList, toggleWishList } from 'containers/ProductList/actions';
import SomethingWentWrong from 'components/SomethingWentWrong';

import Login from 'components/Login';
import { getCustomerId } from 'utils/user';
import {
  getProductDetails,
  getSizeChart,
  resetProductDetails,
  fetchYouMayLikeCount
} from 'containers/ProductDetail/actions';
import SelectSize from 'containers/ProductDetail/views/Page/SelectSize';
import { addToCart, getBagCount } from 'containers/Checkout/actions';

import { showToast } from 'containers/Account/actions';
import ProductTupple from '../ProductTupple';
import NoProduct from '../NoProduct';
import { CURRENT_LANG_PREFIX, COUNTRY } from '../../../../constants';

export default ({ inSideSearch = false, setReloadQuote = () => {} }) => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [addToBagClicked, setAddToBagClicked] = useState(false);
  const [isSizeOptionModalOpen, setSizeOptionsModal] = useState(false);
  const {
    products: [productsState, dispatch],
    account: [accountState, accountDispatch],
    productDetail: [detailState, detailDispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();
  const { bagCount } = shippingState;
  const {
    config: {
      sizeChartConfig = {},
      crossSellConfig = {},
      vmUrlConfig = {}
    } = {}
  } = accountState;

  let vmUrlConfigValues = {};
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === 'development') {
    vmUrlConfigValues = vmUrlConfig?.dev;
  } else {
    vmUrlConfigValues = vmUrlConfig[`${nodeEnv}`];
  }

  const { size_add_direct: addDirectSizes = [] } = crossSellConfig || {};
  const [selectedSize, setSelectedSize] = useState(false);

  const lang = CURRENT_LANG_PREFIX;

  const canonicalLink = window.location.href;
  const alternateLang = lang === 'en' ? 'ar' : 'en';
  const alternativeLink = canonicalLink.replace(
    `/${lang}/`,
    `/${alternateLang}/`
  );

  const {
    wishlistProducts = {},
    wishlistLoading,
    wishListFailure = false,
    wishlistProductsSkuArray = []
  } = productsState;
  const {
    productDetail = {},
    sizeChartData,
    sizeChartLoading,
    productCatInfo
  } = detailState;
  const { configProducts: sizes = [] } = productDetail;
  const quoteSelectedSizes = {};

  const { enabled: mulinSChartEabled } = sizeChartConfig;
  const sizeLevelData = getSkuLevelPrice({
    configProducts: productDetail.configProducts,
    size: selectedSize && selectedSize.size
  });
  const prodPrices =
    selectedSize && sizeLevelData.prices
      ? sizeLevelData.prices
      : productDetail.prices;
  const isInBag =
    quoteSelectedSizes &&
    values(quoteSelectedSizes).find(
      item => Number(item.productId) === Number(selectedSize.productId)
    );
  const [currentBagCount, setCurrentBagCount] = useState();
  const [currentWishListState, setWishListState] = useState(false);
  const [currentProductDetail, setCurrentProductDetail] = useState();
  const [isProducts, setIsProducts] = useState(false);
  const productCheckArr = [];
  // let setCount = 0;
  const handelCartState = async ({ sizeData }) => {
    if (
      !detailState ||
      detailState.isOutOfStock ||
      shippingState.isAddToCartLoading ||
      !productDetail.isVisibleProduct ||
      !prodPrices.price ||
      shippingState.manageMetadataLoading
    ) {
      return false;
    }
    const cSizeData = selectedSize || sizeData;
    if (cSizeData) {
      if (isInBag) {
        window.scrollTo(0, 0);
      } else {
        setSizeOptionsModal(false);
        setCurrentBagCount(shippingState.bagCount);
        setCurrentProductDetail(productDetail);
        await addToCart(
          {
            productId: Number(cSizeData.id),
            parentProductId: productDetail.id,
            productDetail,
            sku: cSizeData.sku,
            parentSku: productDetail.sku,
            sizeOptionId: cSizeData.sizeOptionId,
            quantity: 1,
            quote: shippingState.quote,
            productCatInfo,
            accountDispatch
          },
          shippingDispatch
        );
        getBagCount(shippingDispatch);
        resetProductDetails({}, detailDispatch);
        setReloadQuote(true);
      }
    } else {
      setSizeOptionsModal(false);
    }
    return false;
  };
  useEffect(() => {
    getWishList({}, dispatch);
  }, [currentWishListState]);
  // async function callToggleWishList(id, wishListItemId) {
  //   await toggleWishList(
  //     {
  //       productId: id,
  //       wishListItemId,
  //       mode: 'delete'
  //     },
  //     detailDispatch,
  //     accountDispatch
  //   );
  // }
  useEffect(() => {
    const method = async () => {
      // if (bagCount && currentBagCount && bagCount > currentBagCount) {
      if (bagCount && bagCount > currentBagCount) {
        showToast(
          {
            type: 'success',
            content: 'Item added to bag successfully.'
          },
          accountDispatch
        );
        const wishlistItem = wishlistProducts[currentProductDetail.id];

        await toggleWishList(
          {
            productId: currentProductDetail.id,
            wishListItemId: wishlistItem.wishListItemId,
            mode: 'delete'
          },
          detailDispatch,
          accountDispatch
        );
        setWishListState(!currentWishListState);
      }
      if (bagCount && currentBagCount && bagCount === currentBagCount) {
        showToast(
          {
            type: 'error',
            content: 'Unable to add Item to bag.'
          },
          accountDispatch
        );
      }
    };
    method();
  }, [bagCount]);

  const { brandId, categoryIds } = productDetail || {};
  const handleSizeData = async (data, isFreeSize = false) => {
    if (!(sizeChartData && sizeChartData._id)) {
      await getSizeChart({ brandId, categoryIds, ...data }, detailDispatch);
      if (isFreeSize) handelCartState({ sizeData: data, isFreeSize: true });
    }
  };
  useEffect(() => {
    if (addToBagClicked && !isEmpty(productDetail)) {
      if (
        productDetail.configProducts &&
        productDetail.configProducts.length === 1 &&
        addDirectSizes &&
        !detailState.isOutOfStock &&
        addDirectSizes.indexOf(
          productDetail.configProducts[0].size.toLowerCase()
        ) >= 0
      ) {
        const { endPoint: endPointObj } = sizeChartConfig;
        const endPoint = endPointObj && endPointObj[process.env.NODE_ENV];
        const cSizeData = {
          ...productDetail.configProducts[0],
          productId:
            productDetail.configProducts[0].id ||
            productDetail.configProducts[0].productId
        };
        setSelectedSize({ ...cSizeData });
        setAddToBagClicked(false);
        handleSizeData({ ...cSizeData, endPoint }, true);
      } else {
        if (!detailState.isOutOfStock) {
          setSizeOptionsModal(true);
        } else {
          showToast(
            {
              type: 'error',
              content: translate('This item out of stock.')
            },
            accountDispatch
          );
        }
        setAddToBagClicked(false);
      }
    }
  }, [productDetail]);

  const reloadAPI = () => {
    if (isEmpty(wishlistProducts) && !wishlistLoading) {
      getWishList({}, dispatch);
    }
  };

  const handleToggleLoginModal = () => {
    window.history.back();
  };

  const handleLoginSuccess = () => {
    reloadAPI();
    setShowLoginDialog(false);
  };

  useEffect(() => {
    logPageview({ screenName: 'My_Favorites' });
    getWishList({}, dispatch);
  }, []);

  useEffect(() => {
    if (!getCustomerId()) {
      setShowLoginDialog(true);
    }
  }, []);

  const products = [];

  (wishlistProductsSkuArray || []).forEach(value => {
    products.push(wishlistProducts[value]);
  });

  const handelAddToBag = ({ e, id, sku }) => {
    e.preventDefault();
    e.stopPropagation();
    setAddToBagClicked(true);
    setSelectedSize(false);
    getProductDetails(
      {
        id,
        prodSKU: sku,
        vmUrlConfigValues
      },
      detailDispatch
    );
  };

  useEffect(() => {
    if (!isProducts && products.length) {
      setIsProducts(true);
      const recomendationQty = 0;
      products.forEach(function(data) {
        const productSku = data.id;
        let stockQty = 0;
        data.configProducts.forEach(function(configdata) {
          stockQty += parseFloat(configdata.quantity);
        });
        if (stockQty === 0) {
          // recommendationCount
          fetchYouMayLikeCount({ productSku }, detailDispatch);
        }

        productCheckArr[productSku] = {
          productSku,
          recomendationQty
        };
      });
    }
  }, [products]);

  useEffect(() => {
    if (addToBagClicked && !isEmpty(productDetail)) {
      if (
        productDetail.configProducts &&
        productDetail.configProducts.length === 1 &&
        addDirectSizes &&
        addDirectSizes.indexOf(
          productDetail.configProducts[0].size.toLowerCase()
        ) >= 0
      ) {
        //  const { endPoint: endPointObj } = sizeChartConfig;
        // const endPoint = endPointObj && endPointObj[process.env.NODE_ENV];
        const cSizeData = {
          ...productDetail.configProducts[0],
          productId:
            productDetail.configProducts[0].id ||
            productDetail.configProducts[0].productId
        };
        setSelectedSize({ ...cSizeData });
        setAddToBagClicked(false);
        // handleSizeData({ ...cSizeData, endPoint }, true);
      } else {
        if (!detailState.isOutOfStock) {
          setSizeOptionsModal(true);
        } else {
          showToast(
            {
              type: 'error',
              content: translate('This item out of stock.')
            },
            accountDispatch
          );
        }
        setAddToBagClicked(false);
      }
    }
  }, [productDetail]);
  return (
    <>
      <Choose>
        <When condition={!inSideSearch}>
          <Helmet>
            <title>{translate('WishList')}</title>
            <meta name="description" content={translate('WishList')} />
            <link rel="canonical" href={canonicalLink} />
            <link
              rel="alternate"
              hrefLang={`${alternateLang}-sa`}
              href={alternativeLink.replace(`/${COUNTRY}/`, '/sa/')}
            />
            <link
              rel="alternate"
              hrefLang={`${CURRENT_LANG_PREFIX}-sa`}
              href={canonicalLink.replace(`/${COUNTRY}/`, '/sa/')}
            />
            <link
              rel="alternate"
              hrefLang={`${alternateLang}-ae`}
              href={alternativeLink.replace(`/${COUNTRY}/`, '/ae/')}
            />
            <link
              rel="alternate"
              hrefLang={`${CURRENT_LANG_PREFIX}-ae`}
              href={canonicalLink.replace(`/${COUNTRY}/`, '/ae/')}
            />
            <link
              rel="alternate"
              hrefLang={`${alternateLang}-bh`}
              href={alternativeLink.replace(`/${COUNTRY}/`, '/bh/')}
            />
            <link
              rel="alternate"
              hrefLang={`${CURRENT_LANG_PREFIX}-bh`}
              href={canonicalLink.replace(`/${COUNTRY}/`, '/bh/')}
            />
            <link
              rel="alternate"
              hrefLang={`${alternateLang}-kw`}
              href={alternativeLink.replace(`/${COUNTRY}/`, '/kw/')}
            />
            <link
              rel="alternate"
              hrefLang={`${CURRENT_LANG_PREFIX}-kw`}
              href={canonicalLink.replace(`/${COUNTRY}/`, '/kw/')}
            />
          </Helmet>
          <Choose>
            <When condition={!wishlistLoading && wishListFailure}>
              <If condition={accountState.loggedIn}>
                <div className="ta-c p-20">
                  <SomethingWentWrong onClick={reloadAPI} />
                </div>
              </If>
            </When>
            <Otherwise>
              <Choose>
                <When condition={wishlistLoading}>
                  <div className="row">
                    {new Array(10).fill(0).map(() => {
                      return (
                        <ProductTupple
                          productDetail={{}}
                          isLoading={true}
                          key={`${Math.random()}`}
                        />
                      );
                    })}
                  </div>
                </When>
                <Otherwise>
                  <If condition={products.length === 0}>
                    <NoProduct iscart={true} />
                  </If>

                  <div className="row">
                    <If condition={products.length}>
                      {products.map((value, index) => {
                        return (
                          <ProductTupple
                            productDetail={{
                              ...value,
                              discountPercentage: value.discount,
                              wishListItemId: value?.id ? value.id || 0 : -1
                            }}
                            productIndex={index}
                            key={value.id}
                            productDispatch={dispatch}
                            // threshold={1500}
                            isCart={true}
                            isWishlist={true}
                            caSource="wishlist"
                            caSourceType="wishlist"
                            caSourceValue={value.id}
                            handelAddToBag={handelAddToBag}
                            recommendationCount={productCheckArr}
                            showWishlistIcon={true}
                          />
                        );
                      })}
                    </If>
                    <If condition={isSizeOptionModalOpen === true}>
                      <SelectSize
                        productDetail={productDetail}
                        isOpen={isSizeOptionModalOpen}
                        sizes={sizes}
                        onSelectSize={setSelectedSize}
                        onAddToCart={handelCartState}
                        selectedSize={selectedSize}
                        quoteSelectedSizes={quoteSelectedSizes}
                        onClose={() => {
                          setSizeOptionsModal(false);
                        }}
                        mulinSchartEabled={mulinSChartEabled}
                        handleMulinSizeData={handleSizeData}
                        sizeChartConfig={sizeChartConfig}
                        sizeChartData={sizeChartData}
                        sizeChartLoading={sizeChartLoading}
                      />
                    </If>
                  </div>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
          <Login
            showLoginDialog={showLoginDialog}
            noBorder={true}
            eventAction="wishlist"
            screenName="Wishlist"
            onBack={handleToggleLoginModal}
            onLoginSuccess={handleLoginSuccess}
          />
        </When>
        <Otherwise>
          <If condition={products.length}>
            <>
              <div className="mt-8 mb-8 fw-6 fs-14 lh-18 ls-064">
                {' '}
                {translate('Your Wishlist')}
              </div>
              <div className="overflow-auto-no-bar ml-n-5 mr-n-5">
                {products.map((value, index) => {
                  return (
                    <div className="d-il-block w-150">
                      <ProductTupple
                        productDetail={{
                          ...value,
                          discountPercentage: value.discount
                        }}
                        productIndex={index}
                        key={value.id}
                        productDispatch={dispatch}
                        // threshold={1500}
                        isCart={true}
                        isWishlist={true}
                        isSmallThumb={inSideSearch}
                        caSource="wishlist"
                        caSourceType="wishlist"
                        caSourceValue={value.id}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          </If>
        </Otherwise>
      </Choose>
    </>
  );
};
