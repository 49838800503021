import React, { useState, useEffect } from 'react';

import lazyLoaderImageEnglish from 'assets/images/imageLazyLoader.png';
import lazyLoaderImageArabic from 'assets/images/imageLazyLoaderArabic.png';
import lazyLoaderImageEnglishSquare from 'assets/images/imageLazyLoaderSquare.png';
import lazyLoaderImageArabicSquare from 'assets/images/imageLazyLoaderArabicSquare.png';
import { CURRENT_LANG_PREFIX } from 'constants/index';

export default function Img({
  src = '',
  alt = '',
  isSquare = false,
  ...props
}) {
  const [localSRC, setLocalSRC] = useState(src);
  const loaderImageEnglish = isSquare
    ? lazyLoaderImageEnglishSquare
    : lazyLoaderImageEnglish;
  const loaderImageArabic = isSquare
    ? lazyLoaderImageArabicSquare
    : lazyLoaderImageArabic;

  const placeHolderImg =
    CURRENT_LANG_PREFIX === 'ar' ? loaderImageArabic : loaderImageEnglish;

  useEffect(() => {
    setLocalSRC(src);
  }, [src]);
  const fallBackSrc = () => {
    setLocalSRC(placeHolderImg);
  };
  return <img src={localSRC} alt={alt} onError={fallBackSrc} {...props} />;
}
