import axios from 'utils/axios';
import { timingVisualComplete } from 'utils/analytics';

import { CURRENT_LANG_PREFIX, COUNTRY, COUNTRY_KSA } from '../../constants';

export const ACTION_GET_LANDING_PAGE = 'landing/GET_LANDING_PAGE';

export const getLandingPage = async (option, dispatch) => {
  const startTime = new Date().getTime();
  const result = await axios.get(
    `/strapi_pages/processed/landing/${COUNTRY !== COUNTRY_KSA ? `${COUNTRY}_` : ''
    }${CURRENT_LANG_PREFIX}-${option.pageName}.json`
  );
  dispatch({ type: ACTION_GET_LANDING_PAGE, data: result.data });
  const endTime = new Date().getTime();
  timingVisualComplete(endTime - startTime);
};
