import React from 'react';
import classnames from 'classnames';
import { getNavigationBasePath } from 'utils';
import { isBrowser } from 'react-device-detect';
import './index.scss';

export default ({
  text = '',
  link = '',
  bottomSpace,
  onWidgetClick = () => { },
  viewMoreObj,
  txtColor = null
}) => {
  const doOnClick = event => {
    onWidgetClick({ event, widget: { ...viewMoreObj } });
  };
  return (
    <div className="ta-c" style={{ paddingBottom: isBrowser ? 24 : bottomSpace || 8 }}>
      <a
        href={`${getNavigationBasePath()}/${link}`}
        onClick={doOnClick}
        onKeyPress={doOnClick}
        className={classnames(" fw-5 c-dark-gray d-il-block", isBrowser ? "b-dark-gray fs-14 lh-20 ls-16 pt-14 pb-14 w-150" : "pb-2 fs-15 bb-brand-dashed")}
        style={{ color: txtColor }}
      >
        {text}
      </a>
    </div>
  );
};
