/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import values from 'object.values';
import { useHistory } from 'react-router-dom';
import { getSkuLevelPrice } from 'utils/product';
import { addToCart, getBagCount } from 'containers/Checkout/actions';
import './index.scss';
import { useStateValue } from 'context/app';
import { isBrowser } from 'react-device-detect';
// import HeartAnimation from 'assets/lottie/HeartAnimation';
import HeartIcon from 'assets/icons/heart-o.svg';
import HeartFilled from 'assets/icons/heart-filled.svg';
import { translate } from '../../../../../constants/language';
import { CURRENT_LANG_PREFIX, IS_ARABIC } from '../../../../../constants';

export default ({
  productDetail = {},
  selectedSize,
  quoteSelectedSizes,
  productCatInfo,
  handleAddToWishlist = () => {},
  disableBag = false,
  wishlistItem,
  forDesktop = false,
  accountState = {},
  wishListItemId = -1,
  // doPlay = '',
  setSelectSizeWarning = () => {},
  setProdDiscount = () => {},
  setKnowYourSizeVisible = () => {}
}) => {
  const history = useHistory();
  const [addToCartAnimState, setAddToCartAnim] = useState('');
  const {
    shipping: [shippingState, shippingDispatch],
    productDetail: [productDetailState],
    account: [accountDispatch]
  } = useStateValue();

  const isInBag =
    quoteSelectedSizes &&
    values(quoteSelectedSizes).find(
      item => Number(item.productId) === Number(selectedSize.productId)
    );
  const { configProducts, discount, discountPercentage } = productDetail;

  const sizeLevelData = getSkuLevelPrice({
    configProducts,
    size: selectedSize && selectedSize.size
  });

  const prodPrices = selectedSize ? sizeLevelData.prices : productDetail.prices;

  const prodDiscount = selectedSize
    ? sizeLevelData.discount
    : discount || discountPercentage;

  useEffect(() => {
    if (prodDiscount) setProdDiscount(prodDiscount);
  }, [prodDiscount]);

  const handelCartState = async () => {
    if (
      productDetailState.isOutOfStock ||
      shippingState.isAddToCartLoading ||
      !productDetail.isVisibleProduct ||
      !prodPrices.price ||
      shippingState.manageMetadataLoading
    ) {
      return false;
    }

    if (disableBag) {
      if (wishlistItem) {
        window.scrollTo(0, 0);
        history.push(`/${CURRENT_LANG_PREFIX}/wishlist`);
        return false;
      }
      handleAddToWishlist();
      return false;
    }

    if (selectedSize) {
      if (isInBag) {
        window.scrollTo(0, 0);
        history.push(`/${CURRENT_LANG_PREFIX}/checkout/cart`);
      } else {
        if (addToCartAnimState.length <= 0) {
          setAddToCartAnim('_anim-step-1');
          setTimeout(() => {
            setAddToCartAnim('_anim-step-2');
          }, 700);
          setTimeout(() => {
            setAddToCartAnim('_anim-step-2 _anim-step-3');
          }, 800);
          setTimeout(() => {
            setAddToCartAnim('');
          }, 1200);
        }

        // if (productDetail.sku) {
        //   logEvent({
        //     eventName: 'add_to_cart',
        //     cleverTapData: {
        //       user_id: getCustomerId(),
        //       product_name: productDetail?.name,
        //       sku_id: productDetail?.sku,
        //       cat_id: productDetail?.categories?.level3[0],
        //       cat_name: productDetail?.categories?.level3[0],
        //       parent_cat_id: productDetail?.categories?.level2[0],
        //       parent_cat_name: productDetail?.categories?.level2[0],
        //       super_cat_id: productDetail?.categories?.level1[0],
        //       super_cat_name: productDetail?.categories?.level1[0],
        //       size: selectedSize?.size,
        //       price: Number(
        //         selectedSize?.specialPrice
        //           ? selectedSize?.specialPrice
        //           : selectedSize?.price
        //       ),
        //       source_screen_name: 'productDetailpage'
        //     },
        //     data: {
        //       event_category: 'cart_update',
        //       event_value: 'from_pdp_main',
        //       value: Number(
        //         productDetail?.prices?.specialPrice ||
        //           productDetail?.prices?.price
        //       ),
        //       items: await formatCartItem(
        //         productDetail,
        //         1,
        //         Number(selectedSize.id),
        //         {
        //           productCatInfo
        //         }
        //       )
        //     },
        //     isOnlyCleverTap: true
        //   });
        // }
        await addToCart(
          {
            productId: Number(selectedSize.id),
            parentProductId: productDetail.id,
            productDetail,
            sku: selectedSize?.sku,
            parentSku: productDetail?.sku,
            sizeOptionId: selectedSize?.sizeOptionId,
            quantity: 1,
            quote: shippingState?.quote,
            productCatInfo,
            caSource: productDetailState?.caSource,
            caSourceType: productDetailState.caSourceType,
            caSourceValue: productDetailState.caSourceValue,
            caSearchId: productDetailState.caSearchId,
            caBannerPromoName: productDetailState.caBannerPromoName,
            accountDispatch
          },
          shippingDispatch
        );

        getBagCount(shippingDispatch);
      }
    } else if (isBrowser && !selectedSize && !forDesktop) {
      setSelectSizeWarning(true);
    }
    return false;
  };

  const [iWishListItemId, setIWishListItemId] = useState(wishListItemId);
  useEffect(() => {
    setIWishListItemId(wishListItemId);
  }, [wishListItemId]);
  const handleAddToWishlistClick = () => {
    if (!accountState.loggedIn) {
      setKnowYourSizeVisible(false);
    }
    setIWishListItemId(!iWishListItemId);
    handleAddToWishlist();
  };
  return (
    <If condition={prodPrices}>
      <div
        className="flex bt-light-gray bottom-fixed pt-10 pr-15 pb-10 pl-15 z-3 bg-white middle-xs"
        style={{ gap: '10px' }}
      >
        <div className="col-xs-6">
          <div
            className={classnames(
              'fs-14 fw-6 ta-c cap h-48 b-box br-3 d-il-block c-green pt-15 pb-15 b-green w100p'
            )}
            onClick={handleAddToWishlistClick}
            onKeyPress={handleAddToWishlistClick}
            role="button"
            disabled={accountState.loggedIn && iWishListItemId > 0}
          >
            <Choose>
              <When condition={accountState.loggedIn && iWishListItemId > 0}>
                <HeartFilled
                  className="v-m ml-3 mr-3 c-green"
                  height="20px"
                  width="20px"
                />
                <span className="d-il-block v-m">{translate('Wishlist')}</span>
              </When>
              <Otherwise>
                <HeartIcon height="15px" className="v-m ml-3 mr-3 c-green" />
                <span className="d-il-block v-m">{translate('Wishlist')}</span>
              </Otherwise>
            </Choose>
          </div>
        </div>
        <div className="col-xs-6">
          <a
            loading={shippingState.addToCartLoading}
            disabled={shippingState.addToCartLoading || !selectedSize}
            className={classnames(
              'fs-14 fw-6 ta-c cap h-48 b-box br-3 d-il-block c-white ml-auto bg-green w100p',
              {
                'o-25':
                  !productDetail.id ||
                  productDetail.partial ||
                  productDetailState.isOutOfStock ||
                  !productDetail.isVisibleProduct ||
                  !prodPrices.price ||
                  shippingState.manageMetadataLoading ||
                  !selectedSize,
                'pt-15 pb-15': !shippingState.isAddToCartLoading
              }
            )}
            onClick={handelCartState}
            onKeyPress={handelCartState}
            role="button"
            id={isInBag ? 'goTobag' : 'addToBag'}
          >
            <Choose>
              <When condition={disableBag && wishlistItem}>
                {translate('Go to favourites')}
              </When>
              <When condition={productDetailState.isOutOfStock}>
                {translate('Out of Stock')}
              </When>
              <When
                condition={
                  productDetail.isVisibleProduct === false || !prodPrices.price
                }
              >
                {translate('Not Available')}
              </When>
              <When condition={shippingState.isAddToCartLoading}>
                <div className="flex center-xs w100p mt-10">
                  <div className="loader" />
                </div>
              </When>
              <Otherwise>
                <div
                  className={classnames('flex center-xs', {
                    'mt-n-3p': IS_ARABIC && isInBag
                  })}
                >
                  <div
                    className={classnames('', {
                      'mt-5': IS_ARABIC && isInBag
                    })}
                  >
                    {translate(isInBag ? 'Go to Bag' : 'Add to Bag')}
                  </div>
                </div>
              </Otherwise>
            </Choose>
          </a>
        </div>
      </div>
    </If>
  );
};
