import React from 'react';

export default ({ widgetData, bottomSpace }) => {
  function createMarkup(dHtml) {
    return { __html: dHtml };
  }

  return widgetData.map(video => {
    return (
      <If condition={video.videoEmbed}>
        <div
          style={{ marginBottom: bottomSpace }}
          dangerouslySetInnerHTML={createMarkup(video.videoEmbed)}
        />
      </If>
    );
  });
};
