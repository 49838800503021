// import { GET_PRODUCT_METADATA } from 'constants/api';
// import axios from 'utils/axios';
import logError from 'utils/logError';
import differenceInDays from 'date-fns/differenceInDays';
// import { getStoreId, path } from 'utils/';
import { logEvent } from './analytics';
import { getStorage, setStorage } from './storage';

function extractCategories({ categoryData, level, type = 'string' }) {
  let finalCategoryString = '';

  if (categoryData) {
    if (type === 'string') {
      finalCategoryString = categoryData.reduce(
        (finalString, categoryString) => {
          const categoryArr = categoryString.split('///');
          return finalString
            ? `${finalString},${categoryArr[level].trim()}`
            : categoryArr[level];
        },
        ''
      );
    } else {
      finalCategoryString = categoryData
        .reduce((finalString, category) => {
          return `${finalString},${category.categoryName.trim()}`;
        }, '')
        .substr(1);
    }
  }

  return finalCategoryString.trim();
}

export async function getProductCategories({ productId, categories = {} }) {
  let finalCat = '';
  let level1Cat = '';
  let level2Cat = '';
  let level3Cat = '';
  let level4Cat = '';
  // let metaData = '';
  try {
    let level1 = [];
    let level2 = [];
    let level3 = [];
    let level4 = [];

    if (Object.keys(categories).length) {
      level1 = categories.level0;
      level2 = categories.level1;
      level3 = categories.level2;
      level4 = categories.level3;
    }

    console.log(
      '🚀 ~ file: product.js:55 ~ getProductCategories ~ productId:',
      productId
    );

    level1Cat = extractCategories({
      categoryData: level1,
      level: 0,
      type: 'string'
    });
    level2Cat = extractCategories({
      categoryData: level2,
      level: 1,
      type: 'string'
    });
    level3Cat = extractCategories({
      categoryData: level3,
      level: 2,
      type: 'string'
    });
    level4Cat = extractCategories({
      categoryData: level4,
      level: 3,
      type: 'string'
    });
    finalCat = `${level1Cat}/${level2Cat}/${level3Cat}/${level4Cat}`;
  } catch (e) {
    logError(e);
  }
  return {
    finalCat,
    // metaData: (metaData && metaData.productCategoryInfos) || {}
    metaData: {}
  };
}

export async function formatProduct({
  productDetail,
  productCatInfo,
  fromSku,
  listName,
  listPosition = false
}) {
  const itemArr = [];
  try {
    if (productDetail) {
      const { finalCat: category } = await getProductCategories({
        productId: productDetail.id,
        categories: productDetail.categories
      });

      const obj = {
        id: productDetail.sku,
        name: productDetail.name,
        brand: productDetail.brand,

        quantity: 1,
        metadata: productCatInfo,
        currency: productDetail.currency
      };

      let objPrice = 0;

      if (productDetail.prices) {
        objPrice = Number(
          productDetail.prices.specialPrice || productDetail.prices.price
        );
      }
      obj.price = objPrice;

      if (listPosition || listPosition === 0) {
        obj.list_position = listPosition;
      }
      if (listName) {
        obj.list_name = listName;
      }
      if (category) {
        obj.category = category;
      }
      if (fromSku) {
        obj.fromSku = fromSku;
      }
      itemArr.push(obj);
    }
  } catch (e) {
    logError(e);
  }
  return itemArr;
}

export async function logProductViewEvent({ productDetail, productCatInfo }) {
  logEvent({
    eventName: 'view_item',
    data: {
      items: await formatProduct({ productDetail, productCatInfo })
    },
    cleverTapData: {
      product_name: productDetail.name,
      sku_id: productDetail.sku,
      cat_name: productDetail?.categories?.level3[0],
      cat_id: productDetail?.categoryIds ? productDetail?.categoryIds[3] : '',
      parent_cat_name: productDetail?.categories?.level2[0],
      parent_cat_id: productDetail?.categoryIds
        ? productDetail?.categoryIds[2]
        : '',
      super_cat_name: productDetail?.categories?.level1[0],
      super_cat_id: productDetail.categoryIds
        ? productDetail?.categoryIds[1]
        : '',
      source_screen_name: 'Category_Listing'
    },
    additionalData: { isMainPDP: true },
    screenName: 'Product_Details'
  });
  // }
}

export async function updateRecentlyViewed(sku) {
  let rVData = (await getStorage('rVData')) || [];
  rVData = rVData.filter((product, index) => {
    const dayDiff = differenceInDays(new Date().getTime(), product.viewedAt);
    return product.sku !== sku && index < 50 && dayDiff < 2;
  });
  const newObj = { sku, viewedAt: new Date().getTime() };
  rVData.push(newObj);
  await setStorage('rVData', rVData);
}

export async function removeRecentlyViewed(skuArr) {
  let rVData = (await getStorage('rVData')) || [];
  rVData = rVData.filter(product => skuArr.indexOf(product.sku) === -1);
  await setStorage('rVData', rVData);
}

export async function getLocalRecentlyViewed(numOfProducts) {
  let rVData = (await getStorage('rVData')) || [];
  rVData = rVData.reverse().slice(0, numOfProducts);
  return rVData;
}

export function getSkuLevelPrice({ configProducts = [], size }) {
  if (!configProducts.length) {
    return {};
  }
  const filteredProd = configProducts.find(cp => cp.size === size) || {};
  const { prices, discount } = filteredProd;
  return { prices, discount };
}
