import { IS_ARABIC } from 'constants/index';
import map from 'lodash.map';

import { getSelectedAddressId } from 'utils/';

import { getOrderSummaryFromQuote, validateCart } from 'utils/cart';
import { UNKNOWN_ERROR } from '../../constants/language';
import {
  // ACTION_SET_QUOTE_DETAILS,
  ACTION_SET_ORDER_DETAILS_LOADING,
  ACTION_SET_ADDRESS,
  ACTION_GET_ADDRESS_LOADING,
  ACTION_SET_SELECTED_ADDRESS_ID,
  ACTION_GET_CITY_LIST,
  ACTION_SAVE_ADDRESS_LOADING,
  ACTION_SET_ADDRESS_LIST,
  ACTION_SAVE_ADDRESS_SUCCESS,
  ACTION_SAVE_ADDRESS_FAILED,
  ACTION_SET_UPDATED_QUOTE_DETAILS,
  ACTION_PROCEED_TO_PAYMENT,
  ACTION_UPDATE_ADDRESS_WITH_MAPPER,
  ACTION_APPLY_COUPON_LOADING,
  ACTION_APPLY_COUPON_SUCCESS,
  ACTION_APPLY_COUPON_FAILED,
  ACTION_ADD_TO_BAG_LOADING,
  ACTION_CHANGE_QUOTE_DETAILS,
  ACTION_SIZE_STOCK_LOADING,
  ACTION_SIZE_STOCK_LOADED,
  ACTION_SHOW_TOAST,
  ACTION_HIDE_TOAST,
  ACTION_RESET,
  ACTION_MANAGE_METADATA_ERROR,
  ACTION_MANAGE_METADATA_SUCCESS,
  ACTION_MANAGE_METADATA_LODING,
  ACTION_GET_BAG_COUNT_LOADING,
  ACTION_GET_BAG_COUNT_FINISHED,
  ACTION_GET_TOTALS_FAILED,
  ACTION_GET_TOTALS_LOADING,
  ACTION_GET_TOTALS_SUCCESS,
  ACTION_GET_CITY_LIST_NEW,
  ACTION_DEFAULT_CITY_DETAILS_FETCHED,
  ACTION_DEFAULT_CITY_DETAILS_LOADING,
  ACTION_DEFAULT_CITY_DETAILS_ERROR,
  ACTION_UPDATE_ADDRESS,
  ACTION_UPDATE_QUOTE_METADATA
} from './actions';

export const initialState = {
  selectedTabIndex: 0,
  orderSummary: {}
};

// const addressTypes = { 1: 'Home', 2: 'Office' };

// const getOrderSummaryFromTotals = totals => {
//   return {
//     total: totals.subtotal,
//     baseGrandTotal: totals.grand_total,
//     shippingAmount: totals.shipping_amount,
//     discount: Number(totals.discount_amount),
//     couponDiscount: 0,
//     tax: totals.tax_amount
//   };
// };
const getFormattedProductItems = (products, quote) => {
  const formattedProductItems = products.map(item => {
    return {
      ...item,
      qty: Number(item.quantity),
      currency: quote.currency
    };
  });
  return formattedProductItems;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_RESET: {
      return { ...state, cartItems: undefined, bagCount: 0 };
    }

    case ACTION_SHOW_TOAST: {
      const { content, type, timeOut, ctaAction } = action.data;
      return {
        ...state,
        showToast: true,
        toastContent: content,
        toastType: type,
        toastTimeout: timeOut,
        ctaAction
      };
    }

    case ACTION_HIDE_TOAST: {
      return {
        ...state,
        showToast: false,
        toastContent: undefined,
        toastType: undefined,
        toastTimeout: undefined
      };
    }

    case ACTION_MANAGE_METADATA_ERROR: {
      return {
        ...state,
        manageMetadataLoading: false
      };
    }

    case ACTION_MANAGE_METADATA_SUCCESS: {
      return {
        ...state,
        manageMetadataLoading: false
      };
    }

    case ACTION_MANAGE_METADATA_LODING: {
      return {
        ...state,
        manageMetadataLoading: true
      };
    }
    case ACTION_SET_UPDATED_QUOTE_DETAILS: {
      const productItems = getFormattedProductItems(
        (action.data.quote && action.data.quote.products) || [],
        action.data.quote
      );
      const updatedQuote = {
        ...action.data.quote,
        paymentsConfig: state && state.quote && state.quote.paymentsConfig
      };

      return {
        ...state,
        quoteError: action.data.quoteError,
        orderSummary: getOrderSummaryFromQuote(
          action.data.quote,
          action.data.config
        ),
        selectedAddressId: getSelectedAddressId(action.data.quote || {}),
        productItems,
        bagErrors: validateCart(action.data.quote || {}),
        quote: updatedQuote,
        cartItems: action.data.cartItems || state.cartItems,
        currentCartCount: (
          (action.data.quote && action.data.quote.products) ||
          []
        ).length,
        orderDetailsLoading: false,
        cartModified: false,
        firstFreeShipping: action.data.firstFreeShipping || {}
      };
    }

    case ACTION_GET_TOTALS_LOADING: {
      const { isTotalPartialLoading } = action.data;
      return {
        ...state,
        totalsLoading: true,
        orderDetailsLoading: true,
        isTotalPartialLoading
      };
    }

    case ACTION_GET_TOTALS_SUCCESS: {
      const { quote, config } = action.data;
      const { quote: existingQuote } = state;
      return {
        ...state,
        totalsLoading: false,
        isTotalPartialLoading: false,
        orderDetailsLoading: false,
        orderSummary: {
          ...getOrderSummaryFromQuote(quote, config),
          prepaidPayable: quote.prepaidPayable
        },
        quote: { ...existingQuote, ...quote }
      };
    }
    case ACTION_GET_TOTALS_FAILED: {
      return {
        ...state,
        orderDetailsLoading: false,
        isTotalPartialLoading: false,
        totalsLoading: false
      };
    }

    case ACTION_ADD_TO_BAG_LOADING: {
      const { loading, cartItems = state.cartItems } = action.data;
      return {
        ...state,
        isAddToCartLoading: loading,
        // manageMetadataLoading: true,
        cartItems,
        cartModified: true
      };
    }

    case ACTION_GET_BAG_COUNT_LOADING: {
      return {
        ...state,
        bagCountLoading: true
      };
    }

    case ACTION_GET_BAG_COUNT_FINISHED: {
      const { bagCount } = action.data;
      return {
        ...state,
        bagCountLoading: false,
        bagCount
      };
    }

    case ACTION_SET_ADDRESS_LIST: {
      return {
        ...state,
        addressLoading: false,
        addresses: action.data.addresses,
        addressMapperModification: {},
        guestAddress: action.data.guestAddress
      };
    }
    case ACTION_CHANGE_QUOTE_DETAILS: {
      return {
        ...state,
        quote: { ...state.quote, ...action.data }
      };
    }
    case ACTION_UPDATE_ADDRESS_WITH_MAPPER: {
      return {
        ...state,
        addressMapperModification: action.data.addressMapperModification
      };
    }
    case ACTION_SET_ORDER_DETAILS_LOADING: {
      return {
        ...state,
        orderDetailsLoading: true,
        quoteError: false
      };
    }
    case ACTION_SET_SELECTED_ADDRESS_ID: {
      return {
        ...state,
        selectedAddressId: action.data.addressId
      };
    }
    case ACTION_SET_ADDRESS: {
      return {
        ...state,
        selectedAddress: action.data
      };
    }
    case ACTION_SAVE_ADDRESS_SUCCESS: {
      return {
        ...state,
        isAddressSaveLoading: false,
        showAddressSuccessMessage: action.data.showAddressSuccessMessage,
        selectedAddressId: action.data.addresId || state.selectedAddressId
      };
    }

    case ACTION_SAVE_ADDRESS_LOADING: {
      return {
        ...state,
        showAddressSuccessMessage: false,
        isAddressSaveLoading: true,
        addressFailedMessage: false
      };
    }

    case ACTION_SAVE_ADDRESS_FAILED: {
      return {
        ...state,
        isAddressSaveLoading: false,
        addressFailedMessage: action.data.statusMsg || UNKNOWN_ERROR
      };
    }

    case ACTION_GET_CITY_LIST: {
      const provinces = {};
      const { areas: areaMaster, provinces: provincesMaster } = action.data;
      map(provincesMaster, (country, countryCode) => {
        if (countryCode !== 'config') {
          if (!provinces[countryCode]) {
            provinces[countryCode] = {};
          }
          map(country, (province, code) => {
            const firstChar = province.name.charAt(0);
            if (!provinces[countryCode][firstChar]) {
              provinces[countryCode][firstChar] = {};
            }
            const citiesAlphabetical = {};
            map(province.cities, ({ name, id }) => {
              const firstCityChar = name.charAt(0);
              if (!citiesAlphabetical[firstCityChar]) {
                citiesAlphabetical[firstCityChar] = {};
              }
              const { areas: cityAreas = {} } = areaMaster[id] || {};
              const cityAreasAplhabetical = {};
              map(cityAreas, ({ id: areaId, name: areaName }) => {
                const firstAreaChar = areaName.charAt(0);
                if (!cityAreasAplhabetical[firstAreaChar]) {
                  cityAreasAplhabetical[firstAreaChar] = {};
                }
                cityAreasAplhabetical[firstAreaChar][areaId] = {
                  name: areaName,
                  id: areaId
                };
              });

              citiesAlphabetical[firstCityChar][name] = {
                name,
                areas: cityAreasAplhabetical
              };
            });

            provinces[countryCode][firstChar][code] = {
              ...province,
              citiesAlphabetical
            };
          });
        }
      });

      return {
        ...state,
        provinces,
        addressMapperModification: {}
        // cities: action.data.cities
      };
    }

    case ACTION_GET_CITY_LIST_NEW: {
      const { provinceData, cntry, lang } = action.data || {};
      const country = cntry.toUpperCase();
      const provinces = {};
      provinces[country] = {};

      map(provinceData, (province, provinceId) => {
        const firstChar =
          lang === 'en' ? province.name.charAt(0) : province.name_ar.charAt(0);
        if (!provinces[country][firstChar]) {
          provinces[country][firstChar] = {};
        }
        const provinceAlphabaticalCities = {};
        map(province.cities, cityData => {
          const cityName = IS_ARABIC ? cityData.name_ar : cityData.name_en;
          const firstCharCity = cityName.charAt(0);
          if (!provinceAlphabaticalCities[firstCharCity]) {
            provinceAlphabaticalCities[firstCharCity] = {};
          }

          const alphabaticalAreas = {};
          map(cityData.area, areaData => {
            const areaName = IS_ARABIC ? areaData.name_ar : areaData.name_en;
            const firstCharArea = areaName.charAt(0);
            if (!alphabaticalAreas[firstCharArea]) {
              alphabaticalAreas[firstCharArea] = {};
            }
            alphabaticalAreas[firstCharArea][areaName] = {
              ...areaData,
              name: areaName
            };
          });

          provinceAlphabaticalCities[firstCharCity][cityName] = {
            ...cityData,
            name: cityName,
            areas: alphabaticalAreas
          };
        });

        provinces[country][firstChar][provinceId] = {
          ...province,
          name: IS_ARABIC ? province.name_ar : province.name,
          citiesAlphabetical: provinceAlphabaticalCities
        };
      });

      return {
        ...state,
        provinces,
        addressMapperModification: {}
      };
    }

    case ACTION_PROCEED_TO_PAYMENT: {
      return {
        ...state,
        showAddressSuccessMessage: false,
        addressFailedMessage: false
        // orderSummary: undefined
      };
    }

    case ACTION_GET_ADDRESS_LOADING: {
      return {
        ...state,
        addressLoading: true,
        showAddressSuccessMessage: false,
        addressFailedMessage: false
      };
    }

    case ACTION_APPLY_COUPON_LOADING: {
      return {
        ...state,
        applyCouponSuccess: false,
        removeCouponSuccess: false,
        applyCouponFailed: false,
        applyCouponLoading: true,
        couponPropError: undefined
      };
    }

    case ACTION_APPLY_COUPON_SUCCESS: {
      const { removeCouponSuccess } = action.data || {};
      return {
        ...state,
        applyCouponSuccess: true,
        removeCouponSuccess,
        applyCouponLoading: false,
        couponPropError: undefined
      };
    }

    case ACTION_APPLY_COUPON_FAILED: {
      return {
        ...state,
        applyCouponFailed: true,
        removeCouponSuccess: false,
        applyCouponLoading: false,
        couponPropError: action.data && action.data.statusMsg
      };
    }

    case ACTION_SIZE_STOCK_LOADING: {
      return { ...state, sizeStock: undefined };
    }
    case ACTION_SIZE_STOCK_LOADED: {
      const { sizeStock } = action.data;
      return { ...state, sizeStock };
    }

    case ACTION_DEFAULT_CITY_DETAILS_LOADING: {
      return {
        ...state,
        defaultCityDetailsLoading: true,
        defaultCityDetailsError: false
      };
    }

    case ACTION_DEFAULT_CITY_DETAILS_ERROR: {
      return {
        ...state,
        defaultCityDetailsLoading: false,
        defaultCityDetailsError: true,
        defaultCityDetails: undefined,
        provincesNew: undefined
      };
    }

    case ACTION_DEFAULT_CITY_DETAILS_FETCHED: {
      return {
        ...state,
        defaultCityDetails: action.data.defaultCity || {},
        provincesNew: action.data.provinces || {},
        defaultCityDetailsLoading: undefined,
        defaultCityDetailsError: false
      };
    }

    case ACTION_UPDATE_ADDRESS: {
      const quote = { ...state.quote, ...action.data };
      return {
        ...state,
        quote
      };
    }

    case ACTION_UPDATE_QUOTE_METADATA: {
      return {
        ...state,
        quoteMetadata: action.data || []
      };
    }

    default:
      return state;
  }
};
