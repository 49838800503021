import React from 'react';
import classnames from 'classnames';

import CheckboxSquareInactive from './checkbox-inactive.svg';
import CheckboxSquareActive from './checkbox-active.svg';
import DisabledRadio from './radio-button-disabled.svg';
import RadioActive from './radio-button-active.svg';
import RadioInActive from './radio-button-inactive.svg';

import './index.scss';

export default ({
  checked,
  isSquare,
  className = '',
  checkBoxClass = '',
  isDisabledRadio = false
}) => (
  <Choose>
    <When condition={isSquare}>
      <Choose>
        <When condition={checked}>
          <CheckboxSquareActive className={('d-block', checkBoxClass)} />
        </When>

        <Otherwise>
          <CheckboxSquareInactive className={('d-block', checkBoxClass)} />
        </Otherwise>
      </Choose>
    </When>
    <Otherwise>
      <div className={className}>
        <Choose>
          <When condition={isDisabledRadio}>
            <DisabledRadio className={classnames('d-block', checkBoxClass)} />
          </When>
          <When condition={checked}>
            <RadioActive className={classnames('d-block', checkBoxClass)} />
          </When>
          <Otherwise>
            <RadioInActive className={classnames('d-block', checkBoxClass)} />
          </Otherwise>
        </Choose>
      </div>
    </Otherwise>
  </Choose>
);
