import React, { useState, useEffect } from 'react';
import './index.scss';
import classnames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import values from 'object.values';
import CategoryView from 'containers/CategoriesNew/views/Page';
import { getDesktopCategories } from 'containers/Home/actions';
import { useStateValue } from 'context/app';
import BackDrop from 'components/libs/BackDrop';
import isEmpty from 'lodash.isempty';
import { getTabCookie } from 'utils/';
import HeaderTop from './HeaderTop';
import MenuHeader from './MenuHeader';
import { CURRENT_LANG_PREFIX } from '../../constants';

export default function({ showHederTop = true }) {
  const tabValue = getTabCookie();
  const { category, level1 } = useParams();
  const categoryIndex = { women: 0, men: 1, kids: 2, beauty: 3 };
  const [activeTab, setactiveTab] = useState(
    categoryIndex[category || level1 || tabValue || 'women']
  );
  const {
    home: [homeState, dispatch],
    shipping: [shippingState],
    // account: [accountState],
    products: [productState]
  } = useStateValue();
  const { bagCount } = shippingState;
  const { wishlistCount: wishListCount } = productState;
  const { categoriesData = {}, isLoading = true } = homeState;
  useEffect(() => {
    if (isEmpty(categoriesData)) {
      getDesktopCategories({ page: 'ar_list_women' }, dispatch);
    }
  }, []);
  useEffect(() => {
    setactiveTab(categoryIndex[category || level1 || tabValue || 'women']);
    // logPageview();
  }, [window.location.pathname]);

  const [activeCategoryIndex, setActiveCategoryIndex] = useState(-1);
  const [isCategoryPaneOpen, setIsCategoryPaneOpen] = useState(false);
  const handelActiveCategoryIndex = index => {
    setActiveCategoryIndex(index);
    if (index >= 0) setIsCategoryPaneOpen(true);
  };
  const [tabs, setTabs] = useState([]);
  const [categoryPage, setCategoryPage] = useState([]);
  const [pageTypes, setPageTypes] = useState([]);
  useEffect(() => {
    const pageArray = values(categoriesData);
    if (pageArray && pageArray.length) {
      const tabArray = [];
      const catPages = [];
      const pTypeArr = [];
      pageArray.forEach(p => {
        tabArray.push({ title: p.title, tag: p.tag || {} });
        catPages.push(p.pageUniqueId && p.pageUniqueId.split('_').pop());
        pTypeArr.push(p.pageUniqueId);
      });
      setTabs(tabArray);
      setCategoryPage(catPages);
      setPageTypes(pTypeArr);
    }
  }, [categoriesData]);
  useEffect(() => {
    if (pageTypes.length && !pageTypes[activeTab] && !categoryPage[activeTab])
      setactiveTab(0);
  }, [activeTab, pageTypes, categoryPage]);
  const history = useHistory();
  const updateActiveTabHandler = (selectedTab, event) => {
    setactiveTab(selectedTab);
    if (event) {
      event.preventDefault();
    }
    setactiveTab(selectedTab);
    history.push(`/${CURRENT_LANG_PREFIX}/list/${categoryPage[selectedTab]}`);
    window.scrollTo(0, 0);
  };
  const closeCategoryPane = () => {
    setActiveCategoryIndex(-1);
    setIsCategoryPaneOpen(false);
  };
  // const [headerFixed, setHeaderFixed] = useState(false);
  const scrollListner = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    // setHeaderFixed(winScroll > 44);
    if (winScroll > 44)
      document.getElementsByTagName('BODY')[0].classList.add('_scrolled');
    else document.getElementsByTagName('BODY')[0].classList.remove('_scrolled');
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollListner);
    return () => {
      window.removeEventListener('scroll', scrollListner);
      document.getElementsByTagName('BODY')[0].classList.remove('_scrolled');
    };
  }, []);
  useEffect(() => {
    if (activeCategoryIndex >= 0) {
      document.getElementsByTagName('BODY')[0].classList.add('overflow-hidden');
    } else {
      document
        .getElementsByTagName('BODY')[0]
        .classList.remove('overflow-hidden');
    }
  }, [activeCategoryIndex]);
  useEffect(() => {
    closeCategoryPane();
  }, [window.location.href]);
  return (
    <div className="header-wrap" onScroll={scrollListner}>
      <If condition={showHederTop}>
        <HeaderTop />
      </If>
      <MenuHeader
        tabs={tabs || []}
        categoryTabs={
          categoriesData &&
          categoriesData[pageTypes[activeTab]] &&
          categoriesData[pageTypes[activeTab]].tabs
        }
        updateActiveTab={updateActiveTabHandler}
        activeTab={activeTab}
        setActiveCategoryIndex={handelActiveCategoryIndex}
        bagCount={bagCount}
        wishListCount={wishListCount}
      />
      <If condition={isCategoryPaneOpen && activeCategoryIndex >= 0}>
        <BackDrop doClick={closeCategoryPane} />
      </If>
      <div
        className={classnames(
          'pos-fix z-101 top-0 bottom-0 w-670 bg-white transition-03',
          CURRENT_LANG_PREFIX === 'ar' ? 'r-0' : 'l-0',
          isCategoryPaneOpen && activeCategoryIndex >= 0
            ? 'o-1 pointer-e-all'
            : 'o-0 pointer-e-none'
        )}
        style={
          CURRENT_LANG_PREFIX === 'en'
            ? {
                marginLeft:
                  isCategoryPaneOpen && activeCategoryIndex >= 0
                    ? '0'
                    : '-100px'
              }
            : {
                marginRight:
                  isCategoryPaneOpen && activeCategoryIndex >= 0
                    ? '0'
                    : '-100px'
              }
        }
      >
        <CategoryView
          data={categoriesData[pageTypes[activeTab]] || {}}
          isLoading={isLoading}
          closeCategoryPane={closeCategoryPane}
          activeCategoryIndex={activeCategoryIndex}
          activeTab={activeTab}
          categoryPage={categoryPage}
          cateTabs={tabs}
          categoriesData={categoriesData}
          pageTypes={pageTypes}
        />
      </div>
    </div>
  );
}
