import { COUNTRY } from 'constants/index';
import logError from './logError';
import { getPaymentEnum } from './payment';
import { getCustomerId } from './user';

export function getCatData(metaData) {
  if (metaData)
    return {
      super_category_id:
        (metaData['level-1'] &&
          metaData['level-1'].map(l => l.categoryId).join(',')) ||
        '',
      super_category_name:
        (metaData['level-1'] &&
          metaData['level-1'].map(l => l.categoryName).join(',')) ||
        '',
      parent_category_id:
        (metaData['level-2'] &&
          metaData['level-2'].map(l => l.categoryId).join(',')) ||
        '',
      parent_category_name:
        (metaData['level-2'] &&
          metaData['level-2'].map(l => l.categoryName).join(',')) ||
        '',
      category_id:
        (metaData['level-3'] &&
          metaData['level-3'].map(l => l.categoryId).join(',')) ||
        '',
      category_name:
        (metaData['level-3'] &&
          metaData['level-3'].map(l => l.categoryName).join(',')) ||
        '',
      sub_category_id:
        (metaData['level-4'] &&
          metaData['level-4'].map(l => l.categoryId).join(',')) ||
        '',
      sub_category_name:
        (metaData['level-4'] &&
          metaData['level-4'].map(l => l.categoryName).join(',')) ||
        ''
    };
  return {};
}

function prepareProduct(items = []) {
  const product = items[0];

  if (product) {
    const metadata = product.metadata || {};
    const categoryData = getCatData(metadata);
    const response = {
      name: product.name,
      product_id: metadata.productId,
      sku: product.id,
      ...categoryData,
      price: String(product.price || 0),
      currency: product.currency
    };

    if (product.variant) response.size = product.variant;

    return response;
  }
  return {};
}

function prepareBag(bagItems = []) {
  const bagData = {};
  bagItems.forEach((item, index) => {
    bagData[`item_${index}`] = prepareProduct([item]);
  });

  return bagData;
}

export async function logBranchEvent(eventData) {
  try {
    if (window.branch) {
      window.branch.init(process.env.STYLI_APP_BRANCH_API_KEY, function (err) {
        console.log({ branchInit: { err } });
      });
      const { name, data = {}, additionalData = {} } = eventData;
      const {
        language,
        items,
        //  promoName,
        searchTerm = '',
        paymentMethod: pMethod = '',
        grandTotal = 0,
        currency = '',
        quoteId = '',
        total_items: totalItems,
        quantity = 0,
        couponCodeApplied = ''
      } = data;
      const {
        firstName = '',
        lastName = '',
        customerId = '',
        email = '',
        isMainPDP = false,
        loginType = ''
        // categoryInfo
      } = additionalData;
      const itemObj = prepareProduct(items);

      const paymentMethod = pMethod ? getPaymentEnum(pMethod) : '';

      let eventDataObj = {
        language,
        country: COUNTRY
      };
      if (getCustomerId()) {
        eventDataObj.user_id = getCustomerId();
      }

      let contents = {};

      let eventName = '';
      const branchFn = window.branch;

      // const appBoy = window.appboy;
      switch (name) {
        case 'login':
          branchFn.setIdentity(customerId, (err, res) => {
            console.log({ loginResponse: { err, res } });
          });

          eventDataObj = {
            ...eventDataObj,
            language,
            user_id: customerId,
            email,
            first_name: firstName,
            last_name: lastName,
            signup_type: loginType
          };

          contents = {
            ...eventDataObj
          };
          eventName = 'login';
          window.isLoggedIn = true;
          break;

        case 'user_input_manual':
          eventName = 'start_signup';
          break;

        case 'guest_checkout':
          eventName = 'GUEST_CHECKOUT';
          contents = {
            ...eventDataObj
          };
          break;

        case 'sign_up_form_success':
          // appBoy.changeUser(customerId);
          eventName = 'COMPLETE_REGISTRATION';
          eventDataObj = {
            ...eventDataObj,
            language,
            user_id: customerId,
            email,
            first_name: firstName,
            last_name: lastName,
            signup_type: loginType
          };

          break;

        // case "view_item_list":
        //     if (categoryInfo.name || categoryInfo.id) {
        //         eventName = 'product_list';
        //         eventDataObj = {
        //             ...eventDataObj,
        //             c_name: categoryInfo.name,
        //             id: categoryInfo.id || ""
        //         }
        //     }
        //     break;

        case 'view_item':
          if (isMainPDP) {
            eventName = 'VIEW_ITEM';
            eventDataObj = { ...eventDataObj, ...itemObj };
            contents = {
              ...eventDataObj,
              $price: eventDataObj.price,
              $sku: eventDataObj.sku,
              $currency: eventDataObj.currency
            };
          }
          break;

        case 'add_to_wishlist':
          if (items.length) {
            eventName = 'ADD_TO_WISHLIST';
            eventDataObj = { ...eventDataObj, ...itemObj };
            contents = {
              ...eventDataObj,
              $price: eventDataObj.price,
              $sku: eventDataObj.sku,
              $currency: eventDataObj.currency
            };
            // const product = items[0] || {};
            // const metadata = product.metadata || {};
            // const categoryData = getCatData(metadata);
            // appBoy.logCustomEvent("add_to_wishlist", {
            //     language,
            //     user_id: getCustomerId(),
            //     p_name: product.name,
            //     product_id: metadata.productId,
            //     ...categoryData,
            //     sku: product.id
            // })
          }
          break;

        case 'add_to_cart':
          if (items.length) {
            eventName = 'ADD_TO_CART';
            eventDataObj = { ...eventDataObj, ...itemObj };
            contents = {
              ...eventDataObj,
              $price: eventDataObj.price,
              $sku: eventDataObj.sku,
              $currency: eventDataObj.currency
            };
          }
          break;

        case 'view_cart':
          if (items.length) {
            eventName = 'VIEW_CART';
            eventDataObj = { ...eventDataObj, ...itemObj };
            contents = {
              ...eventDataObj,
              $price: eventDataObj.price,
              $sku: eventDataObj.sku,
              $currency: eventDataObj.currency
            };
            // const product = items[0] || {};
            // const metadata = product.metadata || {};
            // const categoryData = getCatData(metadata);
            // appBoy.logCustomEvent("view_cart", {
            //     language,
            //     user_id: getCustomerId(),
            //     p_name: product.name,
            //     product_id: metadata.productId,
            //     ...categoryData,
            //     sku: product.id,
            //     size: product.variant
            // })
          }
          break;
        case 'begin_checkout':
          if (items.length) {
            eventName = 'INITIATE_PURCHASE';
            eventDataObj = { ...itemObj, ...eventDataObj };
            const bagData = prepareBag(items);
            eventDataObj = {
              ...eventDataObj,
              bag_details: JSON.stringify(bagData),
              payment_method: paymentMethod,
              tot_price: grandTotal,
              currency,
              metadata: quoteId || ''
            };

            contents = {
              ...eventDataObj,
              $price: String(grandTotal || 0),
              $quantity: Number(totalItems),
              $currency: currency
            };
          }
          break;

        case 'change_payment':
          eventName = 'ADD_PAYMENT_INFO';
          eventDataObj = {
            ...eventDataObj,
            language,
            quantity,
            tot_price: grandTotal,
            currency,
            coupon: String(!!couponCodeApplied),
            payment_method: paymentMethod,
            metadata: ''
          };
          contents = { ...eventDataObj };
          break;

        case 'purchase':
          if (items.length) {
            eventName = 'PURCHASE';
            const bagData = prepareBag(items);
            eventDataObj = {
              ...eventDataObj,
              bag_details: JSON.stringify(bagData),
              payment_method: paymentMethod,
              tot_price: grandTotal,
              currency,
              metadata: quoteId || ''
            };
            contents = { ...eventDataObj };
          }
          break;

        // case "couponcode_success":
        //     eventName = 'couponcode_success';
        //     eventDataObj = {
        //         ...eventDataObj, promo_name: promoName
        //     }
        //     break;

        // case "couponcode_failed":
        //     eventName = 'couponcode_failed';
        //     eventDataObj = {
        //         ...eventDataObj, promo_name: promoName
        //     }
        //     break;

        case 'search':
          eventName = 'SEARCH';
          eventDataObj = {
            ...eventDataObj,
            search_term: searchTerm
          };
          contents = { ...eventDataObj };
          break;
        default:
      }

      if (eventName) {
        const finalData = { ...eventDataObj };
        const finalContent = { ...(contents || {}) };

        branchFn.logEvent(eventName, finalData, [finalContent], (err, res) => {
          console.log({ err, res });
        });
        eventName = '';
      }
    }
  } catch (e) {
    logError(e);
  }
}
