import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { firebase } from '@firebase/app';
import 'firebase/auth';
import { getNavigationBasePath, initializeFirebase } from 'utils/';
import AppleLogin from 'react-apple-login';
import SlidePane from 'components/libs/SlidePane';
import {
  getLoginValidate,
  getLogin,
  resetRegistration,
  resetValidate
} from 'containers/Account/actions';
import Registration from 'components/Registration';

import Input from 'components/libs/Input';
import Button from 'components/libs/Buttons';
import { useStateValue } from 'context/app';
import { logEvent } from 'utils/analytics';

import logError from 'utils/logError';

import { isValidPhone, isValidEmail } from 'utils/validators';
import { path, getStoreId } from 'utils';
import { setStorage, getStorage } from 'utils/storage';
import { formatCheckoutData } from 'utils/cart';
import { isIOS, isBrowser, osName, isSafari } from 'react-device-detect';
import CloseIcon from 'assets/icons/close-popup.svg';
import GoogleIcon from 'assets/icons/googleIcon.svg';
import AppleIcon from 'assets/icons/appleIcon.svg';
import { translate } from '../../constants/language';
import {
  COUNTRY,
  CURRENT_LANG_PREFIX,
  isRTL,
  APPLE_SIGIN_CLIENT_ID,
  APPLE_SIGIN_REDIRECT_URI
} from '../../constants';
import Password from './password';
import PhonePrompt from './phonePrompt';
// import PromptGuest from './PromptGuest';

export default ({
  showLoginDialog = false,
  onBack = () => {},
  onLoginSuccess = () => {},
  isStandaloneLogin = false,
  fromBag = false,
  eventAction = '',
  screenName = ''
}) => {
  const [useridentifier, setUseridentifier] = useState('');
  const [loginType, setLoginType] = useState('MOBILE_LOGIN');
  const [socialReqObj, setSocialObj] = useState({});
  const [showPhoneDialog, setPhoneDialog] = useState(false);
  const history = useHistory();
  const storeId = getStoreId();
  const [error, setError] = useState('');
  const [btnDisabled, setButtonDisabled] = useState(false);
  const [isLoggedInLoading, setIsLoggedInLoading] = useState(false);
  const [isAppleLoggedInLoading, setIsAppleLoggedInLoading] = useState(false);
  const {
    account: [accountState, dispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();
  const { isLoginProcessing, otpLoading } = accountState;
  const codePassword = accountState.siteConfig && accountState.siteConfig.code;

  const { config: { socialLogin = {}, otpConfig = {} } = {} } = accountState;

  const { socialLogin: socialLoginConfig = {} } = otpConfig;

  const { phoneVerifyRegister, phoneMandatoryRegisterForm } = socialLoginConfig;

  const onClosePhone = () => {
    setPhoneDialog(false);
    setIsLoggedInLoading(false);
    setIsAppleLoggedInLoading(false);
  };
  const clickHandler = () => {
    if (
      !isValidPhone(useridentifier, storeId) &&
      !isValidEmail(useridentifier)
    ) {
      setError(
        [13, 12, 14, 16, 18, 20].indexOf(storeId) > -1
          ? translate(
              `Please use a valid email or mobile number${
                COUNTRY.toLowerCase() === 'in' ? '' : ' (eg. 50123456)'
              }`
            )
          : translate(
              `Please use a valid email or mobile number${
                COUNTRY.toLowerCase() === 'in' ? '' : ' (eg. 501234567)'
              }`
            )
      );
    } else {
      if (isValidPhone(useridentifier, storeId)) setLoginType('MOBILE_LOGIN');
      else setLoginType('EMAIL_LOGIN');
      setError('');

      try {
        logEvent({
          eventName: 'CUSTOM_EVENT',
          data: {
            event_category: 'login',
            event_action: eventAction,
            event_label: `${
              loginType === 'MOBILE_LOGIN' ? 'email' : 'mobilenumber'
            }_signup_login`
          },
          screenName
        });
      } catch (e) {
        logError(e);
      }

      getLoginValidate({ useridentifier, loginType }, dispatch);
    }
  };

  const handleFocus = input => {
    if (input && showLoginDialog) {
      // input.focus();
    } else if (input) {
      input.blur();
    }
  };

  const handleChange = event => {
    setUseridentifier(event.target.value);
    if (
      isValidPhone(event.target.value, storeId) ||
      isValidEmail(event.target.value)
    ) {
      if (isValidPhone(event.target.value, storeId))
        setLoginType('MOBILE_LOGIN');
      else setLoginType('EMAIL_LOGIN');
      setError('');
    }
  };

  const handleBackToLogin = () => {
    setButtonDisabled(false);
    resetRegistration({}, dispatch);
  };
  const handleGoToGuest = async () => {
    resetRegistration({}, dispatch);
    onBack();
    setButtonDisabled(false);
    const guestAddress = await getStorage('guestAddress');
    await setStorage('guestAddress', {
      ...(guestAddress || {}),
      [loginType === 'EMAIL_LOGIN' ? 'email' : 'phoneNumber']: useridentifier
    });
    try {
      const eventData = {
        event_category: 'login_signup',
        event_label: 'true'
      };
      logEvent({
        eventName: 'user_select_guest',
        data: eventData,
        screenName: 'Login_User_Input'
      });
      const beginCheckoutData = {
        event_category: 'checkout',
        checkout_step: 1,
        ...formatCheckoutData({
          quote: shippingState.quote,
          quoteMetadata: shippingState.quoteMetadata
        })
      };
      logEvent({
        eventName: 'begin_checkout',
        data: beginCheckoutData,
        screenName: 'Login_User_Input'
      });
    } catch (e) {
      logError(e);
    }
    history.push(`/${CURRENT_LANG_PREFIX}/checkout`);
  };

  useEffect(() => {
    resetValidate({}, dispatch);
    try {
      logEvent({
        eventName: 'CUSTOM_EVENT',
        data: {
          event_category: 'login',
          event_action: eventAction,
          event_label: 'view'
        },
        screenName
      });
    } catch (e) {
      logError(e);
    }
  }, []);
  useEffect(() => {
    if (accountState.profile) {
      onLoginSuccess();
    }
  }, [accountState.profile]);

  useEffect(() => {
    if (
      accountState.registartionRequired &&
      !accountState.error &&
      accountState.apiErrorCode === '201' &&
      !isStandaloneLogin
    ) {
      handleGoToGuest();
    }
  }, [accountState.registartionRequired, accountState.loggedIn]);

  const phoneSocialLogin = loginData => {
    getLogin(
      { ...socialReqObj, ...loginData, fromBag },
      dispatch,
      shippingDispatch
    );
  };

  useEffect(() => {
    if (accountState.phoneVerified && accountState?.validatedUser) {
      getLogin(
        {
          useridentifier:
            accountState.validatedUser &&
            accountState.validatedUser.mobileNumber,
          password: codePassword,
          loginType: 'MOBILE_LOGIN',
          isStandaloneLogin,
          quote: shippingState.quote,
          loginData: accountState.loginData,
          fromBag
        },
        dispatch,
        shippingDispatch
      );
    }
  }, [accountState.phoneVerified]);

  const signInWithGoogle = (isContinue = false, hasPassword = false) => {
    // Initialize Firebase
    if (isAppleLoggedInLoading || isLoggedInLoading) {
      return;
    }

    console.log(`isContinue: ${isContinue}`);

    if (!isContinue) {
      try {
        logEvent({
          eventName: 'CUSTOM_EVENT',
          data: {
            event_category: 'login',
            event_action: eventAction,
            event_label: `google_signup_login`
          },
          screenName
        });
      } catch (e) {
        logError(e);
      }
    } else {
      try {
        logEvent({
          eventName: 'CUSTOM_EVENT',
          data: {
            event_category: 'login',
            event_action: eventAction,
            event_label: `google_${
              hasPassword ? 'withpassword' : 'withoutpassword'
            }`
          },
          screenName
        });
      } catch (e) {
        logError(e);
      }
    }
    setLoginType('SOCIAL_LOGIN_GOOGLE');
    initializeFirebase();
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async response => {
        const { credential } = response;
        let startSocialLogin = false;

        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = credential.idToken;
        setIsLoggedInLoading(true);
        setSocialObj({
          useridentifier: response.user.email,
          loginType: 'SOCIAL_LOGIN_GOOGLE',
          isStandaloneLogin,
          quote: shippingState.quote,
          token,
          fullName: response.user.displayName
        });
        if (phoneMandatoryRegisterForm) {
          const validateResponse = await getLoginValidate(
            {
              useridentifier: response.user.email,
              loginType: 'EMAIL_LOGIN',
              initiatedFrom: 'phonePrompt'
            },
            dispatch
          );
          if (validateResponse && !validateResponse.exists) {
            setPhoneDialog(true);
          } else {
            startSocialLogin = true;
          }
        } else {
          startSocialLogin = true;
        }

        if (startSocialLogin) {
          phoneSocialLogin({
            useridentifier: response.user.email,
            loginType: 'SOCIAL_LOGIN_GOOGLE',
            isStandaloneLogin,
            quote: shippingState.quote,
            token,
            fullName: response.user.displayName,
            eventActionFrom: eventAction
          });
        }

        // setInitSocial(startSocialLogin);
      })
      .catch(googleError => {
        setIsLoggedInLoading(false);

        console.log(googleError);
      });
  };
  const signInWithGoogleWithoutContinue = () => signInWithGoogle(false, false);

  const signInWithApple = async appleResponse => {
    if (isAppleLoggedInLoading || isLoggedInLoading) {
      return;
    }
    setIsAppleLoggedInLoading(true);

    console.log(`appleResponse: `);
    console.log(appleResponse);
    if (
      !appleResponse.error &&
      appleResponse.authorization &&
      appleResponse.authorization.code &&
      appleResponse.authorization.id_token
    ) {
      const tokenChunks = appleResponse.authorization.id_token.split('.');
      let tokenPayloadObj = { email: undefined };
      if (tokenChunks && tokenChunks.length > 1) {
        try {
          tokenPayloadObj = JSON.parse(Buffer.from(tokenChunks[1], 'base64'));
        } catch (err) {
          tokenPayloadObj = { email: undefined };
        }
      }
      console.log('tokenPayloadObj: ');
      console.log(tokenPayloadObj);
      if (!tokenPayloadObj.email) {
        setIsAppleLoggedInLoading(false);
        return;
      }
      const fullName = `${tokenPayloadObj.email.split('@')[0]} .`;
      setLoginType('SOCIAL_LOGIN_APPLE');
      let startSocialLogin = false;
      const token = appleResponse.authorization.code;
      setSocialObj({
        useridentifier: tokenPayloadObj.email,
        loginType: 'SOCIAL_LOGIN_APPLE',
        isStandaloneLogin,
        quote: shippingState.quote,
        token,
        fullName
      });
      if (phoneMandatoryRegisterForm) {
        const validateResponse = await getLoginValidate(
          {
            useridentifier: tokenPayloadObj.email,
            loginType: 'SOCIAL_LOGIN_APPLE',
            initiatedFrom: 'phonePrompt'
          },
          dispatch
        );
        if (validateResponse && !validateResponse.exists) {
          setPhoneDialog(true);
        } else {
          startSocialLogin = true;
        }
      } else {
        startSocialLogin = true;
      }

      if (startSocialLogin) {
        await phoneSocialLogin({
          useridentifier: tokenPayloadObj.email,
          loginType: 'SOCIAL_LOGIN_APPLE',
          isStandaloneLogin,
          quote: shippingState.quote,
          token,
          fullName,
          eventActionFrom: eventAction
        });
      }
    }

    setIsAppleLoggedInLoading(false);
  };

  const showPasswordDialog = path(['validatedUser', 'exists'], accountState);
  const subTitle = isStandaloneLogin
    ? 'Track your orders, get best offers, coupons & more'
    : 'Please login or sign up for a smoother shopping experience';

  return (
    <>
      <SlidePane
        isOpen={showLoginDialog}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        style={{ 'box-sizing': 'border-box' }}
        // height="100%"
        onRequestClose={onBack}
        className={classnames(
          isBrowser ? 'pl-30 pr-30 pt-32 pb-32' : 'pl-15 pr-15 pt-24 pb-24'
        )}
        isCenter={true}
        isLogin={true}
      >
        <div className="">
          <div
            className={classnames(
              'c-dark-gray',
              isBrowser
                ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
                : 'fs-14 lh-18 fw-6'
            )}
          >
            <div>
              {translate(
                isStandaloneLogin ? 'Login or Sign up' : 'You’re almost there!'
              )}
            </div>
            <If condition={isBrowser}>
              <Button
                className="normal mr-0 ml-auto p-0"
                onClick={onBack}
                onKeyPress={onBack}
              >
                <CloseIcon className="d-block" />
              </Button>
            </If>
          </div>
          <div
            className={classnames(
              'c-brown-gray',
              isBrowser ? 'fs-16 lh-20 ls-48 mt-20' : 'fs-12 lh-16 ls-072 mt-4'
            )}
          >
            {translate(subTitle)}
          </div>

          <Input
            label={
              <div className="flex middle-xs">
                <div className="fs-12 lh-16 ls-072 c-dark-gray fw-6">
                  {translate('*Email/Phone Number')}
                </div>
              </div>
            }
            // floatLabel="dknvknd"
            placeholder={translate(
              COUNTRY.toLowerCase() === 'in'
                ? '9000000000 or abd@gmail.com'
                : '050505050 or Abd@gmail.com'
            )}
            labelClass="block ls-072 fs-13"
            inputRef={handleFocus}
            wrapClassNames="mt-16"
            value={useridentifier}
            onChange={handleChange}
            error={error}
            dir="ltr"
            className={classnames('en-font', { 'ta-r': isRTL, 'ta-l': isRTL })}
          />
          <Button
            className="w100p primary cap mt-16 border-none h-48"
            loadingOnDisabled={false}
            disabled={isLoginProcessing || btnDisabled || otpLoading}
            onClick={clickHandler}
            id="validateUserIdentifier"
          >
            <span className="fs-14 lh-20 ls-16 fw-6">
              {translate('CONTINUE')}
            </span>
          </Button>

          <If
            condition={
              false &&
              socialLogin &&
              (socialLogin.google_sign_in || socialLogin.apple_sign_in)
            }
          >
            <div className="pt-16 pb-16 flex middle-xs between-xs">
              <div className="bb-light-gray w100p" />
              <span className="w-200 c-brown-gray ta-c fs-14">
                {translate('or')}
              </span>
              <div className="bb-light-gray w100p" />
            </div>

            {/* Google sign-in */}
            <If condition={socialLogin && socialLogin.google_sign_in}>
              <div
                className={classnames(
                  'c-dark-gray',
                  isBrowser
                    ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
                    : 'fs-14 lh-18 fw-6'
                )}
              >
                <Button
                  className={classnames(
                    'normal w100p cap b-dark-gray h-40 br-3 c-black bg-white bg-transparent'
                  )}
                  onClick={signInWithGoogleWithoutContinue}
                  id="googleSignIn"
                  type="button"
                  showLoader={isLoggedInLoading}
                  loaderClass="mt-n-5"
                >
                  <div className="flex center-xs">
                    <If condition={!isLoggedInLoading}>
                      <span className="mr-r-10">
                        <GoogleIcon className="d-block" width="18px" />
                      </span>
                      <span className="fw-6 lh-20">
                        {translate('Continue with Google')}
                      </span>
                    </If>
                  </div>
                </Button>
              </div>
            </If>

            {/* Apple sign-in */}
            <If
              condition={
                socialLogin &&
                socialLogin.apple_sign_in &&
                (isIOS || (osName === 'Mac OS' && isSafari))
              }
            >
              <div
                className={classnames(
                  'c-dark-gray pt-16',
                  isBrowser
                    ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
                    : 'fs-14 lh-18 fw-6'
                )}
              >
                <AppleLogin
                  clientId={APPLE_SIGIN_CLIENT_ID}
                  redirectURI={APPLE_SIGIN_REDIRECT_URI}
                  usePopup={true}
                  autoLoad={false}
                  callback={signInWithApple}
                  scope="name email"
                  responseMode="query"
                  render={renderProps => {
                    const myOnClick = p => {
                      if (!isAppleLoggedInLoading && !isLoggedInLoading) {
                        try {
                          logEvent({
                            eventName: 'CUSTOM_EVENT',
                            data: {
                              event_category: 'login',
                              event_action: eventAction,
                              event_label: `apple_signup_login`
                            },
                            screenName
                          });
                        } catch (e) {
                          logError(e);
                        }
                        setIsAppleLoggedInLoading(true);
                        renderProps.onClick(p);
                      }
                    };
                    return (
                      <Button
                        onClick={myOnClick}
                        id="appleSignIn"
                        type="button"
                        className={classnames(
                          `normal w100p cap b-dark-gray h-40 br-3 c-black bg-white bg-transparent`
                        )}
                        showLoader={isAppleLoggedInLoading}
                        loaderClass="mt-n-5"
                      >
                        <div className="flex center-xs">
                          <If condition={!isAppleLoggedInLoading}>
                            <span className="mr-r-10">
                              <AppleIcon className="d-block" width="18px" />
                            </span>
                            <span className="fw-6 lh-20">
                              {translate('Continue with Apple')}
                            </span>
                          </If>
                        </div>
                      </Button>
                    );
                  }}
                />
              </div>
            </If>
          </If>

          <div
            className={classnames(
              'c-brown-gray ta-c',
              isBrowser ? 'fs-12 lh-20 mt-20' : 'fs-11 lh-16 mt-16'
            )}
          >
            {translate('By continuing, you agree to our terms and conditions.')}
          </div>
          <div className="ta-c">
            <a
              href={`${getNavigationBasePath()}/account/terms`}
              target="_blank"
              rel="noopener noreferrer"
              className="c-black-light fs-12 bb-black-light pb-2"
            >
              {translate('Terms and Conditions')}
            </a>
          </div>
        </div>
      </SlidePane>
      <If condition={loginType === 'EMAIL_LOGIN'}>
        <Choose>
          <When condition={socialLogin && socialLogin.google_sign_in}>
            <Password
              showPasswordDialog={
                showPasswordDialog && loginType === 'EMAIL_LOGIN'
              }
              noBorder={true}
              isStandaloneLogin={isStandaloneLogin}
              codePassword={codePassword}
              signInWithGoogle={signInWithGoogle}
              signInWithApple={signInWithApple}
              setIsAppleLoggedInLoading={setIsAppleLoggedInLoading}
              fromBag={fromBag}
              eventAction={eventAction}
              screenName={screenName}
            />
          </When>
          <Otherwise>
            <Password
              showPasswordDialog={
                showPasswordDialog && loginType === 'EMAIL_LOGIN'
              }
              noBorder={true}
              isStandaloneLogin={isStandaloneLogin}
              codePassword={codePassword}
              eventAction={eventAction}
              screenName={screenName}
            />
          </Otherwise>
        </Choose>
      </If>
      <If condition={loginType !== 'SOCIAL_LOGIN_GOOGLE'}>
        <Registration
          showRegistrationDialog={
            isStandaloneLogin && accountState.apiErrorCode === '201'
          }
          onBack={handleBackToLogin}
          noBorder={true}
          eventAction={eventAction}
        />
      </If>

      <If condition={showPhoneDialog}>
        <PhonePrompt
          showPhoneDialog={showPhoneDialog}
          onClose={onClosePhone}
          phoneVerifyRegister={phoneVerifyRegister}
          email={socialReqObj && socialReqObj.useridentifier}
          phoneSocialLogin={phoneSocialLogin}
        />
      </If>
      {/* <PromptGuest
        onBack={handleBackToLogin}
        showDialog={step3Dialog === 'prompt' && !accountState.apiErrorMsg}
        onGuestClick={handleGoToGuest}
        onSignUpClick={handleGoToSignup}
      /> */}
    </>
  );
};
