import { ACTION_GET_STATIC_PAGE, ACTION_GET_STATIC_PAGE_ERROR, ACTION_GET_STATIC_PAGE_LOADING } from './actions';

export const initialState = { isLoading: true };

export default (state = initialState, action) => {

  switch (action.type) {
    case ACTION_GET_STATIC_PAGE_LOADING: {
      return {
        ...state,
        isLoading: true,
        pageContent: undefined,
        isStaticPageError: false
      };
    }
    case ACTION_GET_STATIC_PAGE: {
      const { data = [] } = action;
      return {
        ...state,
        isLoading: false,
        pageContent: data,
        isStaticPageError: false
      };
    }
    case ACTION_GET_STATIC_PAGE_ERROR: {
      return {
        ...state,
        isLoading: false,
        pageContent: undefined,
        isStaticPageError: true
      };
    }
    default:
      return state;
  }
};
