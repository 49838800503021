import axios from 'utils/axios';
import isEmpty from 'lodash.isempty';
import logError from 'utils/logError';
import {
  getUpdateQuoteDetails,
  getCurrentQuoteId,
  createReplica,
  createReplicaPaymentHold,
  // setSelectedAddressId,
  // manageQuote,
  changePaymentMethod,
  showCheckoutToast
} from 'containers/Checkout/actions';
import { getOrderSummaryFromQuote } from 'utils/cart';
import { cleverTapProcess } from 'utils/cleverTap';

import {
  getJWT,
  getStoreId,
  getBalanceApplied,
  path,
  getCookieValue
} from 'utils';

import { getToken, deleteCard } from 'utils/payment';

import { getStorage, removeStorage, setStorage } from 'utils/storage';

import { getCustomerId, getXHeaderToken, getJwtFromEmail } from 'utils/user';

import { logEvent, logUIEvent } from 'utils/analytics';

import {
  // WALLET_PAYMENT_INDEX,
  CURRENT_LANG_PREFIX,
  SOURCE_OF_ORDER,
  OTP_API,
  SEND_ORDER_CONFIRMATION,
  PAYMENT_CODE_VAULT,
  COUNTRY_STORE_IDS,
  COUNTRY
} from '../../constants';
import { UNKNOWN_ERROR, translate } from '../../constants/language';

import {
  // API_CHANGE_PAYMENT_METHOD,
  API_CREATE_ORDER,
  API_ORDER_EMAIL,
  API_INVENTORY_CHECK,
  API_STORE_CREDIT_UPDATE,
  API_RE_ORDER,
  API_RETRY_PAYMENT,
  DELETE_CARD_API,
  GET_SELECTED_ADDRESS,
  GET_SAVED_CARD_LIST,
  GET_BANK_OFFERS,
  FETCH_PAYMENT_CONFIGS
} from '../../constants/api';

export const ACTION_SELECTED_TAB = 'payment/SELECTED_TAB';
export const ACTION_MAKE_PAYMENT_LOADING = 'payment/MAKE_PAYMENT_LOADING';
export const ACTION_MAKE_PAYMENT = 'payment/MAKE_PAYMENT';
export const ACTION_MAKE_PAYMENT_SUCCESS = 'payment/MAKE_PAYMENT_SUCCESS';
export const ACTION_MAKE_PAYMENT_FAILED = 'payment/MAKE_PAYMENT_FAILED';
export const ACTION_SET_ERRORS = 'payment/SET_ERRORS';
export const ACTION_SET_ORDER_DETAILS = 'payment/SET_ORDER_DETAILS';
export const ACTION_SET_ORDER_DETAILS_LOADING = 'payment/_SET_ADDRESS';
export const ACTION_SET_ADDRESS = 'payment/SET_ADDRESS';
export const ACTION_SET_SAVED_CARDS = 'payment/SET_SAVED_CARDS';
export const ACTION_SET_LANGUAGE = 'payment/ACTION_SET_LANGUAGE';
export const ACTION_RESET = 'payment/RESET';
export const ACTION_DELETE_CARD_LOADING = 'payment/DELETE_CARD_LOADING';
export const ACTION_CARD_DELETED = 'payment/CARD_DELETED';
export const ACTION_RETRY_REORDER_SUCCESS = 'payment/RETRY_REORDER_SUCCESS';
export const ACTION_RETRY_REORDER_ERROR = 'payment/RETRY_REORDER_ERROR';
export const ACTION_RETRY_REORDER_LOADING = 'payment/RETRY_REORDER_LOADING';
export const ACTION_GET_BANK_OFFERS_LOADING = 'payment/GET_BANK_OFFERS_LOADING';
export const ACTION_GET_BANK_OFFERS_FETCHED = 'payment/GET_BANK_OFFERS_FETCHED';
export const ACTION_GET_BANK_OFFERS_ERROR = 'payment/GET_BANK_OFFERS_ERROR';
export const ACTION_FETCH_PAYMENT_CONFIG_LOADING =
  'payment/FETCH_PAYMENT_CONFIG_LOADING';
export const ACTION_PAYMENT_CONFIGS_FETCHED = 'payment/PAYMENT_CONFIGS_FETCHED';
export const ACTION_PAYMENT_CONFIGS_ERROR = 'payment/PAYMENT_CONFIGS_ERROR';

export const ACTION_VERIFY_PHONE_FINISHED = 'account/VERIFY_PHONE_FINISHED';

const errorRedirect = () => {};
export async function inventoryCheck({
  productArray: quoteProductArray,
  returnData = false
}) {
  const storeId = getStoreId();
  const productArray =
    quoteProductArray &&
    quoteProductArray.map(item => ({
      productId: item.productId,
      sku: item.sku,
      name: item.name,
      quantity: item.quantity,
      storeId
    }));
  const skuStockMap = [];
  let checkSuccess = true;
  if (productArray) {
    productArray.forEach(product => {
      skuStockMap[product.sku] = product.quantity;
    });
  }

  const result = await axios.post(
    API_INVENTORY_CHECK(),
    {
      products: productArray,
      storeId: getStoreId()
    },
    {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    }
  );

  const responseProductArray = path(
    ['data', 'response', 'productStatus'],
    result
  );
  let response = [];
  if (returnData) {
    response = responseProductArray;
  } else {
    responseProductArray.forEach(resProduct => {
      if (Number(resProduct.value) < Number(skuStockMap[resProduct.sku])) {
        checkSuccess = false;
      }
    });
    response = checkSuccess;
  }
  return response;
}

export function resetPaymentState(options, dispatch) {
  dispatch({ type: ACTION_RESET });
}

export async function fetchBankOffers(options, dispatch) {
  dispatch({ type: ACTION_GET_BANK_OFFERS_LOADING });
  try {
    const response = await axios.post(
      GET_BANK_OFFERS(),
      {
        storeId: getStoreId()
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const responseData = path(['data', 'response'], response);
    if (
      response.data.statusCode === '200' &&
      responseData &&
      responseData.length
    ) {
      dispatch({ type: ACTION_GET_BANK_OFFERS_FETCHED, data: responseData });
    }
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_BANK_OFFERS_ERROR });
  }
}

export function resetPaymentErrors(dispatch) {
  dispatch({ type: ACTION_SET_ERRORS, data: { errors: null } });
}

export async function getSelectedAddressDetails({ addressId }, dispatch) {
  let address = {};

  try {
    // dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
    const customerId = getCustomerId();

    const result = await axios.get(
      GET_SELECTED_ADDRESS({ addressId, customerId }),
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    logError(result.data.response);
    dispatch({
      type: ACTION_SET_ADDRESS,
      data: result.data.response.address
    });
    address = result.data.response.address;
  } catch (e) {
    logError(e);
  }
  return address;
}

// export async function getOrderDetails(dispatch) {
//   let orderDetails = {};
//   try {
//     const customerId = getCustomerId();
//     const storeId = getStoreId();

//     dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
//     const result = await axios.get(
//       `/proxy/rest/auth/quote/customer/${customerId}/store/${storeId}`,
//       {
//         headers: {
//           'X-Header-Token': getXHeaderToken(),
//           Token: getJWT(),
//           'Content-Type': 'application/json'
//         }
//       }
//     );
//     const response = (result.data && result.data.response) || result.data; // slight confustion with the api
//     if (
//       response &&
//       response.quoteId &&
//       response.products &&
//       response.products.length
//     ) {
//       dispatch({ type: ACTION_SET_ORDER_DETAILS, data: response });
//       orderDetails = response;
//     }
//   } catch (error) {
//     logError(error);
//   }
//   return orderDetails;
// }

export async function getSavedCardsList(dispatch) {
  let savedCards = [];
  try {
    const customerId = getCustomerId();
    if (!customerId) {
      return false;
    }

    let jwt = getJWT();
    if (!jwt) {
      try {
        await getJwtFromEmail({ email: 'guest@stylishop.com' });
      } catch (e) {
        logError(e);
      }
      jwt = getJWT();
    }

    dispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });

    const result = await axios.post(
      GET_SAVED_CARD_LIST({ customerId }),
      { customerId, storeId: getStoreId() },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    // logError(result.data.response);
    savedCards = result.data.response.filter(
      item => COUNTRY_STORE_IDS.indexOf(Number(item.storeId)) >= 0
    );
    logEvent({
      eventName: 'card_count',
      skipGtm: true,
      data: { cardCount: savedCards && savedCards.length },
      screenName: 'Checkout_Payment'
    });
    dispatch({
      type: ACTION_SET_SAVED_CARDS,
      data: savedCards
    });
  } catch (e) {
    logError(e);
  }
  return savedCards;
  // return response;
}

export async function applyStoreCredit(
  { orderSummary = {}, walletSelected },
  { shippingDispatch }
) {
  try {
    if (shippingDispatch) {
      shippingDispatch({ type: ACTION_SET_ORDER_DETAILS_LOADING });
    }
    const { remaining } = getBalanceApplied(orderSummary);

    const reqBody = {
      customerId: getCustomerId(),
      storeId: getStoreId()
    };

    if (walletSelected) {
      reqBody.amount = remaining;
    }

    await axios({
      method: walletSelected ? 'post' : 'delete',
      url: API_STORE_CREDIT_UPDATE(),
      data: reqBody,
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });
    // return result;
  } catch (e) {
    logError(e);
  }
  if (shippingDispatch) {
    await getUpdateQuoteDetails({ bagView: 0 }, shippingDispatch);
  }
}

// export async function changePaymentMethod(
//   {
//     quote,
//     paymentCode,
//     previousSelectedMethod,
//     orderSummary,
//     selectWallet = false
//   },
//   { shippingDispatch }
// ) {
//   if (!paymentCode) {
//     return false;
//   }

//   const quoteId = await getCurrentQuoteId();
//   if (shippingDispatch) {
//     shippingDispatch({
//       type: ACTION_SET_ORDER_DETAILS_LOADING,
//       data: { currentPaymentCode: paymentCode }
//     });
//   }
//   if (previousSelectedMethod === WALLET_PAYMENT_INDEX || selectWallet) {
//     await applyStoreCredit(
//       {
//         orderSummary,
//         walletSelected: !!selectWallet
//       },
//       {}
//     );
//   }
//   try {
//     const data = {
//       quoteId,
//       paymentCode,
//       storeId: getStoreId()
//     };

//     await axios.post(API_CHANGE_PAYMENT_METHOD(), data, {
//       headers: {
//         'X-Header-Token': getXHeaderToken(),
//         Token: getJWT(),
//         'Content-Type': 'application/json'
//       }
//     });

//     logCheckoutProgress({ quote, paymentCode });

//     // return result;
//   } catch (e) {
//     logError(e);
//   }
//   if (shippingDispatch) {
//     await getUpdateQuoteDetails({ bagView: 0 }, shippingDispatch);
//   }
//   return {};
// }

export async function createOrder(
  options,
  dispatch,
  history,
  shippingDispatch
) {
  const {
    quote,
    mobileNumber,
    tabbyDetails,
    isProxy,
    tabbyPaymentId,
    tamaraOrderId,
    source,
    appVersion,
    otpvalue,
    accountDispatch,
    isPaymentHoldCall,
    orderIncrementId,
    retryPaymentReplica,
    method,
    replicaHoldorder,
    phfv
  } = options;
  const storeId = getStoreId();
  let validOtp = true;
  let orderId = '';
  try {
    if (!window.location.href?.includes('replica')) {
      dispatch({
        type: ACTION_MAKE_PAYMENT_LOADING,
        data: { paymentProcessing: true }
      });
    }
    const quoteIdFromAPI = options?.quoteId;
    // const invenState = await inventoryCheck({
    //   productArray: quote.products,
    //   returnData: false
    // });
    const quoteId = (await getCurrentQuoteId()) || quoteIdFromAPI;
    const customerId = getCustomerId() || options?.customerId;
    if (!quoteId && !window.location.href?.includes('replica')) {
      dispatch({
        type: ACTION_MAKE_PAYMENT_FAILED,
        data: { error: { message: UNKNOWN_ERROR }, paymentProcessing: false }
      });
      return false;
    }
    let reqObj = {
      quoteId,
      storeId,
      source,
      appVersion,
      paymentId: tabbyPaymentId || tamaraOrderId || options?.paymentId,
      isProxy,
      otpvalue
    };
    console.log('reqObj for create Order:::', reqObj);
    const configValue = await getStorage('config');
    const { paymentFailedHold } = configValue;
    const lang = COUNTRY;
    const { msite_hold_order: msiteHoldorder } =
      paymentFailedHold?.[lang] || {};
    const shouldHoldPayment = msiteHoldorder || msiteHoldorder === 'true';
    const orderData = await getStorage('orderResponse');
    const holdStatus = await getStorage('holOrderStatus');
    if (
      isPaymentHoldCall &&
      shouldHoldPayment &&
      ((holdStatus &&
      (holdStatus === true || holdStatus === 'true')) || phfv === true || phfv === "true")
    ) {
      reqObj.retryPaymentReplica = retryPaymentReplica || true;
      reqObj.orderIncrementId =
        orderData?.incrementId ||
        orderIncrementId ||
        quote?.payfortMerchantReference;
    } else {
      reqObj.retryPaymentReplica = false;
      reqObj.orderIncrementId =
        orderIncrementId || quote?.payfortMerchantReference;
    }
    if (customerId) reqObj.customerId = customerId;
    await setStorage('paymentId', '');
    await setStorage('paymentMethod', '');
    await removeStorage('styliCredithold');

    if (
      replicaHoldorder &&
      shouldHoldPayment &&
      ((holdStatus && (holdStatus === true || holdStatus === 'true')) ||
        phfv === true ||
        phfv === 'true')
    ) {
      let proxyVal = false;
      if (method === 'BNPL') {
        proxyVal = true;
      } else {
        proxyVal = false;
      }
      dispatch({
        type: ACTION_MAKE_PAYMENT_LOADING,
        data: { paymentProcessing: true }
      });
      reqObj = {
        quoteId: orderData?.quoteId,
        storeId: orderData?.storeId,
        customerId: orderData?.customerId,
        otpvalue: '',
        source: SOURCE_OF_ORDER,
        appVersion: `${process.env.TAG_NAME || process.env.BRANCH || 'local'}`,
        isProxy: proxyVal,
        retryPaymentReplica: true,
        orderIncrementId: orderData?.incrementId || orderIncrementId
      };
    }
    const result = await axios.post(API_CREATE_ORDER(), reqObj, {
      headers: {
        Token: getJWT(),
        'Content-Type': 'application/json',
        'X-Header-Token': getXHeaderToken()
      }
    });
    const { response = {}, status, error, statusCode } = result.data;

    const orderNumber = (response && response.incrementId) || '';
    orderId = (response && response.orderId) || '';
    const paymentId =
      response && (response.tabbyPaymentId || response.paymentId);
    response.storeId = storeId;
    response.customerId = customerId;
    response.method = 'BNPL';
    response.quote = quote;
    if (replicaHoldorder) {
      response.isPaymentHoldCall = true;
    }
    if (!(holdStatus && (holdStatus === true || holdStatus === 'true'))) {
      await setStorage('orderResponse', response);
    }
    await setStorage('paymentId', paymentId);
    await setStorage('paymentMethod', paymentId);
    if (`${statusCode}` === '217') {
      validOtp = false;
    }
    if (status) {
      try {
        const productCount = quote.products.length - 1;
        const productTitle = quote.products[0].name;
        let orderMsg;

        if (productCount >= 1) {
          orderMsg = 'THANKS_MESSAGE_MULTIPLE_PRODUCTS';
        } else {
          orderMsg = 'THANKS_MESSAGE_SINGLE_PRODUCT';
        }

        const msg = translate(orderMsg)
          .replace('{orderNumber}', orderNumber)
          .replace('{productTitle}', productTitle)
          .replace('{productCount}', productCount);

        if (SEND_ORDER_CONFIRMATION) {
          if (
            process.env.NODE_ENV === 'production' ||
            getCookieValue('test_otp') === '1'
          ) {
            await axios.post(
              OTP_API,
              { to: mobileNumber, msg },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            );
          }
          await axios.post(
            API_ORDER_EMAIL(),
            {
              orderId
            },
            {
              headers: {
                Token: getJWT(),
                'Content-Type': 'application/json'
              }
            }
          );
        }
      } catch (e) {
        logError(e);
      }
      if (tabbyDetails && tabbyDetails.web_url) {
        return window.location.assign(tabbyDetails.web_url);
      }

      logUIEvent({ eventName: 'place_order', data: { type: 'cod' } });
      if (!method) {
        history.replace(
          `/${CURRENT_LANG_PREFIX}/checkout/payment/confirmation?orderNumber=${orderNumber}&orderId=${orderId}`
        );
      }
    } else {
      await getUpdateQuoteDetails({ bagView: 0 }, shippingDispatch);
      dispatch({
        type: ACTION_MAKE_PAYMENT_FAILED,
        data: { error, paymentProcessing: false }
      });
    }
    if (accountDispatch)
      accountDispatch({
        type: ACTION_VERIFY_PHONE_FINISHED,
        data: { success: validOtp, isRegistration: false }
      });
  } catch (e) {
    logError(e);
    dispatch({
      type: ACTION_MAKE_PAYMENT_FAILED,
      data: { error: { message: UNKNOWN_ERROR }, paymentProcessing: false }
    });
  }
  return { isValid: validOtp, createOrderId: orderId };
}

export async function retryReorder(
  options,
  dispatch,
  shippingDispatch,
  history
) {
  let response = {};
  dispatch({ type: ACTION_RETRY_REORDER_LOADING });
  try {
    const reqBody = {
      customerId: getCustomerId(),
      storeId: getStoreId(),
      orderId: options.orderId,
      source: SOURCE_OF_ORDER,
      ipAddress: null
    };
    const result = await axios.post(
      options.isReorder ? API_RE_ORDER() : API_RETRY_PAYMENT(),
      reqBody,
      {
        headers: {
          Token: getJWT(),
          'Content-Type': 'application/json',
          'X-Header-Token': getXHeaderToken()
        }
      }
    );
    const { statusCode } = result.data || {};
    if (statusCode === '200') {
      dispatch({ type: ACTION_RETRY_REORDER_SUCCESS });

      if (options.isReorder) {
        response = { success: true };
      } else {
        history.push(`/${CURRENT_LANG_PREFIX}/checkout/cart`);
      }
    } else {
      response = { success: false };
      showCheckoutToast(
        { type: 'error', content: UNKNOWN_ERROR },
        shippingDispatch
      );
      dispatch({ type: ACTION_RETRY_REORDER_ERROR });
    }
  } catch (e) {
    response = { success: false };
    showCheckoutToast(
      { type: 'error', content: UNKNOWN_ERROR },
      shippingDispatch
    );
    dispatch({ type: ACTION_RETRY_REORDER_ERROR });

    logError(e);
  }
  return response;
}

export async function makePayment(
  {
    cardForm,
    profile,
    quote,
    appleData,
    payfortCredentials,
    isApple = false,
    // cloudFunctionUrl,
    selectedCardBin,
    creditCardForm,
    paymentConfigs = {},
    isCashFree = false,
    cashFreeDetails
    // invalidAddressMsg
  },
  dispatch,
  shippingDispatch,
  history
) {
  // const currentQuote = await getStorage('currentQuote');

  // if (currentQuote && !currentQuote.selectedPaymentMethod) {
  //   window.location.reload();
  // } else {
  try {
    const customerId = getCustomerId();
    const storeId = getStoreId();
    const quoteId = (await getCurrentQuoteId()) || quote?.quoteId;

    dispatch({
      type: ACTION_MAKE_PAYMENT_LOADING,
      data: { paymentProcessing: true }
    });
    // const invenState = await inventoryCheck({
    //   productArray: quote.products,
    //   returnData: false
    // });
    // if (!invenState) {
    //   dispatch({
    //     type: ACTION_MAKE_PAYMENT_LOADING,
    //     data: { paymentProcessing: false }
    //   });
    //   return history.replace(`/${CURRENT_LANG_PREFIX}/checkout/cart`);
    // }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: getJWT(),
      token: getJWT(),
      'X-Header-Token': getXHeaderToken()
    };
    const customerInfo = {};
    if (!customerId) {
      const guestAddress = await getStorage('guestAddress');
      customerInfo.customerEmail = guestAddress.email;
      customerInfo.firstName = guestAddress.firstName;
      customerInfo.lastName = guestAddress.lastName;
    } else if (profile) {
      const {
        customer: { email, firstName, lastName }
      } = profile || {};
      customerInfo.customerEmail = email;
      customerInfo.firstName = firstName;
      customerInfo.lastName = lastName;
    }
    const orderData = await getStorage('orderResponse');
    const paymentRequest = {
      ...cardForm,
      ...appleData,
      ...customerInfo,
      quoteId,
      paymentRedirectHost: window.location.host,
      customerId,
      storeId,
      source: SOURCE_OF_ORDER,
      xSourceHeader: 'msite',
      appVersion: `${process.env.TAG_NAME || process.env.BRANCH || 'local'}`,
      device_fingerprint: document.getElementById('device_fingerprint').value
    };

    if (window?.location?.search.includes('paymentHoldCall=1')) {
      paymentRequest.retryPaymentReplica = true;
      paymentRequest.orderIncrementId = orderData?.incrementId;
    }
    let tokenResponse;
    if (!isCashFree && !isApple) {
      if (!paymentRequest.cardToken) {
        tokenResponse = await getToken({
          paymentRequest,
          payfortCredentials,
          paymentConfigs
        });
        if (typeof tokenResponse.cardToken !== 'string') {
          dispatch({
            type: ACTION_MAKE_PAYMENT_FAILED,
            data: { error: { message: UNKNOWN_ERROR } }
          });
          return false;
        }

        if (!(tokenResponse && tokenResponse.cardToken)) {
          dispatch({
            type: ACTION_MAKE_PAYMENT_FAILED,
            data: { error: {} }
          });
          return { tokenizationError: true };
        }
        paymentRequest.cardToken = tokenResponse.cardToken;
        paymentRequest.isClientTokenized = true;
        const cleverTapData = {
          card_type: creditCardForm?.cardType
        };
        cleverTapProcess({
          eventName: 'card_added',
          cleverTapData
        });
      } else {
        paymentRequest.isClientTokenized = false;
      }
    }

    // const url = isApple
    //   ? `/styli-payment/${USE_MICROSERVICE ? 'v2/' : ''}apple`
    //   : `${cloudFunctionUrl}/payment/payment/${
    //       USE_MICROSERVICE ? 'v2/' : ''
    //     }card`;

    const url = isApple
      ? paymentConfigs.applePay.order_url
      : paymentConfigs.card.order_url || cashFreeDetails.paymentURL;

    axios
      .post(
        url,
        {
          ...paymentRequest
        },
        { headers }
      )
      // .then(res => res.json())
      .then(async response => {
        const { success, error, orderId, orderNumber } = response.data;
        if (isEmpty(error) && success) {
          if (isCashFree) {
            if (response.data.status && success.redirectUrl) {
              window.location.href = success.redirectUrl;
              dispatch({
                type: ACTION_MAKE_PAYMENT_SUCCESS,
                data: { success, paymentProcessing: false }
              });
            } else {
              dispatch({
                type: ACTION_MAKE_PAYMENT_FAILED,
                data: { error, paymentProcessing: false }
              });
            }
            return;
          }
          const payfortStatusCode = success.payfort.status;
          const paymentStatus =
            payfortStatusCode === '15' || payfortStatusCode === '19'
              ? 'pending'
              : '';
          // success
          if (success.redirectUrl) {
            if (window.sessionStorage) {
              sessionStorage.payfortVisited = true;
            }
            window.location.href = success.redirectUrl;
          } else if (
            payfortStatusCode === '14' ||
            payfortStatusCode === '02' ||
            payfortStatusCode === '15' ||
            payfortStatusCode === '19'
          ) {
            history.replace(
              `/${CURRENT_LANG_PREFIX}/checkout/payment/confirmation?orderNumber=${success.order.response.incrementId}&orderId=${success.order.response.orderId}&paymentStatus=${paymentStatus}`
            );
          }
          if (isApple) {
            history.replace(
              `/${CURRENT_LANG_PREFIX}/checkout/payment/confirmation?orderNumber=${success.order.response.incrementId}&orderId=${success.order.response.orderId}&paymentStatus=${paymentStatus}`
            );
          }

          dispatch({
            type: ACTION_MAKE_PAYMENT_SUCCESS,
            data: { success, paymentProcessing: false }
          });
        } else {
          const payfortErrorStatusCode = error.payfort.status;
          if (
            payfortErrorStatusCode === '03' ||
            payfortErrorStatusCode === '13'
          ) {
            history.replace(
              `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${error?.message}&paymentStatus=failed`
            );
          }
          if (!customerId || customerId === '') {
            if (error && error.status === 'FAILURE') {
              await createReplica(
                {
                  orderId,
                  paymentStatus: 'failed',
                  responseMessage: error.message
                },
                shippingDispatch
              );
            } else {
              await createReplica({ orderId }, shippingDispatch);
            }
          }
          const configVal = await getStorage('config');
          const { paymentFailedHold } = configVal;
          const lang = COUNTRY;
          const { msite_hold_order: msiteHoldorder } =
            paymentFailedHold?.[lang] || {};
          if ((!msiteHoldorder || msiteHoldorder !== 'true') && !isApple) {
            history.replace(
              `/${CURRENT_LANG_PREFIX}/checkout/payment?orderId=${orderId}&orderNumber=${orderNumber}&response_message=${error?.message}&paymentStatus=failed`
            );
          }
          if (orderId) {
            const shouldHoldPayment =
              error &&
              error.status === 'FAILURE' &&
              (msiteHoldorder || msiteHoldorder === 'true');

            const replicaParams = shouldHoldPayment
              ? {
                  orderId,
                  failedPaymentMethod: 'card'
                }
              : {
                  orderId,
                  paymentStatus: error ? 'failed' : undefined,
                  responseMessage: error ? error.message : undefined
                };

            const replicaFunction = shouldHoldPayment
              ? createReplicaPaymentHold
              : createReplica;

            await replicaFunction(
              replicaParams,
              null,
              history || shippingDispatch
            );
            // const config = await getStorage('config');
            // const orderSummary = getOrderSummaryFromQuote(quote, config);
            // const { remaining } = getBalanceApplied(orderSummary);

            // if (quote.selectedAddressId) {
            // await setSelectedAddressId(
            //   { addressId: quote.selectedAddressId, selectedAddress: quote.shippingAddress, invalidAddressMsg },
            //   shippingDispatch
            // );
            // }
            // else {
            //   const address = await getStorage('guestAddress');
            //   await manageQuote(
            //     { address, quote, isGuestUser: true },
            //     shippingDispatch
            //   );
            //   // await getUpdateQuoteDetails({}, shippingDispatch);
            // }
            // await getUpdateQuoteDetails({}, shippingDispatch);
            if (isApple) {
              history.replace(
                `/${CURRENT_LANG_PREFIX}/checkout/payment?paymentStatus=failed`
              );
            }
          }
          errorRedirect(history, null, error.message, error);
          // failed

          if (!isApple) {
            await getUpdateQuoteDetails({ bagView: 0 }, shippingDispatch);
            const config = await getStorage('config');
            const orderSummary = getOrderSummaryFromQuote(quote, config);
            const { remaining } = getBalanceApplied(orderSummary);

            await changePaymentMethod(
              {
                quote,
                paymentCode: PAYMENT_CODE_VAULT,
                cardBin: selectedCardBin,
                selectWallet: remaining > 0
              },
              shippingDispatch
            );
          }
          dispatch({
            type: ACTION_MAKE_PAYMENT_FAILED,
            data: { error, paymentProcessing: false }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: ACTION_MAKE_PAYMENT_FAILED,
          data: { error: { message: UNKNOWN_ERROR } }
        });
        logError(error);
        errorRedirect(history, null, error.message);
      });

    logUIEvent({ eventName: 'place_order', data: { type: 'card' } });
  } catch (error) {
    history.replace(
      `/${CURRENT_LANG_PREFIX}/checkout/payment?paymentStatus=failed`
    );
    dispatch({
      type: ACTION_MAKE_PAYMENT_FAILED,
      data: { error: { message: UNKNOWN_ERROR } }
    });
    logError(error);
    errorRedirect(history, null, error.message);
  }
  // }
  return {};
}

export async function deleteSavedCard(options, dispatch) {
  const cardDetails = { ...options.cardDetails };
  const payfortCredentials = { ...options.payfortCredentials };
  const paymentConfigs = { ...options.paymentConfigs };

  dispatch({ type: ACTION_DELETE_CARD_LOADING });
  try {
    await deleteCard({
      cardDetails,
      payfortCredentials,
      paymentConfigs
    });
    await axios.delete(DELETE_CARD_API(), {
      data: {
        customerId: getCustomerId(),
        id: cardDetails.id
      },
      headers: {
        Token: getJWT(),
        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
      }
    });
    dispatch({ type: ACTION_CARD_DELETED });
  } catch (e) {
    logError(e);
  }
}

export async function fetchPaymentConfigs(options, dispatch) {
  try {
    dispatch({
      type: ACTION_FETCH_PAYMENT_CONFIG_LOADING
    });

    const paymentConfig = await axios.post(
      FETCH_PAYMENT_CONFIGS(),
      {
        countryCode: COUNTRY
      },
      {
        headers: {
          token: getJWT(),
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const statusCode = path(['status'], paymentConfig);
    const data = path(['data'], paymentConfig);

    if (statusCode === 200 && data) {
      dispatch({
        type: ACTION_PAYMENT_CONFIGS_FETCHED,
        data: data.response
      });
    } else {
      dispatch({ type: ACTION_PAYMENT_CONFIGS_ERROR });
    }
  } catch (e) {
    dispatch({ type: ACTION_PAYMENT_CONFIGS_ERROR });
    logError(e);
  }
}
