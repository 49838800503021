import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './index.scss';
import { path, getResizedImg } from 'utils/';
import { isBrowser, BrowserView, isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ArrowRight from 'assets/icons/arrow-right.svg';
import RowTitle from '../RowTitle';
import { CURRENT_LANG_PREFIX, BASE_PATH, isRTL } from '../../../../constants';

export default ({
  widgetData,
  rowTitle,
  borderBottom,
  bottomSpace,
  columnCountDesktop = 6,
  onWidgetClick = () => {}
}) => {
  const doOnClick = (event, slide, widgetIndex) => {
    onWidgetClick({ event, widget: slide, widgetIndex });
  };

  const [slideWidth, setSlideWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setSlideWidth((ref.clientWidth - 100) / columnCountDesktop);
    }
  };

  const [slideScrollleft, setSlideScrollleft] = useState(0);
  const [sliderAtStart, setSliderAtStart] = useState(true);
  const [sliderAtEnd, setSliderAtEnd] = useState(true);
  const [sliderElm, setSliderElm] = useState();
  // const [sliderWraperElm, setSliderWraperElm] = useState();
  const handelRefSlider = ref => {
    if (ref) {
      setSliderElm(ref);
    }
  };
  const handelRefSlider1 = ref => {
    if (ref && sliderElm) {
      const scrollLeft =
        isRTL && sliderElm.scrollLeft !== 0
          ? sliderElm.scrollLeft * -1
          : sliderElm.scrollLeft;
      if (sliderElm.clientWidth + scrollLeft === ref.scrollWidth) {
        setSliderAtEnd(true);
      } else {
        setSliderAtEnd(false);
      }
      // setSliderWraperElm(ref);
    }
  };
  const onArrowClick = arrowPos => {
    const scrollLeft =
      isRTL && sliderElm.scrollLeft !== 0
        ? sliderElm.scrollLeft * -1
        : sliderElm.scrollLeft;

    if (arrowPos === 'left') {
      setSlideScrollleft(scrollLeft - (slideWidth + 20));
    } else if (arrowPos === 'right') {
      setSliderAtStart(false);
      setSlideScrollleft(scrollLeft + slideWidth + 20);
    }
  };

  useEffect(() => {
    if (sliderElm) {
      if (slideScrollleft === 0 || slideScrollleft < 1) {
        setSlideScrollleft(0);
        setSliderAtStart(true);
      }
      sliderElm.scroll({
        top: 0,
        left: isRTL ? slideScrollleft * -1 : slideScrollleft,
        behavior: 'smooth'
      });
    }
  }, [slideScrollleft]);
  useEffect(() => {
    setSlideScrollleft(0);
  }, [widgetData]);

  return (
    <div className="" style={{ marginBottom: bottomSpace }}>
      <Choose>
        <When condition={widgetData.length > 0}>
          <If condition={borderBottom}>
            <hr className="mb-0 mt-0 mr-8 ml-8" />
          </If>
          <div
            ref={handelRef}
            className="category-carousel pos-relt"
            style={{
              // paddingBottom: Math.max(bottomSpace, 8),
              paddingTop: isBrowser ? '24px' : Math.max(bottomSpace, 8)
            }}
          >
            <If condition={rowTitle.length > 0}>
              <RowTitle title={rowTitle} noMB={true} mTop={false} />
            </If>
            <div
              ref={handelRefSlider}
              className={classnames(
                'cat-slide ta-c',
                isBrowser
                  ? 'overflow-hidden ws-nowrap'
                  : 'overflow-auto-no-bar pl-8 pr-8'
              )}
            >
              <div ref={handelRefSlider1}>
                <If condition={widgetData.length > 0}>
                  {widgetData
                    .filter(slide => path(['imageMedia', [0], 'url'], slide))
                    .map((slide, widgetIndex) => {
                      const imgURL = path(['imageMedia', [0], 'url'], slide);
                      return (
                        <If condition={imgURL}>
                          <a
                            href={`${BASE_PATH}/${CURRENT_LANG_PREFIX}/${slide.link}`}
                            onClick={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                            onKeyPress={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                            className={classnames(
                              'd-il-block v-t category-tile',
                              isBrowser ? 'mr-20' : 'w-70 mr-8'
                            )}
                            style={{
                              marginBottom: isBrowser
                                ? '24px'
                                : Math.max(bottomSpace, 8),
                              width: isBrowser ? slideWidth : ''
                            }}
                            title={slide.title}
                          >
                            <div
                              className={classnames(
                                'w100p category-carousel-cover',
                                { 'h-70': isMobile },
                                slideWidth
                              )}
                              style={{
                                height: isBrowser ? slideWidth : ''
                              }}
                            >
                              <LazyLoadImage
                                className="w100p d-block"
                                src={getResizedImg({
                                  imgURL,
                                  reqWidth: isBrowser ? slideWidth : '70'
                                })}
                                alt={slide.name}
                              />
                            </div>
                            <div
                              className={classnames(
                                'ta-c txt-overflow-ellipsis c-dark-gray',
                                isBrowser
                                  ? 'pb-16 pt-16 fs-14 ls-064 fw-6'
                                  : 'pb-3 pt-3 fs-11'
                              )}
                            >
                              <Choose>
                                <When condition={slide.title}>
                                  {slide.title}
                                </When>
                                <Otherwise>&nbsp;</Otherwise>
                              </Choose>
                            </div>
                          </a>
                        </If>
                      );
                    })}
                </If>
              </div>
            </div>
            <BrowserView>
              <If condition={!sliderAtStart}>
                <div
                  className={classnames(
                    'white-shade pos-abs w-40 bg-white top-0 bottom-0 mb-20 mt-20 z-1 mb-0',
                    isRTL ? 'r-0 rot-180' : 'l-0'
                  )}
                />
                <div
                  className={classnames(
                    'pos-abs z-2 top-0 h100p flex middle-xs ml-25',
                    isRTL ? 'r-0 mr-25' : 'l-0 ml-25'
                  )}
                  onClick={() => onArrowClick('left')}
                  onKeyPress={() => onArrowClick('left')}
                  role="button"
                >
                  <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                    <ArrowRight
                      height="12px"
                      className={classnames(isRTL ? '' : 'rot-180')}
                    />
                  </div>
                </div>
              </If>
              <If condition={!sliderAtEnd}>
                <div
                  className={classnames(
                    'white-shade pos-abs w-40 bg-white top-0 bottom-0 mb-20 mt-20 z-1 mb-0',
                    isRTL ? 'l-0' : 'r-0 rot-180'
                  )}
                />
                <div
                  className={classnames(
                    'pos-abs z-2 top-0 h100p flex middle-xs',
                    isRTL ? 'l-0 ml-25' : 'r-0 mr-25'
                  )}
                  onClick={() => onArrowClick('right')}
                  onKeyPress={() => onArrowClick('right')}
                  role="button"
                >
                  <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                    <ArrowRight height="12px" className="rtl-r" />
                  </div>
                </div>
              </If>
            </BrowserView>
          </div>
          <If condition={borderBottom}>
            <hr className="mb-0 mt-0 mr-8 ml-8" />
          </If>
        </When>
      </Choose>
    </div>
  );
};
