import React from 'react';
import './index.scss';
import classnames from 'classnames';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import { isWhiteSpace } from 'utils/';
import { logEvent } from 'utils/analytics';

import CloseIcon from 'assets/icons/close.svg';
import BackIcon from 'assets/icons/arrow-back-blk.svg';
import SearchIcon from 'components/Header/magnifying-glass.svg';
import { MobileView } from 'react-device-detect';
import {
  //  ALGOLIA_INDEX_PREFIX,
  CURRENT_LANG_PREFIX
} from '../../../constants';
import { translate } from '../../../constants/language';
import SearchHits from '../SearchHits';

const Autocomplete = ({
  hits = [],
  currentRefinement = '',
  refine,
  limit,
  categoryIndex,
  productIndex,
  showCategories,
  showProducts,
  onBackClick = () => {}
}) => {
  const clearSrch = () => {
    refine('');
  };
  const history = useHistory();
  const handleSubmit = ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    if (!isWhiteSpace(currentRefinement)) {
      logEvent({
        eventName: 'search',
        data: {
          searchTerm: currentRefinement
        },
        skipGtm: true,
        screenName: 'Search_Suggestion'
      });
      history.push(
        `/${CURRENT_LANG_PREFIX}/catalogsearch/result?q=${currentRefinement}`
      );
      onBackClick();
    }
  };
  const lang = CURRENT_LANG_PREFIX;
  // let suggestionHits = [];
  // const returnSearchData = ({ sHits, dIndex }) => {
  //   sHits.map(hitDatax => {
  //     if(dIndex )
  //     return hitDatax;
  //   });
  // };
  return (
    <>
      <div className="search-bar flex pt-5 pb-5 pl-15 pr-15 middle-xs bg-white">
        <MobileView>
          <a
            className="flex flex-start"
            onClick={onBackClick}
            role="button"
            onKeyPress={onBackClick}
          >
            <BackIcon width="14px" className="c-black-light rtl-reverse v-m" />
          </a>
        </MobileView>
        <div className="w100p pl-10 pr-10">
          <form
            action={`/${lang}/catalogsearch/result`}
            onSubmit={handleSubmit}
          >
            <input
              className={classnames(
                { 'en-font': currentRefinement },
                'fs-14 w100p h-40 p-0 b-none outline-none'
              )}
              name="q"
              type="text"
              autoComplete="off"
              value={currentRefinement}
              onChange={event => refine(event.currentTarget.value)}
              placeholder={translate('What are you looking for?')}
              ref={input => input && input.focus()}
            />
          </form>
        </div>
        <If condition={currentRefinement.length}>
          <div
            className="flex flex-end w40 center-xs"
            onClick={clearSrch}
            onKeyPress={clearSrch}
            role="button"
          >
            <CloseIcon />
          </div>
        </If>
        <If condition={!currentRefinement.length}>
          <a
            onClick={clearSrch}
            onKeyPress={clearSrch}
            role="button"
            className="c-main-gray flex middle-xs search-link"
          >
            <SearchIcon />
          </a>
        </If>
      </div>
      <div className="search-result bg-white overflow-auto bt-light">
        <If condition={currentRefinement.length}>
          <SearchHits
            hits={showProducts ? hits || [] : []}
            indexName={productIndex}
            dataType="product"
            onBackClick={onBackClick}
            currentRefinement={currentRefinement}
            refine={refine}
            limit={limit}
          />
          <SearchHits
            hits={showCategories ? hits || [] : []}
            indexName={categoryIndex}
            dataType="category"
            onBackClick={onBackClick}
            currentRefinement={currentRefinement}
            refine={refine}
            limit={limit}
          />
        </If>

        {/* <SearchHits
          hits={hits || []}
          indexName={`magento2_${ALGOLIA_INDEX_PREFIX}_${lang}_categories`}
          // dataType="suggestions"
          onBackClick={onBackClick}
          currentRefinement={currentRefinement}
          refine={refine}
        /> */}
      </div>
    </>
  );
};

export default connectAutoComplete(Autocomplete);
