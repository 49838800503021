import React, { useEffect, useState } from 'react';
import { Tabs, DefaultTabBar } from 'rmc-tabs';
import classnames from 'classnames';
import './index.scss';
import { isBrowser } from 'react-device-detect';
import { getNavigationBasePath } from 'utils/';
import isArabic from 'constants/isArabic';
import ImageTileRow from '../ImageTileRow';

export default ({
  widgetData,
  viewMore,
  rowType,
  analyticsData = {},
  bottomSpace,
  itemTextCenterAlign = false,
  columnCountMob,
  columnCountTab,
  columnCountDesktop,
  cloumnStyling,
  BGColor,
  selectedTabTextColor,
  nonSelectedTabBorderColor,
  showItemCount,
  selectedTabBackgroundColor,
  selectedTabBorderColor
}) => {
  const [tabData, setTabdata] = useState({});
  const textColor = cloumnStyling ? cloumnStyling.subTitleColor : '';

  useEffect(() => {
    if (widgetData.length) {
      setTabdata({ tab: widgetData[0] });
    }
  }, [widgetData]);

  return (
    <>
      <div>
        <Tabs
          tabs={widgetData}
          tabBarTextStyle={{}}
          animated={false}
          onChange={tab => {
            setTabdata({ tab });
          }}
          // renderTabBar={renderTabData}
          renderTabBar={props => (
            <DefaultTabBar
              {...props}
              page={3.5}
              renderTab={tab => {
                let tabStyle = {
                  width: '100%',
                  height: '100%',
                  padding: '8px 5px',
                  textAlign: 'center',
                  position: 'relative',
                  wordBreak: 'break-word'
                };

                if (props.tabs[props.activeTab].id === tab.id) {
                  tabStyle = {
                    ...tabStyle,
                    color: selectedTabTextColor,
                    border: `0.5px solid ${selectedTabBorderColor}`,
                    backgroundColor: selectedTabBackgroundColor
                  };
                } else {
                  tabStyle = {
                    ...tabStyle,
                    color: tab.titleTextColor,
                    border: `0.5px solid ${nonSelectedTabBorderColor}`,
                    backgroundColor: tab.backgroundColor
                  };
                }

                return (
                  <div className="fw-6 fs-11-2 lh-15 ls-04 " style={tabStyle}>
                    {tab.title}
                    <If condition={showItemCount && tab.itemCount}>
                      <br />
                      <span className="fw-4 lh-16 fs-10 ls-04">
                        {tab.itemCount} items{' '}
                      </span>
                    </If>
                    <If condition={props.tabs[props.activeTab].id === tab.id}>
                      <div
                        style={{
                          borderColor: selectedTabBorderColor,
                          background: selectedTabBackgroundColor
                        }}
                        className="activeTabBar"
                      />
                    </If>
                  </div>
                );
              }}
            />
          )}
          // renderTabBar={renderTabData}
          // renderTabBar={props => <DefaultTabBar>{props}</DefaultTabBar>}
        >
          <div className={classnames('pl-8 pr-8', { 'forced-rtl': isArabic })}>
            <If
              condition={
                tabData.tab &&
                tabData.tab.products &&
                tabData.tab.products.length
              }
            >
              <ImageTileRow
                analyticsData={analyticsData || {}}
                products={tabData.tab.products}
                columnCountMob={columnCountMob}
                columnCountTab={columnCountTab}
                columnCountDesktop={columnCountDesktop}
                cloumnStyling={cloumnStyling}
                rowType={rowType}
                bottomSpace={bottomSpace}
                hasBGColor={!!BGColor}
                itemTextCenterAlign={itemTextCenterAlign}
              />
            </If>
            <If
              condition={
                viewMore && viewMore.text && tabData.tab && tabData.tab.link
              }
            >
              <div className="ta-c pb-24">
                <a
                  className={classnames(
                    'fw-5 c-dark-gray d-il-block cursor-pointer',
                    isBrowser
                      ? 'b-dark-gray fs-14 lh-20 ls-16 pt-14 pb-14 w-150'
                      : 'pb-2 fs-15 bb-brand-dashed'
                  )}
                  href={`${getNavigationBasePath()}/${tabData.tab.link}`}
                  title={viewMore.text}
                  style={{ color: `${textColor}` }}
                >
                  {viewMore.text}
                </a>
              </div>
            </If>
          </div>
        </Tabs>
      </div>
    </>
  );
};
