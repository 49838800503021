import React, { useState } from 'react';
import classnames from 'classnames';
import { getNavigationBasePath } from 'utils';
import SlidePane from 'components/libs/SlidePane';

import ModalHeader from 'components/ModalHeader';
import ArrowRight from 'assets/icons/arrow-right.svg';

import Detail from 'containers/Account/Detail';
import './index.scss';
import { translate } from 'constants/language';
import { isBrowser } from 'react-device-detect';
import SizeButton from './SizeButton';
// import KnowYourSize from './KnowYourSize';

export default ({
  sizes = [],
  onSelectSize = () => {},
  selectedSize,
  quoteSelectedSizes,
  productDetail,
  selectSizeWarning = false,
  setSelectSizeWarnin = () => {}
}) => {
  const [knowYourSizeVisible, setKnowYourSizeVisible] = useState(false);
  const handleKnowYourSize = () => {
    setKnowYourSizeVisible(!knowYourSizeVisible);
  };

  const handleSizeSelect = data => {
    onSelectSize(data);
    setSelectSizeWarnin(false);
  };
  return (
    <div className="mb-10">
      <div className="flex middle-xs mb-15">
        <div className="fs-16 fw-5 c-main-gray">
          {translate('Pick your size')}
        </div>
        <div className="flex middle-xs ml-auto">
          <div
            className={classnames(
              'fs-12 ml-4 mr-4',
              isBrowser ? 'ls-072 c-dark-gray' : 'fw-5'
            )}
            onClick={handleKnowYourSize}
            onKeyPress={handleKnowYourSize}
            role="link"
          >
            {translate('Size chart')}
          </div>
          <ArrowRight className="rot-90 d-block" />
        </div>
      </div>
      <div className="flex flex-wrap size-unit-list mb-10">
        {sizes.map(size => (
          <SizeButton
            size={size}
            onSelect={handleSizeSelect}
            selected={
              quoteSelectedSizes &&
              selectedSize.productId &&
              Number(size.id) === Number(selectedSize.productId)
            }
          />
        ))}
      </div>
      <If condition={isBrowser && selectSizeWarning}>
        <div className="fs-12 lh-14 ls-038 c-secondary-negative start-xs">
          {translate('Please select a size to add the item to the bag')}
        </div>
      </If>
      <SlidePane
        isOpen={knowYourSizeVisible}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        height={isBrowser ? '100%' : '90%'}
        onRequestClose={handleKnowYourSize}
        className={isBrowser ? 'top-0' : 'bottom-0'}
      >
        <ModalHeader
          onBackButtonPress={handleKnowYourSize}
          backAction={handleKnowYourSize}
          noBorder={true}
          className="w100"
          title={translate('Size chart?')}
          showHeaderRight={false}
        />
        <div style={{ overflowY: 'scroll' }}>
          <Detail
            showTitle={false}
            href={`${getNavigationBasePath()}/sizechart/display/index/id/${
              productDetail.id
            }?mobile=1`}
          />
        </div>
        {/* <KnowYourSize productId={productDetail.id} /> */}
      </SlidePane>
    </div>
  );
};
