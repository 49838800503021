import React from 'react';
import classnames from 'classnames';

export default function BackDrop({ classes = "", doClick = () => { }, ...props }) {

  return (
    <div
      className={classnames(classes, 'screen-fixed bg-gray-3 z-101')}
      onClick={doClick}
      onKeyPress={doClick}
      role="button"
      {...props}
    >&nbsp;
    </div>
  );
}
