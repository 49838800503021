import {
  ACTION_GET_HOME_PAGE,
  ACTION_GET_DESKTOP_CATEGORIES,
  ACTION_FETCH_RECENTLY_VIEWED_LOADING,
  ACTION_FETCH_RECENTLY_VIEWED_FETCHED,
  ACTION_FETCH_RECENTLY_VIEWED_ERROR,
  ACTION_PROMO_NAME,
  ACTION_GET_STRAPI_ROWS,
  ACTION_INITAL_LOAD
} from './actions';

export const initialState = {
  initalLoad: false,
  isLoading: true,
  isProfileLoading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_INITAL_LOAD: {
      return {
        ...state,
        initalLoad: true
      };
    }

    case ACTION_GET_HOME_PAGE: {
      const { data = [] } = action;
      const pages = {};
      data.forEach(page => {
        pages[page.pageUniqueId] = page;
      });
      return {
        ...state,
        isLoading: false,
        pages
      };
    }

    case ACTION_GET_DESKTOP_CATEGORIES: {
      const { data = [] } = action;
      const pages = {};
      data.forEach(page => {
        pages[page.pageUniqueId] = page;
      });
      return {
        ...state,
        isCategoryLoading: false,
        categoriesData: pages,
        isLoading: false
      };
    }
    case ACTION_FETCH_RECENTLY_VIEWED_LOADING: {
      return {
        ...state,
        recentlyViewedLoading: true
      };
    }

    case ACTION_FETCH_RECENTLY_VIEWED_FETCHED: {
      return {
        ...state,
        recentlyViewedLoading: false,
        recentlyViewedList: action.data
      };
    }

    case ACTION_FETCH_RECENTLY_VIEWED_ERROR: {
      return {
        ...state,
        recentlyViewedLoading: false,
        recentlyViewedList: undefined
      };
    }

    case ACTION_PROMO_NAME: {
      const { promoBannerName = {} } = action.data;
      return {
        ...state,
        promoBannerName
      };
    }

    case ACTION_GET_STRAPI_ROWS: {
      const strapiWidgets = action.data;
      return {
        ...state,
        strapiWidgets
      };
    }

    default:
      return state;
  }
};
