import React, { useState } from 'react';
import { CURRENT_LANG_PREFIX } from 'constants/index';
import { cmToInchRange } from 'utils/';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { translate } from 'constants/language';
import SizeButtonRadio from '../SizeChart/SizeButtonRadio';

export default ({
  sizeChartData = {},
  translations,
  sizeChartLoading,
  handleSizeSelect = () => {},
  selectedSize,
  quoteSelectedSizes,
  productSizes = []
}) => {
  const {
    sizes = [],
    [`size_label_${CURRENT_LANG_PREFIX}`]: sizeLabel,
    // [`scale_${CURRENT_LANG_PREFIX}`]: scale,
    [`description_${CURRENT_LANG_PREFIX}`]: description,
    previewImages = []
  } = sizeChartData;
  const columnHeadings =
    (sizes[0] &&
      sizes[0].value.map(data => data[`${CURRENT_LANG_PREFIX}_label`])) ||
    [];
  const isTypeShoes =
    sizes[0] &&
    sizes[0].value &&
    sizes[0].value.filter(v => v.append_scale).length <= 0;

  const descriptionArr = (description && description.split('\n')) || [];

  const tdClasses = 'ta-c pl-7 pr-7 pt-10 pb-10 fs-13 b-cream va-middle';
  const tdClasses1 = 'ta-c pl-2 pr-2 pt-10 pb-10 fs-13 b-cream va-middle';

  const [checked, setChecked] = useState('cm');
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  return (
    <div className={classnames('mt-20', { 'pr-16 pl-16 pb-50': isMobile })}>
      <hr className="mt-0 mb-8 h-2 ml-n-15 mr-n-15 c-dark-gray" />
      <div className="row mb-8">
        <div className="col-xs-9 fs-12">
          {translate(
            'Our sizes are in (CM) by default, you can also switch to (IN)'
          )}
        </div>
        <div
          className={classnames(
            'col-xs-3',
            CURRENT_LANG_PREFIX === 'ar' ? 'ta-l' : 'ta-r'
          )}
        >
          <div className="measure-toggle">
            <input
              checked={checked === 'cm'}
              onClick={() => handleChange('cm')}
              name="measure"
              type="radio"
              id="measure-toggle-cm"
            />
            <label className="radio-button" htmlFor="measure-toggle-cm">
              {' '}
              CM
            </label>
            <input
              checked={checked === 'in'}
              onClick={() => handleChange('in')}
              name="measure"
              type="radio"
              id="measure-toggle-in"
            />
            <label className="radio-button" htmlFor="measure-toggle-in">
              {' '}
              IN
            </label>
          </div>
        </div>
      </div>
      <Choose>
        <When condition={sizeChartLoading}>
          <div className="center-xs flex flex-grow-1 h100vh middle-xs mt-50">
            <div className="loader b-box mr-5 ml-5" />
          </div>
        </When>
        <Otherwise>
          <Choose>
            <When condition={!(sizeChartData && sizeChartData._id)}>
              <div>{translations.KEY_NO_SIZE_CHART}</div>
            </When>
            <Otherwise>
              <div style={{ overflowX: 'scroll' }}>
                <If condition={checked === 'cm'}>
                  <table
                    cellSpacing="0"
                    className="w100p"
                    style={{
                      'border-collapse': 'collapse'
                    }}
                  >
                    <thead>
                      <tr className="bg-cream">
                        <td
                          className={classnames(`${tdClasses} fw-6 `, {
                            'ta-l': !isTypeShoes,
                            w33p: columnHeadings.length + 1 === 3
                          })}
                          style={{
                            width: `${
                              isTypeShoes
                                ? `${100 / columnHeadings.length + 1}`
                                : 'auto'
                            }`
                          }}
                        >
                          {sizeLabel}
                        </td>
                        {columnHeadings.map(ch => {
                          return <td className={`${tdClasses} fw-6`}>{ch}</td>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {sizes.map(s => {
                        const size = productSizes.find(
                          v => String(v.size) === String(s.key_en)
                        );
                        return (
                          <If condition={size}>
                            <tr>
                              <td
                                className={classnames(
                                  `${tdClasses} en-font bg-cream`
                                )}
                              >
                                <SizeButtonRadio
                                  size={size}
                                  onSelect={handleSizeSelect}
                                  selected={
                                    quoteSelectedSizes &&
                                    selectedSize.productId &&
                                    Number(size.id) ===
                                      Number(selectedSize.productId)
                                  }
                                  onlySizes={SizeButtonRadio}
                                />
                              </td>
                              {(s.value || []).map(v => {
                                return (
                                  <td
                                    className={`${tdClasses} en-font ${
                                      !v.append_scale ? 'fw-6' : ''
                                    }`}
                                  >
                                    <div className="h-20 lh-20">{v.value}</div>
                                  </td>
                                );
                              })}
                            </tr>
                          </If>
                        );
                      })}
                    </tbody>
                  </table>
                </If>
                <If condition={!isTypeShoes && checked === 'in'}>
                  <table
                    className="w100p"
                    cellSpacing="0"
                    style={{
                      'border-collapse': 'collapse'
                    }}
                  >
                    <thead>
                      <tr className="bg-cream">
                        <td className={`${tdClasses} fw-6 ta-l`}>
                          {sizeLabel}
                        </td>
                        {columnHeadings.map(ch => {
                          return <td className={`${tdClasses} fw-6`}>{ch}</td>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {sizes.map(s => {
                        const size = productSizes.find(
                          v => String(v.size) === String(s.key_en)
                        );
                        return (
                          <If condition={size}>
                            <tr>
                              <td
                                className={classnames(
                                  `${tdClasses} en-font bg-cream`
                                )}
                              >
                                <SizeButtonRadio
                                  size={size}
                                  onSelect={handleSizeSelect}
                                  selected={
                                    quoteSelectedSizes &&
                                    selectedSize.productId &&
                                    Number(size.id) ===
                                      Number(selectedSize.productId)
                                  }
                                  onlySizes={SizeButtonRadio}
                                />
                              </td>
                              {(s.value || []).map(v => {
                                return (
                                  <td
                                    className={`${tdClasses1} en-font ${
                                      !v.append_scale ? 'fw-6' : ''
                                    }`}
                                  >
                                    <div className="h-20 lh-20">
                                      {!v.append_scale
                                        ? v.value
                                        : cmToInchRange(v.value)}
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          </If>
                        );
                      })}
                    </tbody>
                  </table>
                </If>
              </div>
              <div className="mt-50">
                {descriptionArr.map(data => {
                  const bulletArray = data && data.split('##');
                  if (bulletArray.length) {
                    return bulletArray.map((b, index) => {
                      if (index === 0) return <p>{b}</p>;
                      return (
                        <div className="flex">
                          <div>&#8226;</div>
                          <div className="ml-r-15">{b}</div>
                        </div>
                      );
                    });
                  }
                  return <p>{data}</p>;
                })}
              </div>

              <div className="center-xs">
                {previewImages.map(imgSrc => {
                  return (
                    <img
                      className="mt-50 w100p"
                      src={imgSrc && imgSrc[CURRENT_LANG_PREFIX]}
                      alt="img"
                    />
                  );
                })}
              </div>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </div>
  );
};
