import React, { useEffect } from 'react';

import { useStateValue } from 'context/app';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import { formatOrderData } from 'utils/cart';

import { logEvent } from 'utils/analytics';
// import Footer from 'components/Footer';
import urlParse from 'url-parse';

import ButtonBottomFixed from 'components/libs/Buttons/ButtonBottomFixed';
// import Button from 'components/libs/Buttons';
import { createReplica } from 'containers/Checkout/actions';

import { resetQuote, getOrderDetails } from 'containers/Confirmation/actions';

import { CURRENT_LANG_PREFIX, IS_ARABIC } from '../../constants';
import { translate } from '../../constants/language';

export default () => {
  const { query } = urlParse(window.location.href, true);
  const history = useHistory();
  const handleBack = () => {
    history.replace(`/${CURRENT_LANG_PREFIX}/`);
  };

  const { paymentStatus, orderNumber, orderId } = query;
  const handleRetry = () => {
    const isReOrder = false;
    createReplica({ orderId, isReOrder }, null, history);
  };

  const {
    confirmation: [confirmationState, dispatch]
  } = useStateValue();

  useEffect(() => {
    if (
      confirmationState.orderSummary &&
      confirmationState.orderSummary.orderId
    ) {
      const order = confirmationState.orderSummary;
      const data = {
        event_category: 'order_data',
        ...formatOrderData({ order })
      };
      logEvent({
        eventName: 'order_failed',
        data,
        screenName: 'Order_Failed'
      });
    }
  }, [confirmationState.orderSummary]);

  useEffect(() => {
    getOrderDetails({ orderId }, dispatch);
    resetQuote();
  }, []);

  let title = 'Payment Failed';
  let description = 'PAYMENT_FAILURE';
  let subText = 'PAYMENT_FAILURE';
  let orderNumberText =
    orderNumber && ` ${translate('Your Order ID is')} ${orderNumber}`;
  switch (paymentStatus) {
    case 'success':
    case 'pending':
      title = 'Awaiting Payment Confirmation';
      description = 'Awaiting Payment Confirmation';
      subText =
        "We are checking payment status with your bank. This may take up to 30 minutes. We'll send you an email once we receive an update from your bank";
      break;
    case 'failed':
      title = '';
      description = 'Oops!! Something went Wrong';
      subText = 'Your payment was declined by your bank. Please try again';
      orderNumberText = '';
      break;
    default:
      break;
  }
  return (
    <>
      <Helmet>
        <html lang={IS_ARABIC ? 'ar' : 'en'} dir={IS_ARABIC ? 'rtl' : 'ltr'} />
      </Helmet>
      <Header
        title={translate(title)}
        showLogo={false}
        backAction={handleBack}
      />
      <div className="flex middle-xs center-xs h100vh">
        <div>
          {/* <FontAwesomeIcon
            icon={faHeartBroken}
            className="c-error-icon"
            style={{ fontSize: 100 }}
          /> */}
          <div className="fs-14 c-label-hint mx-w-250 mt-10">
            {translate(description)}
          </div>
          <div className="mt-10 fs-12 c-label-hint mx-w-250">
            {translate(subText)}
          </div>
          <div className="mt-10 fs-14 fs-14 c-label-hint mx-w-250">
            {orderNumberText}
          </div>
        </div>
        <If condition={paymentStatus === 'failed'}>
          <ButtonBottomFixed
            className="primary cap"
            onClick={handleRetry}
            id="retryPayment"
          >
            {translate('Retry Payment')}
          </ButtonBottomFixed>
        </If>
        <If condition={paymentStatus === 'success'}>
          <ButtonBottomFixed
            className="primary cap"
            onClick={handleBack}
            id="continueShopping"
          >
            {translate('Continue Shopping')}
          </ButtonBottomFixed>
        </If>
      </div>
    </>
  );
};
