import React, { useState, useEffect, useRef, useCallback } from 'react';
import algoliasearch from 'algoliasearch/lite';
import debounce from 'lodash.debounce';
import { isBrowser } from 'react-device-detect';
// import throttle from 'lodash.throttle';

import { useStateValue } from 'context/app';

import { InstantSearch } from 'react-instantsearch-dom';
import { getSearchData } from 'containers/Account/actions';
import { isWhiteSpace, getTabCookie } from 'utils/';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import './index.scss';
import Listing from './Listing';
import VmListing from './VmListing';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
  // ALGOLIA_INDEX_PREFIX,
  CURRENT_LANG_PREFIX,
  COUNTRY
} from '../../constants';

export default function ({ onBackClick }) {
  const {
    account: [accountState, dispatch]
  } = useStateValue();
  const [currentQuery, setQuery] = useState('');
  const { config, searchData, searchDataLoading, popularSearch, popularSearchLoading, showPreSearch, popularSearchTitle } = accountState;
  const { algoliaConfig, searchConfig = {} } = config || {};
  const [finalData, setFinalData] = useState(null);

  const productIndex =
    algoliaConfig &&
    algoliaConfig[COUNTRY] &&
    algoliaConfig[COUNTRY][`${CURRENT_LANG_PREFIX}_indices`].product[0];
  const categoryIndex =
    algoliaConfig &&
    algoliaConfig[COUNTRY] &&
    algoliaConfig[COUNTRY][`${CURRENT_LANG_PREFIX}_indices`].categories[0];
  const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

  const { category, level1 } = useParams();
  const tabValue = getTabCookie();
  const [cLevel1,] = useState(category || level1 || tabValue || 'women');
  const debouncedFunctionRef = useRef();
  debouncedFunctionRef.current = query => {
    if (!isWhiteSpace(query)) {
      getSearchData(
        { query, limit: searchConfig && searchConfig.limit, level1: cLevel1 },
        dispatch
      );
    }
  };

  useEffect(() => {
    getSearchData(
      { query: "", limit: searchConfig && searchConfig.limit, level1: cLevel1 },
      dispatch
    );
  }, [])

  useEffect(() => {
    // const { products, categories } = searchData || {};
    // let fData = finalData;
    // if (searchConfig.show_products && products) fData = products;

    // if (searchConfig.show_categories && categories) {
    //   if (searchConfig.show_products) {
    //     fData = [...fData, ...categories];
    //   } else {
    //     fData = categories;
    //   }
    // }

    // setFinalData(fData);

    setFinalData(searchData || []);
  }, [searchData]);

  const delayedSearchCall = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), 300, {
      leading: false,
      trailing: true
    }),
    []
  );

  const fetchSearchData = query => {
    if (query !== currentQuery) {
      delayedSearchCall(query);
      setQuery(query);
    }
  };

  return (
    <div className={classnames("search-box bg-white", isBrowser ? '_desktop' : '_mobile')}>
      <If condition={false && searchConfig && searchConfig.mode === 0}>
        <InstantSearch
          searchClient={searchClient}
          // indexName={`magento2_${ALGOLIA_INDEX_PREFIX}_${CURRENT_LANG_PREFIX}_categories`}
          indexName={categoryIndex}
        >
          <Listing
            onBackClick={onBackClick}
            productIndex={productIndex}
            categoryIndex={categoryIndex}
            showCategories={searchConfig && searchConfig.show_categories}
            showProducts={searchConfig && searchConfig.show_products}
            limit={searchConfig && searchConfig.limit}
          />
        </InstantSearch>
      </If>
      {/* <If condition={searchConfig}> */}
      <VmListing
        onBackClick={onBackClick}
        searchDataLoading={searchDataLoading}
        finalData={finalData}
        currentRefinement={currentQuery}
        refine={fetchSearchData}
        showCategories={searchConfig && searchConfig.show_categories}
        showProducts={searchConfig && searchConfig.show_products}
        popularSearch={popularSearch}
        popularSearchLoading={popularSearchLoading}
        showPreSearch={showPreSearch}
        popularSearchTitle={popularSearchTitle}
      />
      {/* </If> */}
    </div>
  );
}
