import values from 'object.values';
import logError from 'utils/logError';
import { DEFAULT_CURRENCY } from 'constants/index';

import { getBalanceApplied } from '.';

export function getCartProduct({ productDetail, productId, quantity: qty }) {
  const {
    configProducts = [],
    prices: { price: mainPrice, specialPrice: mainSpecialPrice } = {}
  } = productDetail || {};
  const product = configProducts.find(
    prod => Number(productId) === Number(prod.id)
  );

  const sizes = configProducts.map(
    ({
      sizeOptionId,
      sizeName,
      sku,
      id,
      quantity,
      prices: { price, specialPrice } = {}
    }) => ({
      sizeOptionId,
      label: sizeName,
      productId: Number(id),
      sku,
      price: Number(price || mainPrice),
      specialPrice: Number(specialPrice || mainSpecialPrice),
      quantity
    })
  );
  if (product) {
    return {
      ...product,
      prices: {
        price: product.prices && Number(product.prices.price),
        specialPrice: product.prices && Number(product.prices.specialPrice)
      },
      productId,
      parentProductId: Number(productDetail.id),
      sizes,
      quantity: qty,
      quantityStock: Number(product.quantity),
      images: productDetail.images
    };
  }
  return false;
}

export function getQuoteFromGuestCartItems({ cartItems = {} }) {
  const products = values(cartItems)
    .map(getCartProduct)
    .filter(prod => prod); // Just to filter False products
  const itemsCount = products.length;
  let itemsQty = 0;
  let subtotal = 0;
  let total = 0;
  products.forEach(({ prices: { price, specialPrice } = {}, quantity }) => {
    itemsQty += quantity;
    subtotal += Number(price) * quantity;
    total += Number(specialPrice || price) * quantity;
  });

  return {
    itemsCount,
    itemsQty,
    subtotal,
    baseGrandTotal: total,
    total: subtotal,
    discount: subtotal - total,
    products,
    hideTaxAndShipping: true
  };
}

export const getOrderSummaryFromQuote = (quote, config) => {
  // let { shippingAmount } = quote;
  const {
    // minBagValueForFreeShipping = 100,
    // shippingCharge = 10,
    freeShippingDifference = 10
  } = config || {};
  const baseGrandTotal = Number(quote.baseGrandTotal);
  // if (!quote.quoteId && quote.baseGrandTotal < minBagValueForFreeShipping) {
  //   shippingAmount = shippingCharge;
  //   baseGrandTotal += shippingAmount;
  // }

  const orderSummary = {
    freeShippingDifference,
    minBagValueForFreeShipping: Number(quote.shippingThreshold),
    shippingFreeCeiling: Number(quote.shippingFreeCeiling),
    total: Number(quote.total),
    subTotal: Number(quote.subtotal),
    autoCouponDiscount: Number(quote.autoCouponDiscount || 0),
    baseGrandTotal,
    grandTotal: Number(quote.grandTotal || 0),
    importFeesAmount: Number(quote.importFeesAmount),
    codCharges: Number(quote.codCharges),
    shippingAmount: Number(quote.shippingAmount),
    discount: Number(quote.discount),
    couponDiscount: Number(quote.couponDiscount),
    totalSavings:
      Number(quote.discount) +
        Number(quote.couponDiscount) +
        Number(quote.autoCouponDiscount || 0) || 0,
    storeCreditBalance: Number(quote.storeCreditBalance || 0),
    storeCreditApplied: Number(quote.storeCreditApplied || 0),
    tax: Number(quote.taxAmount),
    discountData: quote.discountData,
    currency: quote.currency || DEFAULT_CURRENCY,
    donationAmount: quote.donationAmount || 0,
    selectedPaymentMethod: quote.selectedPaymentMethod,
    taxPercent: Number(quote.taxPercent),
    addQuoteAmountToEnableStyliCredit: quote.addQuoteAmountToEnableStyliCredit,
    isStoreCreditEnabled: quote.isStoreCreditEnabled
  };
  const { remaining, sum, cleanGrandTotal } = getBalanceApplied(orderSummary);

  const fullCredit = Number(quote.storeCreditBalance) >= cleanGrandTotal;
  return { ...orderSummary, remaining, sum, fullCredit };
};

export const getProductImageSrc = ({ images: { image = '' } = {} } = {}) => {
  // return process.env.NODE_ENV === 'production'
  //   ? image.replace(
  //       'stylishop.com/media',
  //       'stylishop.com/cdn-cgi/image/width=200,quality=90/media'
  //     )
  //   : image;
  return image;
};

export const validateCartItem = product => {
  let flag = false;
  // const price =
  //   product.prices && product.prices.specialPrice
  //     ? product.prices.specialPrice
  //     : product.price;
  // if (Number(product.quantityStock) <= 0 || Number(price) <= 0) {
  if (Number(product.quantityStock) <= 0) {
    flag = 'outOfStock';
  } else if (Number(product.quantityStock) < Number(product.quantity)) {
    flag = 'lessStock';
  }
  return flag;
};
export const validateCart = quote => {
  return ((quote && quote.products) || [])
    .map(validateCartItem)
    .filter(item => item);
};

// REQ1: Add to cart event
export const formatCartItem = (
  productDetail,
  quantity,
  selectedProductId,
  { productCatInfo }
) => {
  const itemArray = [];
  try {
    if (productDetail.configProducts) {
      const {
        categories: { level3 = [], level2 = [], level1 = [], level0 = [] } = {}
      } = productDetail;
      const catLevel = level3[0] || level2[0] || level1[0] || level0[0];
      const [l1, l2, l3, l4] = catLevel.split(' /// ');

      productDetail.configProducts.forEach(item => {
        if (Number(item.id) === Number(selectedProductId)) {
          itemArray.push({
            item_id: productDetail.sku,
            item_name: productDetail.name,
            variant: item.size,
            item_variant: item.size,
            price: Number(item.prices.specialPrice || item.prices.price),
            quantity: Number(quantity),
            metadata: productCatInfo,
            currency: productDetail.currency,
            discount: productDetail.discount,
            item_brand: productDetail?.brand,
            item_category: l1,
            item_category2: l2,
            item_category3: l3,
            item_category4: l4
          });
        }
      });
    }
  } catch (e) {
    logError(e);
  }
  return itemArray;
};

export async function formatRemoveFromCart(productDetail, quantity) {
  const itemArray = [];
  try {
    if (productDetail) {
      itemArray.push({
        item_id: productDetail.sku.substr(0, productDetail.sku.length - 2),
        item_name: productDetail.name,
        item_variant: productDetail.size,
        price: Number(
          productDetail.prices.specialPrice || productDetail.prices.price
        ),
        quantity: Number(quantity),
        item_brand: productDetail.brandName
      });
    }
  } catch (e) {
    logError(e);
  }
  return itemArray;
}

export const formatCheckoutData = ({ quote, quoteMetadata }) => {
  const itemArr = [];
  try {
    if (quote && quote.products && quote.products.length) {
      quote.products.forEach(product => {
        itemArr.push({
          id: product.parentSku,
          name: product.name,
          variant: product.size,
          price: Number(product.prices.specialPrice || product.prices.price),
          quantity: Number(product.quantity),
          brand: product.brandName || product.brand,
          metadata:
            quoteMetadata &&
            quoteMetadata.length &&
            quoteMetadata.find(m => m.sku === product.parentSku)
        });
      });
    }
  } catch (e) {
    logError(e);
  }
  return {
    value: quote.subtotal,
    total_items: quote.itemsCount,
    grandTotal: quote.grandTotal,

    coupon: quote.couponCodeApplied,
    paymentMethod: quote.paymentMethod,
    currency: quote.currency,
    items: itemArr
  };
};

export const formatOrderData = ({ order, quote, quoteMetadata }) => {
  const itemArr = [];
  const products = (quote && quote.products) || (order && order.products);
  try {
    if (products && products.length) {
      products.forEach(product => {
        itemArr.push({
          item_id:
            product.parentSku || (product.sku && product.sku.slice(0, -2)),
          item_name: product.name,
          item_variant: product.size,
          price: Number(
            (product.prices &&
              (product.prices.specialPrice || product.prices.price)) ||
              product.price
          ),
          quantity: Number(product.quantity || product.qty),
          item_brand: product.brandName,
          metadata:
            quoteMetadata &&
            quoteMetadata.length &&
            quoteMetadata.find(m => m.sku === product.parentSku)
        });
      });
    }
  } catch (e) {
    logError(e);
  }
  return {
    transaction_id: order?.incrementId || '',
    quoteId: (quote && quote?.quoteId) || '',
    value: order?.subtotal || quote?.subtotal,
    grandTotal: order?.grandTotal || quote?.grandTotal,
    shipping: order?.shippingAmount || quote?.shippingAmount,
    total_items:
      quote && quote.products
        ? quote.products.reduce((total, product) => {
            return total + Number(product.quantity);
          }, 0)
        : 0,
    coupon: quote?.isCouponApplicable
      ? order?.couponCode || quote?.couponDiscount
      : null,
    paymentMethod: order?.paymentMethod || quote?.selectedPaymentMethod,
    currency: order?.currency || quote?.currency,
    items: itemArr
  };
};
