import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import urlParse from 'url-parse';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';

import BackIcon from 'components/Header/back.svg';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/FooterDesktop';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import logError from 'utils/logError';
import { path } from 'utils/';
import { isMobile } from 'react-device-detect';
import { ACCOUNT_PAGE_PROPERTIES } from '../../constants/account';
import { translate } from '../../constants/language';
import {
  COUNTRY,
  COUNTRY_KSA,
  COUNTRY_KWT,
  COUNTRY_QA,
  COUNTRY_BH,
  COUNTRY_UAE,
  COUNTRY_IND
} from '../../constants';

export default ({ page, href, showTitle = true, isLegacy = false }) => {
  let getPage = page;
  const pathName = path(['location', 'pathname'], page);

  if (isLegacy) {
    const pathArr = pathName.split('/account/');
    [, getPage] = pathArr;
  }

  const { title, src, hash } =
    (getPage && path([getPage], ACCOUNT_PAGE_PROPERTIES)) || {};
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();
  const handleBackClick = () => {
    history.goBack();
    // goBack(history);
  };
  const loadHandler = content => {
    content.addEventListener('load', () => {
      try {
        content.contentWindow.document
          .getElementsByTagName('body')[0]
          .classList.add('mobileapp');
        const cmsClass = content.contentWindow.document.getElementsByClassName(
          'item cms_page'
        );
        if (cmsClass && cmsClass.length) {
          cmsClass[0].remove();
        }
        const itemHomeclass = content.contentWindow.document.getElementsByClassName(
          'item home'
        );
        if (itemHomeclass && itemHomeclass.length) {
          itemHomeclass[0].remove();
        }
      } catch (e) {
        logError(e);
      }
      setLoading(false);
    });
  };
  const handleRef = ref => {
    const content = ref;
    if (content) {
      loadHandler(content);
      try {
        content.contentWindow.addEventListener('unload', () => {
          setLoading(true);
          loadHandler(content);
        });
      } catch (e) {
        logError(e);
      }
    }
  };
  const { query } = urlParse(window.location.href, true);

  const loc = query.url && decodeURIComponent(query.url);
  let targetUrl =
    href || loc || `${src}?mobile=1${hash && isMobile ? `#${hash}` : ''}`;

  /* HACK FIX DUE TO MAGENTO URL ISSUE */
  if (COUNTRY === COUNTRY_KSA) {
    targetUrl = targetUrl.replace('/sa/', '/');
  } else if (COUNTRY === COUNTRY_UAE) {
    targetUrl = targetUrl
      .replace('/ae/ar/', '/ae_ar/')
      .replace('/ae/en/', '/ae_en/');
  } else if (COUNTRY === COUNTRY_KWT) {
    targetUrl = targetUrl
      .replace('/kw/ar/', '/kw_ar/')
      .replace('/kw/en/', '/kw_en/');
  } else if (COUNTRY === COUNTRY_BH) {
    targetUrl = targetUrl
      .replace('/bh/ar/', '/bh_ar/')
      .replace('/bh/en/', '/bh_en/');
  } else if (COUNTRY === COUNTRY_QA) {
    targetUrl = targetUrl
      .replace('/qa/ar/', '/qa_ar/')
      .replace('/qa/en/', '/qa_en/');
  } else if (COUNTRY === COUNTRY_IND) {
    targetUrl = targetUrl
      .replace('/in/ar/', '/in_en/')
      .replace('/in/en/', '/in_en/');
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  /* HACK FIX DUE TO MAGENTO URL ISSUE */
  return (
    <>
      <If condition={showTitle}>
        <If condition={targetUrl.indexOf('wfs/offer') > -1}>
          <Helmet>
            <html lang="en" dir="ltr" />
          </Helmet>
        </If>

        <PageHeader
          title={translate(title)}
          showLogo={false}
          HeaderRight={<div className="w100" />}
          iconLeft={
            <a
              onClick={handleBackClick}
              onKeyPress={handleBackClick}
              role="link"
            >
              <BackIcon
                // icon={faArrowLeft}
                className="c-black-light rtl-reverse v-m"
              />
            </a>
          }
        />
      </If>
      <ContainerDesktop isUserPage={true}>
        <Helmet>
          <html lang="en" dir="ltr" />
        </Helmet>
        <div className="mt-5 h100vh">
          <If condition={isLoading}>
            <div className="center-xs flex flex-grow-1 h100vh middle-xs mt-50">
              <div className="loader" />
            </div>
          </If>
          <iframe
            name="account_detail"
            className={classnames('h100p w100p', {})}
            style={{ border: 'initial' }}
            noborder={true}
            src={targetUrl}
            title={title}
            ref={handleRef}
          />
        </div>
      </ContainerDesktop>
      <PageFooter />
    </>
  );
};
