import React, { useState, useEffect } from 'react';
import Button from 'components/libs/Buttons';
import classnames from 'classnames';
import { isBrowser } from 'react-device-detect';
import CloseIcon from 'assets/icons/close-popup.svg';
import Input from 'components/libs/Input';
import { isValidPhone } from 'utils/validators';
import { getStoreId } from 'utils';
import { useStateValue } from 'context/app';
import { getLoginValidate, sendOTP } from 'containers/Account/actions';
import { translate } from '../../constants/language';
import { isRTL } from '../../constants';
import SlidePane from '../libs/SlidePane';

function ContactDetailsModal({
  showPhoneDialog,
  onClose = () => {},
  phoneVerifyRegister,
  phoneSocialLogin,
  email
}) {
  // const [btnDisabled, setButtonDisabled] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('');
  const [validatedPhone, setPhone] = useState('');
  const [error, setError] = useState('');
  const {
    account: [accountState, dispatch]
  } = useStateValue();
  const storeId = getStoreId();

  const handleChange = event => {
    console.log('Input:', event.target.value);
    const inputtxt = event.target.value;
    setMobileNumber(inputtxt);

    if (isValidPhone(inputtxt, storeId)) {
      setError('');
      // setButtonDisabled(false);
    }
  };

  const handleConfirm = async () => {
    if (!isValidPhone(mobileNumber, storeId)) {
      setError(translate('Please use a valid  mobile number  (eg. 501234567)'));
      // setButtonDisabled(true);
      return;
    }
    const validateResponse = await getLoginValidate(
      {
        useridentifier: mobileNumber,
        loginType: 'MOBILE_LOGIN',
        initiatedFrom: 'phonePrompt',
        phoneVerifyRegister,
        socialEmail: email
      },
      dispatch
    );

    if (!validateResponse.exists) {
      console.log('is', phoneVerifyRegister);
      setPhone(validateResponse.finalUserIndentifier);
      if (phoneVerifyRegister) {
        console.log('is33');
        sendOTP(
          { phone: validateResponse.finalUserIndentifier, isPhonePrompt: true },
          dispatch
        );
      } else {
        console.log('is 2');
        phoneSocialLogin({
          phoneNumber: validateResponse.finalUserIndentifier
        });
      }
    }
  };

  useEffect(() => {
    if (accountState.phoneVerified) {
      phoneSocialLogin({
        phoneNumber: validatedPhone
      });
    }
  }, [accountState.phoneVerified]);

  return (
    <div>
      <SlidePane
        isOpen={showPhoneDialog}
        onRequestClose={onClose}
        // style={customStyles}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        style={{ 'box-sizing': 'border-box' }}
        // height="100%"
        // onRequestClose={onBack}
        className={classnames(
          isBrowser ? 'pl-30 pr-30 pt-32 pb-32' : 'pl-15 pr-15 pt-24 pb-24'
        )}
        isCenter={true}
        isLogin={true}
      >
        <div
          className={classnames(
            'c-dark-gray',
            isBrowser
              ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
              : 'fs-14 lh-18 fw-6'
          )}
        >
          {translate('Phone Number')}
          <If condition={isBrowser}>
            <Button
              className="normal mr-0 ml-auto p-0"
              onClick={onClose}
              onKeyPress={onClose}
            >
              <CloseIcon className="d-block" />
            </Button>
          </If>
        </div>

        <form>
          {/* <input type="number" /> */}
          <Input
            label={
              <div className="flex middle-xs">
                <div className="fs-12 lh-16 ls-072 c-dark-gray fw-6">
                  {translate(
                    '*Please enter Your phone number to complete Your Profile'
                  )}
                </div>
              </div>
            }
            placeholder="050505050"
            labelClass="block ls-072 fs-13"
            // inputRef={handleFocus}
            wrapClassNames="mt-16"
            value={mobileNumber}
            onChange={handleChange}
            error={error}
            className={classnames('en-font', { 'ta-r': isRTL, 'ta-l': isRTL })}
          />
          <br />
          <Button
            className="w100p primary cap mt-16 border-none h-48"
            loadingOnDisabled={false}
            // disabled={btnDisabled}
            onClick={handleConfirm}
            id="validateUserIdentifier"
          >
            <span className="fs-14 lh-20 ls-16 fw-6">
              {translate('CONFIRM')}
            </span>
          </Button>
        </form>
      </SlidePane>
      {/* <VerifyPhone mobileNumber={mobileNumber} /> */}
    </div>
  );
}

export default ContactDetailsModal;
