import React, { useEffect } from 'react';
import Toast from 'components/libs/Toast';
// import { isChrome } from 'react-device-detect';
import urlParse from 'url-parse';

import 'utils/axios';

import {
  getBannerImageCards,
  getProfile,
  getRemoteConfig,
  getStyliConfig
  //  getWishListCount
} from 'containers/Account/actions';
import { getUpdateQuoteDetails } from 'containers/Checkout/actions';
import { useStateValue } from 'context/app';
import { timingFirstPaint } from 'utils/analytics';
import NotificationPrompt from 'components/NotificationPrompt';
import { useHistory } from 'react-router-dom';
import { getWishList } from 'containers/ProductList/actions';
import {
  CURRENT_LANG_PREFIX,
  ACC_OPTION_FOR_LOGGED_OUT_USER
} from 'constants/index';
import { isBrowser } from 'react-device-detect';
import VerifyPhone from 'components/VerifyPhone';

export default ({ children }) => {
  const {
    shipping: [shippingState, shippingDispatch],
    account: [accountState, accountDispatch],
    products: [, dispatch]
  } = useStateValue();

  const history = useHistory();

  const { pathname, query = {} } = urlParse(window.location.href, true);
  const { url } = query;
  const pathArray = pathname.split('/');
  const accountIndex = pathArray.indexOf('account');
  const cartIndex = pathArray.indexOf('cart');
  const paymentIndex = pathArray.indexOf('payment');
  const createPasswordIndex = pathArray.indexOf('createPassword');
  const trackingIndex = url && url.indexOf('waybill');

  useEffect(() => {
    timingFirstPaint();
    getProfile({}, accountDispatch, shippingDispatch);
    // if (false)
    if (cartIndex < 0 && paymentIndex < 0)
      getUpdateQuoteDetails(
        { cacheOnly: 1, bagView: 0, accountDispatch },
        shippingDispatch
      );
    getRemoteConfig({}, accountDispatch);
    getStyliConfig({}, accountDispatch);
    getBannerImageCards(accountDispatch);
  }, []);
  useEffect(() => {
    if (dispatch) getWishList({}, dispatch);
  }, [dispatch]);
  // useEffect(() => {
  //   if (
  //     (productsState.wishlistProducts || productsState.wishlistProductIds) &&
  //     pathname.indexOf('wishlist') === -1
  //   )
  //   // call getwishlist
  //    //  getWishListCount({}, accountDispatch);
  //    getWishList({}, dispatch);
  // }, [
  //   productsState.wishlistProducts,
  //   productsState.wishlistProductIds,
  //   productsState.wishlistCount
  // ]);
  useEffect(() => {
    if (createPasswordIndex !== -1) {
      if (!accountState.isProfileLoading && accountState.loggedIn) {
        if (isBrowser) history.push(`/${CURRENT_LANG_PREFIX}`);
        else history.push(`/${CURRENT_LANG_PREFIX}/account`);
      }
    }
    if (
      !createPasswordIndex &&
      accountIndex > 0 &&
      ACC_OPTION_FOR_LOGGED_OUT_USER.indexOf(pathArray[accountIndex + 1]) < 0
    ) {
      if (!accountState.isProfileLoading && !accountState.loggedIn) {
        if (isBrowser) history.push(`/${CURRENT_LANG_PREFIX}`);
        else history.push(`/${CURRENT_LANG_PREFIX}/account`);
      }
    }
  }, [accountState.isProfileLoading]);
  useEffect(() => {
    if (trackingIndex > -1) {
      window.location = url;
    }
  }, []);

  return (
    <>
      {children}
      <Toast
        type={accountState.toastType || shippingState.toastType}
        timeOut={accountState.toastTimeout || shippingState.toastTimeout}
        showBar={accountState.showToast || shippingState.showToast}
        ctaAction={accountState.ctaAction || shippingState.ctaAction}
      >
        {accountState.toastContent || shippingState.toastContent}
      </Toast>
      <If
        condition={
          accountState.config &&
          accountState.config.isWebPushNotificationEnabled &&
          pathname.indexOf('checkout') === -1
        }
      >
        <NotificationPrompt />
      </If>
      <If
        condition={
          accountState && accountState.showVerifyPhone && paymentIndex < 0
        }
      >
        <VerifyPhone
          showVerifyPhoneDialog={accountState.showVerifyPhone}
          phone={
            (accountState.validatedUser &&
              accountState.validatedUser.mobileNumber) ||
            accountState.socialPhone
          }
          isLoginFlow={true}
          isSocialPhone={accountState.socialPhone}
        />
      </If>
    </>
  );
};
