import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as branch from 'branch-sdk';

import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.STYLI_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: `${process.env.TAG_NAME || process.env.BRANCH}`,

    tags: {
      domain: window && window.location.hostname,
      commit: process.env.COMMIT_SHA,
      branch: process.env.BRANCH
    },
    debug: process.env.NODE_ENV === 'qa'
  });

  if (window.newrelic) {
    window.newrelic.addRelease(
      `${process.env.TAG_NAME || process.env.BRANCH}`,
      `${process.env.TAG_NAME || process.env.BRANCH}`
    );
    window.newrelic.setCustomAttribute(
      'domain',
      window && window.location.hostname
    );
    window.newrelic.setCustomAttribute('commit', process.env.COMMIT_SHA);
    window.newrelic.setCustomAttribute('branch', process.env.BRANCH);
    window.newrelic.setCustomAttribute('environment', process.env.NODE_ENV);
  }
}

if (process.env.STYLI_APP_BRANCH_API_KEY) {
  setTimeout(() => {
    branch.init(process.env.STYLI_APP_BRANCH_API_KEY, function (err) {
      console.log({ branchInit: { err } });
    });
  }, 12000)
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
