import React from 'react';
import classnames from 'classnames';
import { translate } from 'constants/language';

export default ({ saleTimer, digitSet, showColons = false, label = '' }) => {
  return (
    <If condition={digitSet !== undefined && digitSet !== null}>
      <div className="flex top-xs">
        <div className="center-xs">
          <div className="flex middle-xs">
            <div
              className={classnames('fw-6 fs-15 w-18 br-3 ml-3 mr-3')}
              style={{
                color: `${saleTimer.timerTextColor}`,
                background: `${saleTimer.timerTileBackgroundColor}`
              }}
            >
              {String(digitSet)[1] ? String(digitSet)[0] : '0'}
            </div>
            <div
              className={classnames('fw-6 fs-15 w-18 br-3 ml-3 mr-3')}
              style={{
                color: `${saleTimer.timerTextColor}`,
                background: `${saleTimer.timerTileBackgroundColor}`
              }}
            >
              {String(digitSet)[1] ? String(digitSet)[1] : String(digitSet)[0]}
            </div>
          </div>
          <div
            className={classnames('fs-8 lh-11 fw-6 cap mt-3 lh-11')}
            style={{
              color: `${saleTimer.timerLabelTextColor}`
            }}
          >
            <Choose>
              <When condition={label === 'DAYS'}>
                {digitSet && digitSet === 1
                  ? translate('DAY')
                  : translate('DAYS')}
              </When>
              <Otherwise>{label}</Otherwise>
            </Choose>
          </div>
        </div>
        <If condition={showColons}>
          <div
            className="flex middle-xs fs-15 pl-2 pr-2"
            style={{ color: `${saleTimer.timerColonColor}` }}
          >
            :
          </div>
        </If>
      </div>
    </If>
  );
};
