import React from 'react';
import classnames from 'classnames';
import Button from '.';

export default ({
  onClick,
  children,
  className = '',
  disabled,
  loadingOnDisabled = true,
  buttonType = '',
  wrapClassName = '',
  hideBorderTop = false,
  isPrimary = true,
  isDefault = false,
  ...props
}) => {
  // const returnElement = classnames
  return (
    <div
      // style={{ bottom: 250 }}
      className={classnames(' bg-white bottom-fixed pb-15', wrapClassName, {
        ' bt-light': !hideBorderTop
      })}
    >
      <div
        className={classnames({ 'pr-10 pl-10': buttonType !== 'stretched' })}
      >
        <Button
          disabled={disabled}
          className={classnames(
            {
              primary: isPrimary,
              default: isDefault
            },
            className,
            'full-width mt-10 fw-6 fs-16'
          )}
          onClick={onClick}
          //   disabled={paymentProcessing}
          {...props}
        >
          <If condition={disabled && loadingOnDisabled}>
            <div className="flex center-xs w100p">
              <div className="loader" />
            </div>
          </If>
          {children}
        </Button>
      </div>
    </div>
  );
};
