import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';
import AppleLogin from 'react-apple-login';

import SlidePane from 'components/libs/SlidePane';
import Button from 'components/libs/Buttons';
import { getCurrentLanguage } from 'utils/';
import VerifyPhone from 'components/VerifyPhone';

import ShowPWIcon from 'assets/icons/show-pw.svg';
import HidePWIcon from 'assets/icons/hide-pw.svg';
import BackIcon from 'assets/icons/arrow-back-blk.svg';
import GoogleIcon from 'assets/icons/googleIcon.svg';
import CloseIcon from 'assets/icons/close.svg';
import CloseIcon2 from 'assets/icons/close-popup.svg';
import BackPopup from 'assets/icons/back-popup.svg';
import AppleIcon from 'assets/icons/appleIcon.svg';
import {
  resetValidate,
  getLogin,
  forgotPassword,
  // verifyPhone,
  sendOTP,
  verifyRecaptcha
} from 'containers/Account/actions';

import Input from 'components/libs/Input';
import { useStateValue } from 'context/app';
import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView,
  isIOS,
  osName,
  isSafari
} from 'react-device-detect';
import logError from 'utils/logError';
import { logEvent } from 'utils/analytics';
import WithdrawRequest from './withdrawRequest';
import { translate } from '../../constants/language';
import {
  ARABIC_LANG,
  APPLE_SIGIN_CLIENT_ID,
  APPLE_SIGIN_REDIRECT_URI
} from '../../constants';
import EmailSent from './emailSent.svg';

export const isRTL = getCurrentLanguage() === ARABIC_LANG;

export default ({
  showPasswordDialog = false,
  // onBack = () => {},
  isStandaloneLogin = true,
  codePassword,
  signInWithGoogle,
  signInWithApple,
  setIsAppleLoggedInLoading,
  fromBag,
  eventAction = '',
  screenName = ''
}) => {
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showWithdrawalScreen, setShowWithdrawalScreen] = useState(false);
  // const [isBackButton, setIsBackButton] = useState(true);
  const [type, setType] = useState('password');

  const [error, setError] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const {
    account: [accountState, dispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();

  const { config: { socialLogin = {} } = {} } = accountState;

  const GOOGLE_SUBTITLE =
    'It looks like you’ve logged in earlier via Google, would you like to:';
  const APPLE_SUBTITLE =
    'It looks like you’ve logged in earlier via Apple, would you like to:';

  const clickHandler = () => {
    if (password.length <= 5) {
      setError(translate('Min. 6 characters required'));
    } else {
      setError('');
      getLogin(
        {
          useridentifier: accountState.validatedUser.email,
          password,
          isStandaloneLogin,
          quote: shippingState.quote,
          fromBag,
          eventActionFrom: eventAction
        },
        dispatch,
        shippingDispatch
      );
    }
  };

  useEffect(() => {
    if (accountState.invalidPassword === true) {
      setError(
        translate('Invalid credientials. Please try with correct credientials')
      );
    }
    if (accountState.recaptchaNeeded === true) {
      setShowRecaptcha(true);
    }
    if (accountState.loginType === 'MOBILE_LOGIN') {
      setShowForgotPassword(false);
    }
    if (accountState.deleteRequested === true) {
      setShowWithdrawalScreen(true);
    }
  }, [
    accountState.invalidPassword,
    accountState.loginApiError,
    accountState.loginType
  ]);

  const showPasswordHandler = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  const closeHandler = () => {
    if (showForgotPassword) {
      setShowForgotPassword(false);
      // setIsBackButton(true);
    } else {
      setShowRecaptcha(false);
      resetValidate({}, dispatch);
    }
  };

  const handleFocus = input => {
    if (input && showPasswordDialog && !accountState.loginLoading) {
      input.focus();
    } else if (input && !showPasswordDialog) {
      input.blur();
    }
  };

  const handleChange = event => {
    setPassword(event.target.value);
    if (event.target.value.length > 5) {
      setError('');
    }
  };

  const signInWithGoogleClick = () => {
    if (signInWithGoogle) {
      signInWithGoogle(
        true,
        accountState.validatedUser.passwordAvailable === true
      );
    }
  };

  const signInWithAppleWithLoader = appleResponse => {
    closeHandler();
    signInWithApple(appleResponse);
  };

  const forgotPasswordNewHandler = (setForFirst = false) => {
    if (setForFirst) {
      try {
        logEvent({
          eventName: 'CUSTOM_EVENT',
          data: {
            event_category: 'login',
            event_action: eventAction,
            event_label: `set_password`
          },
          screenName
        });
      } catch (e) {
        logError(e);
      }
    }
    forgotPassword(
      { useridentifier: accountState.validatedUser.email },
      dispatch
    );
    // setIsBackButton(false);
    setShowForgotPassword(true);
  };
  const closeWithdrawalScreen = () => {
    setShowWithdrawalScreen(false);
  };

  const forgotPasswordHandler = () => forgotPasswordNewHandler(false);

  const handleOtpVerify = () => {
    if (!accountState.otpLoading) {
      // verifyPhone({ phone: accountState.validatedUser.mobileNumber }, dispatch);
      sendOTP(
        {
          phone: accountState.validatedUser.mobileNumber,
          email: accountState.validatedUser.email
        },
        dispatch
      );
    }
  };

  const onRecaptchaChange = token => {
    if (token) {
      verifyRecaptcha(token, dispatch);
    }
  };
  useEffect(() => {
    if (accountState.phoneVerified) {
      getLogin(
        {
          useridentifier: accountState.validatedUser.mobileNumber,
          password: codePassword,
          loginType: 'MOBILE_LOGIN',
          isStandaloneLogin,
          quote: shippingState.quote,
          loginData: accountState.loginData,
          fromBag
        },
        dispatch,
        shippingDispatch
      );
    }
  }, [accountState.phoneVerified]);

  return (
    <>
      <SlidePane
        isOpen={showPasswordDialog}
        from="bottom"
        width="100%"
        overlayClassName={showPasswordDialog && 'z-100'}
        style={{ 'box-sizing': 'border-box' }}
        onRequestClose={closeHandler}
        className={classnames(
          'pb-24',
          isBrowser ? 'pl-30 pr-30' : 'pl-15 pr-15',
          {
            '_pb-for-footer top-0': showForgotPassword
          },
          showForgotPassword ? 'pt-15' : 'pt-24'
        )}
        isCenter={true}
      >
        <Choose>
          <When condition={showWithdrawalScreen}>
            {<WithdrawRequest closeWithdrawalScreen={closeWithdrawalScreen} />}
          </When>
          <When condition={showForgotPassword}>
            <a onClick={closeHandler} onKeyPress={closeHandler} role="link">
              <CloseIcon className="c-black-light rtl-reverse v-m" />
            </a>
            <div className="mt-180 ta-c">
              <EmailSent />
              <div>
                <span className="c-black-light fs-14 fw-6">
                  {translate('Check your email')}
                </span>
              </div>
              <div>
                <span className="c-brown-gray fs-10 fw-6">
                  {translate('We have sent an email to')}
                </span>
              </div>
              <div>
                <span className="c-brown-gray fs-10 fw-6">
                  {`"${accountState.validatedUser.email}"`}
                </span>
              </div>
            </div>
            <div className="bottom-fixed row pb-10 pr-8 pl-8 pt-10 bt-light">
              <div className="col-xs-6 pr-2 pl-2">
                <Button
                  className="Button b-white w100p border-none default fw-6"
                  onClick={forgotPasswordHandler}
                  loading={accountState.forgotPasswordLoading}
                  id="resendPassword"
                >
                  {translate('Resend')}
                </Button>
              </div>
              <div className="col-xs-6 pr-2 pl-2">
                <Button
                  className="Button b-black w100p border-none primary fw-6"
                  onClick={closeHandler}
                  id="proceedToEnterPassword"
                >
                  {translate('Log In')}
                </Button>
              </div>
            </div>
          </When>
          <When condition={!showForgotPassword}>
            <div className="flex middle-xs">
              <MobileView>
                <a onClick={closeHandler} onKeyPress={closeHandler} role="link">
                  <BackIcon className="h-20 w-20 d-block rtl-reverse" />
                </a>
              </MobileView>
              <If
                condition={
                  isMobile &&
                  accountState.validatedUser &&
                  !accountState.validatedUser.signedInNowUsing === 1
                }
              >
                <div
                  role="button"
                  className="fw-5 c-black-light fs-12 ml-auto"
                  onClick={forgotPasswordHandler}
                  onKeyPress={forgotPasswordHandler}
                  id="resetPassword"
                >
                  <div>{translate('Reset password?')}</div>
                </div>
              </If>
            </div>
            <div className="">
              <If condition={isBrowser}>
                <div className="flex middle-xs mb-16">
                  <div
                    className="flex middle-xs"
                    onClick={closeHandler}
                    onKeyPress={closeHandler}
                    role="button"
                  >
                    <div className="mr-r-10">
                      <BackPopup className="rtl-flip d-block" />
                    </div>
                    <div className="fs-12 ls-072 fw-6">{translate('Back')}</div>
                  </div>
                  <a
                    onClick={closeHandler}
                    onKeyPress={closeHandler}
                    role="link"
                    className="mr-0 ml-auto"
                  >
                    <CloseIcon2 className="d-block" />
                  </a>
                </div>
              </If>
              <div
                className={classnames(
                  'fw-6 c-dark-gray mt-16',
                  isBrowser ? 'fs-18 lh-24 ls-032' : 'fs-14 lh-18'
                )}
              >
                {translate('Welcome back')}
              </div>
              <If
                condition={
                  socialLogin &&
                  (socialLogin.apple_sign_in || socialLogin.google_sign_in) &&
                  accountState.validatedUser &&
                  accountState.validatedUser.signedInNowUsing &&
                  [1, 2].includes(accountState.validatedUser.signedInNowUsing)
                }
              >
                <div
                  className={classnames(
                    'c-brown-gray',
                    isBrowser
                      ? 'fs-16 lh-20 ls-048 mt-8'
                      : 'fs-12 lh-16 ls-072 pb-16'
                  )}
                >
                  {translate(
                    String(accountState.validatedUser.signedInNowUsing ?? 0) ===
                      '2'
                      ? APPLE_SUBTITLE
                      : GOOGLE_SUBTITLE
                  )}
                </div>
              </If>

              <If
                condition={
                  false &&
                  socialLogin &&
                  socialLogin.google_sign_in &&
                  accountState.validatedUser &&
                  accountState.validatedUser.signedInNowUsing === 1
                }
              >
                <div
                  className={classnames(
                    'c-dark-gray',
                    isBrowser
                      ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
                      : 'fs-14 mt-8 lh-18 fw-6'
                  )}
                >
                  <button
                    className={classnames(
                      'w100p cap b-dark-gray h-40 br-3 c-black bg-white bg-transparent'
                    )}
                    onClick={signInWithGoogleClick}
                    id="googleSignIn"
                    type="button"
                  >
                    <div className="flex middle-xs center-xs">
                      <span className="mr-r-10">
                        <GoogleIcon className="d-block" width="18px" />
                      </span>
                      <span className="fw-6 lh-20">
                        {translate('Continue with Google')}
                      </span>
                    </div>
                  </button>
                </div>
              </If>

              <If
                condition={
                  socialLogin &&
                  socialLogin.apple_sign_in &&
                  accountState.validatedUser &&
                  accountState.validatedUser.signedInNowUsing === 2 &&
                  (isIOS || (osName === 'Mac OS' && isSafari))
                }
              >
                <div
                  className={classnames(
                    'c-dark-gray',
                    isBrowser
                      ? 'fs-18 lh-24 ls-032 fw-6 flex middle-xs'
                      : 'fs-14 mt-8 lh-18 fw-6'
                  )}
                >
                  <AppleLogin
                    clientId={APPLE_SIGIN_CLIENT_ID}
                    redirectURI={APPLE_SIGIN_REDIRECT_URI}
                    usePopup={true}
                    autoLoad={false}
                    callback={signInWithAppleWithLoader}
                    scope="name email"
                    responseMode="query"
                    render={renderProps => {
                      const myOnClick = p => {
                        try {
                          logEvent({
                            eventName: 'CUSTOM_EVENT',
                            data: {
                              event_category: 'login',
                              event_action: eventAction,
                              event_label: `apple_${
                                accountState.validatedUser.passwordAvailable ===
                                true
                                  ? 'withpassword'
                                  : 'withoutpassword'
                              }`
                            },
                            screenName
                          });
                        } catch (e) {
                          logError(e);
                        }
                        setIsAppleLoggedInLoading(true);
                        renderProps.onClick(p);
                      };
                      return (
                        <Button
                          onClick={myOnClick}
                          id="appleSignIn"
                          type="button"
                          className={classnames(
                            `normal w100p cap b-dark-gray h-40 br-3 c-black bg-white bg-transparent`
                          )}
                          showLoader={false}
                          loaderClass="mt-n-5"
                        >
                          <div className="flex center-xs">
                            <If condition={true}>
                              <span className="mr-r-10">
                                <AppleIcon className="d-block" width="18px" />
                              </span>
                              <span className="fw-6 lh-20">
                                {translate('Continue with Apple')}
                              </span>
                            </If>
                          </div>
                        </Button>
                      );
                    }}
                  />
                </div>
              </If>
              <If
                condition={
                  socialLogin &&
                  (socialLogin.apple_sign_in || socialLogin.google_sign_in) &&
                  accountState.validatedUser &&
                  accountState.validatedUser.signedInNowUsing &&
                  [1, 2].includes(accountState.validatedUser.signedInNowUsing)
                }
              >
                <div className="pt-16 pb-16 flex middle-xs between-xs">
                  <div className="bb-light-gray w100p" />
                  <span className="w-200 c-brown-gray fs-14 ta-c">
                    {translate('or')}
                  </span>
                  <div className="bb-light-gray w100p" />
                </div>
              </If>
              <Choose>
                <When
                  condition={
                    !socialLogin ||
                    !socialLogin.google_sign_in ||
                    (accountState.validatedUser &&
                      accountState.validatedUser.passwordAvailable === true)
                  }
                >
                  <If
                    condition={
                      accountState.validatedUser &&
                      accountState.validatedUser.signedInNowUsing === 0
                    }
                  >
                    <div
                      className={classnames(
                        'c-brown-gray',
                        isBrowser
                          ? 'fs-16 lh-20 ls-048 mt-8'
                          : 'fs-12 lh-16 ls-072'
                      )}
                    >
                      {translate('Please enter your password')}
                    </div>
                  </If>

                  <div>
                    <a
                      className="c-dark-gray fs-12 bb-dark-gray pos-abs r-0 z-1"
                      onClick={forgotPasswordHandler}
                      onKeyPress={forgotPasswordHandler}
                      id="resetPassword"
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate('Reset password?')}
                    </a>
                    <Input
                      label={translate('*Password')}
                      labelClass="block fs-12 lh-16 ls-072 c-dark-gray fw-6"
                      inputRef={handleFocus}
                      value={password}
                      wrapClassNames="mt-16"
                      onChange={handleChange}
                      error={error}
                      type={type}
                      isPassword={true}
                      suffixIcon={
                        <span
                          role="button"
                          className={classnames(
                            isRTL ? 'mt-27' : 'mt-25',
                            'end fs-14 fw-6 mt-25 p-10 pt-15'
                          )}
                          onClick={showPasswordHandler}
                          onKeyPress={showPasswordHandler}
                        >
                          <Choose>
                            <When condition={type === 'password'}>
                              <ShowPWIcon width="20px" height="20px" />
                            </When>
                            <Otherwise>
                              <HidePWIcon width="20px" height="20px" />
                            </Otherwise>
                          </Choose>
                        </span>
                      }
                    />
                    <If condition={showRecaptcha}>
                      <ReCAPTCHA
                        sitekey={process.env.STYLI_APP_RECAPTCHA_SITE_KEY}
                        onChange={onRecaptchaChange}
                      />
                    </If>
                  </div>

                  <Button
                    className="w100p primary cap mt-16 border-none h-50 fw-5 fs-16"
                    loadingOnDisabled={false}
                    disabled={accountState.loginLoading}
                    onClick={clickHandler}
                    id="proceedToLogin"
                  >
                    {translate('Log In')}
                  </Button>
                  <If
                    condition={
                      accountState.validatedUser &&
                      accountState.validatedUser.mobileNumber
                    }
                  >
                    <div className="ta-c pt-24">
                      <div className="fs-12 lh-16 ls-072 c-brown-gray">
                        {translate('Don’t remember your password?')}
                      </div>
                      <div className="mt-4">
                        <span
                          className="fs-14 lh-18 c-dark-gray fw-6 pb-4 bb-brand-dashed d-il-block"
                          onClick={handleOtpVerify}
                          role="button"
                          onKeyPress={handleOtpVerify}
                        >
                          {translate('Log in via OTP instead')}
                        </span>
                      </div>
                    </div>
                  </If>
                </When>
                <Otherwise>
                  <div
                    className={classnames(
                      'c-brown-gray ta-c',
                      isBrowser ? 'fs-16 lh-20' : 'fs-12 lh-16'
                    )}
                  >
                    {translate('To continue via email')}
                  </div>

                  <If condition={isMobile}>
                    {/* <div
                      role="button"
                      className="ta-c c-black-light fs-12 bb-black-light pb-2"
                      onClick={forgotPasswordHandler}
                      onKeyPress={forgotPasswordHandler}
                      id="resetPassword"
                    >
                      {translate('Set a new password')}
                    </div> */}

                    <div className="ta-c">
                      <a
                        role="button"
                        onClick={() => forgotPasswordNewHandler(true)}
                        onKeyPress={() => forgotPasswordNewHandler(true)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-black-light fs-14 bb-black-light"
                      >
                        {translate('Set a new password')}
                      </a>
                    </div>
                  </If>

                  <BrowserView>
                    <div className="ta-c">
                      <a
                        role="button"
                        onClick={() => forgotPasswordNewHandler(true)}
                        onKeyPress={() => forgotPasswordNewHandler(true)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-black-light fs-14 bb-black-light"
                      >
                        {translate('Set a new password')}
                      </a>
                    </div>
                  </BrowserView>
                  {/* <div className="ta-c">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-black-light"
                    >
                      <span className="fs-12 bb-brand-dashed pb-2">
                        {translate('Set a new password')}
                      </span>
                    </a>
                  </div> */}
                </Otherwise>
              </Choose>
            </div>
          </When>
        </Choose>
      </SlidePane>
      <VerifyPhone
        showVerifyPhoneDialog={accountState.showVerifyPhone}
        phone={
          accountState.validatedUser && accountState.validatedUser.mobileNumber
        }
        isLoginFlow={true}
      />
    </>
  );
};
