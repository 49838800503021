import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'assets/icons/arrow-right.svg';
import { isBrowser } from 'react-device-detect';

import { CURRENT_LANG_PREFIX } from '../../../constants';

import { translate } from '../../../constants/language';

export default ({
  id,
  icon,
  title,
  noBorder = false,
  right,
  rightAngle = true,
  onClick,
  isLink = false,
  useSvg = false,
  SvgIcon,
  caption,
  isTopMenu = false
}) => {
  const listClassName = classnames(
    'flex row between-xs middle-xs',
    {
      'bb-light': !noBorder,
      'ml-15 mr-15': icon,
      'ml-5 mr-5 w100p': !icon,
      'pt-12 pb-12 fs-16 fw-5 lh-20': !isTopMenu && isBrowser,
      'pt-8 pb-8 fs-14 lh-18': isTopMenu && isBrowser,
      'pt-16 pb-16 fs-14': !isBrowser
    },
    isBrowser ? 'c-dark-gray' : 'lh-18 fw-6 c-main-gray'
  );
  const titleClassName = 'ml-10 mr-10';
  // const iconClassName = 'w25';
  const rowClassName = 'row middle-xs';
  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      return onClick(id);
    }
    if (isLink) {
      return false;
    }
    return history.push(`/${CURRENT_LANG_PREFIX}/account/${id}`);
  };
  return (
    <div
      className={classnames(listClassName)}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      id={id}
    >
      <div className={classnames(rowClassName)}>
        <If condition={icon}>
          <span className="w25">
            <If condition={useSvg}>
              <SvgIcon />
            </If>
            {/* <If condition={!useSvg}>
              <FontAwesomeIcon icon={icon} className={iconClassName} />
            </If> */}
          </span>
        </If>
        <div className={classnames(titleClassName)}>{translate(title)}</div>
        <If condition={caption && !isTopMenu}>
          <div>{caption}</div>
        </If>
      </div>
      <div className="row flex middle-xs">
        <If condition={right}>{right}</If>
        <If condition={rightAngle}>
          <ArrowRight className="rtl-reverse" />
        </If>
      </div>
    </div>
  );
};
