import React from 'react';
import classnames from 'classnames';
import { isRTL } from 'constants/index';

import './index.scss';

export default function Input({
  type = 'text',
  label = '',
  children,
  className = '',
  isOtp = false,
  isPassword = false,
  value,
  error,
  floatLabel,
  prefix,
  FieldInput,
  fieldProps,
  wrapClassNames,
  suffixIcon,
  id,
  inputRef,
  labelClass,
  floatLabelStyle = {},
  ...props
}) {
  const { name } = props;
  const inputClasses = classnames(className, {
    hasValue: !!value,
    'fs-14': !(className.indexOf('fs-') > -1),
    'c-black-10': !(className.indexOf('c-black-10') > -1),
    w100p: !(className.indexOf('w-') > -1),
    'bg-white': !(className.indexOf('bg-') > -1),
    'otp-border no-spin': isOtp,
    'default-input-border': !isOtp && !(className.indexOf('b-') > -1),
    'h-48': !(className.indexOf('h-') > -1),
    'pl-16': !(className.indexOf('pl-') > -1),
    'pr-16': !(className.indexOf('pr-') > -1),
    'pt-0': !(className.indexOf('pt-') > -1),
    'pb-0': !(className.indexOf('pb-') > -1),
    'br-3': !isOtp && className.indexOf('br-') === -1,
    'en-font': !!value,
    'forced-rtl': isPassword && isRTL,
    hasError: !!error
  });
  const wrapClass = classnames('Input', wrapClassNames, {
    floatLabel: !!floatLabel
  });
  const inputId = id || name || new Date().getTime();
  return (
    <div className={wrapClass}>
      <If condition={!floatLabel && label}>
        <label
          htmlFor={inputId}
          className={classnames(
            'c-dark-gray mb-8 fs-12 fw-5 d-block',
            labelClass
          )}
        >
          {label}
        </label>
      </If>
      <If condition={prefix}>{prefix}</If>
      <Choose>
        <When condition={FieldInput}>
          <FieldInput {...props} {...fieldProps} className={inputClasses} />
        </When>
        <Otherwise>
          <input
            value={value}
            id={inputId}
            className={inputClasses}
            type={type}
            ref={inputRef}
            autoComplete="off"
            {...props}
          />
        </Otherwise>
      </Choose>

      <If condition={floatLabel && label}>
        <label htmlFor={inputId} style={floatLabelStyle} className={labelClass}>
          {label}
        </label>
      </If>

      {suffixIcon}
      <If condition={error}>
        <span className="error fs-11 mt-4 lh-14 d-block">{error}</span>
      </If>
    </div>
  );
}
