import axios from 'utils/axios';
import { getStoreId, getJWT, path } from 'utils/';
import logError from 'utils/logError';
import { getCustomerId, getXHeaderToken } from 'utils/user';
import { timingVisualComplete } from 'utils/analytics';
import {
  API_PRODUCT_DETAILS_BY_SKU_CUROFY,
  API_PRODUCT_DETAILS_BY_ID_CUROFY,
  GET_PROMO_DETAILS,
  GET_YOU_MAY_LIKE,
  GET_ALSO_BOUGHT,
  GET_ALSO_BOUGHT_MIX,
  GET_MODEL_INFO,
  API_BEAUTY_PRODUCT_INFO,
  GET_PDP_RATINGS,
  PUT_ITEM_VIEW_DETAILS,
  GET_YOU_MAY_LIKE_COUNT
} from 'constants/api';
import { getStoreURL } from 'utils/index';
import { IS_RECOM_ON } from 'constants/index';

export const ACTION_GET_PRODUCT_DETAILS = 'productDetail/GET_PRODUCT_DETAILS';
export const ACTION_GET_PRODUCT_DETAILS_FAILURE =
  'productDetail/GET_PRODUCT_DETAILS_FAILURE';
export const ACTION_GET_PRODUCT_DETAILS_LOADING =
  'productDetail/GET_PRODUCT_DETAILS_LOADING';
export const ACTION_GET_BEAUTY_PRODUCT_INFO =
  'productDetail/GET_BEAUTY_PRODUCT_INFO';
export const ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE =
  'productDetail/GET_BEAUTY_PRODUCT_INFO_FAILURE';
export const ACTION_GET_BEAUTY_PRODUCT_INFO_LOADING =
  'productDetail/GET_BEAUTY_PRODUCT_INFO_LOADING';
export const ACTION_GET_PROMOS = 'productDetail/GET_PROMOS';
export const ACTION_GET_PROMOS_FAILURE = 'productDetail/GET_PROMOS_FAILURE';
export const ACTION_GET_PROMOS_LOADING = 'productDetail/GET_PROMOS_LOADING';
export const ACTION_RESET_PRODUCT_DETAILS =
  'productDetail/RESET_PRODUCT_DETAILS';
export const ACTION_SET_PRODUCT_CAT_INFO = 'productDetail/SET_PRODUCT_CAT_INFO';

export const ACTION_FETCH_ALSO_BOUGHT_LOADING =
  'productDetail/FETCH_ALSO_BOUGHT_LOADING';
export const ACTION_FETCH_ALSO_BOUGHT_LOADED =
  'productDetail/FETCH_ALSO_BOOGHT_LOADED';
export const ACTION_FETCH_ALSO_BOUGHT_ERROR =
  'productDetail/FETCH_ALSO_BOUGHT_ERROR';

export const ACTION_FETCH_ALSO_BOUGHT_MIX_LOADING =
  'productDetail/FETCH_ALSO_BOUGHT_MIX_LOADING';
export const ACTION_FETCH_ALSO_BOUGHT_MIX_LOADED =
  'productDetail/FETCH_ALSO_BOOGHT_MIX_LOADED';
export const ACTION_FETCH_ALSO_BOUGHT_MIX_ERROR =
  'productDetail/FETCH_ALSO_BOUGHT_MIX_ERROR';

export const ACTION_FETCH_YOU_MAY_LIKE_LOADING =
  'productDetail/FETCH_YOU_MAY_LIKE_LOADING';
export const ACTION_FETCH_YOU_MAY_LIKE_LOADED =
  'productDetail/FETCH_YOU_MAY_LIKE_LOADED';
export const ACTION_FETCH_YOU_MAY_LIKE_ERROR =
  'productDetail/FETCH_YOU_MAY_LIKE_ERROR';
export const ACTION_FETCH_YOU_MAY_LIKE_COUNT_ERROR =
  'productDetail/FETCH_YOU_MAY_LIKE_COUNT_ERROR';
export const ACTION_FETCH_YOU_MAY_LIKE_COUNT_LOADING =
  'productDetail/ACTION_FETCH_YOU_MAY_LIKE_COUNT_LOADING';

export const ACTION_FETCH_RECOM_PROD_LOADING =
  'productDetail/FETCH_RECOM_PROD_LOADING';
export const ACTION_FETCH_RECOM_PROD_LOADED =
  'productDetail/FETCH_RECOM_PROD_LOADED';
export const ACTION_FETCH_RECOM_PROD_ERROR =
  'productDetail/FETCH_RECOM_PROD_ERROR';
export const ACTION_GET_MODEL_INFO_LOADING =
  'productDetail/GET_MODEL_INFO_LOADING';
export const ACTION_GET_MODEL_INFO_LOADED =
  'productDetail/GET_MODEL_INFO_LOADED';
export const ACTION_GET_MODEL_INFO_ERROR = 'productDetail/GET_MODEL_INFO_ERROR';
export const ACTION_GET_PDP_RATINGS_LOADING =
  'productDetail/GET_PDP_RATINGS_LOADING';
export const ACTION_GET_PDP_RATINGS_LOADED =
  'productDetail/GET_PDP_RATINGS_LOADED';
export const ACTION_GET_PDP_RATINGS_ERROR =
  'productDetail/GET_PDP_RATINGS_ERROR';
export const ACTION_GET_FIT_INSIGHT_RATINGS_LOADING =
  'productDetail/GET_FIT_INSIGHT_RATINGS_LOADING';
export const ACTION_GET_FIT_INSIGHT_RATINGS_LOADED =
  'productDetail/GET_FIT_INSIGHT_RATINGS_LOADED';
export const ACTION_GET_FIT_INSIGHT_RATINGS_ERROR =
  'productDetail/GET_FIT_INSIGHT_RATINGS_ERROR';

export const ACTION_SIZECHART_LOADING = 'productDetail/SIZECHART_LOADING';
export const ACTION_SIZECHART_ERROR = 'productDetail/SIZECHART_ERROR';
export const ACTION_SIZECHART_FETCHED = 'productDetail/SIZECHART_FETCHED';
export const ACTION_FETCH_YOU_MAY_LIKE_COUNT =
  'productDetail/FETCH_YOU_MAY_LIKE_COUNT';

export async function getProductDetails(
  {
    id,
    prodSKU,
    productDetailPathName,
    relatedProductItems = [],
    vmUrlConfigValues = {}
  },
  dispatch
) {
  try {
    const startTime = new Date().getTime();

    const storeId = getStoreId();
    if (!storeId) {
      return;
    }
    const {
      vm_url_pdp_enabled: vmUrlPDPEnabled,
      new_pdp_get_list_url: newPDPListUrl,
      old_pdp_get_list_url: oldPDPListUrl,
      new_pdp_get_url: newPDPUrl,
      old_pdp_get_url: oldPDPUrl
    } = vmUrlConfigValues;

    const reqPDPListUrl = vmUrlPDPEnabled ? newPDPListUrl : oldPDPListUrl;
    const reqPDPUrl = vmUrlPDPEnabled ? newPDPUrl : oldPDPUrl;
    dispatch({ type: ACTION_GET_PRODUCT_DETAILS_LOADING });
    let result;
    let response;
    if (id && reqPDPUrl) {
      if (typeof id === 'string') {
        result = await axios.post(
          API_PRODUCT_DETAILS_BY_ID_CUROFY({ reqPDPUrl }),
          {
            productId: parseInt(id, 10),
            storeId
          },
          {
            headers: {
              'X-Header-Token': getXHeaderToken(),
              'Content-Type': 'application/json'
            }
          }
        );
        response = (result.data && result.data.response) || result.data; // slight confustion with the api
      }
    } else if (prodSKU && reqPDPListUrl) {
      result = await axios.post(
        API_PRODUCT_DETAILS_BY_SKU_CUROFY({ reqPDPListUrl }),
        { skus: [prodSKU], storeId },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            'Content-Type': 'application/json'
          }
        }
      );
      response = path(['data', 'response', 'productDetailsList', 0], result); // slight confustion with the api
    }
    if (response) {
      const newResponse = response;
      if (
        relatedProductItems.length > 0 &&
        response.relatedProducts &&
        response.relatedProducts.products &&
        response.relatedProducts.products.length
      ) {
        newResponse.relatedProducts.products = relatedProductItems;
      }
      dispatch({
        type: ACTION_GET_PRODUCT_DETAILS,
        data: { ...newResponse, productDetailPathName }
      });
      const endTime = new Date().getTime();
      timingVisualComplete(endTime - startTime);
    } else if (reqPDPUrl || reqPDPListUrl) {
      dispatch({
        type: ACTION_GET_PRODUCT_DETAILS_FAILURE,
        data: { loadMagentoPage: true }
      });
    }
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ACTION_GET_PRODUCT_DETAILS_FAILURE,
        data: { loadMagentoPage: true }
      });
    } else {
      dispatch({ type: ACTION_GET_PRODUCT_DETAILS_FAILURE, data: {} });
      logError(e);
    }
  }
}

export async function getPromotionsDetails({ productId = '780' }, dispatch) {
  try {
    const storeId = getStoreId();
    if (!storeId) {
      return;
    }
    dispatch({ type: ACTION_GET_PROMOS_LOADING });
    const result = await axios.get(GET_PROMO_DETAILS({ productId, storeId }), {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });

    const response = path(['data', 'response'], result) || []; // slight confustion with the api
    dispatch({ type: ACTION_GET_PROMOS, data: { list: [...response] } });
  } catch (e) {
    dispatch({ type: ACTION_GET_PROMOS_FAILURE });
    logError(e);
  }
}

export async function setPartialProductDetails(
  {
    product,
    contextRuleId,
    query,
    caSource,
    caSourceType,
    caSourceValue,
    caBannerPromoName,
    searchId
  },
  dispatch
) {
  dispatch({
    type: ACTION_GET_PRODUCT_DETAILS,
    data: {
      ...product,
      productDetailPathName: window.location.pathname,
      contextRuleId,
      query,
      caSource,
      caSourceType,
      caSourceValue,
      caBannerPromoName,
      searchId,
      partial: true
    }
  });
}

export async function resetProductDetails(options, dispatch) {
  dispatch({ type: ACTION_RESET_PRODUCT_DETAILS });
}
export async function fetchYouMayLikeCount(options, dispatch) {
  if (!IS_RECOM_ON) return '';
  const { productSku } = options;
  dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_COUNT_LOADING });
  try {
    const response = await axios.post(
      GET_YOU_MAY_LIKE_COUNT(),
      {
        item_id: String(productSku)
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );
    const statusCode = path(['data', 'statusCode'], response);
    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_COUNT_ERROR });

    const { data: resdata } = response;

    dispatch({
      type: ACTION_FETCH_YOU_MAY_LIKE_COUNT,
      data: { recomCount: resdata.recommendation_count, productSku }
    });
    // return resdata.recommendation_count;
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_COUNT_ERROR });
  }
  return '';
}
export async function fetchYouMayLike(options, dispatch) {
  if (!IS_RECOM_ON) return '';
  const { sku, productDetail } = options;
  if (sku) {
    dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_LOADING });
    try {
      const response = await axios.post(
        GET_YOU_MAY_LIKE(),
        {
          // const response = await axios.get(GET_YOU_MAY_LIKE(), {
          item_id: String(sku),
          store: getStoreId(),
          L4: (path(['categories', 'level3', 0], productDetail) || '').split(
            ' /// '
          )[3],
          customer_id: String(getCustomerId())
        },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );

      const statusCode = path(['data', 'statusCode'], response);

      if ([200, '200'].indexOf(statusCode) < 0)
        return dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_ERROR });

      const productList = path(['data', 'response'], response);

      dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_LOADED, data: productList });
    } catch (e) {
      logError(e);
      dispatch({ type: ACTION_FETCH_YOU_MAY_LIKE_ERROR });
    }
  }

  return '';
}
export async function fetchRecomProdList(options, dispatch) {
  if (!IS_RECOM_ON) return '';
  const {
    sku,
    sectionConfig: {
      url: { qa: apiURLQA = '', live: apiURLLive = '' } = {},
      type = ''
    } = {},
    productDetail
  } = options;

  const apiURL = process.env.NODE_ENV === 'production' ? apiURLLive : apiURLQA;

  dispatch({
    type: ACTION_FETCH_RECOM_PROD_LOADING,
    data: { type }
  });
  try {
    const response = await axios.post(
      apiURL,
      {
        item_id: String(sku),
        store: getStoreId(),
        L4: (path(['categories', 'level3', 0], productDetail) || '').split(
          ' /// '
        )[3],
        customer_id: String(getCustomerId())
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['data', 'statusCode'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({
        type: ACTION_FETCH_RECOM_PROD_ERROR,
        data: { type }
      });

    const productList = path(['data', 'response'], response);

    dispatch({
      type: ACTION_FETCH_RECOM_PROD_LOADED,
      data: { productList, type }
    });
  } catch (e) {
    logError(e);
    dispatch({
      type: ACTION_FETCH_RECOM_PROD_ERROR,
      data: { type }
    });
  }
  return '';
}

export async function fetchAlsoBought(options, dispatch) {
  if (!IS_RECOM_ON) return '';
  const { sku, productDetail } = options;
  dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_LOADING });
  try {
    const response = await axios.post(
      GET_ALSO_BOUGHT(),
      {
        // const response = await axios.get(GET_ALSO_BOUGHT(), {
        item_id: Number(sku),
        store: getStoreId(),
        L4: (path(['categories', 'level3', 0], productDetail) || '').split(
          ' /// '
        )[3],
        customer_id: String(getCustomerId())
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['data', 'statusCode'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_ERROR });
    const productList = path(['data', 'response'], response);

    dispatch({
      type: ACTION_FETCH_ALSO_BOUGHT_LOADED,
      data: productList
    });
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_ERROR });
  }
  return '';
}

export async function getSizeChart(options, dispatch) {
  try {
    const { endPoint, categoryIds, brandId, kidsGender } = options || {};
    dispatch({ type: ACTION_SIZECHART_LOADING });
    // const response = await axios.get('/stubs/sizeChartResponse.json',
    const iEndPoint = getStoreURL(endPoint);
    const response = await axios.post(iEndPoint, {
      brands: [brandId],
      categories: categoryIds,
      gender: kidsGender,
      showRangeSizeChart: true
    });
    const sizeChartData = path(['data', 'response'], response) || {};
    dispatch({ type: ACTION_SIZECHART_FETCHED, data: sizeChartData });
  } catch (e) {
    dispatch({ type: ACTION_SIZECHART_ERROR });
  }
}

export async function fetchAlsoBoughtMix(options, dispatch) {
  const { sku, productDetail } = options;

  dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_MIX_LOADING });
  try {
    const response = await axios.post(
      GET_ALSO_BOUGHT_MIX(),
      {
        // const response = await axios.get(GET_YOU_MAY_LIKE(), {
        item_id: Number(sku),
        store: getStoreId(),
        L4: (path(['categories', 'level3', 0], productDetail) || '').split(
          ' /// '
        )[3],
        customer_id: String(getCustomerId())
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['data', 'statusCode'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_MIX_ERROR });

    const productList = path(['data', 'response'], response);

    dispatch({
      type: ACTION_FETCH_ALSO_BOUGHT_MIX_LOADED,
      data: productList
    });
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_FETCH_ALSO_BOUGHT_MIX_ERROR });
  }
  return '';
}

export async function getModelInfo(modelId, dispatch) {
  dispatch({ type: ACTION_GET_MODEL_INFO_LOADING });
  try {
    const response = await axios.post(
      GET_MODEL_INFO(),
      { model_id: modelId },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['status'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_GET_MODEL_INFO_ERROR });

    const modelInfo = path(['data'], response);

    dispatch({
      type: ACTION_GET_MODEL_INFO_LOADED,
      data: modelInfo
    });
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_MODEL_INFO_ERROR });
  }
  return '';
}

export async function getBeautyProductInfo({ productId, sku }, dispatch) {
  try {
    const storeId = getStoreId();
    if (!storeId) {
      return;
    }
    dispatch({ type: ACTION_GET_BEAUTY_PRODUCT_INFO_LOADING });
    const result = await axios.post(
      API_BEAUTY_PRODUCT_INFO(),
      {
        productId: parseInt(productId, 10),
        sku,
        storeId
      },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          'Content-Type': 'application/json'
        }
      }
    );
    const response = path(['data', 'response'], result);
    if (response) {
      dispatch({
        type: ACTION_GET_BEAUTY_PRODUCT_INFO,
        data: { ...response }
      });
    } else {
      dispatch({
        type: ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE
      });
    }
  } catch (e) {
    if (e.isAxiosError) {
      dispatch({
        type: ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE
      });
    } else {
      dispatch({ type: ACTION_GET_BEAUTY_PRODUCT_INFO_FAILURE });
      logError(e);
    }
  }
}

export async function getRatingsOnPDP(sku, dispatch) {
  dispatch({ type: ACTION_GET_PDP_RATINGS_LOADING });
  try {
    const response = await axios.post(
      GET_PDP_RATINGS(),
      { sku },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['status'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_GET_PDP_RATINGS_ERROR });

    const ratingsData = path(['data'], response);

    dispatch({
      type: ACTION_GET_PDP_RATINGS_LOADED,
      data: ratingsData
    });
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_PDP_RATINGS_ERROR });
  }
  return '';
}

export async function getFitInsightRatings(sku, dispatch, apiURL) {
  dispatch({ type: ACTION_GET_FIT_INSIGHT_RATINGS_LOADING });
  try {
    const response = await axios.post(
      apiURL,
      { sku },
      {
        headers: {
          'X-Header-Token': getXHeaderToken(),
          Token: getJWT(),
          'Content-Type': 'application/json'
        }
      }
    );

    const statusCode = path(['status'], response);

    if ([200, '200'].indexOf(statusCode) < 0)
      return dispatch({ type: ACTION_GET_PDP_RATINGS_ERROR });

    const fitInsightRatingsData = path(['data', 'response'], response);
    dispatch({
      type: ACTION_GET_FIT_INSIGHT_RATINGS_LOADED,
      data: fitInsightRatingsData
    });
  } catch (e) {
    logError(e);
    dispatch({ type: ACTION_GET_FIT_INSIGHT_RATINGS_ERROR });
  }
  return '';
}

export async function logViewItemDetails(itemDetails) {
  try {
    const storeId = getStoreId();
    const customerId = getCustomerId();
    const logViewEventStructure = {
      topic_name: 'get_view_item_event_data',
      event_type: 'view_item_details(PDP)'
    };
    const logItemDetails = {};
    if (!storeId) {
      logItemDetails.store_id = null;
    }
    if (customerId === '') {
      logItemDetails.customer_id = 'Guest';
    } else {
      logItemDetails.customer_id = customerId.toString();
    }
    const cat1 = itemDetails.categories.level0[0];
    const cat2 = itemDetails.categories.level1[0];
    const cat3 = itemDetails.categories.level2[0];
    const cat4 = itemDetails.categories.level3[0];
    logItemDetails.store_id = storeId;
    logItemDetails.item_id = itemDetails.id.toString();
    logItemDetails.item_name = itemDetails.name;
    logItemDetails.item_brand = itemDetails.brand;
    logItemDetails.price_in_usd = null;
    logItemDetails.price = itemDetails.prices.price;
    logItemDetails.category_l1 = cat1;
    logItemDetails.category_l2 = cat2;
    logItemDetails.category_l3 = cat3;
    logItemDetails.category_l4 = cat4;
    logItemDetails.timestamp = Date.now().toString();
    logViewEventStructure.data = [logItemDetails];
    await axios.post(PUT_ITEM_VIEW_DETAILS(), logViewEventStructure, {
      headers: {
        'X-Header-Token': getXHeaderToken(),
        Token: getJWT(),
        'Content-Type': 'application/json'
      }
    });
  } catch (e) {
    logError(e);
  }
}
