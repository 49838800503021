import isEmpty from 'lodash.isempty';
import values from 'object.values';
import { forEach } from 'lodash';

// import pick from 'lodash.pick';
import {
  ACTION_PRODUCT_LIST,
  ACTION_PRODUCT_LIST_LOADING,
  ACTION_PRODUCT_LIST_SET_FILTERS,
  ACTION_SET_CART_COUNT,
  ACTION_PRODUCT_WISHLIST,
  ACTION_PRODUCT_LIST_SET_APPLIED_FILTERS,
  ACTION_FILTERS_LOADING,
  ACTION_PRODUCT_STATE,
  ACTION_GET_WISHLIST_FAILURE,
  ACTION_GET_WISHLIST_LOADING,
  ACTION_GET_WISHLIST_IDS_LOADING,
  ACTION_PRODUCT_WISHLIST_IDS,
  ACTION_UPDATE_WISHLIST_LOADING,
  ACTION_UPDATE_WISHLIST_COMPLETE,
  ACTION_UPDATE_WISHLIST_ERROR,
  ACTION_WISHLIST_PRODUCT_REMOVED,
  ACTION_CROSS_SELL_COMPLETE,
  ACTION_CROSS_SELL_LOADING,
  ACTION_CROSS_SELL_FAILURE
  // ACTION_SET_CATEGORY_INFO
} from './actions';
import { CURRENT_LANG_PREFIX, BASE_PATH } from '../../constants';

export const initialState = {
  productListLoading: true,
  sortInfo: { sortOrder: undefined, sortKey: undefined },
  pageOffset: 0,
  wishlistLoading: true,
  filterTabProductFilter: {}
};

export const getDiscountFilters = (discounts = []) => {
  const discountArray = discounts.map(item => item.value);
  // let minDiscount = Math.min(...discountArray);
  // minDiscount = Math.floor(minDiscount / 10) * 10;
  let maxDiscount = Math.max(...discountArray);
  maxDiscount = Math.floor(maxDiscount / 10) * 10;
  const arr = [];
  const discountSets = [10, 20, 50, 70];
  for (let i = 10; i <= maxDiscount; i += 10) {
    if (discountSets.indexOf(i) > -1) {
      arr.push(i);
    }
  }
  return arr;
};

export const extractMetaInfo = ({
  custom_attributes: customAttributes = []
}) => {
  const metaInfo = {};
  customAttributes.forEach(attr => {
    metaInfo[attr.attribute_code] = attr.value;
  });
  return metaInfo;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PRODUCT_LIST_LOADING: {
      const { loading, totalCount, listingError } = action.data;
      return {
        ...state,
        productListLoading: loading,
        totalCount,
        listingError,
        ruleName: '',
        ruleNameAR: ''
      };
    }

    case ACTION_PRODUCT_LIST: {
      const {
        productList,
        appliedFilters = {},
        filterOrder = [],
        appliedNumericFilters,
        productFilter,
        genderFilter,
        selectedGenderValue,
        query,
        // filterTabProductFilter,
        category,
        facetLabels,
        sortInfo,
        pageOffset,
        totalCount,
        minPrice,
        maxPrice,
        categoryIds,
        categoryInfo,
        productListLoading = false,
        productListPathName,
        totalPages,
        page,
        contextRuleId,
        flashSale,
        searchId,
        widgetData,
        seoMetadata,
        arrangedCategoryFilter,
        ruleName,
        ruleNameAR
      } = action.data;
      let newProductList = [];
      if (pageOffset === 1) {
        newProductList = [...productList];
      } else {
        newProductList = [...(state.productList || []), ...productList];
      }
      let filters = state.productFilter || productFilter;
      filters = {
        ...filters,
        ...{
          // discount_percentage: getDiscountFilters(
          //   filters.discount_percentage,
          //   'productDiscountFilters 1'
          // ),
          Price: [minPrice, maxPrice]
        },
        Categories: arrangedCategoryFilter
      };
      const newCategoryInfo = state.categoryInfo || categoryInfo;
      if (state.categoryInfo && !state.categoryInfo.name && categoryInfo) {
        newCategoryInfo.name = categoryInfo.name;
      }

      return {
        ...state,
        query: state.query || query,
        categoryInfo: newCategoryInfo,
        metaInfo: extractMetaInfo(state.categoryInfo || categoryInfo),
        productDiscountFilters: !isEmpty(state.productDiscountFilters)
          ? state.productDiscountFilters
          : getDiscountFilters(
              filters.discount_percentage,
              'productDiscountFilters 2'
            ),
        productList: newProductList,
        productFilter: filters,
        filterOrder,
        genderFilter,
        selectedGenderValue,
        flashSale,
        facetLabels: isEmpty(facetLabels) ? state.facetLabels : facetLabels,
        appliedFilters,
        appliedNumericFilters,
        category,
        categoryIds,
        sortInfo,
        pageOffset,
        totalCount,
        totalPages,
        page,
        contextRuleId,
        countWithoutFilters: state.countWithoutFilters || totalCount,
        productListLoading,
        listingError: false,
        productPageInitialized: true,
        productListPathName: productListPathName || state.productListPathName,
        fromPdp: false,
        searchId: state.searchId || searchId,
        widgetData,
        seoMetadata,
        searchIdContextIdMap: `${state.searchId || searchId}_${contextRuleId ||
          query}`,
        ruleName,
        ruleNameAR
      };
    }
    case ACTION_PRODUCT_LIST_SET_FILTERS: {
      const {
        filterTabProductFilter = {},
        facetLabels,
        filterOrder = [],
        appliedFilters = {},
        lastSelectedFacet,
        minPrice,
        maxPrice,
        setAppliedFilterTab,
        totalCountFilter,
        discountFilters: resetDiscountFilters,
        arrangedCategoryFilter
      } = action.data;

      let newProductTabFilters = filterTabProductFilter;
      let newTotalCountFilter = totalCountFilter;
      if (isEmpty(filterTabProductFilter)) {
        newProductTabFilters = state.filterTabProductFilter;
        newTotalCountFilter = state.newTotalCountFilter;
      } else if (
        lastSelectedFacet &&
        isEmpty(newProductTabFilters[lastSelectedFacet])
      ) {
        newProductTabFilters = state.filterTabProductFilter;

        newTotalCountFilter = state.newTotalCountFilter;
      } else if (!isEmpty(state.filterTabProductFilter) && lastSelectedFacet) {
        newProductTabFilters = {
          ...filterTabProductFilter,
          [lastSelectedFacet]: state.filterTabProductFilter[lastSelectedFacet]
        };
      }
      const discountFilters =
        lastSelectedFacet !== 'discount_percentage' ||
        (lastSelectedFacet === 'discount_percentage' &&
          !appliedFilters.discount_percentage)
          ? getDiscountFilters(filterTabProductFilter.discount_percentage)
          : state.discountFilters;
      const price =
        (lastSelectedFacet !== 'Price' &&
          minPrice !== '' &&
          minPrice !== undefined &&
          minPrice !== null) ||
        (lastSelectedFacet === 'Price' &&
          appliedFilters &&
          !appliedFilters.Price)
          ? [minPrice, maxPrice]
          : (state.filterTabProductFilter &&
              state.filterTabProductFilter.Price) ||
            {};
      price.isMainFilter = true;
      newProductTabFilters = { ...newProductTabFilters, Price: price };
      const optionalData = {};
      if (setAppliedFilterTab) {
        optionalData.appliedFilterTabProductFilter = {
          ...newProductTabFilters
        };
      }

      const excludeFacetList = [
        // 'Category',
        // 'price',
        'categoryIds',
        'ranking'
        // 'السعر',
        // 'الفئة'
        // 'discount_percentage'
      ];

      if (arrangedCategoryFilter && arrangedCategoryFilter.length) {
        newProductTabFilters = {
          ...newProductTabFilters,
          Categories: arrangedCategoryFilter
        };
      }

      let facetlist = Object.keys(newProductTabFilters);

      if (filterOrder && filterOrder.length > 0) {
        facetlist = [];
        forEach(filterOrder, e => {
          if (e.value && !excludeFacetList.includes(e.value)) {
            if (e.value === 'price') {
              if (newProductTabFilters.Price && !facetlist.includes('Price')) {
                facetlist.push('Price');
              }
            } else {
              forEach(newProductTabFilters || {}, (value, key) => {
                if (
                  key &&
                  key !== 'Price' &&
                  value &&
                  value.length &&
                  value.length > 0 &&
                  value[0] &&
                  value[0] !== undefined
                ) {
                  if (
                    value[0].attributeCode &&
                    value[0].attributeCode.split('///').includes(e.value)
                  ) {
                    if (!facetlist.includes(key)) {
                      facetlist.push(key);
                    }
                    return false;
                  }
                }
                return true;
              });
            }
          }
        });
      } else {
        facetlist = Object.keys(newProductTabFilters);
      }

      // facetlist = facetlist.filter(facet => {
      //   if (facet && excludeFacetList.indexOf(facet) === -1) {
      //     return true;
      //   }
      //   return false;
      // });
      // facetlist.sort((a, b) => {
      //   return a.toLowerCase().localeCompare(b.toLowerCase());
      // });
      return {
        ...state,
        ...optionalData,
        filterTabProductFilter: newProductTabFilters,
        facetLabels: isEmpty(facetLabels) ? state.facetLabels : facetLabels,
        discountFilters:
          resetDiscountFilters && resetDiscountFilters.length
            ? resetDiscountFilters
            : discountFilters,
        facetlist,
        totalCountFilter: Number(newTotalCountFilter),
        listingError: false,
        filtersLoading: false,
        productListLoading: false,
        filterOrder
      };
    }
    case ACTION_SET_CART_COUNT: {
      const { cartCount } = action.data;
      return {
        ...state,
        cartCount
      };
    }
    case ACTION_PRODUCT_LIST_SET_APPLIED_FILTERS: {
      const { filters } = action.data;
      return {
        ...state,
        filterTabProductFilter: filters
      };
    }

    case ACTION_GET_WISHLIST_IDS_LOADING: {
      return {
        ...state,
        // wishlistProductIds: undefined,
        wishlistProductIdsLoading: true
      };
    }

    case ACTION_PRODUCT_WISHLIST_IDS: {
      const { productIds = [] } = action.data || {};
      const wishlistProductIds = {};
      (productIds || []).forEach(wishlistObj => {
        wishlistProductIds[wishlistObj.productId] = wishlistObj;
      });

      return {
        ...state,
        wishlistProductIds,
        wishlistProductIdsLoading: false
      };
    }

    case ACTION_PRODUCT_WISHLIST: {
      const { products = [] } = action.data || {};
      const wishlistProducts = {};
      const wishlistProductsSkuArray = [];
      (products || []).forEach(product => {
        wishlistProducts[product.id] = product;
        wishlistProductsSkuArray.push(product.id);
      });
      const wishlistCount = (products || []).length;
      return {
        ...state,
        wishlistProducts,
        wishlistCount,
        wishlistLoading: false,
        wishlistProductsSkuArray
      };
    }

    case ACTION_WISHLIST_PRODUCT_REMOVED: {
      const {
        removedProductId,
        wishlistProductsSkuArray: wishlistProductsSku
      } = action.data;
      // console.log('action', wishlistProductsSku);
      if (wishlistProductsSku) {
        wishlistProductsSku.pop(removedProductId);
      }
      const { wishlistProducts = {} } = state;
      const updatedWishList = values(wishlistProducts).filter(
        item => item.id !== removedProductId
      );
      console.log('updatedWishList', { updatedWishList });
      const formatedUpdatedWishList = [];
      (updatedWishList || []).forEach(product => {
        formatedUpdatedWishList[product.id] = product;
      });
      const wishlistCount = Object.keys(formatedUpdatedWishList)?.length;
      return {
        ...state,
        wishlistProducts: formatedUpdatedWishList,
        wishlistCount,
        wishlistLoading: false,
        wishlistProductsSkuArray: wishlistProductsSku
      };
    }

    case ACTION_UPDATE_WISHLIST_LOADING: {
      return {
        ...state,
        wishlistUpdateLoading: true,
        wishlistUpdateError: undefined,
        wishListUpdateSuccess: undefined
      };
    }
    case ACTION_UPDATE_WISHLIST_COMPLETE: {
      return {
        ...state,
        wishlistUpdateLoading: undefined,
        wishlistUpdateError: false,
        wishListUpdateSuccess: true
      };
    }
    case ACTION_UPDATE_WISHLIST_ERROR: {
      return {
        ...state,
        wishlistUpdateLoading: undefined,
        wishlistUpdateError: true,
        wishListUpdateSuccess: false
      };
    }

    case ACTION_GET_WISHLIST_FAILURE: {
      const { loginRequired = false } = action.data || {};
      return {
        ...state,
        wishlistLoading: false,
        wishListFailure: true,
        loginRequired
      };
    }
    case ACTION_GET_WISHLIST_LOADING: {
      return {
        ...state,
        wishlistLoading: true,
        wishListFailure: false
      };
    }
    // case ACTION_SET_CATEGORY_INFO: {
    //   const { category } = action.data;

    //   return {
    //     ...state,

    //   };
    // }
    case ACTION_FILTERS_LOADING: {
      return {
        ...state,
        filtersLoading: true,
        wishListFailure: false
      };
    }
    case ACTION_PRODUCT_STATE: {
      const { category, fromPdp } = action.data;
      let productListing = {};
      if (category) {
        productListing = {
          productPageInitialized: true,
          productListPathName: `${BASE_PATH}/${CURRENT_LANG_PREFIX}/${category.magentoPathUrl}`,
          categoryIds: [category.id],
          categoryInfo: {
            ...category,
            level: category.level,
            custom_attributes: [
              { attribute_code: 'meta_title', value: category.metaTitle },
              {
                attribute_code: 'meta_description',
                value: category.metaDescription
              },
              { attribute_code: 'meta_keywords', value: category.metaKeywords }
            ]
          }
        };
      }

      if (fromPdp) {
        productListing = { ...state, fromPdp };
      }

      if (fromPdp === 'reset') productListing = { ...state, fromPdp: false };

      return { ...initialState, ...productListing };
    }

    case ACTION_CROSS_SELL_LOADING: {
      return {
        ...state,
        isCrossSellLoading: true,
        isCrossSellFailure: false,
        crossSellList: undefined
      };
    }

    case ACTION_CROSS_SELL_COMPLETE: {
      const {
        productList = [],
        collectionData = [],
        contextRuleId
      } = action.data;
      return {
        ...state,
        crossSellList: productList,
        isCrossSellLoading: false,
        collectionData,
        contextRuleId
      };
    }

    case ACTION_CROSS_SELL_FAILURE: {
      return {
        ...state,
        isCrossSellLoading: false,
        isCrossSellFailure: true,
        crossSellList: undefined
      };
    }

    default:
      return state;
  }
};
