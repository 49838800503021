import logError from "./logError";
import { getPaymentEnum } from "./payment";
import { getCustomerId } from "./user";

export function getCatData(metaData) {
    if (metaData)
        return {
            super_category_id: metaData['level-1'] && metaData['level-1'].map(l => l.categoryId).join(",") || '',
            super_category_name: metaData['level-1'] && metaData['level-1'].map(l => l.categoryName).join(",") || '',
            parent_category_id: metaData['level-2'] && metaData['level-2'].map(l => l.categoryId).join(",") || '',
            parent_category_name: metaData['level-2'] && metaData['level-2'].map(l => l.categoryName).join(",") || '',
            category_id: metaData['level-3'] && metaData['level-3'].map(l => l.categoryId).join(",") || '',
            category_name: metaData['level-3'] && metaData['level-3'].map(l => l.categoryName).join(",") || '',
            sub_category_id: metaData['level-4'] && metaData['level-4'].map(l => l.categoryId).join(",") || '',
            sub_category_name: metaData['level-4'] && metaData['level-4'].map(l => l.categoryName).join(",") || '',
        }
    return {}
}

function prepareProduct(items = []) {
    const product = items[0];
    if (product) {
        const metadata = product.metadata || {};
        const categoryData = getCatData(metadata);
        const response = {
            p_name: product.name,
            product_id: metadata.productId,
            sku: product.id,
            ...categoryData
        };

        if (product.variant)
            response.size = product.variant
        return response
    }
    return {}
}

function prepareBag(bagItems = []) {
    const bagData = {};
    bagItems.forEach((item, index) => {
        bagData[`item_${index}`] = prepareProduct([item])
    })

    return bagData
}

export async function logBrazeEvent(eventData) {
    try {
        if (window.appboy && process.env.STYLI_APP_BRAZE_API_KEY) {
            window.appboy.initialize(process.env.STYLI_APP_BRAZE_API_KEY, {
                enableLogging: process.env.NODE_ENV !== 'production',
                // baseUrl: 'https://sdk.fra-01.braze.eu/api/v3',
                baseUrl: 'https://sdk.fra-01.braze.eu',
                safariWebsitePushId: 'web.com.stylishop.styli'
            });
            const appBoy = window.appboy;
            const currentUser = appBoy.getUser();

            const { name, data = {}, additionalData = {} } = eventData;
            const {
                language = '',
                items = [],
                promoName = '',
                searchTerm = '',
                paymentMethod: pMethod,
                currency = '',
                quoteId = '',
                quantity = 0,
                grandTotal = 0,
                couponCodeApplied = '',
                cardCount = 0,
                transaction_id: orderId,
                total_items: totalItems
            } = data
            const { firstName = '', lastName = '', customerId = '', email = '', isMainPDP = false, categoryInfo = '', mobileNumber = '' } = additionalData;
            const itemObj = prepareProduct(items);

            const paymentMethod = pMethod ? getPaymentEnum(pMethod) : '';

            if (language) {
                currentUser.setCustomUserAttribute('language', language);
            }

            if (paymentMethod) {
                currentUser.setCustomUserAttribute('payment_method', paymentMethod)
            }


            let eventDataObj = {
                language,
                user_id: getCustomerId(),
            };
            let eventName = '';

            switch (name) {
                case 'login':
                    if (customerId) {
                        appBoy.changeUser(customerId);
                        const user = appBoy.getUser();
                        user.setEmail(email);
                        user.setFirstName(firstName);
                        user.setLastName(lastName);
                        user.setLanguage(language);

                        if (mobileNumber)
                            user.setPhoneNumber(mobileNumber);

                        eventDataObj = {
                            ...eventDataObj,
                            language,
                            user_id: customerId,
                            email,
                            first_name: firstName,
                            last_name: lastName
                        }
                        eventName = 'login';
                        // );
                        // window.appboy.setEmail(email);
                        // window.appboy.setFirstName(firstName);
                        // window.appboy.setLastName(lastName);
                        // window.appboy.setLanguage(language);
                        // window.appboy.setUser({
                        //     email,
                        //     customerId,
                        //     firstName,
                        //     lastName
                        // });
                    }
                    break;

                case "user_input_manual":
                    eventName = 'start_signup';
                    break;

                case "card_count":
                    currentUser.setCustomUserAttribute('creditcard_count', cardCount)
                    break;

                case "sign_up_form_success":
                    if (customerId) {
                        appBoy.changeUser(customerId);
                        const user = appBoy.getUser();
                        user.setEmail(email);
                        user.setFirstName(firstName);
                        user.setLastName(lastName);
                        user.setLanguage(language);

                        if (mobileNumber)
                            user.setPhoneNumber(mobileNumber);

                        eventName = 'signup';
                        eventDataObj = {
                            ...eventDataObj,
                            language,
                            user_id: customerId,
                            email,
                            first_name: firstName,
                            last_name: lastName
                        }
                    }
                    break;

                case "change_payment":
                    eventName = 'payment_inserted';
                    eventDataObj = {
                        ...eventDataObj,
                        language,
                        user_id: customerId,
                        quantity,
                        tot_price: grandTotal,
                        currency,
                        coupon: String(!!couponCodeApplied),
                        payment_method: paymentMethod,
                        metadata: ""
                    }
                    break;

                case "view_item_list":
                    if (categoryInfo.name || categoryInfo.id) {
                        eventName = 'product_list';
                        eventDataObj = {
                            ...eventDataObj,
                            c_name: categoryInfo.name,
                            id: categoryInfo.id || ""
                        }
                    }
                    break;

                case "view_item":
                    if (isMainPDP) {
                        eventName = 'pdp_view';
                        eventDataObj = { ...eventDataObj, ...itemObj }
                    }
                    break;

                case "add_to_wishlist":
                    if (items.length) {
                        eventName = 'add_to_wishlist';
                        eventDataObj = { ...eventDataObj, ...itemObj }
                    }
                    break;

                case "add_to_cart":
                    if (items.length) {
                        eventName = 'add_to_cart';
                        eventDataObj = { ...eventDataObj, ...itemObj }
                    }
                    break;

                case "view_cart":
                    if (items.length) {
                        eventName = 'view_cart';
                        eventDataObj = { ...eventDataObj, ...itemObj }
                    }
                    break;
                case "begin_checkout":
                    if (items.length) {
                        eventName = 'start_checkout';
                        eventDataObj = { ...eventDataObj, ...itemObj }
                    }
                    break;

                case "purchase":
                    if (items.length) {
                        eventName = 'purchase';
                        const bagData = prepareBag(items);
                        eventDataObj = {
                            ...eventDataObj,
                            bag_details: JSON.stringify(bagData),
                            payment_method: paymentMethod,
                            tot_price: grandTotal,
                            currency,
                            metadata: quoteId || ""
                        }
                        appBoy.logPurchase(orderId, Number(grandTotal), currency, Number(totalItems));
                    }


                    break;

                case "couponcode_success":
                    eventName = 'couponcode_success';
                    eventDataObj = {
                        ...eventDataObj, promo_name: promoName
                    }
                    break;
                case "couponcode_failed":
                    eventName = 'couponcode_fail';
                    eventDataObj = {
                        ...eventDataObj, promo_name: promoName
                    }
                    break;

                case "search":
                    eventName = 'search';
                    eventDataObj = {
                        ...eventDataObj, search_term: searchTerm
                    }
                    break;
                default:
            }

            if (eventName && appBoy && typeof appBoy.logCustomEvent === 'function') {
                appBoy.logCustomEvent(eventName, eventDataObj)
                eventName = '';
            }

        }
    } catch (e) {
        logError(e)
    }
}
