/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import urlParse from 'url-parse';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { logEvent } from 'utils/analytics';
import { formatProduct } from 'utils/product';
import HeartIcon from 'assets/icons/heart-o.svg';
import HeartAnimation from 'assets/lottie/HeartAnimation';
import flashIcon from 'assets/icons/flash-icon.png';
import PlusGreen from 'assets/icons/plus-green.svg';
import { cleverTapProcess } from 'utils/cleverTap';

import './index.scss';
import { useStateValue } from 'context/app';
import {
  toggleWishList,
  resetProductState
} from 'containers/ProductList/actions';

import {
  formatPrice,
  // getProductGalleryImg,
  getNavigationBasePath,
  getPLPImg
} from 'utils/';

import {
  setPartialProductDetails
  // fetchYouMayLikeCount
} from 'containers/ProductDetail/actions';
import logError from 'utils/logError';
import { isBrowser } from 'react-device-detect';

import { translate } from '../../../../constants/language';
import lazyLoaderImageEnglish from './imageLazyLoader.png';
import lazyLoaderImageArabic from './imageLazyLoaderArabic.png';
import {
  CURRENT_LANG_PREFIX,
  CURRENCY_FRACTION,
  IS_ARABIC,
  COUNTRY,
  COUNTRY_KWT,
  COUNTRY_BH,
  COUNTRY_QA
} from '../../../../constants';

const lang = CURRENT_LANG_PREFIX;

export default function ProductTupple({
  youMayLikeRef,
  alsoViewedMixRef,
  listName,
  isLoading = false,
  productDetail,
  productDispatch,
  setShowLoginDialog = () => {},
  productIndex = 0,
  flashSaleActive,
  isWishlist = false,
  youMayLike = false,
  alsoViewed = false,
  alsoViewedMix = false,
  colClassName = 'col-xs-6 col-lg-3 col-md-4',
  useProductionImage,
  alsoViewedRef,
  fromSku,
  handleToggleLoginModal,
  showWishlistIcon = true,
  isSmallThumb = false,
  query,
  caSource,
  caSourceType,
  caSourceValue,
  caBannerPromoName,
  searchId,
  isEnable = true,
  handelAddToBag = () => {}
}) {
  const [doPlay, setDoPlay] = useState('');
  const [promoBadge, setPromoBadge] = useState('');
  const [systemGenerate, setSystemGenerate] = useState('');
  const [tag, setTag] = useState('');
  const [skuRecomendation, setSkuRecomendation] = useState('');
  const {
    productDetail: [productDetailState, productDetailDispatch],
    products: [productsState, productStateDispatch],
    account: [accountState, accountDispatch]
  } = useStateValue();
  const history = useHistory();
  const {
    config: { wishlistConfig = {}, cdnOptimizedImageConfig = {}, plp } = {}
  } = accountState;
  const { plp: plpcompress = false } = cdnOptimizedImageConfig;
  const { similarCta = {} } = wishlistConfig;
  const {
    images: { image = '' } = {},
    prices = {},
    id,
    wishListItemId = -1,
    // stock: unitsLeft = 40,
    // stockCap: capVal = 100,
    isFlashSale,
    barHint,
    stockPercent
  } = productDetail;
  const productionImage =
    image && `https://images.stylishop.com${image.split('.com')[1]}`;

  const { wishlistUpdateLoading } = productsState;
  const { recomCountData } = productDetailState;
  const barHintCount = barHint && barHint.split('${')[1].split('}$')[0];

  const wrapperClass = classnames({ shine: isLoading });
  let productUrl = (productDetail.url || '')
    .replace('/sa_en/', '/')
    .replace('/sa_ar/', '/')
    .replace('/ae_ar/', '/')
    .replace('/ae_en/', '/')
    .replace('/kw_en/', '/')
    .replace('/kw_ar/', '/')
    .replace(`/${COUNTRY}/`, '/');
  // .replace(`/${CURRENT_LANG_PREFIX}/`, '/');

  productUrl =
    productUrl ||
    (productDetail.name &&
      `https://${window.location.host}${
        youMayLike || alsoViewed || alsoViewedMix ? `/${COUNTRY}` : ``
      }/${CURRENT_LANG_PREFIX}/product-${productDetail.name
        .toLowerCase()
        .replace(/ /g, '-')}-${productDetail.sku}`);

  const { pathname } = urlParse(productUrl);

  const handleWishListPress = async ({ nativeEvent }) => {
    if (wishlistUpdateLoading) return;
    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    nativeEvent.stopImmediatePropagation();
    setDoPlay(wishListItemId > 0 ? 'remove' : 'add');
    if (accountState.loggedIn) {
      if (wishListItemId <= 0) {
        let wishListEventName = '';
        if (youMayLike) wishListEventName = 'wishlist_youMayLike';
        if (alsoViewed) wishListEventName = 'wishlist_alsoViewed';

        const productData = await formatProduct({
          fromSku,
          productDetail
        });
        cleverTapProcess({
          eventName: 'add_to_wishlist',
          cleverTapData: {
            sku_id: productData[0].id,
            product_name: productData[0].name
          }
        });
        if (wishListEventName) {
          logEvent({
            eventName: wishListEventName,
            data: {
              content_type: 'product',
              items: productData
            },
            screenName: 'Category_Listing'
          });
        }
      }
      toggleWishList(
        {
          productId: id,
          wishListItemId,
          mode: wishListItemId > 0 ? 'delete' : 'post',
          noLoading: !isWishlist,
          isWishlist,
          wishlistProductsSkuArray: productsState.wishlistProductsSkuArray
        },
        productDispatch,
        accountDispatch
      );
    } else {
      if (youMayLike || alsoViewed || alsoViewedMix) {
        handleToggleLoginModal();
      }

      setShowLoginDialog();
    }
  };
  const handleProductClick = async ({ nativeEvent }) => {
    nativeEvent.preventDefault();
    if (!isEnable) return false;
    resetProductState({ fromPdp: true }, productStateDispatch);

    if (isLoading) return false;
    if (nativeEvent.target.parentElement) {
      if (
        nativeEvent.target.parentElement.classList.contains('wl') ||
        nativeEvent.target.parentElement.nodeName === 'g' ||
        nativeEvent.target.parentElement.getAttribute('role') === 'button'
      ) {
        return false;
      }
    }
    if (productUrl) {
      setPartialProductDetails(
        {
          product: productDetail,
          query,
          caSource,
          caSourceType,
          caSourceValue,
          caBannerPromoName,
          searchId
        },
        productDetailDispatch
      );
      let prefix = '';
      if (!(pathname.indexOf(`/${CURRENT_LANG_PREFIX}/`) > -1)) {
        prefix = `/${CURRENT_LANG_PREFIX}`;
      }

      const eventData = {
        content_type: 'product',
        items: await formatProduct({
          fromSku,
          productDetail,
          listPosition: productIndex,
          listName
        })
      };

      if (youMayLike || alsoViewed || alsoViewedMix)
        eventData.event_category = 'recommendations';

      if (youMayLike) eventData.event_label = `youMaylike_${productDetail.sku}`;

      if (alsoViewed) eventData.event_label = `alsoViewed_${productDetail.sku}`;

      if (alsoViewedMix)
        eventData.event_label = `alsoLiked_${productDetail.sku}`;
      try {
        const {
          prices: { specialPrice, price } = {},
          categories: {
            level3 = [],
            level2 = [],
            level1 = [],
            level0 = []
          } = {}
        } = productDetail;
        const catLevel = level3[0] || level2[0] || level1[0] || level0[0];
        const [l1, l2, l3, l4] = catLevel.split(' /// ');
        logEvent({
          eventName: 'select_item',
          screenName: 'Category_Listing',
          data: {
            value: Number(eventData.items[0]?.price),
            items: {
              item_id: productDetail.id,
              item_name: productDetail.name,
              item_brand: productDetail.brand,
              item_category: l1,
              item_category2: l2,
              item_category3: l3,
              item_category4: l4,
              item_list_name: listName,
              price: specialPrice || price,
              quantity: 1
            }
          }
        });
      } catch (e) {
        logError(e);
      }
      if (youMayLike || alsoViewed || alsoViewedMix) {
        // window.location.href = `${prefix}${pathname}`;
        history.push(`${pathname}`);
      } else {
        history.push(`${prefix}${pathname}`);
      }
    }
    return false;
  };
  const {
    config: { plp: { bottom_badge: bottomBadge } = {} } = {}
  } = accountState;

  // const { config } = accountState;
  // const { badgeMapper: { [productDetail.promoBadge]: promoInfo = {} } = {} } = config || {};

  useEffect(() => {
    if (productDetail && productDetail.promoBadge) {
      setPromoBadge(productDetail.promoBadge);
    }
    if (productDetail && productDetail.promoBadgeText) {
      setPromoBadge(productDetail.promoBadgeText);
    }
    if (productDetail && productDetail.systemGenerate) {
      setSystemGenerate(productDetail.systemGenerate);
    }
    if (productDetail && productDetail.tag) {
      setTag(productDetail.tag);
    }
  }, [productDetail]);

  useEffect(() => {
    if (recomCountData) {
      setSkuRecomendation(recomCountData);
    }
  }, [skuRecomendation]);

  const getHeartCSS = () => {
    const isArabic = window.location.href.indexOf('/ar/');
    const isArabicNotloggedInCSS =
      isArabic !== -1 ? '-2px 0px 0px -24px' : '-5px -25px 0px 0px';
    const arabicPLPCSS =
      isArabic !== -1 ? '-5px 0px 0px -10px' : '-5px -10px 0 0';
    const notLoggedinCSS =
      youMayLike || alsoViewed || alsoViewedMix || useProductionImage
        ? isArabicNotloggedInCSS
        : arabicPLPCSS;

    const defaultPLPCSS =
      isArabic !== -1 ? '-14px 0px 0px -10px' : '-14px -10px 0px 0px';
    const isArabicLoggedInCSS =
      isArabic !== -1 ? '-9px 0px 0px -10px' : '-9px -10px 0 0';

    const loggedInCSS =
      youMayLike || alsoViewed || alsoViewedMix || useProductionImage
        ? isArabicLoggedInCSS
        : defaultPLPCSS;
    return {
      margin: accountState.loggedIn ? loggedInCSS : notLoggedinCSS
    };
  };
  const { configProducts } = productDetail;

  let stockQty = 0;
  let overlayClass = '';
  let prductClickfunction = handleProductClick;

  if (configProducts) {
    Object.keys(configProducts).forEach(key => {
      if (configProducts[key].quantity && configProducts[key].quantity > 0) {
        stockQty += configProducts[key].quantity;
        // isInStock = true;
      }
    });
  }
  if (stockQty === 0 && isWishlist === true) {
    overlayClass = 'o-25';
    prductClickfunction = false;
  }
  const discountThreshold = plp?.bogo_ui_change?.threshold_value[COUNTRY] || 0;
  const discountTextColor = plp?.discount_text_color[COUNTRY] || '#d64045';
  const [imageWidth, setImageWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setImageWidth(ref.getBoundingClientRect().width);
    }
  };
  return (
    <a
      href={
        stockQty === 0 && isWishlist === true
          ? false
          : `${getNavigationBasePath()}${pathname.replace(
              `/${CURRENT_LANG_PREFIX}/`,
              '/'
            )}`
      }
      title={productDetail.name}
      className={classnames(
        `${colClassName} c-main-gray cursor-default product-item cursor-pointer`
      )}
      onClick={prductClickfunction}
    >
      <div
        className={classnames(
          'ml-5 mr-5 prod-list-item c-main-gray',
          !isBrowser && youMayLike ? 'mb-18' : '',
          {
            'mb-8': !(youMayLike && alsoViewedMix)
          }
        )}
        ref={youMayLikeRef || alsoViewedMixRef || alsoViewedRef}
      >
        <div className={classnames('_thumb pos-relt ')}>
          <div
            className="_inner bg-thumb-gray flex center-xs middle-xs br-3 overflow-hidden"
            ref={handelRef}
          >
            <img
              className={classnames(
                'img-fluid shine-img mh-150 product-image',
                { 'o-25 pointer-e-none': !isEnable && isWishlist },
                { 'o-50 pointer-e-none': !isEnable },
                overlayClass
              )}
              alt={productDetail.sku}
              src={
                (!((image || '').indexOf('/placeholder/') > -1) &&
                  getPLPImg({
                    imgURL:
                      useProductionImage &&
                      process.env.NODE_ENV !== 'production'
                        ? productionImage
                        : image,
                    reqWidth: imageWidth * 1.1,
                    isBrowser,
                    plpcompress
                  })) ||
                (lang === 'ar' ? lazyLoaderImageArabic : lazyLoaderImageEnglish)
              }
            />
            {
              <If condition={isWishlist === true && stockQty > 0}>
                {/* e, uid, sku */}
                <a
                  role="button"
                  onClick={e => handelAddToBag({ e, ...productDetail })}
                  onKeyPress={e => handelAddToBag({ e, ...productDetail })}
                  className="pos-abs bottom-0 r-0 m-5"
                >
                  <PlusGreen className="d-block" />
                </a>
              </If>
            }
            <If condition={!isLoading && (!isEnable || stockQty === 0)}>
              <If
                condition={
                  similarCta.enabled &&
                  similarCta.enabled === true &&
                  productDetail.sku &&
                  recomCountData &&
                  recomCountData[productDetail.sku] >=
                    similarCta.minNumOfProducts
                }
              >
                <div
                  style={{ top: '50%', zIndex: 1 }}
                  className={classnames(
                    'end-0 lh-16 fs-10 .fw-4 pos-abs start-0 top-0 pt-4 pb-4 w100p fw-5 c-brown-gray txt-overflow-ellipsis bg-gray-1 bg-similar-items'
                  )}
                >
                  <Choose>
                    <When condition={isWishlist}>
                      <a
                        style={{ textDecoration: 'none', color: '#000000' }}
                        href={`${getNavigationBasePath()}/SimilarProducts/${
                          productDetail.sku
                        }`}
                      >
                        {translate('Show similar items >')}
                      </a>
                    </When>
                    <Otherwise>{translate('Out of Stock')}</Otherwise>
                  </Choose>
                </div>
              </If>
            </If>

            <If condition={!isLoading}>
              <If
                condition={
                  productDetail.showBrandNameOnImage &&
                  !productDetail.hideBrandName
                }
              >
                <span
                  className={classnames(
                    'bottom-0 wl pos-abs overflow-hidden rot-90n fs-14',
                    { 'end-0': IS_ARABIC },
                    { 'start-0': !IS_ARABIC }
                  )}
                  style={{
                    left: '9px',
                    textTransform: 'uppercase',
                    transformOrigin: 'top left'
                  }}
                >
                  {productDetail.brand}
                </span>
              </If>
              <If condition={promoBadge}>
                <span
                  className={classnames(
                    'pos-abs end-0 top-0 mt-10 pl-5 pr-5 fw-6 c-main-gray txt-overflow-ellipsis pt-1 pb-1',
                    isBrowser ? 'fs-11 lh-14 pt-4 pb-4' : 'fs-9 lh-16'
                  )}
                  style={{
                    maxWidth: '70%',
                    backgroundColor: productDetail.promoBgColor || '#B8E2FF',
                    color: productDetail.promoTextColor || '#2d2d2d'
                  }}
                >
                  {promoBadge}
                </span>
              </If>
              <If condition={!isEnable}>
                <div
                  className={classnames(
                    'bg-white-o-065 fs-10 lh-14 ls-032 p-5 pos-abs',
                    isBrowser ? 'w-320' : 'w-187'
                  )}
                >
                  OUT OF STOCK
                </div>
              </If>

              <If
                condition={
                  productDetail.relatedProductCount !== '0' &&
                  Number(productDetail.relatedProductCount) > 0
                }
              >
                <div
                  className={classnames(
                    'pos-abs end-0 bottom-0 mb-8 pl-5 pr-5 pt-8 pb-8 color-bg h-24 mw-24 flex center-xs middle-xs b-box',
                    { 'ml-10': IS_ARABIC },
                    { 'mr-10': !IS_ARABIC }
                  )}
                >
                  <span className="fs-10 lh-12 ls-107 fw-6">
                    +{productDetail.relatedProductCount}
                  </span>
                </div>
              </If>
            </If>
          </div>
        </div>
        <div
          className={classnames(
            'm-0 mt-5 fw-4 pos-relt _title ws-normal',
            { 'pl-22': IS_ARABIC },
            { 'pr-22': !IS_ARABIC },
            wrapperClass
          )}
        >
          <Choose>
            <When condition={prices.price}>
              <div
                className={classnames(
                  'd-il-block fw-4 c-dark-gray fw-6 ls-048 en-font',
                  isBrowser && !isSmallThumb ? 'fs-16 lh-20' : 'fs-11-2'
                )}
              >
                <If condition={!flashSaleActive && isFlashSale}>
                  <img src={flashIcon} alt="" className="h-10 pl-5 pr-5" />
                </If>
                {formatPrice(
                  Number(prices.specialPrice) > 0
                    ? prices.specialPrice
                    : prices.price,
                  null,
                  {
                    currency: productDetail.currency || 'sar',
                    maximumFractionDigits: CURRENCY_FRACTION,
                    minimumFractionDigits: CURRENCY_FRACTION
                  }
                )}
              </div>
              <If condition={alsoViewed}>
                <br />
              </If>
              <span className="d-il-block">
                <If
                  condition={
                    prices.specialPrice > 0 &&
                    parseInt(prices.specialPrice, 10) <
                      parseInt(prices.price, 10)
                  }
                >
                  <If
                    condition={
                      [COUNTRY_KWT, COUNTRY_BH, COUNTRY_QA].includes(COUNTRY) &&
                      !alsoViewed
                    }
                  >
                    <br />
                  </If>
                  <If
                    condition={
                      productDetail.discountPercentage > discountThreshold
                    }
                  >
                    <del
                      className={classnames(
                        'fw-4 c-black-50 c-light-gray ls-040 en-font',
                        isBrowser && !isSmallThumb ? 'fs-14 lh-20' : 'fs-10',
                        {
                          'ml-5 mr-5':
                            !alsoViewed &&
                            ![COUNTRY_KWT, COUNTRY_BH, COUNTRY_QA].includes(
                              COUNTRY
                            ),
                          'mr-l-5':
                            alsoViewed ||
                            [COUNTRY_KWT, COUNTRY_BH, COUNTRY_QA].includes(
                              COUNTRY
                            )
                        }
                      )}
                    >
                      {formatPrice(prices.price, null, {
                        currency: productDetail.currency || 'sar',
                        maximumFractionDigits: CURRENCY_FRACTION,
                        minimumFractionDigits: CURRENCY_FRACTION
                      })}
                    </del>
                  </If>
                </If>
                <If
                  condition={
                    productDetail.discountPercentage > discountThreshold &&
                    prices.specialPrice > 0
                  }
                >
                  <div
                    style={{ color: discountTextColor }}
                    className={classnames(
                      'fw-7 cap d-il-block ls-032',
                      isBrowser && !isSmallThumb ? 'fs-14 lh-20' : 'fs-10'
                    )}
                  >
                    <div className="d-il-block en-font">
                      {parseInt(productDetail.discountPercentage, 10)}%
                    </div>
                    {/* <div className="d-il-block en-font">%</div> */}
                    <span className="caps">&nbsp;{translate('off')}&nbsp;</span>
                  </div>
                </If>
              </span>
              <If condition={showWishlistIcon}>
                <div
                  style={getHeartCSS()}
                  className={classnames(
                    'heart-icon top-0 wl pos-abs overflow-hidden end-0 toggle-wishlist',
                    {
                      'w-60 h-50': !(youMayLike || alsoViewed || alsoViewedMix),
                      'w-70 h-70': youMayLike || alsoViewed || alsoViewedMix
                    }
                  )}
                  onClick={handleWishListPress}
                  onKeyPress={handleWishListPress}
                  role="link"
                >
                  <Choose>
                    <When condition={accountState.loggedIn}>
                      <If condition={wishListItemId >= 0}>
                        <HeartAnimation
                          width="100px"
                          height="100px"
                          doPlay={doPlay}
                          wishListItemId={wishListItemId}
                          isPLP={true}
                          wrapperClass={
                            youMayLike || alsoViewed || alsoViewedMix
                              ? `mt-n-32 wl`
                              : `${
                                  CURRENT_LANG_PREFIX === 'ar'
                                    ? `mr-n-10`
                                    : 'ml-n-10'
                                } mt-n-25 wl`
                          }
                        />
                      </If>
                    </When>
                    <Otherwise>
                      <HeartIcon
                        height="11px"
                        width="12x"
                        className={classnames('', {
                          'm-10': !(youMayLike || alsoViewed || alsoViewedMix),
                          'mt-8': youMayLike || alsoViewed || alsoViewedMix,
                          'mr-n30':
                            (youMayLike || alsoViewed || alsoViewedMix) &&
                            !IS_ARABIC,
                          'ml-n30':
                            (youMayLike || alsoViewed || alsoViewedMix) &&
                            IS_ARABIC
                        })}
                      />
                    </Otherwise>
                  </Choose>
                </div>
              </If>
            </When>
            <Otherwise>&nbsp;</Otherwise>
          </Choose>
        </div>
        <If condition={!alsoViewed}>
          <If condition={!isLoading}>
            <div
              className={classnames(
                'm-0 fw-4 txt-overflow-ellipsis c-dark-gray',
                wrapperClass,
                isBrowser ? 'fs-14 lh-18 ls-064 mt-4' : 'fs-10  ls-032'
              )}
            >
              <Choose>
                <When
                  condition={
                    !isLoading &&
                    (!isEnable || stockQty === 0) &&
                    isWishlist === true
                  }
                >
                  <span
                    className={classnames('cap fw-4 lh-16')}
                    style={{ color: '#D64045' }}
                  >
                    {translate('Out of Stock')}
                  </span>
                </When>
                <Otherwise>
                  {/* <If condition={!isLoading && (!isEnable || stockQty > 0)}> */}
                  <span>
                    {!productDetail.hideBrandName &&
                    !productDetail.showBrandNameOnImage
                      ? `${productDetail.brand} `
                      : ''}
                    {productDetail.name}
                  </span>
                  {/* </If> */}
                </Otherwise>
              </Choose>
            </div>
          </If>
          <div
            className={classnames(
              'm-0 fw-4 txt-overflow-ellipsis c-dark-gray',
              wrapperClass,
              isBrowser ? 'fs-14 lh-18 ls-064 mt-4' : 'fs-9  ls-032 mt-5'
            )}
          >
            <Choose>
              <When condition={systemGenerate.promo_badge_text}>
                <span
                  className="pl-4 pr-4 fw-6 pt-2 pb-2"
                  style={{
                    color: systemGenerate.promo_text_color || '#2d2d2d',
                    backgroundColor: systemGenerate.promo_bg_color || '#B8E2FF'
                  }}
                >
                  {systemGenerate.promo_badge_text}
                </span>
              </When>
              <Otherwise>
                {tag !== '' && (
                  <span
                    className="pl-4 pr-4 pt-2 pb-2 br-3 d-il-block"
                    style={{
                      color: bottomBadge.promoBadgeTextColor || '',
                      backgroundColor: bottomBadge.promoBadgeBgColor || ''
                    }}
                  >
                    {tag}
                  </span>
                )}
              </Otherwise>
            </Choose>
          </div>
          {/* <If
            condition={
              productDetail &&
              !productDetail.showBrandName &&
              !productDetail.showBrandNameOnImage
            }
          >
            <div
              className={classnames(
                'm-0 fw-4 c-brown-gray _brand ls-072',
                wrapperClass,
                isBrowser ? 'fs-12 lh-16 mt-4' : 'fs-9 mt-5'
              )}
            >
              <span>
                {translate('by')} {productDetail.brand}
              </span>
            </div>
          </If> */}
          <If condition={flashSaleActive && barHint}>
            <div className="middle-xs mt-5">
              <div
                className={classnames('fs-9 lh-12 ls-096 c-dark-gray en-font', {
                  'ta-r': !IS_ARABIC,
                  'ta-l': IS_ARABIC
                })}
              >
                {IS_ARABIC
                  ? `${barHint && barHint.split(' ')[1]} ${barHintCount}`
                  : `${barHintCount} ${barHint && barHint.split('$ ')[1]}`}
              </div>
              <div className=" h-4 bg-thumb-gray flex">
                <div
                  className="bg-brand h-4 w-0"
                  style={{ width: `${stockPercent}%` }}
                />
              </div>
            </div>
          </If>
        </If>
      </div>
    </a>
  );
}
