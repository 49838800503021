
import React from 'react';
import { useStateValue } from 'context/app';
import { BrowserView } from 'react-device-detect';
import { COUNTRY } from 'constants/index';
import { path } from 'utils/';
import FooterBottom from './FooterBottom';
import FooterMain from './FooterMain';
import FooterTop from './FooterTop';
import FooterUser from './FooterUser';
import PopularSearches from './PopularSearches';

export default function ({ isUserPage = false, isHome = false, gender, showPopularSearch = false }) {
  const {
    account: [accountState,]
  } = useStateValue();
  const {
    config = {}
  } = accountState;
  const { vatInfo = {} } = config;
  const vatCountryInfo = path(['countryData', [COUNTRY]], vatInfo);
  return (
    <>
      <BrowserView>
        <Choose>
          <When condition={isUserPage}>
            <FooterUser vatInfo={vatInfo} vatCountryInfo={vatCountryInfo} />
          </When>
          <Otherwise>
            <FooterTop />
            <FooterMain />
            <FooterBottom vatInfo={vatInfo} vatCountryInfo={vatCountryInfo} />
            <If condition={showPopularSearch}>
              <PopularSearches isHome={isHome} gender={gender === "beauty" ? "women" : gender} />
            </If>
          </Otherwise>
        </Choose>
      </BrowserView>
    </>
  );
}
