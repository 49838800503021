import React from 'react';
import { translate } from 'constants/language';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import { CURRENT_LANG_PREFIX } from 'constants/index';
import PopularSearcheItem from './PopularSearcheItem';

export default function ({ gender = "women" }) {
  const items = {
    women: [
      {
        label: { en: "Dresses", ar: "فساتين" },
        link: "list/women/context/dresses-w"
      },
      {
        label: { en: "Tops", ar: "بلايز" },
        link: "list/women/context/tops-w"
      },
      {
        label: { en: "Bottoms", ar: "سفليّة" },
        link: "list/women/context/bottoms-w"
      },
      {
        label: { en: "T-Shirts", ar: "تيشيرتات" },
        link: "list/women/context/t-shirts-w"
      },
      {
        label: { en: "Nightwear", ar: "للنوم" },
        link: "list/women/context/nightwear-w"
      },
      {
        label: { en: "Footwear", ar: "أحذية" },
        link: "list/women/context/footwear-w"
      },
      {
        label: { en: "Bags", ar: "شنط" },
        link: "list/women/context/bags-and-wallets-w"
      },
      {
        label: { en: "Accessories", ar: "إكسسوارات" },
        link: "list/women/context/accessories-w"
      },
      {
        label: { en: "Kimonos", ar: "كيمونو" },
        link: "list/women/context/shrugs-and-kimonos-w"
      },
      {
        label: { en: "Pyjama Sets", ar: "بيجامات" },
        link: "list/women/context/topcarousel-loungesets-pajama-sets-w"
      },
      {
        label: { en: "Lingerie", ar: "لانجري" },
        link: "list/women/context/lingerie"
      },
      {
        label: { en: "Swimwear", ar: "للسباحة" },
        link: "list/women/context/swimwear-category-w"
      },
      {
        label: { en: "Co-ords", ar: "أطقم" },
        link: "list/women/context/co-ords-w"
      },
      {
        label: { en: "Jumpsuits", ar: "أفرولات" },
        link: "list/women/context/jumpsuits-and-rompers-w"
      },
      {
        label: { en: "Plus Size", ar: "بلَس سايز" },
        link: "list/women/context/plus-size-w"
      },
      {
        label: { en: "Loungewear", ar: "للراحة" },
        link: "list/women/context/loungewear-w"
      },
      {
        label: { en: "Activewear", ar: "للرياضة" },
        link: "list/women/context/activewear-w"
      },
      {
        label: { en: "Outerwear", ar: "للخروجات" },
        link: "list/women/context/outerwear-w"
      }
    ],
    men: [
      {
        label: { en: "Footwear", ar: "أحذية" },
        link: "list/men/context/footwear-m"
      },
      {
        label: { en: "T-Shirts", ar: "تيشيرتات" },
        link: "list/men/context/t-shirts"
      },
      {
        label: { en: "Bottoms", ar: "بناطيل وشورتات" },
        link: "list/men/context/jeans-joggers-trousers-m"
      },
      {
        label: { en: "Activewear", ar: "للرياضة" },
        link: "list/men/context/activewear-m"
      },
      {
        label: { en: "Shorts", ar: "شورتات" },
        link: "list/men/context/shorts-m"
      },
      {
        label: { en: "Loungewear", ar: "للراحة " },
        link: "list/men/context/loungewear-m"
      },
      {
        label: { en: "Shirts", ar: "قمصان" },
        link: "list/men/context/shirts-m"
      },
      {
        label: { en: "Accessories", ar: "إكسسوارات" },
        link: "list/men/context/accessories-m"
      },
      {
        label: { en: "Bags", ar: "شنط" },
        link: "list/men/context/bags-and-wallets-m"
      },
      {
        label: { en: "Nightwear", ar: "للنوم" },
        link: "list/men/context/nightwear-m"
      },
      {
        label: { en: "Innerwear", ar: "داخلية" },
        link: "list/men/context/innerwear-m"
      },
      {
        label: { en: "Outerwear", ar: "جاكيتات" },
        link: "list/men/context/outerwear-m"
      }
    ]
  }
  return (
    <div className="bg-light-gray">
      <ContainerDesktop>
        <div className="bt-white-two pt-40 pb-40">
          <div className="fs-14 lh-18 ls-064 fw-6 cap">{translate('Popular searches')}</div>
          <div className="mt-16 row">
            {gender && items[gender] && items[gender].map((item, index) => (
              <div className="flex middle-xs">
                <PopularSearcheItem link={item.link} test={item.label[CURRENT_LANG_PREFIX]}>{item.label[CURRENT_LANG_PREFIX]}</PopularSearcheItem>
                <If condition={index !== items[gender].length - 1}><span className="w-1 h-8 bg-dark-gray ml-7 mr-7 d-il-block" /></If>
              </div>
            ))}
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}