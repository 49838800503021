import React from 'react';
import LogoMix from 'assets/logo.svg';
import LogoEN from 'assets/logo-en.svg';
import { translate } from 'constants/language';
import LangSwitch from 'components/libs/LangSwitch';
import CountrySwitch from 'components/libs/CountrySwitch';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import { COUNTRY, CURRENT_LANG_PREFIX } from 'constants/index';

const Logo = COUNTRY.toLowerCase() === 'in' ? LogoEN : LogoMix;

export default function({ vatInfo = {}, vatCountryInfo = {} }) {
  return (
    <div className="bg-light-gray ">
      <ContainerDesktop>
        <div className="bt-white-two pt-24 pb-24 flex middle-xs">
          <div className="ml-0 mr-auto flex middle-xs">
            <div>
              <Logo className="h-40 w-70 d-block" />
            </div>
            <div className="fs-12 lh-16 ls-072 c-dark-gray ml-30 mr-30">
              <If
                condition={
                  vatInfo &&
                  vatInfo.enable &&
                  vatCountryInfo &&
                  vatCountryInfo.enable
                }
              >
                <div className="en-font">
                  {vatCountryInfo &&
                    vatCountryInfo.texts &&
                    vatCountryInfo?.texts?.map((vatcntry, index) => (
                      <a
                        className="c-soft-blue fw-5"
                        href={
                          vatcntry[CURRENT_LANG_PREFIX] &&
                          vatcntry[CURRENT_LANG_PREFIX].url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        {vatcntry[CURRENT_LANG_PREFIX] &&
                          vatcntry[CURRENT_LANG_PREFIX].label}{' '}
                        <If
                          condition={index !== vatCountryInfo?.texts.length - 1}
                        >
                          <span className="c-brown-gray fw-5">|</span>
                        </If>
                      </a>
                    ))}
                </div>
              </If>
              <div className="en-font">
                {translate('©2021 Retail World Limited.')}
              </div>
              <div>{translate('All rights reserved')}</div>
            </div>
          </div>
          <div className="mr-0 ml-auto flex middle-xs">
            <CountrySwitch
              popupPosition="top"
              blackArrow={true}
              isDropDown={true}
              classes="b-dark-gray c-dark-gray ml-30 mr-30 pl-10 pt-6 pr-10 pb-6"
            />
            <If condition={COUNTRY.toLowerCase() !== 'in'}>
              <div className="b-dark-gray fs-14 lh-18 ls-064 c-dark-gray pl-10 pt-6 pr-10 pb-6">
                <LangSwitch />
              </div>
            </If>
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
