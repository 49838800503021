import React from 'react';
import classnames from 'classnames';

import SlidePane from 'components/libs/SlidePane';
import Button from 'components/libs/Buttons';
import { getCurrentLanguage, getStoreId } from 'utils/';
import CloseIcon from 'assets/icons/close.svg';

import { withdrawDelete, getLogin } from 'containers/Account/actions';
import { useStateValue } from 'context/app';
import { isBrowser } from 'react-device-detect';
import { translate } from '../../constants/language';
import { ARABIC_LANG, CURRENT_LANG_PREFIX } from '../../constants';

export const isRTL = getCurrentLanguage() === ARABIC_LANG;

export default ({ closeWithdrawalScreen }) => {
  const {
    account: [accountState, accountDispatch],
    shipping: [shippingState, shippingDispatch]
  } = useStateValue();
  const { config: { deleteUser = {} } = {} } = accountState;
  const withdraw = deleteUser?.default?.withdraw;

  const withdrawHandler = async () => {
    withdrawDelete({
      customerId: accountState.customerId,
      storeId: getStoreId(),
      requestType: 'withdraw'
    });
    closeWithdrawalScreen();
    // showToast(
    //   {
    //     type: 'error',
    //     content: result?.data?.statusMsg
    //   },
    //   accountDispatch
    // );
    getLogin(
      {
        useridentifier:
          accountState.validatedUser && accountState.validatedUser.email,
        password: accountState?.password,
        loginType: accountState?.loginType,
        quote: shippingState?.quote,
        loginData: accountState?.loginData,
        fromBag: false,
        isWithdraw: true,
        token: accountState?.token,
        fullName: accountState?.fullName
      },
      accountDispatch,
      shippingDispatch
    );
  };

  return (
    <>
      <SlidePane
        isOpen={true}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        style={{ 'box-sizing': 'border-box' }}
        className={classnames(
          'pb-24',
          isBrowser ? 'pl-30 pr-30' : 'pl-15 pr-15'
        )}
        isCenter={true}
      >
        <div className="mt-14 fs-14 lh-18 fw-6 c-dark-gray flex middle-xs">
          <div>
            {withdraw &&
              withdraw.title &&
              translate(withdraw.title[CURRENT_LANG_PREFIX])}
          </div>
          <a
            className="pl-r-20 mr-0 ml-auto"
            onClick={closeWithdrawalScreen}
            onKeyPress={closeWithdrawalScreen}
            role="button"
          >
            <CloseIcon className="d-block" />
          </a>
        </div>
        <div className="row pb-10 pr-8 pl-8 pt-10 bt-light">{}</div>
        <div className=" ta-c h-80">
          <img src={withdraw.image} alt="" className="h100p" />
        </div>
        <div className=" ta-c ">
          <span className="c-black-light fs-14 fw-4">
            {withdraw.text[CURRENT_LANG_PREFIX]}
          </span>
        </div>
        <div className="row pb-10 pr-8 pl-8 pt-10 bt-light">{}</div>
        <Button
          className="Button w100p border-none default fw-5"
          onClick={withdrawHandler}
          id="withdrawHandler"
        >
          {withdraw.cta[CURRENT_LANG_PREFIX]}
        </Button>
      </SlidePane>
    </>
  );
};
