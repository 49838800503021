import React from 'react';
import SlidePane from '.';

export default function ({ children, isOpen, inModal = true, ...props }) {
  return (
    <>
      <Choose>
        <When condition={inModal}>
          <SlidePane isOpen={isOpen} inModal={inModal} {...props}>
            {children}
          </SlidePane>
        </When>
        <Otherwise>
          <If condition={isOpen}>
            {children}
          </If>
        </Otherwise>
      </Choose>
    </>
  );
}
