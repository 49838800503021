import {
  ACTION_MAKE_PAYMENT_FAILED,
  ACTION_SELECTED_TAB,
  ACTION_MAKE_PAYMENT_LOADING,
  ACTION_MAKE_PAYMENT_SUCCESS,
  ACTION_SET_ERRORS,
  ACTION_SET_ORDER_DETAILS,
  ACTION_SET_ORDER_DETAILS_LOADING,
  ACTION_SET_ADDRESS,
  ACTION_SET_SAVED_CARDS,
  ACTION_SET_LANGUAGE,
  ACTION_RESET,
  ACTION_DELETE_CARD_LOADING,
  ACTION_CARD_DELETED,
  ACTION_RETRY_REORDER_SUCCESS,
  ACTION_RETRY_REORDER_ERROR,
  ACTION_RETRY_REORDER_LOADING,
  ACTION_GET_BANK_OFFERS_LOADING,
  ACTION_GET_BANK_OFFERS_FETCHED,
  ACTION_GET_BANK_OFFERS_ERROR,
  ACTION_FETCH_PAYMENT_CONFIG_LOADING,
  ACTION_PAYMENT_CONFIGS_FETCHED,
  ACTION_PAYMENT_CONFIGS_ERROR
} from './actions';
import {
  CARD_PAYMENT_INDEX,
  CASHFREE_PAYMENT_INDEX,
  COUNTRY
} from '../../constants';

export const initialState = {
  selectedTabIndex:
    COUNTRY.toLowerCase() === 'in' ? CASHFREE_PAYMENT_INDEX : CARD_PAYMENT_INDEX
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_RESET: {
      return {
        ...state,
        selectedTabIndex:
          COUNTRY.toLowerCase() === 'in'
            ? CASHFREE_PAYMENT_INDEX
            : CARD_PAYMENT_INDEX,
        paymentError: null,
        paymentProcessing: false
      };
    }
    case ACTION_SET_LANGUAGE:
      return {
        ...state,
        data: action.lang
      };
    case ACTION_SELECTED_TAB:
      return {
        ...state,
        selectedTabIndex: action.selectedTabIndex
      };

    case ACTION_MAKE_PAYMENT_LOADING: {
      const {
        currentPaymentCode = state.currentPaymentCode,
        paymentProcessing = true
      } = action.data || {};
      return {
        ...state,
        paymentProcessing,
        paymentError: null,
        currentPaymentCode
      };
    }
    case ACTION_MAKE_PAYMENT_FAILED: {
      const orderCreationError = action.data.error && action.data.error.order;

      return {
        ...state,
        paymentError:
          (orderCreationError && orderCreationError.error) || action.data.error,

        paymentProcessing: false
      };
    }
    case ACTION_MAKE_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentError: null
      };
    }
    case ACTION_SET_ERRORS: {
      return {
        ...state,
        paymentError: action.data.errors
      };
    }
    case ACTION_SET_ORDER_DETAILS: {
      return {
        ...state,
        orderSummary: action.data,
        orderDetailsLoading: false
      };
    }
    case ACTION_SET_ORDER_DETAILS_LOADING: {
      return {
        ...state,
        orderDetailsLoading: true
      };
    }

    case ACTION_SET_ADDRESS: {
      return {
        ...state,
        selectedAddress: action.data
      };
    }
    case ACTION_SET_SAVED_CARDS: {
      return {
        ...state,
        savedCards: action.data
      };
    }

    case ACTION_DELETE_CARD_LOADING: {
      return {
        ...state,
        deleteCardLoading: true
      };
    }

    case ACTION_CARD_DELETED: {
      return {
        ...state,
        deleteCardLoading: false
      };
    }

    case ACTION_RETRY_REORDER_LOADING: {
      return {
        ...state,
        retryReorderLoading: true
      };
    }

    case ACTION_RETRY_REORDER_ERROR: {
      return {
        ...state,
        retryReorderLoading: false
      };
    }

    case ACTION_RETRY_REORDER_SUCCESS: {
      return {
        ...state,
        retryReorderLoading: false
      };
    }

    case ACTION_GET_BANK_OFFERS_LOADING: {
      return {
        ...state,
        bankOffersLoading: true,
        bankOffersError: false,
        bankOffersData: undefined
      };
    }

    case ACTION_GET_BANK_OFFERS_FETCHED: {
      return {
        ...state,
        bankOffersLoading: false,
        bankOffersError: false,
        bankOffersData: action.data
      };
    }

    case ACTION_GET_BANK_OFFERS_ERROR: {
      return {
        ...state,
        bankOffersLoading: false,
        bankOffersError: true,
        bankOffersData: undefined
      };
    }

    case ACTION_FETCH_PAYMENT_CONFIG_LOADING: {
      return {
        ...state,
        paymentConfigsLoading: true,
        paymentConfigsError: false,
        paymentConfigsData: undefined
      };
    }

    case ACTION_PAYMENT_CONFIGS_FETCHED: {
      return {
        ...state,
        paymentConfigsLoading: false,
        paymentConfigsError: false,
        paymentConfigsData: action.data
      };
    }

    case ACTION_PAYMENT_CONFIGS_ERROR: {
      return {
        ...state,
        paymentConfigsLoading: false,
        paymentConfigsError: true,
        paymentConfigsData: undefined
      };
    }

    default:
      return state;
  }
};
