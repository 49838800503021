import React, { useState, useEffect } from 'react';
import { useStateValue } from 'context/app';
import {
  COUNTRY,
  COUNTRIES as countries,
  COUNTRIES_NAMES as countryNames,
  CURRENT_LANG_PREFIX
} from 'constants/index';
import classnames from 'classnames';
import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowDownBlk from 'assets/icons/arrow-down.svg';
import saFlag from 'assets/flags/ksa.png';
import aeFlag from 'assets/flags/uae.png';
import kwFlag from 'assets/flags/kwt.png';
import bhFlag from 'assets/flags/bhr.png';
import qaFlag from 'assets/flags/qat.png';
import inFlag from 'assets/flags/ind.png';
import Checkbox from 'components/libs/Checkbox';
import { setDefaultCountry } from 'utils/';
import { translate } from 'constants/language';

export default function CountrySwitch({
  classes,
  popupPosition = 'bottom',
  blackArrow = false,
  isDropDown = false,
  ...props
}) {
  const {
    account: [accountState]
  } = useStateValue();
  const { config: { indDisable = false } = {} } = accountState;
  const [showDropDown, setShowDropDown] = useState(false);

  const COUNTRIES = countries.filter(c => c !== 'qa');
  const COUNTRIES_NAMES = countryNames.filter(cn => cn !== 'Qatar');

  const countryList = COUNTRIES.map(country => {
    return country === COUNTRY;
  });
  const [selectedCountry, setSelectedCountry] = useState(countryList);
  const handleCountryChange = (cCode, index) => {
    const newCountryList = COUNTRIES.map((country, cIndex) => {
      return cIndex === index;
    });
    setSelectedCountry(newCountryList);

    setDefaultCountry(cCode);
    window.location.href = `/${cCode}/${CURRENT_LANG_PREFIX}`;
  };

  const scrollListner = () => {
    setShowDropDown(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollListner);
    return () => {
      window.removeEventListener('scroll', scrollListner);
    };
  }, []);

  return (
    <div
      className={classnames('flex middle-xs pos-relt pr-8', classes)}
      onClick={() => setShowDropDown(!showDropDown)}
      onKeyPress={() => setShowDropDown(!showDropDown)}
      role="button"
      {...props}
    >
      <Choose>
        <When condition={COUNTRY.toLowerCase() === 'in'}>
          <>
            <img
              src={inFlag}
              alt="AE Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[4])}
            </div>
          </>
        </When>
        <When condition={COUNTRY.toLowerCase() === 'qa'}>
          <>
            <img
              src={qaFlag}
              alt="QA Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[4])}
            </div>
          </>
        </When>
        <When condition={COUNTRY.toLowerCase() === 'bh'}>
          <>
            <img
              src={bhFlag}
              alt="BH Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[3])}
            </div>
          </>
        </When>
        <When condition={COUNTRY.toLowerCase() === 'kw'}>
          <>
            <img
              src={kwFlag}
              alt="KW Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[2])}
            </div>
          </>
        </When>
        <When condition={COUNTRY.toLowerCase() === 'ae'}>
          <>
            <img
              src={aeFlag}
              alt="AE Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[1])}
            </div>
          </>
        </When>
        <Otherwise>
          <>
            <img
              src={saFlag}
              alt="SA Flag"
              width="24px"
              height="16px"
              className="d-block"
            />
            <div className="fs-14 lh-18 ls-064 pl-r-10">
              {translate(COUNTRIES_NAMES[0])}
            </div>
          </>
        </Otherwise>
      </Choose>
      <div className="pl-r-5 flex middle-xs">
        <Choose>
          <When condition={blackArrow}>
            <ArrowDownBlk height="6px" width="16px" />
          </When>
          <Otherwise>
            <ArrowDown height="16px" width="16px" />
          </Otherwise>
        </Choose>
      </div>
      <If condition={showDropDown}>
        <div
          className={classnames(
            'pos-abs z-2 r-0 bg-white w-228 b-shadow-b-1_5',
            popupPosition === 'top' ? 'b-100p mb-6' : 't-100p mt-6'
          )}
        >
          <div
            className={classnames(
              'caret pos-abs r-0 mr-10',
              popupPosition === 'top'
                ? 'bottom-0 mb-n-12 rot-180'
                : 'top-0 mt-n-12'
            )}
          />
          {COUNTRIES.filter(c => c !== 'in' || !indDisable).map(
            (country, index) => {
              const key = index;
              return (
                <a
                  className={classnames(
                    'ml-15 mr-15 pt-16 pb-16 flex middle-xs',
                    {
                      'bt-light-gray': index
                    }
                  )}
                  onClick={() => handleCountryChange(country, index)}
                  onKeyPress={() => handleCountryChange(country, index)}
                  role="button"
                >
                  <Choose>
                    <When condition={isDropDown}>
                      <div className="">
                        <If condition={country === 'in'}>
                          <img
                            src={inFlag}
                            alt="IN Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'qa'}>
                          <img
                            src={qaFlag}
                            alt="QA Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'bh'}>
                          <img
                            src={bhFlag}
                            alt="BH Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'kw'}>
                          <img
                            src={kwFlag}
                            alt="KW Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'ae'}>
                          <img
                            src={aeFlag}
                            alt="AE Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'sa'}>
                          <img
                            src={saFlag}
                            alt="SA Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                      </div>
                    </When>
                    <Otherwise>
                      <Checkbox
                        checked={selectedCountry[index]}
                        role="checkbox"
                      />
                    </Otherwise>
                  </Choose>
                  <div
                    className={classnames(
                      'ml-10 mr-10 fs-14 lh-18',
                      selectedCountry[index]
                        ? 'fw-6 c-dark-gray'
                        : 'c-brown-gray'
                    )}
                  >
                    {translate(COUNTRIES_NAMES[key])}
                  </div>
                  <Choose>
                    <When condition={!isDropDown}>
                      <div className="ml-auto">
                        <If condition={country === 'qa'}>
                          <img
                            src={qaFlag}
                            alt="QA Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'bh'}>
                          <img
                            src={bhFlag}
                            alt="BH Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'kw'}>
                          <img
                            src={kwFlag}
                            alt="KW Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'ae'}>
                          <img
                            src={aeFlag}
                            alt="AE Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                        <If condition={country === 'sa'}>
                          <img
                            src={saFlag}
                            alt="SA Flag"
                            width="20px"
                            height="auto"
                            className="d-block"
                          />
                        </If>
                      </div>
                    </When>
                    <Otherwise>
                      <div className="ml-auto mr-0">
                        <Checkbox
                          checked={selectedCountry[index]}
                          role="checkbox"
                        />
                      </div>
                    </Otherwise>
                  </Choose>
                </a>
              );
            }
          )}
        </div>
        <div
          className="screen-fixed bg-white z-1 o-0"
          onClick={() => setShowDropDown(false)}
          onKeyPress={() => setShowDropDown(false)}
          role="button"
        >
          &nbsp;
        </div>
      </If>
    </div>
  );
}
