import axios from 'utils/axios';
import { getStoreId } from 'utils/';
import logError from 'utils/logError';
import { getXHeaderToken } from 'utils/user';
import { timingVisualComplete } from 'utils/analytics';
import { GET_CATEGORY_DATA } from 'constants/api';

export const ACTION_GET_CATEGORIE_DATA = 'categories/GET_CATEGORIE_DATA';
export const ACTION_GET_CATEGORY_FAILURE = 'categories/GET_CATEGORY_FAILURE';
export const ACTION_GET_CATEGORY_LOADING = 'categories/GET_CATEGORY_LOADING';

export async function getCategoriesData(dispatch) {
  try {
    const startTime = new Date().getTime();

    const storeId = getStoreId();
    if (!storeId) {
      return;
    }
    dispatch({ type: ACTION_GET_CATEGORY_LOADING });
    const result = await axios.get(GET_CATEGORY_DATA({ storeId }), {
      headers: {
        // Token: getJWT(),
        'X-Header-Token': getXHeaderToken(),
        'Content-Type': 'application/json'
      }
    });
    const response = (result.data && result.data.response) || result.data;

    if (result.data.status) {
      dispatch({ type: ACTION_GET_CATEGORIE_DATA, data: { ...response } });
    } else {
      dispatch({ type: ACTION_GET_CATEGORY_FAILURE });
    }
    const endTime = new Date().getTime();
    timingVisualComplete(endTime - startTime);
  } catch (e) {
    dispatch({ type: ACTION_GET_CATEGORY_FAILURE });
    logError(e);
  }
}
