import React, { useEffect, useState } from 'react';
import './index.scss';
import classnames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { path, getResizedImg, getCeilingNum } from 'utils/';
import { isBrowser } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isRTL, CURRENT_LANG_PREFIX, COUNTRY } from '../../../../constants';

export default ({
  widgetData,
  // aspectRatio = '16x9',
  bottomSpace,
  slideShowTime = 0,
  onWidgetClick = () => {}
}) => {
  const [fWidgetData, setfWidgetData] = useState([]);
  useEffect(() => {
    if (isRTL) {
      setfWidgetData(widgetData.reverse());
    } else {
      setfWidgetData(widgetData);
    }
  }, [widgetData]);

  const doOnClick = (event, slide, widgetIndex) => {
    event.preventDefault();
    onWidgetClick({ event, widget: slide, widgetIndex });
  };
  // const [sliderHeight, setSliderHeight] = useState([]);
  // const sliderRef = ref => {
  //   if (aspectRatio && ref) {
  //     setSliderHeight(
  //       (aspectRatio.split('x')[1] / aspectRatio.split('x')[0]) *
  //         ref.offsetWidth
  //     );
  //   }
  // };
  const [columnWidth, setColumnWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setColumnWidth(ref.clientWidth);
    }
  };
  const {
    imageMedia: [
      {
        url: iImgURL,
        dimensions: { width: iImgWidth, height: iImgHeight } = {}
      } = {}
    ] = []
  } = fWidgetData && fWidgetData.length && fWidgetData[0];
  return (
    <div
      // style={{ height: sliderHeight || '', paddingBottom: bottomSpace }}
      className="pb-8"
      style={{ paddingBottom: bottomSpace }}
      ref={handelRef}
    >
      <Choose>
        <When condition={fWidgetData.length > 1}>
          <div
            // className="home-page-carousel-wrap pos-relt bg-thumb-gray mb-8"
            className="home-page-carousel-wrap bg-thumb-gray"
            // ref={sliderRef}
            // style={{ height: sliderHeight || '' }}
          >
            <div
              className={classnames('home-page-carousel', {
                '_is-browser': isBrowser
              })}
            >
              <If condition={fWidgetData.length > 1}>
                <Carousel
                  autoPlay={slideShowTime && slideShowTime > 0}
                  interval={slideShowTime ? slideShowTime * 1000 : 0}
                  transitionTime={slideShowTime && slideShowTime > 0 ? 500 : 0}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={true}
                  infiniteLoop={true}
                  selectedItem={isRTL ? widgetData.length - 1 : 0}
                >
                  {fWidgetData
                    .filter(slide => path(['imageMedia', [0], 'url'], slide))
                    .map((slide, widgetIndex) => {
                      // const imgURL = path(['imageMedia', [0], 'url'], slide);
                      const {
                        imageMedia: [
                          {
                            url: imgURL,
                            dimensions: {
                              width: imgWidth,
                              height: imgHeight
                            } = {}
                          } = {}
                        ] = []
                      } = slide;
                      return (
                        <If condition={imgURL}>
                          <a
                            className="d-block bg-thumb-gray"
                            style={{
                              height:
                                imgHeight &&
                                columnWidth &&
                                (imgHeight / imgWidth) * columnWidth
                            }}
                            href={`/${CURRENT_LANG_PREFIX}/${slide.mlink ||
                              slide.link}`}
                            onClick={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                            onKeyPress={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                          >
                            <LazyLoadImage
                              src={getResizedImg({
                                imgURL,
                                reqWidth: document.body.clientWidth
                              })}
                              alt={slide.name}
                              width="100%"
                              height={
                                imgHeight &&
                                columnWidth &&
                                (imgHeight / imgWidth) * columnWidth
                              }
                            />
                          </a>
                        </If>
                      );
                    })}
                </Carousel>
              </If>
            </div>
          </div>
        </When>
        <When
          condition={
            fWidgetData.length === 1 &&
            path([0, 'imageMedia', 0, 'url'], widgetData)
            // const imgWidth = path([0, 'imageMedia', 0, 'dimensions', "width"], widgetData)
          }
        >
          <a
            className="block shine"
            style={{
              height:
                iImgHeight &&
                columnWidth &&
                (iImgHeight / iImgWidth) * columnWidth
            }}
            href={
              fWidgetData[0].mlink || fWidgetData[0].link
                ? `/${COUNTRY}/${CURRENT_LANG_PREFIX}/${fWidgetData[0].mlink ||
                    fWidgetData[0].link}`
                : `/${COUNTRY}/${CURRENT_LANG_PREFIX}/`
            }
            onClick={event => doOnClick(event, fWidgetData[0])}
            onKeyPress={event => doOnClick(event, fWidgetData[0])}
          >
            <LazyLoadImage
              className="d-block"
              src={getResizedImg({
                imgURL: iImgURL,
                reqWidth: getCeilingNum(document.body.clientWidth)
              })}
              alt=""
              width="100%"
              height={
                iImgHeight &&
                columnWidth &&
                (iImgHeight / iImgWidth) * columnWidth
              }
            />
          </a>
        </When>
      </Choose>
    </div>
  );
};
