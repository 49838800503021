import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { translate } from 'constants/language';
import { useStateValue } from 'context/app';

export default ({ size, onSelect, selected, onlySizes = false }) => {
  const { quantity: qty, size: sizeName, label } = size;

  const finalSize = sizeName || size.size;
  const [quantity, setQuantity] = useState(qty > 0 ? qty : 0);

  const handleSelect = () => {
    if (quantity > 0 || quantity === null) {
      onSelect({ ...size, productId: size.id || size.productId });
    }
  };
  const {
    shipping: [shippingState]
  } = useStateValue();
  const ITEM_LEFT_COUNT = 10;
  useEffect(() => {
    if (
      shippingState.quote &&
      shippingState.quote.quoteId &&
      shippingState.sizeStock
    ) {
      const sizeStock = shippingState.sizeStock.find(variant => {
        return (
          String(variant.procuctId || variant.productId) ===
          String(size.productId)
        );
      });

      if (sizeStock) {
        setQuantity(Number(sizeStock.value));
      }
    }
  }, [shippingState.sizeStock]);

  useEffect(() => {
    setQuantity(qty);
  }, [qty]);

  return (
    <div
      className={classnames(
        'fs-14 fw-5 c-dark-gray ta-c pt-6 pb-6 pl-10 pr-10 mw-40 mb-10 b-light-gray b-box size-button br-3',
        {
          'o-40 pos-relt _cross': quantity !== null && quantity < 1,
          'pos-relt': quantity !== null && quantity < ITEM_LEFT_COUNT,
          'bg-dark-gray c-white': selected
        },
        onlySizes ? 'mr-r-10' : ' mr-15'
      )}
      onClick={handleSelect}
      onKeyPress={handleSelect}
      role="button"
    >
      <span className="lh-ar-2 en-font">{finalSize || label}</span>
      <If
        condition={
          quantity !== null && quantity > 0 && quantity < ITEM_LEFT_COUNT
        }
      >
        <span className="fs-10 c-white bg-pastel-red-two pos-abs mt-3 t-100p l-0 r-0 ta-c">
          <span className=" en-font">{quantity}</span> {translate('left')}
        </span>
      </If>
    </div>
  );
};
