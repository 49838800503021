import React, { useState } from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';
import CloseIcon from 'assets/icons/close-popup.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import ArrowRightBrand from 'assets/icons/arrow-right-brand.svg';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { isRTL } from 'constants/index';

export default function Img({
  tabs = [],
  titleKey = '',
  setCTabIndex = () => {},
  closeCategoryPane = () => {},
  wrapperClass,
  activeIndex,
  appliedTabIndex,
  activeTab = 0,
  setActiveTab = () => {},
  // categoryPage = [],
  cateTabs = [],
  categoriesData = [],
  pageTypes = [],
  ...props
}) {
  const tabClass = isBrowser
    ? 'c-brown-gray pt-8 pb-8'
    : 'bg-light-gray c-brown-gray pt-16 pb-16 pl-10 pr-10';
  const tabClassActive = isBrowser
    ? 'c-dark-gray fw-6 pt-8 pb-8'
    : 'bg-white c-dark-gray fw-6 pt-16 pb-16 pl-10 pr-10';

  const [wraperHeight, setWraperHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentScrolled, setContentScrolled] = useState(false);
  const scrollListner = event => {
    const { scrollTop } = event.target;
    setContentScrolled(scrollTop > 20);
  };
  const handelRef = ref => {
    if (ref) {
      setWraperHeight(ref.clientHeight);
    }
  };
  const handelRefChild = ref => {
    if (ref) {
      setContentHeight(ref.clientHeight - 16);
    }
  };
  return (
    <div {...props}>
      <div className={wrapperClass} style={{ 'overflow-y': 'scroll' }}>
        <MobileView>
          {tabs.map((tab, index) => {
            return (
              <a
                role="button"
                onClick={() => setCTabIndex(index)}
                onKeyPress={() => setCTabIndex(index)}
                className={classnames(
                  'd-block w100p lh-16  b-box wb-ball',
                  isBrowser ? 'fs-14 ls-08' : 'fs-11 bb-white-two',
                  activeIndex === index ? tabClassActive : tabClass
                )}
              >
                <span>{tab[titleKey]}</span>
                <If condition={tab.tagTextNew}>
                  <span className="ml-4" />
                  <span
                    className="pl-3 pr-3 br-3 capitalize fw-5lh-14 ws-nowrap"
                    style={{
                      backgroundColor: tab.tagBackgroundColor || '#ed6c90',
                      color: tab.tagTextColor || '#fdfcfc',
                      fontSize: tab.tagTextSize || '10px'
                    }}
                  >
                    {tab.tagTextNew}
                  </span>
                </If>
              </a>
            );
          })}
        </MobileView>
        <BrowserView>
          <div className="pt-56">
            <CloseIcon
              onClick={closeCategoryPane}
              onKeyPress={closeCategoryPane}
              role="button"
            />
          </div>
          <div
            ref={handelRef}
            onScroll={scrollListner}
            className="overflow-auto-no-bar pos-relt"
            style={{ maxHeight: 'calc(100vh - 95px)' }}
          >
            <div ref={handelRefChild} className="pt-76">
              {cateTabs.map((category, categoryIndex) => {
                return (
                  <>
                    <div
                      role="button"
                      onClick={() => setActiveTab(categoryIndex)}
                      onKeyPress={() => setActiveTab(categoryIndex)}
                      className={classnames(
                        'fs-18 lh-24 ls-032 c-dark-gray cap mb-16 pt-16 flex middle-xs',
                        { 'fw-6': categoryIndex === activeTab }
                      )}
                    >
                      {category.title}
                      <If condition={!isEmpty(category.tag)}>
                        <span className="ml-4" />
                        <span
                          className="pl-3 pr-3 br-3 capitalize fw-5 lh-14 ws-nowrap"
                          style={{
                            backgroundColor:
                              category.tag.backgroundColor || '#ed6c90',
                            color: category.tag.textColor || '#fdfcfc',
                            fontSize: category.tag.textSize || '10px'
                          }}
                        >
                          {isRTL ? category.tag.textAR : category.tag.textEN}
                        </span>
                      </If>
                    </div>
                    <If
                      condition={
                        categoryIndex === activeTab &&
                        categoriesData &&
                        categoriesData[pageTypes[activeTab]]
                      }
                    >
                      <div className="mb-8">
                        {categoriesData[pageTypes[activeTab]].tabs.map(
                          (tab, index) => {
                            return (
                              <a
                                role="button"
                                onClick={() => setCTabIndex(index)}
                                onKeyPress={() => setCTabIndex(index)}
                                className={classnames(
                                  'lh-16 b-box fs-14 ls-08 ws-normal flex middle-xs',
                                  activeIndex === index &&
                                    appliedTabIndex === categoryIndex
                                    ? tabClassActive
                                    : tabClass
                                )}
                              >
                                <div>{tab[titleKey]}</div>
                                <If condition={tab.tagTextNew}>
                                  <span className="ml-4" />
                                  <span
                                    className="pl-3 pr-3 br-3 capitalize fw-5 lh-14 ws-nowrap"
                                    style={{
                                      backgroundColor:
                                        tab.tagBackgroundColor || '#ed6c90',
                                      color: tab.tagTextColor || '#fdfcfc',
                                      fontSize: tab.tagTextSize || '10px'
                                    }}
                                  >
                                    {tab.tagTextNew}
                                  </span>
                                </If>
                                <If
                                  condition={
                                    activeIndex === index &&
                                    appliedTabIndex === categoryIndex
                                  }
                                >
                                  <div className="pl-r-10 w-16 flex center-xs">
                                    <ArrowRightBrand className="d-block rtl-flip" />
                                  </div>
                                </If>
                              </a>
                            );
                          }
                        )}
                      </div>
                    </If>
                  </>
                );
              })}
            </div>
            <If condition={contentHeight > wraperHeight && !contentScrolled}>
              <div className="pos-stk l-0 r-0 bottom-0 flex start-xs white-shade-v">
                <div className="mt-8 mb-8 h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                  <ArrowRight className="rot-90 d-block" />
                </div>
              </div>
            </If>
          </div>
        </BrowserView>
      </div>
    </div>
  );
}
