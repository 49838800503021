import { ACTION_GET_LANDING_PAGE } from './actions';

export const initialState = { isLoading: true, isProfileLoading: true };

export default (state = initialState, action) => {

  switch (action.type) {
    case ACTION_GET_LANDING_PAGE: {
      const { data = [] } = action;
      return {
        ...state,
        isLoading: false,
        page: data
      };
    }
    default:
      return state;
  }
};
