import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import classnames from 'classnames';
import { path, getResizedImg, getCeilingNum } from 'utils/';
import { isBrowser } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isRTL, CURRENT_LANG_PREFIX, COUNTRY } from '../../../../constants';

export default ({
  widgetData,
  bottomSpace,
  slideShowTime = 0,
  cloumnStyling = {},
  onWidgetClick = () => {}
}) => {
  const [fWidgetData, setfWidgetData] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [isStyle, setIsStyle] = useState(true);
  const timer = useRef(null);
  const positionRef = React.useRef(0);

  function resetTimeout() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }

  useEffect(() => {
    if (imgIndex === fWidgetData.length - 1) {
      setImgIndex(0);
    } else {
      resetTimeout();
      timer.current = setTimeout(
        () =>
          setImgIndex(prevIndex =>
            prevIndex === fWidgetData.length - 1 ? 0 : prevIndex + 1
          ),

        slideShowTime * 1000
      );
    }

    return () => {
      resetTimeout();
    };
  }, [imgIndex]);

  useEffect(() => {
    if (isRTL) {
      setfWidgetData(widgetData.reverse());
    } else {
      setfWidgetData(widgetData);
    }
  }, [widgetData]);

  const doOnClick = (event, slide, widgetIndex) => {
    event.preventDefault();
    onWidgetClick({ event, widget: slide, widgetIndex });
  };
  const [columnWidth, setColumnWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setColumnWidth(ref.clientWidth);
    }
  };
  const animateCarousal = () => {
    let data = {};
    if (slideShowTime !== 0) {
      if (CURRENT_LANG_PREFIX === 'en') {
        data = {
          transform:
            imgIndex === fWidgetData.length - 2
              ? `translate3d(${-imgIndex * 87}%, 0, 0)`
              : `translate3d(${-imgIndex * 100}%, 0, 0)`,
          transition: 'ease 1000ms'
        };
      } else if (CURRENT_LANG_PREFIX === 'ar') {
        data = {
          transform:
            imgIndex === fWidgetData.length - 2
              ? `translate3d(${imgIndex * 80}%, 0, 0)`
              : `translate3d(${imgIndex * 100}%, 0, 0)`,
          transition: 'ease 1000ms'
        };
      }
    }
    return data;
  };

  const handleScroll = e => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionRef.current) {
      positionRef.current = x;
      setIsStyle(false);
    }
  };

  const {
    imageMedia: [
      {
        url: iImgURL,
        dimensions: { width: iImgWidth, height: iImgHeight } = {}
      } = {}
    ] = []
  } = fWidgetData && fWidgetData.length && fWidgetData[0];

  const spacing =
    cloumnStyling?.spacing !== 'undefind' && cloumnStyling?.spacing !== null
      ? cloumnStyling?.spacing
      : '10px';
  const layoutCount = 4.5;

  const imgW =
    (columnWidth - (cloumnStyling?.spacing ? 3 * cloumnStyling?.spacing : 0)) /
    layoutCount;

  const imageRatio = iImgWidth / iImgHeight;

  const imgH = imgW / imageRatio;
  return (
    <div
      className="pb-8 bg-white"
      style={{ paddingBottom: bottomSpace }}
      ref={handelRef}
    >
      <Choose>
        <When condition={fWidgetData.length > 1}>
          <div className="home-page-carousel-wrap bg-white" width={columnWidth}>
            <div
              className={classnames('home-page-carousel bg-white', {
                '_is-browser': isBrowser
              })}
            >
              <If condition={fWidgetData.length > 1}>
                <ul className="images" onScroll={handleScroll}>
                  {fWidgetData
                    .filter(slide => path(['imageMedia', [0], 'url'], slide))
                    .map((slide, widgetIndex) => {
                      const { imageMedia: [{ url: imgURL } = {}] = [] } = slide;
                      return (
                        <If condition={imgURL}>
                          <li
                            style={
                              isStyle
                                ? animateCarousal()
                                : { overflowX: 'hidden' }
                            }
                          >
                            <div>
                              <a
                                className="d-block bg-white"
                                style={{
                                  width: imgW
                                }}
                                href={`/${CURRENT_LANG_PREFIX}/${slide.mlink ||
                                  slide.link}`}
                                onClick={event =>
                                  doOnClick(event, slide, widgetIndex)
                                }
                                onKeyPress={event =>
                                  doOnClick(event, slide, widgetIndex)
                                }
                              >
                                <If condition={CURRENT_LANG_PREFIX === 'en'}>
                                  <LazyLoadImage
                                    src={getResizedImg({
                                      imgURL,
                                      reqWidth: imgW
                                    })}
                                    alt={slide.name}
                                    style={{
                                      'margin-left':
                                        widgetIndex > 0 ? spacing : '',
                                      'margin-right':
                                        widgetIndex !== fWidgetData.length - 1
                                          ? spacing
                                          : ''
                                    }}
                                    width={imgW}
                                    height={imgH}
                                  />
                                </If>
                                <If condition={CURRENT_LANG_PREFIX === 'ar'}>
                                  <LazyLoadImage
                                    src={getResizedImg({
                                      imgURL,
                                      reqWidth: imgW
                                    })}
                                    alt={slide.name}
                                    style={{
                                      'margin-left':
                                        widgetIndex !== fWidgetData.length - 1
                                          ? spacing
                                          : '',
                                      'margin-right':
                                        widgetIndex === 0 ? '' : spacing
                                    }}
                                    width={imgW}
                                    height={imgH}
                                  />
                                </If>
                              </a>
                            </div>
                          </li>
                        </If>
                      );
                    })}
                </ul>
              </If>
            </div>
          </div>
        </When>
        <When
          condition={
            fWidgetData.length === 1 &&
            path([0, 'imageMedia', 0, 'url'], widgetData)
          }
        >
          <a
            style={{
              height:
                iImgHeight &&
                columnWidth &&
                (iImgHeight / iImgWidth) * columnWidth
            }}
            href={
              fWidgetData[0].mlink || fWidgetData[0].link
                ? `/${COUNTRY}/${CURRENT_LANG_PREFIX}/${fWidgetData[0].mlink ||
                    fWidgetData[0].link}`
                : `/${COUNTRY}/${CURRENT_LANG_PREFIX}/`
            }
            onClick={event => doOnClick(event, fWidgetData[0])}
            onKeyPress={event => doOnClick(event, fWidgetData[0])}
          >
            <LazyLoadImage
              className="d-block"
              src={getResizedImg({
                imgURL: iImgURL,
                reqWidth: getCeilingNum(document.body.clientWidth)
              })}
              alt=""
              width="100%"
              height={
                iImgHeight &&
                columnWidth &&
                (iImgHeight / iImgWidth) * columnWidth
              }
            />
          </a>
        </When>
      </Choose>
    </div>
  );
};
