import React from 'react';
import { MobileView, BrowserView } from "react-device-detect";
import Header from '../Header'
import HeaderDesktop from "../HeaderDesktop";
import HeaderUser from "../HeaderDesktop/HeaderUser";

export default function ({ isUserPage = false, ...props }) {

  return (
    <>
      <BrowserView>
        <Choose>
          <When condition={isUserPage}>
            <HeaderUser {...props} />
          </When>
          <Otherwise>
            <HeaderDesktop {...props} />
          </Otherwise>
        </Choose>
      </BrowserView>
      <MobileView>
        <Header {...props} />
      </MobileView>
    </>
  );
}
