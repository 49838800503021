import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './index.scss';
// import { path, getResizedImg } from 'utils/';
import { BrowserView, isBrowser } from 'react-device-detect';
import ArrowRight from 'assets/icons/arrow-right.svg';
import { isRTL } from 'constants/index';

import { logEvent } from 'utils/analytics';
import { getProductCategories } from 'utils/product';
import ProductTupple from '../ProductTupple';
// import { CURRENT_LANG_PREFIX, BASE_PATH } from '../../../../constants';
// import { PRODUCT_GRID } from 'constants/home';

export default ({
  // widgetData,
  rowType,
  borderBottom,
  bottomSpace,
  products,
  analyticsData = {},
  widgetData = {},
  // colWrapperClass,
  cloumnStyling,
  onWidgetClick = () => {},
  itemTextCenterAlign = false,
  columnCountDesktop = 6
}) => {
  const [slideWidth, setSlideWidth] = useState();
  const [offsetTopProductCarousel, setOffsetTopProductCarousel] = useState(0);
  const handelRef = ref => {
    if (ref) {
      setSlideWidth((ref.clientWidth - 100) / columnCountDesktop);
      setOffsetTopProductCarousel(ref.offsetTop);
    }
  };
  const [slideScrollleft, setSlideScrollleft] = useState();
  const [sliderAtStart, setSliderAtStart] = useState(true);
  const [sliderAtEnd, setSliderAtEnd] = useState(true);
  const [sliderElm, setSliderElm] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [impressionLog, setImpressionLog] = useState(true);

  const handelRefSlider = ref => {
    if (ref) {
      setSliderElm(ref);
    }
  };

  const handelRefSlider1 = ref => {
    if (ref && sliderElm) {
      const scrollLeft =
        isRTL && sliderElm.scrollLeft !== 0
          ? sliderElm.scrollLeft * -1
          : sliderElm.scrollLeft;
      if (sliderElm.clientWidth + scrollLeft === ref.scrollWidth) {
        setSliderAtEnd(true);
      } else {
        setSliderAtEnd(false);
      }
    }
  };
  const onArrowClick = arrowPos => {
    const scrollLeft =
      isRTL && sliderElm.scrollLeft !== 0
        ? sliderElm.scrollLeft * -1
        : sliderElm.scrollLeft;
    if (arrowPos === 'left') {
      setSlideScrollleft(scrollLeft - (slideWidth + 20));
    } else if (arrowPos === 'right') {
      setSliderAtStart(false);
      setSlideScrollleft(scrollLeft + slideWidth + 20);
    }
  };

  useEffect(() => {
    if (sliderElm) {
      if (slideScrollleft === 0 || slideScrollleft < 1) {
        setSlideScrollleft(0);
        setSliderAtStart(true);
      }
      sliderElm.scroll({
        top: 0,
        left: isRTL ? slideScrollleft * -1 : slideScrollleft,
        behavior: 'smooth'
      });
    }
  }, [slideScrollleft]);
  const listenToScrollProductCarousel = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    window.clearTimeout(window.setPositionTimer1);
    window.setPositionTimer1 = setTimeout(() => {
      setScrollPosition(winScroll);
    }, 200);
  };
  useEffect(() => {
    window.addEventListener('scroll', listenToScrollProductCarousel);
    return () => {
      window.removeEventListener('scroll', listenToScrollProductCarousel);
    };
  }, [window]);

  useEffect(() => {
    if (
      products.length &&
      scrollPosition > offsetTopProductCarousel &&
      impressionLog
    ) {
      setImpressionLog(false);
      const itemsArray = [];
      products.forEach((product, index) => {
        console.log('analyticsData', analyticsData);
        getProductCategories({
          productId: product.id,
          categories: product.categories
        }).then(catData => {
          const obj = {
            id: product.sku,
            name: product.name,
            list_name: analyticsData.promoName,
            category: catData,
            brand: product.brand,
            variant: product.color,
            list_position: index,
            quantity: 1
          };
          let objPrice = 0;
          if (product.prices) {
            objPrice = product.prices.specialPrice
              ? product.prices.specialPrice
              : product.prices.price;
          }
          obj.price = objPrice;
          console.log('index', obj);
          itemsArray.push(obj);
          if (index === products.length - 1) {
            logEvent({
              eventName: 'view_item_list',
              data: {
                items: itemsArray,
                event_category: 'product_impression',
                event_action: 'view_item_list',
                event_label: 'view_item_list'
              },
              screenName: 'home'
            });
          }
        });
      });
    }
  }, [scrollPosition, products]);
  return (
    <div className="" style={{ marginBottom: bottomSpace }}>
      {/* <Choose>
        <When condition={widgetData.length > 0}> */}
      <If condition={borderBottom}>
        <hr className="mb-0 mt-0 mr-8 ml-8" />
      </If>
      <div
        ref={handelRef}
        className="category-carousel pos-relt"
        style={{
          // paddingBottom: Math.max(bottomSpace, 8),
          paddingTop: 4
        }}
      >
        {/* <If condition={rowTitle.length > 0}>
              <RowTitle title={rowTitle} noMB={true} mTop={false} />
            </If> */}
        <div
          ref={handelRefSlider}
          className={classnames(
            'cat-slide',
            isBrowser ? 'overflow-hidden ws-nowrap' : 'overflow-auto-no-bar'
            // {
            //   'overflow-auto-no-bar': isMobile || isTablet || isBrowser
            // }
          )}
        >
          <div ref={handelRefSlider1}>
            {(products || []).map((product, index) => (
              <ProductTupple
                analyticsData={analyticsData || {}}
                wrapperClass={classnames(
                  'd-il-block v-t bg-white',
                  isBrowser ? 'p-4' : 'w-150 p-4'
                )}
                product={product}
                productIndex={index}
                cloumnStyling={cloumnStyling}
                bottomSpace={bottomSpace}
                onWidgetClick={onWidgetClick}
                rowType={rowType}
                isLast={index === products.length - 1}
                itemTextCenterAlign={itemTextCenterAlign}
                slideWidth={slideWidth}
                caSource="home"
                caSourceType={rowType}
                caSourceValue={
                  widgetData &&
                  widgetData.length > 0 &&
                  widgetData[0] &&
                  widgetData[0].id
                }
                caBannerPromoName={analyticsData.promoName}
              />
            ))}
          </div>
        </div>

        <BrowserView>
          <If condition={!sliderAtStart}>
            <div
              className={classnames(
                'white-shade pos-abs w-40 bg-white top-0 bottom-0 mb-20 mt-20 z-1 mb-0',
                isRTL ? 'r-0 rot-180' : 'l-0'
              )}
            />
            <div
              className={classnames(
                'pos-abs z-2 top-0 h100p flex middle-xs ml-25',
                isRTL ? 'r-0 mr-25' : 'l-0 ml-25'
              )}
              onClick={() => onArrowClick('left')}
              onKeyPress={() => onArrowClick('left')}
              role="button"
            >
              <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                <ArrowRight
                  height="12px"
                  className={classnames(isRTL ? '' : 'rot-180')}
                />
              </div>
            </div>
          </If>
          <If condition={!sliderAtEnd}>
            <div
              className={classnames(
                'white-shade pos-abs w-40 bg-white top-0 bottom-0 mb-20 mt-20 z-1 mb-0',
                isRTL ? 'l-0' : 'r-0 rot-180'
              )}
            />
            <div
              className={classnames(
                'pos-abs z-2 top-0 h100p flex middle-xs',
                isRTL ? 'l-0 ml-25' : 'r-0 mr-25'
              )}
              onClick={() => onArrowClick('right')}
              onKeyPress={() => onArrowClick('right')}
              role="button"
            >
              <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                <ArrowRight height="12px" className="rtl-r" />
              </div>
            </div>
          </If>
        </BrowserView>
      </div>

      <If condition={borderBottom}>
        <hr className="mb-0 mt-0 mr-8 ml-8" />
      </If>
      {/* </When>
      </Choose> */}
    </div>
  );
};
