import { path, changeDecimalTo } from 'utils';
import {
  ACTION_DELETE_REASON_FAILURE,
  ACTION_GET_PROFILE_PAGE,
  ACTION_GET_PROFILE_FAILED,
  ACTION_GET_STORE_CREDIT,
  ACTION_LOGOUT,
  ACTION_VALIDATE_LOGIN_SUCCESS,
  ACTION_VALIDATE_LOGIN_FALIURE,
  ACTION_RESET_VALIDATED_USER,
  ACTION_RESET_REGISTRATION,
  ACTION_LOGIN_LOADING,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_REDIRECT,
  ACTION_LOGIN_FALIURE,
  ACTION_REGISTRATION_SUCCESS,
  ACTION_REGISTRATION_FALIURE,
  ACTION_REGISTRATION_LOADING,
  ACTION_PROFILE_UPDATE_LOADING,
  ACTION_PROFILE_UPDATE_SUCCESS,
  ACTION_PROFILE_UPDATE_FAILURE,
  ACTION_VALIDATE_LOGIN_LOADING,
  ACTION_CONFIG_VALUES,
  ACTION_FORGOT_PASSWORD_LOADING,
  ACTION_FORGOT_PASSWORD_SUCCESS,
  ACTION_RESET_PASSWORD_LOADING,
  ACTION_RESET_PASSWORD_SUCCESS,
  ACTION_RESET_PASSWORD_FALIURE,
  ACTION_CREATE_PASSWORD_LOADING,
  ACTION_CREATE_PASSWORD_SUCCESS,
  ACTION_CREATE_PASSWORD_FALIURE,
  ACTION_SHOW_TOAST,
  ACTION_HIDE_TOAST,
  ACTION_SITE_CONFIG_VALUES,
  ACTION_VERIFY_PHONE_START,
  ACTION_VERIFY_PHONE_FINISHED,
  ACTION_VERIFY_PHONE_LOADING,
  ACTION_ORDER_LIST_LOADING,
  ACTION_ORDER_LIST_FETCHED,
  ACTION_ORDER_LIST_ERROR,
  ACTION_RETURN_ORDER_LIST_LOADING,
  ACTION_RETURN_ORDER_LIST_FETCHED,
  ACTION_RETURN_ORDER_LIST_ERROR,
  ACTION_ORDER_CANCEL_LOADING,
  ACTION_ORDER_CANCELED,
  ACTION_ORDER_DETAILS_LOADING,
  ACTION_RATE_DETAILS_LOADING,
  ACTION_RATE_DETAILS_FETCHED,
  ACTION_RATE_DETAILS_ERROR,
  ACTION_SAVE_RATE_DETAILS_LOADING,
  ACTION_RATE_DETAILS_SAVED,
  ACTION_SAVE_RATE_DETAILS_ERROR,
  ACTION_ORDER_DETAILS_FETCHED,
  ACTION_ORDER_DETAILS_ERROR,
  ACTION_RESET_ACCOUNT_STATE,
  ACTION_COUPON_LIST_LOADING,
  ACTION_COUPON_LIST_FETCHED,
  ACTION_ORDER_INIT_LOADING,
  ACTION_ORDER_INIT_LOADED,
  ACTION_ORDER_COUNT_LOADING,
  ACTION_ORDER_COUNT_LOADED,
  ACTION_RETURN_COUNT_LOADING,
  ACTION_RETURN_COUNT_LOADED,
  ACTION_ARCHIVE_RETURN_COUNT_LOADING,
  ACTION_ARCHIVE_RETURN_COUNT_LOADED,
  ACTION_ARCHIVE_ORDER_COUNT_LOADING,
  ACTION_ARCHIVE_ORDER_COUNT_LOADED,
  ACTION_INIT_RMA_LOADING,
  ACTION_INIT_RMA_FETCHED,
  ACTION_CREDIT_HISTORY_LOADING,
  ACTION_CREDIT_HISTORY_FETCHED,
  ACTION_RETURN_ITEM_LOADING,
  ACTION_RETURN_ITEM_LOADED,
  ACTION_CANCEL_RMA_LOADING,
  ACTION_CANCEL_RMA_LOADED,
  ACTION_RETURN_DATA_UPDATE,
  ACTION_RETURN_ITEM_ERROR,
  ACTION_GET_INPAGE_BANNERS,
  ACTION_CITY_DETAILS_FETCHED,
  ACTION_CITY_DETAILS_LOADING,
  ACTION_CITY_DETAILS_ERROR,
  ACTION_CITY_DETAILS_ERROR_RESET,
  ACTION_SEARCH_DATA_FETCHED,
  ACTION_SEARCH_DATA_LOADING,
  ACTION_SEARCH_DATA_ERROR,
  ACTION_POPULAR_SEARCH_DATA_LOADING,
  ACTION_POPULAR_SEARCH_DATA_FETCHED,
  ACTION_POPULAR_SEARCH_DATA_ERROR,
  ACTION_GET_DESKTOP_CATEGORIES2,
  ACTION_GET_BANNER_IMAGE_CARDS,
  ACTION_GET_WISHLIST_COUNT,
  ACTION_GET_WISHLIST_COUNT_LOADING,
  ACTION_GET_WISHLIST_COUNT_FAILURE,
  ACTION_RECAPTCHA_VERIFIED,
  ACTION_RECAPTCHA_ERROR,
  ACTION_BANK_NAMES_LOADING,
  ACTION_BANK_NAMES_FETCHED,
  ACTION_BANK_NAMES_ERROR,
  ACTION_CREATE_BANK_TRANSFER_LOADING,
  ACTION_BANK_TRANSFER_CREATED,
  ACTION_CREATE_BANK_TRANSFER_ERROR,
  ACTION_PAYABLE_ORDERS_FETCHED
} from './actions';

export const initialState = { isLoading: true, isProfileLoading: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_RESET_ACCOUNT_STATE: {
      return {
        ...state,
        orderData: undefined,
        orderListLoading: true,
        orderListingComplete: false
      };
    }
    case ACTION_DELETE_REASON_FAILURE: {
      const { content, type, timeOut } = action.data;
      return {
        ...state,
        showToast: true,
        toastContent: content,
        toastType: type,
        toastTimeout: timeOut
      };
    }
    case ACTION_SHOW_TOAST: {
      const { content, type, timeOut, ctaAction } = action.data;
      return {
        ...state,
        showToast: true,
        toastContent: content,
        toastType: type,
        toastTimeout: timeOut,
        ctaAction
      };
    }
    case ACTION_HIDE_TOAST: {
      return {
        ...state,
        showToast: false,
        toastContent: undefined,
        toastType: undefined,
        toastTimeout: undefined
      };
    }

    case ACTION_ORDER_LIST_LOADING: {
      return {
        ...state,
        orderListLoading: true,
        orderListError: undefined,
        orderDetails: undefined
      };
    }

    case ACTION_ORDER_LIST_ERROR: {
      return {
        ...state,
        orderListLoading: false,
        orderListError: true
      };
    }

    case ACTION_ORDER_LIST_FETCHED: {
      const {
        // orderCount,
        orderData,
        orderPageOffset,
        orderListingComplete,
        isUnpaidOrderList
      } = action.data;

      let newOrderData;
      if (orderPageOffset === 0) {
        newOrderData = [...orderData];
      } else {
        newOrderData = [...(state.orderData || []), ...orderData];
      }

      return {
        ...state,
        orderListLoading: false,
        orderListError: false,
        // orderCount,
        orderData: newOrderData,
        orderPageOffset,
        orderListingComplete,
        isUnpaidOrderList
      };
    }

    case ACTION_RETURN_ORDER_LIST_LOADING: {
      return {
        ...state,
        returnOrderListLoading: true,
        returnOrderListError: undefined,
        returnOrderDetails: undefined
      };
    }

    case ACTION_RETURN_ORDER_LIST_ERROR: {
      return {
        ...state,
        returnOrderListLoading: false,
        returnOrderListError: true
      };
    }

    case ACTION_RETURN_ORDER_LIST_FETCHED: {
      const {
        // orderCount,
        returnOrderData,
        returnOrderPageOffset,
        returnOrderListingComplete
      } = action.data;

      let updatedReturnOrderData;
      if (returnOrderPageOffset === 0) {
        updatedReturnOrderData = [];
      } else {
        updatedReturnOrderData = [...state.returnOrderData];
      }

      returnOrderData.forEach(returnOrderArr => {
        const [orderId, returnOrder] = returnOrderArr;
        updatedReturnOrderData.push({ ...returnOrder, id: orderId });
      });

      return {
        ...state,
        orderListLoading: false,
        orderListError: false,
        returnOrderData: updatedReturnOrderData,
        returnOrderPageOffset,
        returnOrderListingComplete,
        returnOrderListLoading: false
      };
    }
    case ACTION_ORDER_CANCEL_LOADING: {
      return {
        ...state,
        orderCancelLoading: true,
        orderCancelData: undefined
      };
    }
    case ACTION_ORDER_CANCELED: {
      const { orderCancelData } = action.data;
      return {
        ...state,
        orderCancelLoading: false,
        orderCancelData
      };
    }
    case ACTION_COUPON_LIST_LOADING: {
      return {
        ...state,
        couponListLoading: true,
        couponsCount: undefined,
        couponsData: undefined
      };
    }

    case ACTION_COUPON_LIST_FETCHED: {
      const { couponsCount, couponsData } = action.data;
      return {
        ...state,
        couponListLoading: false,
        couponsCount,
        couponsData
      };
    }
    case ACTION_GET_PROFILE_PAGE: {
      const customer = (action.data && action.data.customer) || {};
      const { email } = customer || {};
      return {
        ...state,
        profile: { ...action.data },
        isProfileLoading: false,
        isLoginProcessing: false,
        loggedIn: !!email,
        resetPasswordLoading: false,
        resetPasswordError: undefined,
        resetPasswordSuccess: undefined,
        profileUpdateSuccess: undefined,
        phoneVerified: undefined,
        // orderCount: undefined,
        orderData: undefined
      };
    }
    case ACTION_VALIDATE_LOGIN_LOADING: {
      return {
        ...state,
        profile: undefined,
        isLoginProcessing: true,
        registartionRequired: false,
        apiErrorMsg: undefined,
        apiErrorCode: undefined,
        error: undefined
      };
    }
    case ACTION_GET_PROFILE_FAILED: {
      return {
        ...state,
        profile: undefined,
        isLoginProcessing: false,
        isProfileLoading: false
      };
    }
    case ACTION_GET_STORE_CREDIT: {
      const storeCredit = action.data && action.data.storeCredit;
      const formattedStoreCredit = changeDecimalTo(storeCredit || 0.0, 2);

      return {
        ...state,
        storeCredit: formattedStoreCredit
      };
    }
    case ACTION_LOGOUT: {
      return {
        ...state,
        profile: undefined,
        customer: undefined,
        loggedIn: false,
        invalidPassword: false,
        storeCredit: false
      };
    }
    case ACTION_VALIDATE_LOGIN_SUCCESS: {
      const { response, loginType, useridentifier, error } = action.data;
      return {
        ...state,
        validatedUser: response,
        loginType,
        useridentifier,
        isLoginProcessing: false,
        error
      };
    }

    case ACTION_VERIFY_PHONE_START: {
      const { isValid, isRegistration, phone, otp, profilePhone } = action.data;
      let phoneVerified = { phoneVerified: false, phoneVerifiedRegistr: false };
      if (typeof isRegistration !== 'undefined') {
        phoneVerified = isRegistration
          ? { phoneVerified: false, phoneVerifiedRegistr: isValid }
          : { phoneVerified: isValid, phoneVerifiedRegistr: false };
      }
      return {
        ...state,
        otpLoading: false,
        loginData: action.data,
        ...phoneVerified,
        socialPhone: phone,
        otp,
        profilePhone
      };
    }

    case ACTION_VERIFY_PHONE_LOADING: {
      const { isRegistration, envokedFrom } = action.data || {};
      let showVerifyPhone = {
        showVerifyPhone: false,
        showVerifyPhoneRegistr: false
      };
      if (typeof isRegistration !== 'undefined') {
        showVerifyPhone = isRegistration
          ? { showVerifyPhone: false, showVerifyPhoneRegistr: true }
          : { showVerifyPhone: true, showVerifyPhoneRegistr: false };
      }
      return {
        ...state,
        ...showVerifyPhone,
        otpLoading: true,
        phoneVerified: false,
        loginData: undefined,
        phoneVerifiedRegistr: false,
        socialPhone: undefined,
        profilePhone: undefined,
        envokedFrom
      };
    }

    case ACTION_VERIFY_PHONE_FINISHED: {
      const { success, isRegistration = false } = action.data;
      const phoneVerified = isRegistration
        ? { phoneVerified: false, phoneVerifiedRegistr: success }
        : { phoneVerified: success, phoneVerifiedRegistr: false };

      return {
        ...state,
        showVerifyPhone: false,
        showVerifyPhoneRegistr: false,
        profileUpdateLoading: false,
        validatedUser: undefined,
        otpLoading: false,
        otp: undefined,
        ...phoneVerified,
        isValid: undefined,
        envokedFrom: undefined
      };
    }

    case ACTION_VALIDATE_LOGIN_FALIURE: {
      const {
        useridentifier,
        loginType,
        error,
        statusCode,
        statusMsg
      } = action.data;
      return {
        ...state,
        validatedUser: undefined,
        registartionRequired: true,
        isLoginProcessing: false,
        useridentifier,
        loginType,
        error,
        apiErrorCode: statusCode,
        apiErrorMsg: statusMsg
      };
    }
    case ACTION_RESET_VALIDATED_USER: {
      return {
        ...state,
        validatedUser: undefined,

        isLoginProcessing: false,
        error: undefined
      };
    }
    case ACTION_RESET_REGISTRATION: {
      return {
        ...state,
        registartionRequired: false,
        apiErrorCode: undefined,
        isLoginProcessing: false,
        error: undefined
      };
    }
    case ACTION_REGISTRATION_SUCCESS: {
      return {
        ...state,
        registartionRequired: false,
        loggedIn: true,
        validatedUser: undefined,
        isRegistrationProcessing: false,
        regisApiErrorCode: undefined,
        regisApiErrorMsg: undefined,
        isLoginProcessing: false,
        apiErrorCode: undefined,

        error: undefined,
        isNewUser: true
        // registerOtpValidate: undefined
      };
    }
    case ACTION_REGISTRATION_FALIURE: {
      const { statusCode, statusMsg } = action.data;
      return {
        ...state,
        isNewUser: undefined,
        registartionRequired: true,
        loggedIn: false,
        isRegistrationProcessing: false,
        regisApiErrorCode: statusCode,
        regisApiErrorMsg: statusMsg,
        error: undefined
      };
    }
    case ACTION_REGISTRATION_LOADING: {
      return {
        ...state,
        isRegistrationProcessing: true,
        regisApiErrorCode: undefined,
        regisApiErrorMsg: undefined,
        error: undefined
      };
    }

    case ACTION_LOGIN_REDIRECT: {
      const { redirectFromBag } = action.data;
      return {
        ...state,
        redirectFromBag
      };
    }

    case ACTION_LOGIN_SUCCESS: {
      const { customer, quoteId } = action.data;
      return {
        ...state,
        customer,
        quoteId,
        loggedIn: true,
        validatedUser: undefined,
        loginLoading: false,
        loginApiError: false,
        error: undefined,
        isNewUser: false,
        redirectFromBag: true
      };
    }
    case ACTION_LOGIN_LOADING: {
      return {
        ...state,
        loggedIn: false,
        profile: undefined,
        loginApiError: false,
        loginLoading: true,
        invalidPassword: false
        // validatedUser: undefined
      };
    }
    case ACTION_LOGIN_FALIURE: {
      const {
        invalidPassword,
        recaptchaNeeded,
        error,
        customerId,
        deleteRequested,
        password,
        loginType,
        token,
        fullName
      } = action.data;
      const loginApiError = path(['loginApiError'], action.data);
      return {
        ...state,
        customer: undefined,
        quoteId: undefined,
        loggedIn: false,
        invalidPassword,
        recaptchaNeeded,
        loginLoading: false,
        loginApiError,
        error,
        customerId,
        deleteRequested,
        password,
        loginType,
        token,
        fullName
      };
    }

    case ACTION_PROFILE_UPDATE_LOADING: {
      return {
        ...state,
        profileUpdateSuccess: false,
        profileUpdateLoading: true
      };
    }
    case ACTION_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        profileUpdateSuccess: true,
        profileUpdateLoading: false
      };
    }
    case ACTION_PROFILE_UPDATE_FAILURE: {
      return {
        ...state,
        profileUpdateSuccess: false,
        profileUpdateLoading: false
      };
    }
    case ACTION_CONFIG_VALUES: {
      return {
        ...state,
        config: action.data.config
      };
    }
    case ACTION_SITE_CONFIG_VALUES: {
      return {
        ...state,
        siteConfig: action.data.siteConfig
      };
    }
    case ACTION_FORGOT_PASSWORD_LOADING: {
      return {
        ...state,
        forgotPasswordLoading: true
      };
    }
    case ACTION_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordLoading: false
      };
    }

    case ACTION_RESET_PASSWORD_LOADING: {
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: undefined,
        resetPasswordSuccess: undefined
      };
    }
    case ACTION_RESET_PASSWORD_SUCCESS: {
      const successMsg = action.data.successMsg || '';
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: undefined,
        resetPasswordSuccess: successMsg
      };
    }

    case ACTION_RESET_PASSWORD_FALIURE: {
      const errorMsg = action.data.errorMsg || '';

      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: errorMsg,
        resetPasswordSuccess: undefined
      };
    }

    case ACTION_CREATE_PASSWORD_LOADING: {
      return {
        ...state,
        createPasswordLoading: true,
        createPasswordError: undefined,
        createPasswordSuccess: undefined
      };
    }
    case ACTION_CREATE_PASSWORD_SUCCESS: {
      const successMsg = action.data.successMsg || '';
      return {
        ...state,
        createPasswordLoading: false,
        createPasswordError: undefined,
        createPasswordSuccess: successMsg
      };
    }
    case ACTION_CREATE_PASSWORD_FALIURE: {
      const errorMsg = action.data.errorMsg || '';
      return {
        ...state,
        createPasswordLoading: false,
        createPasswordError: errorMsg,
        createPasswordSuccess: undefined
      };
    }

    case ACTION_ORDER_DETAILS_LOADING: {
      return {
        ...state,
        orderDetailsLoading: true,
        orderDetailsError: undefined,
        // returnQty: undefined,
        returnData: undefined,
        returnEligibleDate: undefined
      };
    }

    case ACTION_RATE_DETAILS_LOADING: {
      return {
        ...state,
        rateDetailsLoading: true,
        rateDetailsError: undefined
      };
    }

    case ACTION_ORDER_DETAILS_FETCHED: {
      return {
        ...state,
        orderDetails: action.data || {},
        rateDetailsLoading: undefined,
        rateDetailsError: false
      };
    }

    case ACTION_PAYABLE_ORDERS_FETCHED: {
      return {
        ...state,
        payableOrderData: action.data || {},
        payableOrderDataLoading: undefined,
        payableOrderDataError: false
      };
    }

    case ACTION_RATE_DETAILS_FETCHED: {
      return {
        ...state,
        rateDetails: action.data || {},
        rateDetailsLoading: undefined,
        rateDetailsError: false
      };
    }

    case ACTION_RATE_DETAILS_SAVED: {
      return {
        ...state,
        saveRate: action.data || {},
        saveRateDetailsLoading: false,
        saveRateDetailsError: undefined
      };
    }

    case ACTION_SAVE_RATE_DETAILS_LOADING: {
      return {
        ...state,
        saveRateDetailsLoading: true,
        saveRateDetailsError: undefined
      };
    }

    case ACTION_ORDER_DETAILS_ERROR: {
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetailsError: true,
        orderDetails: undefined
      };
    }

    case ACTION_RATE_DETAILS_ERROR: {
      return {
        ...state,
        rateDetailsLoading: false,
        rateDetailsError: true,
        rateDetails: undefined
      };
    }

    case ACTION_SAVE_RATE_DETAILS_ERROR: {
      return {
        ...state,
        saveRateDetailsLoading: false,
        saveRateDetailsError: true
      };
    }

    case ACTION_INIT_RMA_LOADING: {
      return {
        ...state,
        initRMALoading: true
      };
    }

    case ACTION_INIT_RMA_FETCHED: {
      return {
        ...state,
        initRMA: action.data || {},
        initRMALoading: undefined
      };
    }

    case ACTION_ORDER_INIT_LOADING: {
      return {
        ...state,
        cancelInitLoading: true,
        orderCancelReasons: undefined
      };
    }
    case ACTION_ORDER_INIT_LOADED: {
      return {
        ...state,
        cancelInitLoading: undefined,
        orderCancelReasons: action.data
      };
    }

    case ACTION_RETURN_ITEM_LOADING: {
      return {
        ...state,
        itemReturnLoading: true,
        itemReturnlData: undefined
      };
    }

    case ACTION_RETURN_ITEM_ERROR: {
      return {
        ...state,
        itemReturnLoading: false
      };
    }
    case ACTION_RETURN_ITEM_LOADED: {
      const { itemReturnData } = action.data;
      return {
        ...state,
        itemReturnLoading: false,
        itemReturnData
      };
    }

    case ACTION_CANCEL_RMA_LOADING: {
      return {
        ...state,
        cancelRMALoading: true,
        cancelRMAData: undefined
      };
    }
    case ACTION_CANCEL_RMA_LOADED: {
      const { cancelRMAData } = action.data;
      return {
        ...state,
        cancelRMALoading: false,
        cancelRMAData
      };
    }

    case ACTION_ORDER_COUNT_LOADING: {
      return {
        ...state,
        orderCountLoading: true
        // orderCount: undefined
      };
    }

    case ACTION_RETURN_COUNT_LOADING: {
      return {
        ...state,
        returnCountLoading: true
        // orderCount: undefined
      };
    }

    case ACTION_ARCHIVE_RETURN_COUNT_LOADING: {
      return {
        ...state,
        returnArchiveCountLoading: true
        // orderCount: undefined
      };
    }

    case ACTION_ORDER_COUNT_LOADED: {
      const { allOrderCount } = action.data || {};
      return {
        ...state,
        orderCountLoading: undefined,
        orderCount: allOrderCount || 0
        // orderCount: 0
      };
    }

    case ACTION_RETURN_COUNT_LOADED: {
      return {
        ...state,
        returnCountLoading: undefined,
        returnCount: action.data || 0
      };
    }

    case ACTION_ARCHIVE_RETURN_COUNT_LOADED: {
      return {
        ...state,
        returnArchiveCountLoading: undefined,
        returnArchiveCount: action.data || 0
      };
    }

    case ACTION_ARCHIVE_ORDER_COUNT_LOADING: {
      return {
        ...state,
        archiveOrderCountLoading: true
        // orderCount: undefined
      };
    }

    case ACTION_ARCHIVE_ORDER_COUNT_LOADED: {
      const { allOrderCount } = action.data || {};
      return {
        ...state,
        archiveOrderCountLoading: undefined,
        archiveOrderCount: allOrderCount || 0
      };
    }

    case ACTION_CREDIT_HISTORY_LOADING: {
      return {
        ...state,
        creditHistoryLoading: true
        // orderCount: undefined
      };
    }

    case ACTION_CREDIT_HISTORY_FETCHED: {
      return {
        ...state,
        creditHistoryLoading: undefined,
        creditHistory: action.data.response,
        returnableAmount: action.data.returnableAmount
      };
    }

    case ACTION_RETURN_DATA_UPDATE: {
      const { returnData, returnEligibleDate } = action.data;
      return {
        ...state,
        // returnQty,
        returnData,
        returnEligibleDate
      };
    }
    case ACTION_GET_INPAGE_BANNERS: {
      const { banners } = action.data;
      return {
        ...state,
        // returnQty,
        banners
      };
    }

    case ACTION_CITY_DETAILS_FETCHED: {
      return {
        ...state,
        cityDetails: action.data || {},
        cityDetailsLoading: undefined,
        cityDetailsError: false
      };
    }

    case ACTION_CITY_DETAILS_ERROR: {
      return {
        ...state,
        cityDetailsLoading: false,
        cityDetailsError: true,
        cityDetails: undefined
      };
    }
    case ACTION_CITY_DETAILS_ERROR_RESET: {
      return {
        ...state,
        cityDetailsError: false
      };
    }

    case ACTION_CITY_DETAILS_LOADING: {
      return {
        ...state,
        cityDetailsLoading: true,
        cityDetailsError: false
      };
    }

    case ACTION_SEARCH_DATA_LOADING: {
      return {
        ...state,
        searchDataLoading: true
      };
    }

    case ACTION_SEARCH_DATA_FETCHED: {
      return {
        ...state,
        searchDataLoading: false,
        searchData: action.data,
        showPreSearch: false
      };
    }

    case ACTION_SEARCH_DATA_ERROR: {
      return {
        ...state,
        searchDataLoading: false,
        searchData: undefined
      };
    }

    case ACTION_POPULAR_SEARCH_DATA_LOADING: {
      return {
        ...state,
        popularSearchLoading: true,
        showPreSearch: true
      };
    }

    case ACTION_POPULAR_SEARCH_DATA_FETCHED: {
      return {
        ...state,
        popularSearchLoading: false,
        popularSearch: action.data.data || [],
        popularSearchTitle: action.data.title || '',
        showPreSearch: true
      };
    }
    case ACTION_POPULAR_SEARCH_DATA_ERROR: {
      return {
        ...state,
        popularSearchLoading: false,
        popularSearch: undefined,
        popularSearchTitle: undefined
      };
    }
    case ACTION_GET_DESKTOP_CATEGORIES2: {
      const { data = [] } = action;
      const pages = {};
      data.forEach(page => {
        pages[page.pageUniqueId] = page;
      });
      return {
        ...state,
        isCategoryLoading: false,
        categoriesData: pages
      };
    }
    case ACTION_GET_BANNER_IMAGE_CARDS: {
      const { data = [] } = action;
      return {
        ...state,
        bannerImageCards: data
      };
    }
    case ACTION_RECAPTCHA_VERIFIED: {
      const { data = [] } = action;
      return {
        ...state,
        recaptchaResponse: data
      };
    }
    case ACTION_RECAPTCHA_ERROR: {
      const { data = [] } = action;
      return {
        ...state,
        reCaptchaError: data
      };
    }
    case ACTION_GET_WISHLIST_COUNT: {
      const { productCount = 0 } = action.data;
      return {
        ...state,
        wishlistCountLoading: false,
        wishListCountFailure: false,
        wishListCount: productCount
      };
    }
    case ACTION_GET_WISHLIST_COUNT_LOADING: {
      return {
        ...state,
        wishlistCountLoading: true,
        wishListCountFailure: false,
        wishListCount: undefined
      };
    }
    case ACTION_GET_WISHLIST_COUNT_FAILURE: {
      return {
        ...state,
        wishlistCountLoading: false,
        wishListCountFailure: true,
        wishListCount: undefined
      };
    }
    case ACTION_BANK_NAMES_LOADING: {
      return {
        ...state,
        bankNamesLoading: true
      };
    }

    case ACTION_BANK_NAMES_FETCHED: {
      return {
        ...state,
        bankNamesLoading: false,
        bankNamesData: action.data
      };
    }

    case ACTION_BANK_NAMES_ERROR: {
      return {
        ...state,
        bankNamesLoading: false,
        bankNamesData: undefined
      };
    }

    case ACTION_CREATE_BANK_TRANSFER_LOADING: {
      return {
        ...state,
        bankTransferLoading: true
      };
    }

    case ACTION_BANK_TRANSFER_CREATED: {
      return {
        ...state,
        bankTransferLoading: false,
        bankTransferData: action.data
      };
    }

    case ACTION_CREATE_BANK_TRANSFER_ERROR: {
      return {
        ...state,
        bankTransferLoading: false,
        bankTransferData: undefined
      };
    }

    default:
      return state;
  }
};
