import React from 'react';
import './index.scss';
import { translate } from '../../../../constants/language';
import EmptyAnchor from './empty.svg';

export default function({ iscart = false }) {
  return (
    <div className="ta-c h60vh flex middle-xs center-xs p-20">
      <article>
        <EmptyAnchor width={200} />
        <div className="fs-14 c-main-gray">
          <Choose>
            <When condition={iscart === false}>
              {translate("We couldn't find any matches")}!
            </When>
            <When condition={iscart === true}>
              {translate('Your favorites list is empty')}
            </When>
          </Choose>
        </div>
        <div className="fs-12 c-label-hint">
          <Choose>
            <When condition={iscart === false}>
              {translate(
                'Please check the spelling or try searching for something else'
              )}
            </When>
            <When condition={iscart === true}>
              {translate(
                'Save your favorite items here and you can review them anytime'
              )}
            </When>
          </Choose>
        </div>
      </article>
    </div>
  );
}
