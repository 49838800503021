import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SlidePane from 'components/libs/SlidePane';
import Button from 'components/libs/Buttons';
import NotificationIcon from 'assets/icons/notifications.svg';

import { translate } from 'constants/language';
import { isIncognito } from 'utils/';
import logError from 'utils/logError';

export default () => {
  const [showDialog, setShowDialog] = useState(false);
  const onBack = () => {
    setShowDialog(false);
  };

  const onSubscribe = () => {
    try {
      window.appboy.registerAppboyPushMessages();
    } catch (e) {
      logError(e);
    }
    setShowDialog(false);
  };

  function initializePushNotification() {
    const { appboy, sessionStorage = {} } = window;
    try {
      isIncognito(its => {
        if (!its && appboy && !sessionStorage.pn) {
          setTimeout(() => {
            if (
              appboy.isPushSupported() &&
              !appboy.isPushPermissionGranted() &&
              !appboy.isPushBlocked()
            ) {
              setShowDialog(false);
              onSubscribe();
              sessionStorage.pn = true;
            } else {
              setShowDialog(false);
            }
          }, 2000);
        }
      });
    } catch (e) {
      logError(e);
    }
  }
  useEffect(() => {
    setTimeout(() => {
      initializePushNotification();
    }, 12000);
  }, []);

  return (
    <SlidePane
      isOpen={showDialog}
      from="bottom"
      width="100%"
      overlayClassName="z-100"
      style={{ 'box-sizing': 'border-box' }}
      // height="100%"
      // onRequestClose={onBack}
      timeOut={0}
      className={classnames('notification ta-c br-4', {
        // 'top-0': false && !isIOS
      })}
      isCenter={true}
    >
      <div className="ml-15 mr-15 mt-25 pr-25 pl-25">
        <NotificationIcon width="56px" height="56px" />

        <p className="c-dark-gray fs-14 fw-5 mt-20">
          <b>{translate('Stay tuned!')}</b>
        </p>
        <p className="c-brown-gray fs-12">
          {translate(
            'Let us send you push notifications to stay up to date with our latest offers, exclusive launches & other fun surprises!'
          )}
        </p>
      </div>
      <div className="row mt-30">
        <div className="col-xs-6">
          <Button
            className="w100p default pt-15"
            buttonType="stretched"
            onClick={onBack}
          >
            {translate('No, thanks!')}
          </Button>
        </div>
        <div className="col-xs-6">
          <Button
            className="w100p primary b-dark-gray pt-15"
            buttonType="stretched"
            onClick={onSubscribe}
          >
            {translate('Agree')}
          </Button>
        </div>
      </div>
    </SlidePane>
  );
};
