import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { PRODUCT_GRID, MULTI_TAB_PRODUCT } from 'constants/home';
import { isBrowser } from 'react-device-detect';
// import { path } from 'utils/';
import './index.scss';
import { getProductCategories } from 'utils/product';
import { logEvent } from 'utils/analytics';
import ImageTile from '../ImageTile';
import ProductTupple from '../ProductTupple';

export default ({
  analyticsData = {},
  widgetData = [],
  products = [],
  columnCountMob,
  columnCountTab,
  columnCountDesktop,
  cloumnStyling,
  rowType,
  bottomSpace,
  hasBGColor,
  onWidgetClick = () => {},
  itemTextCenterAlign = false
}) => {
  const [position, setPosition] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);
  const [productGridImpression, setProductGridImpression] = useState(true);
  // const [impressionLog, setImpressionLog] = useState(true);
  const colClassMob = `col-xs-${12 / columnCountMob}`;
  const colClassTab = `col-sm-${12 / columnCountTab} col-md-${12 /
    columnCountTab}`;
  const colClassDesktop = `col-lg-${12 / columnCountDesktop}`;

  const itemList = (rowType === PRODUCT_GRID && products) || widgetData;
  // const colSpacing = Math.min(
  //   (cloumnStyling && cloumnStyling.spacing) || 0,
  //   16
  // );

  const colSpacing = Math.min(
    cloumnStyling &&
      cloumnStyling.spacing !== null &&
      cloumnStyling.spacing >= 0
      ? cloumnStyling.spacing
      : 1,
    16
  );

  const rowStyle = cloumnStyling && {
    marginLeft: colSpacing > -1 && (colSpacing / 2) * -1,
    marginRight: colSpacing > -1 && (colSpacing / 2) * -1,
    marginBottom:
      rowType === PRODUCT_GRID || rowType === MULTI_TAB_PRODUCT
        ? bottomSpace - 8
        : bottomSpace - (colSpacing > -1 ? colSpacing : 0)
  };
  const colSpacingClass = isBrowser ? 'pl-10 pr-10 mb-20' : 'pl-4 pr-4 mb-8';

  const colWrapperClass = `${colClassMob} ${colClassTab} ${colClassDesktop} ${colSpacingClass}`;

  const handelRefProductGrid = ref => {
    if (ref) {
      setOffsetTop(ref.offsetTop);
    }
  };
  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    window.clearTimeout(window.setPositionTimer);
    window.setPositionTimer = setTimeout(() => {
      setPosition(winScroll);
    }, 200);
  };
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, [window]);
  useEffect(() => {
    if (products.length && position > offsetTop && productGridImpression) {
      setProductGridImpression(false);
      const itemsArray = [];
      products.forEach((product, index) => {
        getProductCategories({
          productId: product.id,
          categories: product.categories
        }).then(catData => {
          const obj = {
            id: product.sku,
            name: product.name,
            category: catData,
            brand: product.brand,
            variant: product.color,
            list_position: index,
            quantity: 1
          };
          let objPrice = 0;
          if (product.prices) {
            objPrice = product.prices.specialPrice
              ? product.prices.specialPrice
              : product.prices.price;
          }
          obj.price = objPrice;
          itemsArray.push(obj);
          if (index === products.length - 1) {
            logEvent({
              eventName: 'view_item_list',
              data: {
                items: itemsArray,
                event_category: 'product_impression',
                event_action: 'view_item_list',
                event_label: 'view_item_list'
              },
              screenName: 'home'
            });
          }
        });
      });
    }
  }, [position, products]);
  return (
    // <div className={classnames('row', colSpacing !== 0 ? 'ml-n8 mr-n8' : '')}>
    <div
      ref={handelRefProductGrid}
      className={classnames(
        'row',
        rowType === PRODUCT_GRID || rowType === MULTI_TAB_PRODUCT
          ? 'ml-n2 mr-n2'
          : 'ml-n4 mr-n4'
      )}
      style={rowStyle}
    >
      <Choose>
        <When
          condition={rowType === PRODUCT_GRID || rowType === MULTI_TAB_PRODUCT}
        >
          {(products || []).map((product, index) => (
            <ProductTupple
              analyticsData={analyticsData || {}}
              productIndex={index}
              wrapperClass={colWrapperClass}
              product={product}
              cloumnStyling={cloumnStyling}
              bottomSpace={bottomSpace}
              itemTextCenterAlign={itemTextCenterAlign}
              hasBGColor={hasBGColor}
              caSource="home"
              caSourceType={rowType}
              caSourceValue={widgetData && widgetData[0] && widgetData[0].id}
              caBannerPromoName={analyticsData.promoName}
            />
          ))}
        </When>
        <Otherwise>
          {itemList.map((itemData, itemIndex) => {
            return (
              <ImageTile
                analyticsData={analyticsData || {}}
                wrapperClass={colWrapperClass}
                widgetData={itemData}
                itemIndex={itemIndex}
                cloumnStyling={cloumnStyling}
                bottomSpace={bottomSpace}
                colSpacing={colSpacing}
                onWidgetClick={onWidgetClick}
                itemTextCenterAlign={itemTextCenterAlign}
              />
            );
          })}
        </Otherwise>
      </Choose>
    </div>
  );
};
