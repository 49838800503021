import {
  ACTION_GET_CATEGORIE_DATA,
  ACTION_GET_CATEGORY_FAILURE,
  ACTION_GET_CATEGORY_LOADING
} from './actions';

export const initialState = { isLoading: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_CATEGORIE_DATA: {
      return {
        ...state,
        isLoading: false,
        categoryData: action.data.categories
      };
    }
    case ACTION_GET_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        categoryFailure: true
      };
    }
    case ACTION_GET_CATEGORY_LOADING: {
      return {
        ...state,
        isLoading: true,
        categoryFailure: false
      };
    }
    default:
      return state;
  }
};
