import React from 'react';
import classnames from 'classnames';
import Button from 'components/libs/Buttons';
import ErrorMain from 'assets/icons/server_error.png';
import NoInternet from 'assets/icons/no_internet.png';
import { isBrowser } from 'react-device-detect';
import { translate } from '../../constants/language';

export default ({ onClick = () => { }, restricedDeliveryMessage }) => {
  return (
    <div className="ta-c p-20">
      <img
        className="w-120"
        src={navigator.onLine ? ErrorMain : NoInternet}
        alt="error"
      />
      <div className="fs-16 fw-6 c-main-gray">
        {restricedDeliveryMessage
          ? restricedDeliveryMessage.title
          : `${navigator.onLine
            ? translate('Something is not right')
            : translate('No Internet Connection')
          }`}
      </div>
      <div className="fs-14 c-label-hint mt-5">
        {restricedDeliveryMessage
          ? restricedDeliveryMessage.subtitle
          : `${navigator.onLine
            ? translate('Please try again, while we take a look at it')
            : translate(
              'Please check your internet connection and refresh the page'
            )
          }`}
      </div>
      <Button
        className={classnames("mt-20 fw-6 fs-12 c-white primary br-8", isBrowser ? "pl-25 pr-25" : "full-width")}
        onClick={onClick}
        role="button"
        onKeyPress={onClick}
      >
        {translate('Refresh')}
      </Button>
    </div>
  );
};
