import React from 'react';
import classnames from 'classnames';
import { isBrowser } from 'react-device-detect';

export default function ContainerDesktop({ className = "", isUserPage = false, children, ...props }) {

  return (
    <div className={classnames({ 'container-browser row center-xs': isBrowser }, className)} {...props}>
      <div className={classnames(isBrowser && isUserPage ? 'container-account' : 'col-xs-12', "start-xs")}>{children}</div>
    </div>
  );
}
