import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './index.scss';
import { path, getResizedImg } from 'utils/';
import { BrowserView, isBrowser, isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowRight from 'assets/icons/arrow-right.svg';

import { CURRENT_LANG_PREFIX, BASE_PATH } from '../../../../constants';

export default ({
  widgetData,
  borderBottom,
  bottomSpace,
  onWidgetClick = () => {},
  columnCountDesktop = 6
}) => {
  const doOnClick = (event, slide, widgetIndex) => {
    onWidgetClick({ event, widget: slide, widgetIndex });
  };

  const [slideWidth, setSlideWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setSlideWidth((ref.clientWidth - 100) / columnCountDesktop);
    }
  };

  const [slideScrollleft, setSlideScrollleft] = useState();
  const [sliderAtStart, setSliderAtStart] = useState(true);
  const [sliderAtEnd, setSliderAtEnd] = useState(true);
  const [sliderElm, setSliderElm] = useState();
  // const [sliderWraperElm, setSliderWraperElm] = useState();
  const handelRefSlider = ref => {
    if (ref) {
      setSliderElm(ref);
    }
  };
  const handelRefSlider1 = ref => {
    if (ref && sliderElm) {
      if (sliderElm.clientWidth + sliderElm.scrollLeft === ref.scrollWidth) {
        setSliderAtEnd(true);
      } else {
        setSliderAtEnd(false);
      }
      // setSliderWraperElm(ref);
    }
  };
  const onArrowClick = arrowPos => {
    if (arrowPos === 'left') {
      setSlideScrollleft(sliderElm.scrollLeft - (slideWidth + 20));
    } else if (arrowPos === 'right') {
      setSliderAtStart(false);
      setSlideScrollleft(sliderElm.scrollLeft + slideWidth + 20);
    }
  };

  useEffect(() => {
    if (sliderElm) {
      if (slideScrollleft === 0 || slideScrollleft < 1) {
        setSlideScrollleft(0);
        setSliderAtStart(true);
      }
      sliderElm.scroll({ top: 0, left: slideScrollleft, behavior: 'smooth' });
    }
  }, [slideScrollleft]);

  return (
    <div className="" style={{ marginBottom: bottomSpace }}>
      <Choose>
        <When condition={widgetData.length > 0}>
          <div ref={handelRef} className="image-carousel pos-relt">
            <div
              ref={handelRefSlider}
              className={classnames('cat-slide ta-c overflow-auto-no-bar')}
            >
              <div ref={handelRefSlider1}>
                <If condition={widgetData.length > 0}>
                  {widgetData
                    .filter(slide => path(['imageMedia', [0], 'url'], slide))
                    .map((slide, widgetIndex) => {
                      const imgURL = path(['imageMedia', [0], 'url'], slide);
                      return (
                        <If condition={imgURL}>
                          <a
                            href={`${BASE_PATH}/${CURRENT_LANG_PREFIX}/${slide.link}`}
                            onClick={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                            onKeyPress={event =>
                              doOnClick(event, slide, widgetIndex)
                            }
                            className={classnames(
                              'd-il-block v-t category-tile',
                              isBrowser ? 'mr-20' : 'mr-10 w-100 b-shadow-b-1_5'
                            )}
                            style={{
                              marginBottom: isBrowser
                                ? '24px'
                                : Math.max(bottomSpace, 8),
                              width: isBrowser ? slideWidth : ''
                            }}
                            title={slide.name}
                          >
                            <div
                              className={classnames(
                                'w100p category-carousel-cover bg-thumb-gray',
                                { 'h-100': isMobile }
                              )}
                            >
                              <LazyLoadImage
                                className="w100p d-block"
                                src={getResizedImg({
                                  imgURL,
                                  reqWidth: '100'
                                })}
                                alt={slide.name}
                              />
                            </div>
                          </a>
                        </If>
                      );
                    })}
                </If>
              </div>
              <BrowserView>
                <If condition={!sliderAtStart}>
                  <div className="white-shade pos-abs w-40 bg-white l-0 top-0 bottom-0 mb-20 mt-20 z-1 mb-0" />
                  <div
                    className="pos-abs z-2 l-0 top-0 h100p flex middle-xs ml-25"
                    onClick={() => onArrowClick('left')}
                    onKeyPress={() => onArrowClick('left')}
                    role="button"
                  >
                    <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                      <ArrowRight height="12px" className="rtl-r rot-180" />
                    </div>
                  </div>
                </If>
                <If condition={!sliderAtEnd}>
                  <div className="white-shade pos-abs w-40 bg-white r-0 top-0 bottom-0 mb-20 mt-20 rot-180 z-1 mb-0" />
                  <div
                    className="pos-abs z-2 r-0 top-0 h100p flex middle-xs mr-25"
                    onClick={() => onArrowClick('right')}
                    onKeyPress={() => onArrowClick('right')}
                    role="button"
                  >
                    <div className="h-32 w-32 bg-white br-50p flex middle-xs center-xs">
                      <ArrowRight height="12px" className="rtl-r" />
                    </div>
                  </div>
                </If>
              </BrowserView>
            </div>
          </div>
          <If condition={borderBottom}>
            <hr className="mb-0 mt-0 mr-8 ml-8" />
          </If>
        </When>
      </Choose>
    </div>
  );
};
