import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { translate } from 'constants/language';
import ProfileIconBG from 'assets/icons/profile-icon.svg';
import ProfileIcon from 'assets/icons/my-account-24-px.svg';
import ProfileIcon16 from 'assets/icons/my-account-16-px.svg';
import {
  COUNTRY,
  CURRENT_LANG_PREFIX,
  MY_ORDERS_COUNT_ENABLE
} from 'constants/index';
import OrdersIcon from 'assets/icons/account/orders.svg';
import OrdersIcon16 from 'assets/icons/orders-16-px.svg';
import UnpaidOrdersIcon from 'assets/icons/account/unpaid-order.svg';
import UnpaidOrdersIcon16 from 'assets/icons/unpaid-order-16-px.svg';
import CouponIcon from 'assets/icons/coupon-25-px.svg';
import DeleteIcon from 'assets/icons/account/delete.svg';
import CouponIcon2 from 'assets/icons/coupon.png';
import WalletIcon from 'assets/icons/account/wallet.svg';
// import WalletIcon16 from 'assets/icons/account/wallet-16-px.svg';
import ReturnIcon from 'assets/icons/return-24-px.svg';
import ReturnIcon1 from 'assets/icons/return-1.png';
import { formatPrice } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import AddressIcon from 'assets/icons/address-24-px.svg';
import AddressIcon16 from 'assets/icons/address-16-px.svg';
import CardIcon from 'assets/icons/account/card.svg';
import CardIcon16 from 'assets/icons/card-16-px.svg';
import { useStateValue } from 'context/app';
import Login from 'components/Login';
// import Button from 'components/libs/Buttons';
import ListItem from './ListItem';
import { getStoreCredit, logout } from '../actions';

export default ({ isTopMenu = false }) => {
  const {
    account: [accountState, dispatch],
    shipping: [, shippingDispatch]
  } = useStateValue();

  const { profile: { customer } = {}, storeCredit = 0 } = accountState;
  const { firstName, lastName, email } = customer || {};
  useEffect(() => {
    getStoreCredit({}, dispatch);
  }, [email]);
  const history = useHistory();
  const location = useLocation();
  const goToNext = id => {
    return history.push(`/${CURRENT_LANG_PREFIX}/account/${id}`);
  };
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const showLoginHandler = id => {
    if (!accountState.loggedIn) {
      return setShowLoginDialog(true);
    }
    return goToNext(id);
  };

  const handleLoginLogout = () => {
    if (email) {
      logout({}, dispatch, shippingDispatch);
    } else {
      setShowLoginDialog(true);
    }
  };

  const handleLogout = () => {
    logout({}, dispatch, shippingDispatch);
    history.push(`/${CURRENT_LANG_PREFIX}/`);
  };

  const handleToggleLoginModal = () => {
    setShowLoginDialog(!showLoginDialog);
  };

  useEffect(() => {
    if (accountState.loggedIn) {
      setShowLoginDialog(false);
    }
  }, [accountState.loggedIn]);

  useEffect(() => {
    if (window.sessionStorage && sessionStorage.openLogin === 'true') {
      sessionStorage.removeItem('openLogin');
      handleLoginLogout();
    }
  }, []);

  return (
    <>
      <div
        className={classnames(
          'br-3',
          isTopMenu
            ? 'bg-white p-15 b-shadow-b-2_15 b-box pos-relt z-2'
            : 'bg-thumb-gray p-25 mr-r-46',
          {
            'w-260': isTopMenu && CURRENT_LANG_PREFIX === 'ar',
            'w-200': isTopMenu && CURRENT_LANG_PREFIX === 'en'
          }
        )}
      >
        <Choose>
          <When condition={isTopMenu}>
            <div
              className={classnames('fw-6 txt-overflow-ellipsis fs-14 ls-064')}
            >
              <If condition={!accountState.loggedIn}>
                <div
                  role="button"
                  onClick={handleLoginLogout}
                  onKeyPress={handleLoginLogout}
                  id="profile"
                >
                  {translate('Log In / Sign up')}
                </div>
              </If>
              <If condition={accountState.loggedIn}>
                {firstName
                  ? translate(`Hello, {firstName}`).replace(
                      '{firstName}',
                      `${firstName} ${lastName}`
                    )
                  : translate('Hello There')}
              </If>
            </div>
          </When>
          <Otherwise>
            <div className="flex middle-xs z-1">
              <div>
                <ProfileIconBG width="48px" height="48px" className="d-block" />
              </div>
              <div
                className={classnames(
                  'fw-6 txt-overflow-ellipsis pl-16 pr-16 pt-5 fs-16'
                )}
              >
                {firstName
                  ? translate(`Hello, {firstName}`).replace(
                      '{firstName}',
                      `${firstName} ${lastName}`
                    )
                  : translate('Hello There')}
              </div>
            </div>
          </Otherwise>
        </Choose>
        <hr
          className={classnames(
            'bb-white-two',
            isTopMenu ? 'mt-16 mb-8' : 'mb-12 mt-24'
          )}
        />
        {/* <If condition={!email}>
          <div className="inner bg-white pl-15 pr-15 pt-16 pb-16 mt-4">
            <Button
              className="b-dark-gray d-block w100p bg-white fs-15 fw-6 cap c-dark-gray"
              id="loginOrSignup"
            // onClick={handleLoginLogout}
            >
              {translate('Log In / Sign up')}
            </Button>
            <div className="fs-10 lh-20 ls-032 pr-8 pl-8 c-soft-blue bg-soft-Blue-15 mt-8 txt-overflow-ellipsis ta-c">
              {translate(
                'Login to unlock your offers, coupons, credits, order history & more…'
              )}
            </div>
          </div>
        </If> */}
        <div className="">
          <div className="flex middle-xs">
            <Choose>
              <When condition={isTopMenu}>
                <div>
                  <ProfileIcon16 width="16px" height="16px" />
                </div>
              </When>
              <Otherwise>
                <ProfileIcon className="w-24" />
              </Otherwise>
            </Choose>
            <ListItem
              id=""
              title="My Account"
              onClick={() => showLoginHandler('')}
              noBorder={true}
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>
          <div className="flex middle-xs">
            <Choose>
              <When condition={isTopMenu}>
                <div>
                  <AddressIcon16 width="16px" height="16px" />
                </div>
              </When>
              <Otherwise>
                <AddressIcon className="w-24" />
              </Otherwise>
            </Choose>
            <ListItem
              id="addresses"
              title="My Addresses"
              onClick={() => showLoginHandler('addresses')}
              noBorder={true}
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>
          <If condition={COUNTRY.toLowerCase() !== 'in'}>
            <div className="flex middle-xs">
              <Choose>
                <When condition={isTopMenu}>
                  <div>
                    <CardIcon16 width="16px" height="16px" />
                  </div>
                </When>
                <Otherwise>
                  <CardIcon className="w-24" />
                </Otherwise>
              </Choose>
              <ListItem
                id="savedcards"
                title="My Saved Cards"
                onClick={() => showLoginHandler('savedcards')}
                noBorder={true}
                rightAngle={false}
                isTopMenu={isTopMenu}
              />
            </div>
          </If>
          <If condition={!isTopMenu}>
            <hr className="mb-12 mt-12 bb-white-two" />
          </If>
          <If condition={!!email}>
            <div className="flex middle-xs">
              <Choose>
                <When condition={isTopMenu}>
                  <div>
                    <UnpaidOrdersIcon16 width="16px" height="16px" />
                  </div>
                </When>
                <Otherwise>
                  <UnpaidOrdersIcon className="w-24" />
                </Otherwise>
              </Choose>
              <ListItem
                id="unpaidorders"
                title="Unpaid Orders"
                onClick={() => showLoginHandler('unpaidOrders')}
                noBorder={true}
                rightAngle={false}
                isTopMenu={isTopMenu}
              />
            </div>
          </If>
          <div className="flex middle-xs">
            <Choose>
              <When condition={isTopMenu}>
                <div>
                  <OrdersIcon16 width="16px" height="16px" />
                </div>
              </When>
              <Otherwise>
                <OrdersIcon className="w-24" />
              </Otherwise>
            </Choose>
            <ListItem
              id="allorders"
              title="All Orders"
              onClick={() => showLoginHandler('orders')}
              noBorder={true}
              isDisable={!email}
              badgeValue={
                accountState.orderCount > 0 &&
                MY_ORDERS_COUNT_ENABLE &&
                accountState.orderCount
              }
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>
          <div className="flex middle-xs">
            <Choose>
              <When condition={isTopMenu}>
                <div>
                  <img src={ReturnIcon1} alt="" width="16px" height="16px" />
                </div>
              </When>
              <Otherwise>
                <ReturnIcon style={{ width: '28px', marginleft: '-2px' }} />
              </Otherwise>
            </Choose>
            <ListItem
              id="returnOrders"
              title="Returns"
              onClick={() => showLoginHandler('returnOrders')}
              noBorder={true}
              isDisable={!email}
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>

          <div className="flex middle-xs">
            <Choose>
              <When condition={isTopMenu}>
                <div>
                  <WalletIcon width="16px" height="16px" />
                </div>
              </When>
              <Otherwise>
                <WalletIcon className="w-24" />
              </Otherwise>
            </Choose>
            <ListItem
              id="credits"
              title="My Credits"
              caption={
                <div className="fs-10 lh-12 ls-107 fw-6 en-font ls-096 ml-10 mr-10 bg-soft-Blue br-20 pl-8 pr-8 pt-5 pb-4">
                  {formatPrice(storeCredit, null, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </div>
              }
              onClick={() => showLoginHandler('credits')}
              noBorder={true}
              isDisable={!email}
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>
          <div className="flex middle-xs">
            <div>
              <Choose>
                <When condition={isTopMenu}>
                  <img src={CouponIcon2} alt="" width="16px" height="16px" />
                </When>
                <Otherwise>
                  <CouponIcon className="w-24" />
                </Otherwise>
              </Choose>
            </div>
            <ListItem
              id="coupons"
              title="Coupons"
              onClick={() => showLoginHandler('coupons')}
              noBorder={true}
              isDisable={!email}
              rightAngle={false}
              isTopMenu={isTopMenu}
            />
          </div>
          <If condition={location.pathname.includes('account')}>
            <div className="flex middle-xs">
              <DeleteIcon className="w-20" />
              <ListItem
                id="deleteAccount"
                title={translate('Delete Account')}
                onClick={() => showLoginHandler('deleteAccount/confirmPage')}
                noBorder={true}
                isDisable={!email}
                rightAngle={false}
                isTopMenu={isTopMenu}
              />
            </div>
          </If>
          <If condition={email && isTopMenu}>
            <hr className="bb-white-two mt-8 mb-16" />
            <div
              className="fs-14 lh-18"
              role="button"
              onClick={handleLogout}
              onKeyPress={handleLogout}
            >
              {translate('Sign Out')}
            </div>
          </If>
        </div>
      </div>
      <If condition={showLoginDialog}>
        <Login
          showLoginDialog={showLoginDialog}
          noBorder={true}
          onBack={handleToggleLoginModal}
          isStandaloneLogin={true}
          eventAction="profile"
          screenName="Accounts"
        />
      </If>
    </>
  );
};
