import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { logEvent } from 'utils/analytics';
import entries from 'object.entries';
import TabsVertical from 'components/libs/TabsVertical';

import WidgetWrap from 'containers/Home/views/WidgetWrap/index';

import './index.scss';
import { isBrowser } from 'react-device-detect';
import { translate } from 'constants/language';
import logError from 'utils/logError';

export default ({
  data = {},
  isLoading = true,
  closeCategoryPane = () => {},
  activeCategoryIndex = -1,
  activeTab = 0,
  categoryPage = [],
  cateTabs = [],
  categoriesData = [],
  pageTypes = []
}) => {
  const {
    tabs = [],
    topSpacing = 4,
    horizontalSpacing = 8,
    bottomSpacing = 8
  } = data;

  const [activeTabIndex, setActiveTabIndex] = useState(activeTab);
  const [appliedTabIndex, setAppliedTabIndex] = useState(activeTab);
  useEffect(() => {
    setActiveTabIndex(activeTab);
    setAppliedTabIndex(activeTab);
  }, [activeTab]);
  const [activeIndex, setActiveIndex] = useState(activeCategoryIndex >= 0 || 0);
  const [rows, setRows] = useState([]);
  const [contenerRef, setContenerRef] = useState();
  const setCTabIndex = index => {
    window.scrollTo(0, 0);
    contenerRef.scrollTo(0, 0);
    if (isBrowser && categoriesData) {
      setAppliedTabIndex(activeTabIndex);
      setRows(categoriesData[pageTypes[activeTabIndex]].tabs[index].rows);
    } else {
      setRows(tabs[index].rows);
    }
    setActiveIndex(index);
  };

  useEffect(() => {
    if (activeCategoryIndex >= 0) setCTabIndex(activeCategoryIndex);
  }, [activeCategoryIndex]);

  useEffect(() => {
    if (activeCategoryIndex === -1 && tabs.length) {
      setActiveIndex(0);
      setRows(tabs[0].rows);
    }
  }, [tabs]);

  const [promotionData, updatePromotionData] = useState([]);
  const [position, setPosition] = useState(0);
  const [widgetRefs, setWidgetRefs] = useState({});

  const logPromotionView = promoToSend => {
    const promoBatchData = [];
    promoToSend.forEach(promoIndex => {
      if (!promotionData[promoIndex].sent) {
        promoBatchData.push(promoIndex);
      }
    });

    if (promoBatchData.length) {
      const finalPromotionArr = [];
      const productListArr = [];

      updatePromotionData(
        promotionData.map((promo, index) => {
          if (promoBatchData.indexOf(index) !== -1) {
            if (promo.widgets && promo.widgets.length) {
              promo.widgets.forEach((widget, widgetIndex) => {
                if (widget.link) {
                  finalPromotionArr.push({
                    id: widget.id,
                    name: promo.name,
                    creative_name: widget.name,
                    creative_slot: widgetIndex
                  });
                }
              });
            } else if (promo.products && promo.products.length) {
              promo.products.forEach((product, productIndex) => {
                productListArr.push({
                  id: product.sku,
                  name: product.name,
                  list_name: promo.name,
                  brand: product.brand,
                  variant: product.color,
                  list_position: productIndex,
                  quantity: 1,
                  price: product.prices.specialPrice
                    ? product.prices.specialPrice
                    : product.prices.price
                });
              });
            }
          }
          return {
            ...promo,
            sent: promo.sent ? true : promoBatchData.indexOf(index) !== -1
          };
        })
      );

      if (finalPromotionArr.length) {
        logEvent({
          eventName: 'view_promotion',
          data: {
            promotions: finalPromotionArr,
            event_category: 'promosion_impressions',
            event_action: 'view_item',
            event_label: 'view_item'
          },
          screenName: 'Category_Listing'
        });
      } else if (productListArr.length) {
        logEvent({
          eventName: 'view_item_list',
          data: {
            items: productListArr,
            event_category: 'product_impression',
            event_action: 'view_item_list',
            event_label: 'view_item_list'
          },
          screenName: 'Category_Listing'
        });
      }
    }
  };

  useEffect(() => {
    if (rows && rows.length) {
      updatePromotionData(
        rows.map(row => {
          // console.log('row', row);
          return {
            id: row.analytics._id,
            name: row.analytics.promoName,
            widgets: row.widgets,
            products: row.products,
            sent: false
          };
        })
      );
    }
  }, [rows]);
  const handleWidgetRef = (ref, index) => {
    try {
      const wRef = widgetRefs;
      wRef[index] = ref;
      setWidgetRefs(wRef);
      window.clearTimeout(window.setPositionTimer);
      window.setPositionTimer = setTimeout(() => {
        if (wRef[rows.length - 1] && position === 0) {
          setPosition(window.screen.height);
        }
      }, 200);
    } catch (e) {
      logError(e);
    }
  };
  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const scrolled = winScroll + window.screen.height;
    window.clearTimeout(window.setPositionTimer1);
    window.setPositionTimer1 = setTimeout(() => {
      setPosition(scrolled);
    }, 200);
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, [window]);

  useEffect(() => {
    const widgetArray = entries(widgetRefs);

    const promoToSendArray = promotionData
      .map((promo, index) => {
        return index;
      })
      .filter(promoIndex => {
        return (
          widgetArray[promoIndex][1] &&
          widgetArray[promoIndex][1].offsetTop < position
        );
      });

    logPromotionView(promoToSendArray);
  }, [position, widgetRefs]);
  const [wraperHeight, setWraperHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentScrolled, setContentScrolled] = useState(false);
  const scrollListner = event => {
    const { scrollTop } = event.target;
    if (contentHeight - wraperHeight > 30) {
      setContentScrolled(scrollTop > 30);
    } else {
      setContentScrolled(scrollTop === contentHeight - wraperHeight);
    }
  };
  const handelRef = ref => {
    if (ref) {
      setContenerRef(ref);
      setWraperHeight(ref.clientHeight);
    }
  };
  const handelRefChild = ref => {
    if (ref) {
      setContentHeight(ref.clientHeight);
    }
  };
  return (
    <div
      className={classnames('', isBrowser ? '' : 'pb-55 mt-85')}
      style={{ paddingTop: isBrowser ? '' : topSpacing }}
    >
      <Choose>
        <When condition={tabs.length}>
          <TabsVertical
            tabs={tabs}
            titleKey="displayName"
            setCTabIndex={setCTabIndex}
            activeIndex={activeIndex}
            appliedTabIndex={appliedTabIndex}
            closeCategoryPane={closeCategoryPane}
            wrapperClass={classnames(
              'pos-fix top-0 bottom-0 left-0',
              isBrowser
                ? 'w-245 pl-56 pr-56 bg-white b-box'
                : 'mt-85 bg-light-gray w-90'
            )}
            setActiveTab={setActiveTabIndex}
            activeTab={activeTabIndex}
            categoryPage={categoryPage}
            cateTabs={cateTabs}
            categoriesData={categoriesData}
            pageTypes={pageTypes}
          />
          <div
            className={classnames(
              'pos-relt',
              isBrowser ? 'bg-light-gray w-425 ml-r-245 ' : 'ml-r-100'
            )}
          >
            <div
              ref={handelRef}
              onScroll={scrollListner}
              className={classnames(
                isBrowser ? 'h100vh overflow-auto b-box' : ''
              )}
            >
              <div
                ref={handelRefChild}
                className={classnames(isBrowser ? 'pt-56' : '')}
              >
                <Choose>
                  <When condition={!isLoading}>
                    {rows.map((rowData, index) => {
                      // console.log(('rowData : ', rowData));
                      const {
                        rowType,
                        rowTitle,
                        widgets = [],
                        products = [],
                        viewMore,
                        analytics,
                        bottomSeparator,
                        layoutCount: { mobile = 1, tab = 1, desktop = 1 } = {},
                        cloumnStyling = {},
                        SaleTimer,
                        selectedTabBackgroundColor,
                        selectedTabBorderColor,
                        selectedTabTextColor
                      } = rowData;
                      return (
                        <>
                          <WidgetWrap
                            widgetRef={ref => handleWidgetRef(ref, index)}
                            rowType={rowType
                              .replace('Landing_', '')
                              .replace('Timer_', '')}
                            rowTitle={rowTitle}
                            iWidgetData={widgets}
                            products={products}
                            viewMore={viewMore}
                            analytics={analytics}
                            borderBottom={bottomSeparator}
                            columnCountMob={mobile}
                            columnCountTab={tab}
                            columnCountDesktop={desktop}
                            cloumnStyling={cloumnStyling}
                            horizontalSpacing={horizontalSpacing}
                            bottomSpacing={bottomSpacing}
                            titleCenterAlign={false}
                            itemTextCenterAlign={true}
                            saleTimer={SaleTimer}
                            selectedTabBackgroundColor={
                              selectedTabBackgroundColor
                            }
                            selectedTabBorderColor={selectedTabBorderColor}
                            selectedTabTextColor={selectedTabTextColor}
                          />
                        </>
                      );
                    })}
                  </When>
                  <Otherwise>
                    <div className="row">
                      <div className="col-xs-12 ">
                        <div className="shine h-27">&nbsp;</div>
                      </div>
                    </div>
                    <div className="row  mt-5">
                      <div className="col-xs-12 ">
                        <div className="shine h-375 ml-10 mr-10">&nbsp;</div>
                      </div>
                    </div>
                    <div className="row mt-5  shine flex center-xs">
                      <div className="w-10 bg-white h-27" />
                    </div>
                    <div className="row   mt-5">
                      <div className="col-xs-12 ">
                        <div className="shine h-375 ml-10 mr-10">&nbsp;</div>
                      </div>
                    </div>
                  </Otherwise>
                </Choose>
              </div>
            </div>
            <If condition={contentHeight > wraperHeight && !contentScrolled}>
              <div className="pos-abs l-0 r-0 bottom-0 flex center-xs white-shade-v">
                <div className="bg-white-o-065 fs-14 fw-6 lh-20 ls-096 pt-8 pb-8 mb-16 mt-16 pl-12 pr-12 br-20">
                  {translate('Scroll Down')}
                </div>
              </div>
            </If>
          </div>
        </When>
        <Otherwise>
          <div className="pos-fix top-0 bottom-0 left-0 mt-85 bg-light-gray w-90" />
        </Otherwise>
      </Choose>
    </div>
  );
};
