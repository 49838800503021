import * as Sentry from '@sentry/browser';
import { handleJwterror } from 'utils/';

export default (...e) => {
  const { statusCode, statusMsg } = e?.[0]?.response?.data || {};
  handleJwterror(statusCode, statusMsg);
  if (process.env.NODE_ENV !== 'development') {
    if (
      e &&
      e[0] &&
      e[0].message &&
      e[0].message.indexOf('Request failed') < 0 &&
      e[0].message.indexOf('Network') < 0 &&
      e[0].message.indexOf('404') < 0
    ) {
      try {
        if (e[0].message.indexOf('Maximum update depth exceeded')) {
          if (window.sessionStorage.maxUpdateDepthExceedCaptured) {
            return false;
          }
          window.sessionStorage.maxUpdateDepthExceedCaptured = 1;
        }
      } catch (maxDepthError) {
        console.error(maxDepthError);
      }
      Sentry.captureException(e);
      // if (window.newrelic) {
      //   window.newrelic.noticeError(e);
      // }
    }
  } else {
    console.error(...e, e.message); //eslint-disable-line
    if (
      e &&
      e[0] &&
      e[0].message &&
      (e[0].message.indexOf('Request failed') < 0 ||
        e[0].message.indexOf('Network') < 0 ||
        e[0].message.indexOf('404') < 0)
    ) {
      // throw new Error(e[0].message);
    }
  }
  return false;
};
