import React, { createContext, useContext, useReducer } from 'react';
// import reducer from '../reducers/data';
import PaymentReducer, { initialState } from 'containers/Payment/reducer';
import ShippingReducer, {
  initialState as shippingInitialState
} from 'containers/Checkout/reducer';
import ConfirmationReducer, {
  initialState as confirmationInitialState
} from 'containers/Confirmation/reducer';
import ProductListReducer, {
  initialState as productListInitialState
} from 'containers/ProductList/reducer';
import HomeReducer, {
  initialState as homeInitialState
} from 'containers/Home/reducer';
import CategoriesReducer, {
  initialState as CategoriesInitialState
} from 'containers/Categories/reducer';
import LandingReducer, {
  initialState as LandingInitialState
} from 'containers/Landing/reducer';
import StaticPageReducer, {
  initialState as StaticPageInitialState
} from 'containers/StaticPages/reducer';
import AccountReducer, {
  initialState as AccountInitialState
} from 'containers/Account/reducer';
import ProductDetailReducer, {
  initialState as ProductDetailInitialState
} from 'containers/ProductDetail/reducer';

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        shipping: useReducer(ShippingReducer, shippingInitialState),
        payment: useReducer(PaymentReducer, initialState),
        confirmation: useReducer(ConfirmationReducer, confirmationInitialState),
        products: useReducer(ProductListReducer, productListInitialState),
        home: useReducer(HomeReducer, homeInitialState),
        categories: useReducer(CategoriesReducer, CategoriesInitialState),
        landing: useReducer(LandingReducer, LandingInitialState),
        staticPage: useReducer(StaticPageReducer, StaticPageInitialState),
        account: useReducer(AccountReducer, AccountInitialState),
        productDetail: useReducer(
          ProductDetailReducer,
          ProductDetailInitialState
        )
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useStateValue = () => useContext(AppContext);
