import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import './index.scss';
import { Index, Configure } from 'react-instantsearch-dom';
import { logEvent } from 'utils/analytics';
import { useHistory } from 'react-router-dom';
import urlParse from 'url-parse';
import { translate } from 'constants/language';
import { resetProductDetails } from 'containers/ProductDetail/actions';
import { useStateValue } from 'context/app';
import { CURRENT_LANG_PREFIX, COUNTRY } from '../../../constants';
// import { translate } from 'constants/language';

export default function({
  indexName,
  hits,
  dataType,
  onBackClick,
  currentRefinement,
  limit = 5
}) {
  const history = useHistory();
  const [results, setResults] = useState(hits);
  const [suggestions, setSuggestions] = useState([]);
  const {
    productDetail: [, dispatch]
  } = useStateValue();

  const handleCategoryClick = ({ url, query }, reqType) => {
    const { pathname: currentUrl } = urlParse(window.location.href, true);
    const lang = CURRENT_LANG_PREFIX;
    const otherLang = lang === 'ar' ? 'en' : 'ar';
    onBackClick();
    resetProductDetails({}, dispatch);

    if (query) {
      history.push(`/${lang}/catalogsearch/result?q=${query}`);
    } else if (reqType === 'category') {
      let { pathname } = urlParse(url);
      if (pathname.indexOf(`/${otherLang}/`) === 0) {
        pathname = pathname.replace(`/${otherLang}/`, `/${lang}/`);
      }
      history.push(pathname);
    } else if (url) {
      let { pathname } = urlParse(url);
      if (pathname.indexOf(`/${otherLang}/`) === 0) {
        pathname = pathname.replace(`/${otherLang}/`, `/${lang}/`);
      }
      if (pathname.indexOf(`/${COUNTRY}/${lang}/`) > -1) {
        if (
          currentUrl.indexOf('list') > -1 ||
          currentUrl.indexOf('catalogsearch') > -1
        ) {
          pathname = pathname.replace(`/${COUNTRY}/`, `/`);
        } else {
          pathname = pathname.replace(`/${COUNTRY}/${lang}/`, ``);
        }
      }
      if (currentUrl.indexOf(`/${lang}/`) < 0 && COUNTRY !== 'sa') {
        pathname = `${lang}/${pathname}`;
      }
      logEvent({
        eventName: 'search',
        data: {
          searchTerm: currentRefinement
        },
        skipGtm: true,
        screenName: 'Search_Listing'
      });
      history.push(pathname);
    }
  };
  useEffect(() => {
    setResults(hits);
    if (isEmpty(suggestions)) {
      setSuggestions(hits);
    }
  }, [hits]);
  return (
    <Index indexName={indexName}>
      <Configure hitsPerPage={limit} />
      <If condition={currentRefinement.length}>
        {results.map(hitData => {
          return (
            <>
              <If
                condition={
                  hitData.index === indexName && dataType !== 'suggestions'
                }
              >
                <ul className="srch-list m-0 p-0">
                  {hitData.hits.map(hit => {
                    return (
                      <li
                        key={hit.objectID}
                        className="bb-light pl-10 pr-10 pt-5 pb-5 block"
                      >
                        <div className="flex middle-xs td-none">
                          <a
                            role="link"
                            onClick={() => handleCategoryClick(hit, dataType)}
                            onKeyPress={() => {
                              handleCategoryClick(hit, dataType);
                            }}
                            className="w100p-m25"
                          >
                            <div className="fs-12 fw-6 c-brand txt-ovewrflow-ellipsis">
                              {/* <Highlight attribute="name" hit={hit} /> */}
                              {hit.name}
                            </div>
                            <div className="fs-10 c-label-hint txt-ovewrflow-ellipsis">
                              {hit.path ||
                                (
                                  hit.categories_without_path || []
                                ).map(category => <> {category}/</>)}
                            </div>
                          </a>
                          {/* <a
                            role="link"
                            onClick={() => refine(hit.name)}
                            onKeyPress={() => refine(hit.name)}
                            className="end-xs w-25 fs-14 c-black-light"
                          ></a> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </If>
            </>
          );
        })}
      </If>

      <If condition={dataType === 'suggestions'}>
        <div className="pl-10 pr-10 pt-15 pb-5">
          {translate('Trending Searches')}
        </div>
        <ul className="srch-list m-0 p-0">
          {suggestions.map(hit => {
            return (
              <li
                key={hit.objectID}
                className="bb-light pl-10 pr-10 pt-5 pb-5 block"
              >
                <div className="flex middle-xs td-none">
                  <a
                    role="link"
                    onClick={() => handleCategoryClick(hit)}
                    onKeyPress={() => handleCategoryClick(hit)}
                    className="w100p-m25"
                  >
                    <div className="fs-12 fw-6 c-brand txt-ovewrflow-ellipsis">
                      {hit.name}
                    </div>
                  </a>
                  {/* <a
                    role="link"
                    onClick={() => refine(hit.name)}
                    onKeyPress={() => refine(hit.name)}
                    className="end-xs w-25 fs-14 c-black-light"
                  ></a> */}
                </div>
              </li>
            );
          })}
        </ul>
      </If>
    </Index>
  );
}
