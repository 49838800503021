import React from 'react';
import classnames from 'classnames';
import ImgList from 'components/libs/ImgList';
import { translate } from 'constants/language';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import AppleIcon from 'assets/icons/store-badge/apple.png';
import GoogleIcon from 'assets/icons/store-badge/google.png';
import AppleIconAR from 'assets/icons/store-badge/apple-ar.png';
import GoogleIconAR from 'assets/icons/store-badge/google-ar.png';
import FBIcon from 'assets/icons/social-media/fb.png';
import YTIcon from 'assets/icons/social-media/youtube.png';
import TTIcon from 'assets/icons/social-media/tik-tok.png';
import InstaIcon from 'assets/icons/social-media/insta.png';
import { COUNTRY, IS_ARABIC } from 'constants/index';
import FooterMenus from './FooterMenus';
import FooterTitle from './FooterTitle';
import WeAccept from './WeAccept';

export default function({ icon, label, value, className, ...props }) {
  const menusShopping = [
    {
      title: {
        en: 'Women',
        ar: 'النساء'
      },
      link: 'list/women'
    },
    {
      title: {
        en: 'Men',
        ar: 'الرجال'
      },
      link: 'list/men'
    }
  ];
  if (['sa', 'ae'].includes(COUNTRY)) {
    menusShopping.push({
      title: {
        en: 'Kids',
        ar: 'أطفال'
      },
      link: 'list/kids'
    });
  }
  if (COUNTRY === 'sa') {
    menusShopping.push({
      title: {
        en: 'Beauty',
        ar: 'الجمال'
      },
      link: 'list/beauty'
    });
  }
  const menusHelp = [
    // {
    //   title: {
    //     en: 'FAQs',
    //     ar: 'الأسئلة الشائعة'
    //   },
    //   link: 'account/faq'
    // },
    {
      title: {
        en: 'Help Center',
        ar: 'مركز المساعدة'
      },
      link: 'account/faq'
    },
    {
      title: {
        en: 'Privacy Policy',
        ar: 'سياسة الخصوصية'
      },
      link: 'account/privacy'
    },
    {
      title: {
        en: 'Terms of Use',
        ar: 'شروط الاستخدام'
      },
      link: 'account/terms'
    }
  ];

  const menusAbout = [];

  const storeBadges = [
    {
      src: IS_ARABIC ? AppleIconAR : AppleIcon,
      href: 'https://apps.apple.com/us/app/styli/id1469688029'
    },
    {
      src: IS_ARABIC ? GoogleIconAR : GoogleIcon,
      href: 'https://play.google.com/store/apps/details?id=com.stylishop.styli'
    }
  ];
  const socialIcons = [
    {
      src: FBIcon,
      href: 'https://www.facebook.com/StyliOfficial/'
    },
    {
      src: YTIcon,
      href: 'https://www.youtube.com/channel/UC9Loux3ZWLtWBR_ehCU8RGg'
    },
    {
      src: TTIcon,
      href: 'https://vm.tiktok.com/ZSJSGb3v9'
    },
    {
      src: InstaIcon,
      href: 'https://www.instagram.com/styli_official/'
    }
  ];
  return (
    <div
      className={classnames('bg-light-gray pt-40 pb-40', className)}
      {...props}
    >
      <ContainerDesktop>
        <div className="">
          <div className="row">
            <div className="col-xs-3">
              <FooterTitle>{translate('Shopping')}</FooterTitle>
              <FooterMenus items={menusShopping} />
            </div>
            <div className="col-xs-3">
              <FooterTitle>{translate('Help')}</FooterTitle>
              <FooterMenus items={menusHelp} />
            </div>
            <If condition={menusAbout.length > 0}>
              <div className="col-xs-2">
                <FooterTitle>{translate('About')}</FooterTitle>
                <FooterMenus items={menusAbout} />
              </div>
            </If>
            <div className="col-xs-4">
              <FooterTitle>{translate('Download our apps')}</FooterTitle>
              <ImgList
                className="mb-32"
                items={storeBadges}
                itemHeight="48px"
              />
              <WeAccept />
            </div>
            <div className="col-xs-2">
              <FooterTitle>{translate('Keep in Touch')}</FooterTitle>
              <ImgList items={socialIcons} itemHeight="24px" />
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
