import firebase from '@firebase/app';
import analytics from '@firebase/analytics';
import { formatCheckoutData } from 'utils/cart';

import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import logError from './logError';
import { getCustomerId } from './user';
import {
  CURRENT_LANG_PREFIX,
  COUNTRY,
  COUNTRIES_SHORT_NAMES,
  COUNTRIES,
  DEFAULT_CURRENCY,
  IS_EVENT_LOGGING
} from '../constants';
import { getStoreId, initializeFirebase } from '.';
import { logBrazeEvent } from './braze';
import { logBranchEvent } from './branch';
import { cleverTapProcess } from './cleverTap';

export const logUIEvent = ({ eventName, data = {} }) => {
  try {
    if (false) {
      console.log(analytics);

      firebase.analytics().logEvent(eventName, {
        ...data,
        language: CURRENT_LANG_PREFIX,
        platform: 'msite'
      });

      initializeFirebase();
      firebase.analytics().logEvent(eventName, {
        ...data,
        language: CURRENT_LANG_PREFIX,
        platform: 'msite'
      });
    }
  } catch (e) {
    logError(e);
  }
};

export const logNavigationEvent = ({ data = {} }) => {
  try {
    // firebase.analytics().setCurrentScreen(screenName, {
    //   ...data,
    //   language: CURRENT_LANG_PREFIX,
    //   platform: 'msite'
    // });
    // console.log('in logNavigationEvent event');
    // initializeFirebase();
    // firebase.analytics().setCurrentScreen(screenName, {
    //   ...data,
    //   language: CURRENT_LANG_PREFIX,
    //   platform: 'msite'
    // });
    if (false) {
      window.gtag('event', 'timing_complete', {
        event_category: 'page_load',
        event_label: 'first_paint',
        ...data
        // 'parameter1': <value>
        // 'parameter2': <value>
      });
    }
  } catch (e) {
    logError(e);
  }
};

export async function logPageview({ screenName = '' } = {}) {
  try {
    // if (window.gtag && window.fbq) {
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname,
      screen_name: screenName
    });
    // }
  } catch (e) {
    logError(e);
  }
}

export function generateUUID() {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return template.replace(/[xy]/g, c => {
    /* eslint-disable no-bitwise */
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    /* eslint-enable no-bitwise */
    return v.toString(16);
  });
}

export async function logEvent({
  eventName,
  data = {},
  additionalData = {},
  skipGtm = false,
  cleverTapData = {},
  isOnlyCleverTap = false,
  screenName = ''
}) {
  try {
    if (IS_EVENT_LOGGING) {
      const customerId = getCustomerId();
      const uuid = generateUUID();
      const userID = getCustomerId();
      if (window.gtag && !skipGtm) {
        const eventData = {
          language: CURRENT_LANG_PREFIX,
          country: COUNTRY,
          logged_in: !!customerId,
          platform_type: isMobile ? 'mobile_web' : 'desktop',
          store_id: getStoreId(),
          timestamp: new Date().getTime(),
          currency: DEFAULT_CURRENCY,
          screen_name: screenName,
          ...data,
          deduplication_id: uuid
        };
        if (
          userID &&
          ((typeof userID === 'number' && userID > 0) ||
            (typeof userID === 'string' && userID.length))
        ) {
          eventData.user_id = userID;
        }
        window.gtag('event', eventName, eventData);
        console.log('gtag Event Log:: Name:', eventName, ' Data:', eventData);
      }

      logBrazeEvent({
        name: eventName,
        data: {
          language: CURRENT_LANG_PREFIX,
          user_id: customerId,
          currency: DEFAULT_CURRENCY,
          country: COUNTRIES_SHORT_NAMES[COUNTRIES.indexOf(COUNTRY)],
          logged_in: !!customerId,
          ...data
        },
        additionalData
      });
      logBranchEvent({
        name: eventName,
        data: {
          language: CURRENT_LANG_PREFIX,
          user_id: customerId,
          currency: DEFAULT_CURRENCY,
          country: COUNTRIES_SHORT_NAMES[COUNTRIES.indexOf(COUNTRY)],
          logged_in: !!customerId,
          ...data
        },
        additionalData
      });
      if (
        !isEmpty(cleverTapData) ||
        eventName === 'login' ||
        eventName === 'sign_up_form_success'
      ) {
        cleverTapProcess({
          eventName,
          additionalData,
          cleverTapData,
          isOnlyCleverTap,
          data
        });
      }
    }
  } catch (e) {
    logError(e);
  }
}

export async function logCheckoutProgress({ quote, paymentCode }) {
  setTimeout(() => {
    if (window.fbq) {
      try {
        let checkoutOption;
        switch (paymentCode) {
          case 'cashondelivery':
            checkoutOption = 'COD';
            break;
          case 'free':
            checkoutOption = 'Wallet';
            break;
          case 'md_payfort_cc_vault':
            checkoutOption = 'Card';
            break;
          default:
        }
        const beginCheckoutData = {
          event_category: 'checkout',
          checkout_step: 2,
          checkout_option: checkoutOption,
          ...formatCheckoutData({ quote })
        };
        logEvent({
          eventName: 'checkout_progress',
          data: beginCheckoutData,
          screenName: 'Checkout_Payment'
        });
      } catch (e) {
        logError(e);
      }
    }
  }, 2000);
}

export function timingFirstPaint() {
  if (false) {
    try {
      if (window.gtag) {
        document.addEventListener('readystatechange', event => {
          if (event.target.readyState === 'complete') {
            const firstPaint = performance.getEntriesByName(
              'first-contentful-paint'
            );
            if (firstPaint.length) {
              window.gtag('event', 'timing_complete', {
                name: 'load',
                event_category: 'page_load',
                event_label: 'first_paint',
                language: CURRENT_LANG_PREFIX,
                value: firstPaint[0].startTime
              });
            }
          }
        });
      }
    } catch (e) {
      logError(e);
    }
  }
}

export function timingVisualComplete(timeDiff) {
  if (false) {
    try {
      if (window.gtag) {
        window.gtag('event', 'timing_complete', {
          name: 'load',
          event_category: 'page_load',
          event_label: 'visual_complete',
          language: CURRENT_LANG_PREFIX,

          value: timeDiff
        });
      }
    } catch (e) {
      logError(e);
    }
  }
}

export const setUser = ({ customerId }) => {
  try {
    if (false) {
      firebase.analytics().setUserId(customerId);
      firebase.analytics().setUserProperties({
        // email,
        store: getStoreId(),
        language: CURRENT_LANG_PREFIX
      });
    }
  } catch (e) {
    logError(e);
  }
};
