import React from 'react';
import ContainerDesktop from 'components/libs/ContainerDesktop';
import { translate } from 'constants/language';
import TrustBadge from 'assets/icons/trust-badge.svg';
import LogoMix from 'assets/logo.svg';
import LogoEN from 'assets/logo-en.svg';
import { isRTL } from 'constants/index';
import { getNavigationBasePath } from 'utils/';
import { COUNTRY } from '../../constants';

const Logo = COUNTRY.toLowerCase() === 'in' ? LogoEN : LogoMix;

export default function() {
  return (
    <div className="bg-light-gray">
      <ContainerDesktop isUserPage={true}>
        <div className="row flex middle-xs pt-15 pb-15">
          <div className="col-xs-4">
            <div className="ml-0 mr-auto">
              <a
                href={`${getNavigationBasePath()}/`}
                title={translate('Styli')}
              >
                <Logo
                  title="styli logo"
                  width="70px"
                  height="40px"
                  className="d-block"
                />
              </a>
            </div>
          </div>
          <div className="col-xs-4 fs-21 lh-20 ls-063 c-dark-gray fw-6 cap ta-c">
            {translate('Checkout')}
          </div>
          <div className="col-xs-4 flex end-xs">
            <div className="mr-0 ml-auto flex middle-xs start-xs">
              <div className="ml-10 mr-10 flex middle-xs">
                <TrustBadge className="d-block" />
              </div>
              <div className="w-187 fs-11 lh-14 c-brown-gray">
                <span>
                  {translate('Safe & Secure payments. Easy Returns.')}{' '}
                </span>
                <span>
                  <If condition={isRTL}>%</If>
                  <span className="en-font">100</span>
                  <If condition={!isRTL}>%</If>
                  &nbsp;
                  {translate('Authentic products')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ContainerDesktop>
    </div>
  );
}
