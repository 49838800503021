/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import SlidePane from 'components/libs/SlidePane';
import ModalHeader from 'components/ModalHeader';
import classnames from 'classnames';
import { isBrowser } from 'react-device-detect';
import { COUNTRY, CURRENT_LANG_PREFIX } from 'constants/index';
import { formatProduct } from 'utils/product';
import { logEvent } from 'utils/analytics';

import './index.scss';
import { translate } from 'constants/language';
// import ViewMoreIcon from 'assets/icons/view-more-arrow.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import SizeButton from '../SizeChart/SizeButton';
import SizeDetails from './SizeDetails';
import Table from './Table';
import TableWithAction from './TableWithAction';
import PDPSizeModalBottom from '../PDPSizeModalBottom';
import ProductDetail from '../ProductDetail';

export default ({
  sizes = [],
  onSelectSize = () => {},
  selectedSize,
  quoteSelectedSizes,
  productDetail,
  sizeChartConfig = {},
  handleSizeData,
  sizeChartData,
  sizeChartLoading,
  inBottom = false,
  selectSizeWarning = false,
  setSelectSizeWarnin = () => {},
  onlySizes = false,
  productCatInfo,
  handleAddToWishlist = () => {},
  disableBag = false,
  wishlistItem,
  prodPrices = {},
  prodDiscount = {},
  accountState = {},
  wishListItemId = -1
}) => {
  const [knowYourSizeVisible, setKnowYourSizeVisible] = useState(false);
  const {
    endPoint: endPointObj,
    translations,
    add_cart_size_chart: addCartSizeChart
  } = sizeChartConfig;

  const sizeChartTranslations =
    (translations && translations[CURRENT_LANG_PREFIX]) || {};

  const endPoint = endPointObj && endPointObj[process.env.NODE_ENV];
  const addCartSizeChartEnable =
    addCartSizeChart && addCartSizeChart.enabled[COUNTRY];
  const handleKnowYourSize = async () => {
    if (!sizeChartData._id) handleSizeData({ endPoint });

    if (!knowYourSizeVisible) {
      const formattedData = await formatProduct({
        productDetail
      });

      logEvent({
        eventName: 'KnowYourSize',
        data: {
          items: formattedData,
          event_category: 'sizechart',
          event_label: `sizechart_${productDetail.sku}`
        },
        screenName: 'Product_Details'
      });
    }

    setKnowYourSizeVisible(!knowYourSizeVisible);
  };

  const handleSizeSelect = data => {
    handleSizeData({ ...data, endPoint });
    onSelectSize(data);
    setSelectSizeWarnin(false);
  };
  return (
    <div>
      <If condition={!onlySizes}>
        <div className="flex middle-xs mb-15">
          {/* <div className="fs-14 fw-5 c-dark-gray">
          {sizeChartTranslations && sizeChartTranslations.KEY_CHOOSE_SIZE}
        </div> */}
          <div className="fs-16 fw-5 c-main-gray">
            {translate('Pick your size')}
          </div>
          <div className="flex middle-xs ml-auto">
            <div
              // className="c-dark-gray fs-11 lh-14 fw-5 ls-08"
              className={classnames(
                'fs-12 ml-4 mr-4',
                isBrowser ? 'ls-072 c-dark-gray ' : 'fw-5 '
              )}
              onClick={handleKnowYourSize}
              onKeyPress={handleKnowYourSize}
              role="link"
            >
              {/* {sizeChartTranslations && sizeChartTranslations.KEY_KNOW_SIZE} */}
              {translate('Size chart')}
            </div>
            <ArrowRight className="rot-90 d-block" />
            {/* <div className="ml-5 mr-5 flex">
            <ViewMoreIcon height="12px" className="d-block rtl-reverse" />
          </div> */}
          </div>
        </div>
      </If>
      <div className="flex flex-wrap size-unit-list mb-10 mh-44">
        {sizes.map(size => (
          <SizeButton
            size={size}
            onSelect={handleSizeSelect}
            selected={
              quoteSelectedSizes &&
              selectedSize.productId &&
              Number(size.id) === Number(selectedSize.productId)
            }
            onlySizes={SizeButton}
          />
        ))}
      </div>
      <If condition={isBrowser && selectSizeWarning}>
        <div className="fs-12 lh-14 ls-038 c-secondary-negative start-xs">
          {translate('Please select a size to add the item to the bag')}
        </div>
      </If>
      <If condition={selectedSize && selectedSize.size && !inBottom}>
        <SizeDetails
          sizeChartData={sizeChartData}
          size={selectedSize && selectedSize.size}
          // translations={sizeChartTranslations}
        />
      </If>
      <If condition={!isBrowser && sizes.length}>
        <hr className="h-8 ml-n-15 mr-n-15 mt-0 mb-10 bg-yellow-gray-300" />
      </If>
      <SlidePane
        isOpen={knowYourSizeVisible}
        from="bottom"
        width="100%"
        overlayClassName="z-100"
        height={isBrowser ? '100%' : '90%'}
        onRequestClose={handleKnowYourSize}
        className={isBrowser ? 'top-0' : 'bottom-0'}
      >
        <ModalHeader
          onBackButtonPress={handleKnowYourSize}
          backAction={handleKnowYourSize}
          noBorder={true}
          className="w100"
          title={translate('Size Chart')}
          showHeaderRight={false}
        />
        <Choose>
          <When condition={!addCartSizeChartEnable}>
            <div style={{ overflowY: 'scroll' }}>
              <Table
                sizeChartData={sizeChartData}
                translations={sizeChartTranslations}
                sizeChartLoading={sizeChartLoading}
              />
            </div>
          </When>
          <Otherwise>
            <ProductDetail
              prodPrices={prodPrices}
              productDetail={productDetail}
              prodDiscount={prodDiscount}
            />
            <div style={{ overflowY: 'scroll' }}>
              <TableWithAction
                sizeChartData={sizeChartData}
                translations={sizeChartTranslations}
                sizeChartLoading={sizeChartLoading}
                handleSizeSelect={handleSizeSelect}
                selectedSize={selectedSize}
                quoteSelectedSizes={quoteSelectedSizes}
                productSizes={sizes}
              />
            </div>
            <PDPSizeModalBottom
              productDetail={productDetail}
              onSelectSize={onSelectSize}
              selectedSize={selectedSize}
              quoteSelectedSizes={quoteSelectedSizes}
              productCatInfo={productCatInfo}
              handleAddToWishlist={handleAddToWishlist}
              disableBag={disableBag}
              wishlistItem={wishlistItem}
              sizeChartLoading={sizeChartLoading}
              prodDiscount={prodDiscount}
              setKnowYourSizeVisible={setKnowYourSizeVisible}
              accountState={accountState}
              wishListItemId={wishListItemId}
            />
          </Otherwise>
        </Choose>
      </SlidePane>
    </div>
  );
};
