import React from 'react';
import { CURRENT_LANG_PREFIX } from 'constants/index';
import { cmToInchRange } from 'utils/';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';

export default ({ sizeChartData = {}, translations, sizeChartLoading }) => {
  const {
    sizes = [],
    [`size_label_${CURRENT_LANG_PREFIX}`]: sizeLabel,
    // [`scale_${CURRENT_LANG_PREFIX}`]: scale,
    [`description_${CURRENT_LANG_PREFIX}`]: description,
    previewImages = []
  } = sizeChartData;
  const columnHeadings =
    (sizes[0] &&
      sizes[0].value.map(data => data[`${CURRENT_LANG_PREFIX}_label`])) ||
    [];
  const isTypeShoes =
    sizes[0] &&
    sizes[0].value &&
    sizes[0].value.filter(v => v.append_scale).length <= 0;

  const descriptionArr = (description && description.split('\n')) || [];

  const tdClasses = 'ta-c pl-7 pr-7 pt-10 pb-10 fs-13 b-brown-grey';
  const tdClasses1 = 'ta-c pl-2 pr-2 pt-10 pb-10 fs-13 b-brown-grey';

  return (
    <div className={classnames('mt-60', { 'pr-16 pl-16 pb-50': isMobile })}>
      <Choose>
        <When condition={sizeChartLoading}>
          <div className="center-xs flex flex-grow-1 h100vh middle-xs mt-50">
            <div className="loader b-box mr-5 ml-5" />
          </div>
        </When>
        <Otherwise>
          <Choose>
            <When condition={!(sizeChartData && sizeChartData._id)}>
              <div>{translations.KEY_NO_SIZE_CHART}</div>
            </When>
            <Otherwise>
              <table
                cellSpacing="0"
                className="w100p"
                style={{
                  'border-collapse': 'collapse'
                }}
              >
                <thead>
                  <tr>
                    <td
                      className={`${tdClasses} fs-16 fw-6 bg-white-two`}
                      colSpan={
                        columnHeadings.length ? columnHeadings.length + 1 : 1
                      }
                    >
                      {isTypeShoes
                        ? translations.KEY_SINGLE_TABLE_HEADING
                        : translations.KEY_MEASURE_IN_CM}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={classnames(`${tdClasses} fw-6 `, {
                        'ta-l': !isTypeShoes,
                        w33p: columnHeadings.length + 1 === 3
                      })}
                      style={{
                        width: `${
                          isTypeShoes
                            ? `${100 / columnHeadings.length + 1}`
                            : 'auto'
                        }`
                      }}
                    >
                      {sizeLabel}
                    </td>
                    {columnHeadings.map(ch => {
                      return <td className={`${tdClasses} fw-6`}>{ch}</td>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sizes.map((s, index) => {
                    return (
                      <tr>
                        <td
                          className={classnames(`${tdClasses} fw-6 en-font`, {
                            'bg-light-gray-1': index % 2 === 0,
                            ' ta-l': !isTypeShoes,
                            w33p: columnHeadings.length + 1 === 3
                          })}
                        >
                          {s[`key_${CURRENT_LANG_PREFIX}`]}
                        </td>
                        {(s.value || []).map(v => {
                          return (
                            <td
                              className={`${tdClasses} en-font ${
                                !v.append_scale ? 'fw-6' : ''
                              } ${index % 2 ? '' : 'bg-light-gray-1'}`}
                            >
                              {v.value}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <If condition={!isTypeShoes}>
                <table
                  className="mt-50 w100p"
                  cellSpacing="0"
                  style={{
                    'border-collapse': 'collapse'
                  }}
                >
                  <thead>
                    <tr>
                      <td
                        className={`${tdClasses} fs-16 fw-6 bg-white-two`}
                        colSpan={
                          columnHeadings.length ? columnHeadings.length + 1 : 1
                        }
                      >
                        {translations.KEY_MEASURE_IN_INCH}
                      </td>
                    </tr>
                    <tr>
                      <td className={`${tdClasses} fw-6 ta-l`}>{sizeLabel}</td>
                      {columnHeadings.map(ch => {
                        return <td className={`${tdClasses} fw-6`}>{ch}</td>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sizes.map((s, index) => {
                      return (
                        <tr>
                          <td
                            className={`${tdClasses} en-font ${
                              index % 2 ? '' : 'bg-light-gray-1'
                            } fw-6 ta-l`}
                          >
                            {s[`key_${CURRENT_LANG_PREFIX}`]}
                          </td>
                          {(s.value || []).map(v => {
                            return (
                              <td
                                className={`${tdClasses1} en-font ${
                                  !v.append_scale ? 'fw-6' : ''
                                } ${index % 2 ? '' : 'bg-light-gray-1'}`}
                              >
                                {!v.append_scale
                                  ? v.value
                                  : cmToInchRange(v.value)}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </If>

              <div className="mt-50">
                {descriptionArr.map(data => {
                  const bulletArray = data && data.split('##');
                  if (bulletArray.length) {
                    return bulletArray.map((b, index) => {
                      if (index === 0) return <p>{b}</p>;
                      return (
                        <div className="flex">
                          <div>&#8226;</div>
                          <div className="ml-r-15">{b}</div>
                        </div>
                      );
                    });
                  }
                  return <p>{data}</p>;
                })}
              </div>

              <div className="center-xs">
                {previewImages.map(imgSrc => {
                  return (
                    <img
                      className="mt-50 w100p"
                      src={imgSrc && imgSrc[CURRENT_LANG_PREFIX]}
                      alt="img"
                    />
                  );
                })}
              </div>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </div>
  );
};