import React, { useEffect, useState } from 'react';
import { arrayUnique } from 'utils/index';
import { fetchProductData, fetchRecentlyViewed } from 'containers/Home/actions';
import { path, getResizedImg, getCeilingNum } from 'utils/';
import { useStateValue } from 'context/app';

import { IS_ARABIC } from 'constants/index';
import classnames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProductTupple from 'containers/ProductList/views/ProductTupple';
import { getLocalRecentlyViewed } from 'utils/product';
import RowTitle from '../RowTitle';

export default ({
  recentlyViewedConfig = {},
  homeDispatch,
  recentlyViewedTitle,
  widgetData
}) => {
  const [skuList, updateSkuList] = useState([]);
  const [currentListOffset, setOffset] = useState(0);
  const [productData, setData] = useState([]);
  const [callUpdate, setCallUpdate] = useState(true);
  const [noOfShimmers, setShimmerNum] = useState(0);

  const {
    account: [accountState]
  } = useStateValue();

  const { config: { vmUrlConfig = {} } = {} } = accountState;
  let vmUrlConfigValues = {};
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === 'development') {
    vmUrlConfigValues = vmUrlConfig?.dev;
  } else {
    vmUrlConfigValues = vmUrlConfig[`${nodeEnv}`];
  }

  const { numberOfProducts, apiPageSize, minCount } =
    recentlyViewedConfig || {};

  const {
    imageMedia: [
      {
        url: iImgURL,
        dimensions: { width: iImgWidth, height: iImgHeight } = {}
      } = {}
    ] = []
  } = widgetData && widgetData.length && widgetData[0];

  const [columnWidth, setColumnWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setColumnWidth(ref.clientWidth);
    }
  };

  useEffect(() => {
    getLocalRecentlyViewed(numberOfProducts).then(async data => {
      const localSkuList = (data && data.map(d => d.sku)) || [];
      let apiList = [];
      const numOfProductsNeeded =
        Number(numberOfProducts) - (data || []).length;
      if (numOfProductsNeeded > 0) {
        apiList = await fetchRecentlyViewed(
          { numOfProductsNeeded },
          homeDispatch
        );
      }

      const finalList = arrayUnique([...localSkuList, ...apiList]);

      updateSkuList(finalList || []);
    });
  }, []);

  useEffect(() => {
    if (skuList.length && skuList.length >= minCount) {
      const fetchNo = Math.min(apiPageSize, skuList.length);
      const skuArray = skuList.slice(0, fetchNo);
      fetchProductData(skuArray, vmUrlConfigValues).then(data => {
        setData(data || []);
        setOffset(fetchNo - 1);
      });
    }
  }, [skuList]);

  const updateProductData = async () => {
    const remaining = Math.min(
      skuList.length - productData.length,
      apiPageSize
    );

    if (remaining > 0) {
      const skuArr = skuList.slice(
        currentListOffset + 1,
        currentListOffset + 1 + remaining
      );
      setShimmerNum(skuArr.length);
      const response = await fetchProductData(skuArr, vmUrlConfigValues);
      setShimmerNum(0);
      const updatedProductData = [...productData, ...response];
      setData(updatedProductData);
      setOffset(currentListOffset + remaining);
      setCallUpdate(true);
    }
  };

  const scrollListner = event => {
    const { scrollLeft, scrollWidth } = event.target;
    if (scrollLeft > scrollWidth / 3 && callUpdate) {
      setCallUpdate(false);
      updateProductData();
    }
  };

  return (
    <If condition={productData && productData.length}>
      <div ref={handelRef}>
        <Choose>
          <When
            condition={
              widgetData.length === 1 &&
              path([0, 'imageMedia', 0, 'url'], widgetData)
              // const imgWidth = path([0, 'imageMedia', 0, 'dimensions', "width"], widgetData)
            }
          >
            <LazyLoadImage
              className="d-block"
              src={getResizedImg({
                imgURL: iImgURL,
                reqWidth: getCeilingNum(document.body.clientWidth)
              })}
              alt=""
              width="100%"
              height={
                iImgHeight &&
                columnWidth &&
                (iImgHeight / iImgWidth) * columnWidth
              }
            />
          </When>
          <Otherwise>
            <RowTitle
              title={recentlyViewedTitle}
              // txtColor={
              //   rowType === PRODUCT_CAROUSEL && cloumnStyling
              //     ? cloumnStyling.mainTitleColor
              //     : ''
              // }
              titleCenterAlign={true}
            />
          </Otherwise>
        </Choose>

        <div>
          <div
            className={classnames('cat-slide mt-16', {
              'overflow-auto-no-bar': isMobile || isTablet,
              'mr-n-15': !IS_ARABIC,
              'ml-n-15': IS_ARABIC
            })}
            onScroll={scrollListner}
          >
            {productData &&
              productData.map(product => {
                return (
                  <div className="w-150 d-il-block v-t bg-white">
                    <ProductTupple
                      productDetail={{
                        ...product,
                        discountPercentage: product.discount
                      }}
                      showWishlistIcon={false}
                      colClassName="d-block"
                      useProductionImage={true}
                      caSource="home"
                      caSourceType="RecentlyViewed"
                      caSourceValue="RecentlyViewed"
                    />
                  </div>
                );
              })}
            <If condition={noOfShimmers}>
              {new Array(noOfShimmers).fill(0).map(() => {
                return (
                  <div className="w-150 d-il-block v-t bg-white">
                    <ProductTupple
                      productDetail={{}}
                      isLoading={true}
                      colClassName="d-block"
                      key={`${Math.random()}`}
                    />
                  </div>
                );
              })}
            </If>
          </div>
        </div>
      </div>
    </If>
  );
};
