import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { translate } from 'constants/language';
import { formatPrice } from 'utils/';
import { useStateValue } from 'context/app';
import { CURRENCY_FRACTION, COUNTRY } from '../../../../../constants';

export default ({
  prodPrices = {},
  currency = '',
  prodDiscount = {},
  isInLine = false,
  combineView = false
}) => {
  const {
    account: [accountState]
  } = useStateValue();

  const {
    config: {
      plp: {
        discount_text_color: discountTextColors,
        bogo_ui_change: { threshold_value: thresholdValue = {} } = {}
      } = {}
    } = {}
  } = accountState;

  const [discountTextColor, setDiscountTextColor] = useState();

  useEffect(() => {
    setDiscountTextColor(
      (discountTextColors && discountTextColors[COUNTRY]) || '#d64045'
    );
  }, [discountTextColors]);

  const discountThreshold = thresholdValue[COUNTRY] || 0;

  return (
    <>
      <div className={classnames(isInLine ? 'flex middle-xs' : '')}>
        <div
          className={classnames(
            isInLine ? 'fs-18' : 'fs-16',
            'fw-6 c-dark-gray middle-xs en-font'
          )}
        >
          <If condition={prodPrices.specialPrice || prodPrices.price}>
            <div className="d-il-block">
              {formatPrice(
                prodPrices.specialPrice > 0
                  ? prodPrices.specialPrice
                  : prodPrices.price,
                undefined,
                {
                  currency,
                  maximumFractionDigits: CURRENCY_FRACTION,
                  minimumFractionDigits: CURRENCY_FRACTION
                }
              )}
            </div>
          </If>
          <If
            condition={
              combineView &&
              prodDiscount > discountThreshold &&
              prodPrices.specialPrice > 0
            }
          >
            <div className="fs-16 d-il-block ml-r-5 c-pastel-red">
              {Number(Math.round(prodDiscount, 10))}%
              <span className={classnames('cap')}>
                &nbsp;{translate('off')}&nbsp;
              </span>
            </div>
          </If>
          <If
            condition={
              prodPrices.specialPrice > 0 &&
              parseInt(prodPrices.specialPrice, 10) <
                parseInt(prodPrices.price, 10) &&
              Number(prodDiscount) > discountThreshold
            }
          >
            <del
              className={classnames(
                isInLine ? 'ml-12 mr-12' : 'ml-3 mr-3',
                'c-dark-gray fs-14 fw-5 d-il-block en-font'
              )}
              style={
                combineView
                  ? { marginRight: 0, marginLeft: 0, marginTop: '0px' }
                  : {}
              }
            >
              {formatPrice(prodPrices.price, undefined, {
                currency,
                maximumFractionDigits: CURRENCY_FRACTION,
                minimumFractionDigits: CURRENCY_FRACTION
              })}
            </del>
          </If>
        </div>
        <If
          condition={
            !combineView &&
            prodDiscount > discountThreshold &&
            prodPrices.specialPrice > 0
          }
        >
          <div
            style={{ color: discountTextColor }}
            className={classnames(isInLine ? 'fs-14' : 'fs-12 mt-3')}
          >
            <div className="d-il-block en-font">
              {Number(Math.round(prodDiscount, 10))}%
            </div>
            {/* <div className="d-il-block">%</div> */}
            <span className={classnames('cap')}>
              &nbsp;{translate('off')}&nbsp;
            </span>
          </div>
        </If>
      </div>
    </>
  );
};
