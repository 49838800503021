import React from 'react';
import classnames from 'classnames';
// import SlidePane from 'components/libs/SlidePane';
import FlexSlidePane from 'components/libs/SlidePane/FlexSlidePane';
import Button from 'components/libs/Buttons';
import { isBrowser } from 'react-device-detect';
import { translate } from '../../../../constants/language';
import SizeChart from './SizeChart';
import MulinSizeChart from './MulinSizeChart';

export default ({
  isOpen,
  onClose,
  sizes = [],
  onSelectSize = () => {},
  selectedSize,
  onAddToCart,
  quoteSelectedSizes,
  productDetail = {},
  mulinSchartEabled,
  handleMulinSizeData,
  sizeChartConfig,
  sizeChartData,
  sizeChartLoading,
  forDesktop = false
}) => {
  return (
    <FlexSlidePane
      overlayClassName="z-100"
      isOpen={isOpen}
      from="bottom"
      width="100%"
      contentClassName="p-0 "
      onRequestClose={onClose}
      // inModal={!forDesktop}
      className={classnames(
        isBrowser ? 'pl-28 pr-30 pt-32 pb-32' : 'pl-14 pr-15 pt-24 pb-24'
      )}
      isCenter={true}
      isLogin={true}
    >
      <div
        className={classnames(
          'pt-15 pl-15 pr-15',
          forDesktop
            ? 'pos-abs bottom-0 bg-white b-white-two mb-50 w100p b-box'
            : ''
        )}
      >
        <Choose>
          <When condition={mulinSchartEabled}>
            <MulinSizeChart
              sizes={sizes}
              onSelectSize={onSelectSize}
              selectedSize={selectedSize}
              quoteSelectedSizes={quoteSelectedSizes}
              productDetail={productDetail}
              handleSizeData={handleMulinSizeData}
              sizeChartConfig={sizeChartConfig}
              sizeChartData={sizeChartData}
              sizeChartLoading={sizeChartLoading}
              inBottom={true}
              onlySizes={forDesktop}
            />
          </When>
          <Otherwise>
            <SizeChart
              sizes={sizes}
              onSelectSize={onSelectSize}
              selectedSize={selectedSize}
              quoteSelectedSizes={quoteSelectedSizes}
              productDetail={productDetail}
              onlySizes={forDesktop}
            />
          </Otherwise>
        </Choose>
      </div>
      <If condition={!forDesktop}>
        <div className="p-15 pt-0 bb-light ">
          <Button
            disabled={!selectedSize}
            className="primary d-block w100p"
            onClick={onAddToCart}
            id="addToBagSelectSize"
          >
            {translate('Add to Bag')}
          </Button>
        </div>
      </If>
    </FlexSlidePane>
  );
};
