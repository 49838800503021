import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './index.scss';
import {
  path,
  getResizedImg,
  getCeilingNum,
  getNavigationBasePath
} from 'utils';

export default ({
  widgetData,
  itemIndex,
  cloumnStyling,
  wrapperClass,
  // bottomSpace
  colSpacing,
  onWidgetClick = () => {},
  itemTextCenterAlign = false
}) => {
  const {
    link,
    title,
    subTitle,
    imageMedia: [
      { url, dimensions: { width: imgWidth, height: imgHeight } = {} } = {}
    ] = []
  } = widgetData;
  const bgColor = widgetData.backgroundColor;

  // const url = path(['imageMedia', 0, 'url'], widgetData);

  const titleFS = path(['mainTitleFontSize'], cloumnStyling)
    ? `fs-${path(['mainTitleFontSize'], cloumnStyling)}`
    : 'fs-16';
  const titleFW = path(['mainTitleBold'], cloumnStyling);
  const titleFC = path(['mainTitleColor'], cloumnStyling);
  const [titleStyle, setTitleStyle] = useState({});

  const subTitleFS = path(['subTextFontSize'], cloumnStyling)
    ? `fs-${path(['subTextFontSize'], cloumnStyling)}`
    : 'fs-12';
  const subTitleFW = path(['subTextBold'], cloumnStyling);
  const subTitleFC = path(['subTitleColor'], cloumnStyling);
  const [subTitleStyle, setSubTitleStyle] = useState({});

  useEffect(() => {
    if (titleFC) {
      setTitleStyle({ color: titleFC });
    }
    if (subTitleFC) {
      setSubTitleStyle({ color: subTitleFC });
    }
  }, [cloumnStyling]);

  const handleClick = event => {
    onWidgetClick({ event, widget: widgetData, widgetIndex: itemIndex });
  };
  const [columnWidth, setColumnWidth] = useState();
  const handelRef = ref => {
    if (ref) {
      setColumnWidth(ref.getBoundingClientRect().width - colSpacing);
    }
  };

  return (
    <div
      ref={handelRef}
      className={wrapperClass}
      style={
        cloumnStyling &&
        colSpacing > -1 && {
          paddingLeft: colSpacing / 2,
          paddingRight: colSpacing / 2,
          marginBottom: colSpacing
        }
      }
    >
      <a
        className="d-block"
        style={{
          background: bgColor
        }}
        onClick={handleClick}
        onKeyPress={handleClick}
        title={title || subTitle}
        href={link && `${getNavigationBasePath()}/${link}`}
      >
        <If condition={url}>
          <div
            style={{
              height:
                imgHeight && columnWidth && (imgHeight / imgWidth) * columnWidth
            }}
          >
            <LazyLoadImage
              className="d-block"
              src={getResizedImg({
                imgURL: url,
                reqWidth: columnWidth
                  ? getCeilingNum(columnWidth)
                  : document.body.clientWidth
              })}
              alt={title || subTitle}
              width="100%"
              height={
                imgHeight && columnWidth && (imgHeight / imgWidth) * columnWidth
              }
            />
          </div>
        </If>
        <If condition={title || subTitle}>
          <div
            className={classnames(
              'pt-10',
              !url ? 'pr-15 pl-15 pb-10' : '',
              itemTextCenterAlign ? 'ta-c' : 'ta-l'
            )}
          >
            <If condition={title}>
              <div
                className={classnames(
                  titleFS,
                  !cloumnStyling || titleFW === true ? 'fw-6' : '',
                  !titleFC ? 'c-dark-gray' : '',
                  'mb-2'
                )}
                style={titleStyle}
              >
                {title}
              </div>
            </If>
            <If condition={subTitle}>
              <div
                className={classnames(
                  subTitleFS,
                  subTitleFW ? 'fw-6' : '',
                  !subTitleFC ? 'c-brown-gray' : ''
                )}
                style={subTitleStyle}
              >
                {subTitle}
              </div>
            </If>
          </div>
        </If>
      </a>
    </div>
  );
};
