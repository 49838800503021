import axios from 'utils/axios';
import { timingVisualComplete } from 'utils/analytics';
import logError from 'utils/logError';
import { getCustomerId, getXHeaderToken } from 'utils/user';
import { getStoreId, path } from 'utils';
import {
  API_PRODUCT_DETAILS_BY_SKU_CUROFY,
  GET_RECENTLY_VIEWED,
  GET_STRAPI_ROWS
} from 'constants/api';
import { CURRENT_LANG_PREFIX, COUNTRY, COUNTRY_KSA } from '../../constants';

export const ACTION_INITAL_LOAD = 'home/INITAL_LOAD';
export const ACTION_GET_HOME_PAGE = 'home/GET_HOME_PAGE';
export const ACTION_GET_DESKTOP_CATEGORIES = 'home/GET_CATEGORY_PAGE';
export const ACTION_FETCH_RECENTLY_VIEWED_LOADING =
  'home/FETCH_RECENTLY_VIEWED_LOADING';
export const ACTION_FETCH_RECENTLY_VIEWED_FETCHED =
  'home/FETCH_RECENTLY_VIEWED_FETCHED';
export const ACTION_FETCH_RECENTLY_VIEWED_ERROR =
  'home/FETCH_RECENTLY_VIEWED_ERROR';
export const ACTION_PROMO_NAME = 'home/PROMO_NAME';
export const ACTION_GET_STRAPI_ROWS = 'home/GET_STRAPI_ROWS';

export const callInitalLoad = async dispatch => {
  dispatch({ type: ACTION_INITAL_LOAD });
};

export const getHomePage = async (option, dispatch) => {
  const startTime = new Date().getTime();
  const result = await axios.get(
    `/strapi_pages/processed/${
      COUNTRY !== COUNTRY_KSA ? `${COUNTRY}_` : ''
    }${CURRENT_LANG_PREFIX}.json`
  );
  dispatch({ type: ACTION_GET_HOME_PAGE, data: result.data });
  const endTime = new Date().getTime();
  timingVisualComplete(endTime - startTime);
};

export const getDesktopCategories = async (option, dispatch) => {
  const startTime = new Date().getTime();
  const result = await axios.get(
    `/strapi_pages/processed/category/${
      COUNTRY !== COUNTRY_KSA ? `${COUNTRY}_` : ''
    }${CURRENT_LANG_PREFIX}.json`
  );
  dispatch({ type: ACTION_GET_DESKTOP_CATEGORIES, data: result.data });
  const endTime = new Date().getTime();
  timingVisualComplete(endTime - startTime);
};

export async function fetchRecentlyViewed(options, dispatch) {
  const { numOfProductsNeeded } = options;
  const customerId = getCustomerId();

  if (customerId) {
    dispatch({ type: ACTION_FETCH_RECENTLY_VIEWED_LOADING });
    try {
      const response = await axios.post(
        GET_RECENTLY_VIEWED(),
        {
          user_id: Number(customerId),
          limit: numOfProductsNeeded
        },
        {
          headers: {
            'X-Header-Token': getXHeaderToken(),
            // Token: getJWT(),
            'Content-Type': 'application/json'
          }
        }
      );

      const statusCode = path(['data', 'statusCode'], response);

      if ([200, '200'].indexOf(statusCode) < 0)
        return dispatch({ type: ACTION_FETCH_RECENTLY_VIEWED_ERROR });

      const productList = path(['data', 'recommended_item_list'], response);
      dispatch({
        type: ACTION_FETCH_RECENTLY_VIEWED_FETCHED,
        data: productList
      });
      return productList || [];
    } catch (e) {
      logError(e);
      dispatch({ type: ACTION_FETCH_RECENTLY_VIEWED_ERROR });
    }
  }
  return '';
}

export async function fetchProductData(skuArray, vmUrlConfigValues) {
  if (skuArray && skuArray.length) {
    try {
      const {
        vm_url_pdp_enabled: vmUrlPDPEnabled,
        new_pdp_get_list_url: newPDPListUrl,
        old_pdp_get_list_url: oldPDPListUrl
      } = vmUrlConfigValues;
      const reqPDPListUrl = vmUrlPDPEnabled ? newPDPListUrl : oldPDPListUrl;

      const storeId = getStoreId();
      const result = await axios.post(
        API_PRODUCT_DETAILS_BY_SKU_CUROFY({ reqPDPListUrl }),
        { skus: skuArray, storeId },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const finalStatusCode = path(['data', 'statusCode'], result);
      if ([200, '200'].indexOf(finalStatusCode) > -1) {
        const productList = path(
          ['data', 'response', 'productDetailsList'],
          result
        );
        const sortedList = [];
        const sortedObj = {};
        if (productList && productList.length) {
          productList.forEach(p => {
            sortedObj[p.sku] = p;
          });
          skuArray.forEach(sku => {
            if (sortedObj[sku]) sortedList.push(sortedObj[sku]);
          });
          return sortedList;
        }
      }
    } catch (e) {
      logError(e);
      return [];
    }
  }
  return [];
}

export function setPromoNameInHomeState(promoName, dispatch) {
  dispatch({ type: ACTION_PROMO_NAME, data: { promoBannerName: promoName } });
}

export async function getStrapiWidgetRow(widgetNames, dispatch) {
  if (widgetNames) {
    try {
      const result = await axios.post(
        GET_STRAPI_ROWS(),
        { rowNames: widgetNames },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch({ type: ACTION_GET_STRAPI_ROWS, data: result.data });
    } catch (e) {
      logError(e);
      return '';
    }
  }
  return '';
}
