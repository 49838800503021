import React from 'react';
import classnames from 'classnames';
import {
  BrowserView,
  isBrowser,
  isMobile,
  MobileView
} from 'react-device-detect';
import './index.scss';
import CloseIcon from 'assets/icons/icon-cancel-black.svg';
import BackIcon from './backIcon.svg';
// import { WEBSITE_DOMAIN } from '../../constants';

export default function({
  HeaderRight = () => {
    return <></>;
  },
  backButtonClassName = '',
  headerRightClassName = '',
  titleClassName = '',
  showHeaderRight = true,
  title = '',
  onBackButtonPress,
  noBorder = false,
  isBackButton = false,
  isFilterModal = false
}) {
  return (
    <header
      className={classnames(
        'flex header middle-xs top-fixed bg-white',
        {
          'bb-light': !noBorder
        },
        isBrowser ? 'pl-56 pt-56 pr-56' : ' between-xs pl-15 pr-15 h-50'
      )}
    >
      {/* <div className="w100"> */}
      <BrowserView>
        <h1
          className={classnames(
            `flex-grow-1 txt-overflow-ellipsis fw-5 ${titleClassName}`,
            isBrowser ? 'fs-18 lh-24 ls-032 ta-l' : 'm-0 ta-c ',
            {
              'fs-15': !(titleClassName.indexOf('fs') > -1)
            }
          )}
        >
          {title}
        </h1>
      </BrowserView>
      <If condition={!isFilterModal}>
        <div
          className={classnames(
            {
              w100: isMobile,
              'mt-15': isBackButton
            },
            isBrowser ? 'mr-0 ml-auto' : ''
          )}
        >
          <a
            onClick={onBackButtonPress}
            onKeyPress={onBackButtonPress}
            role="link"
            className="d-block"
          >
            <Choose>
              <When condition={isBackButton}>
                <BackIcon className={`rtl-flip ${backButtonClassName}`} />
              </When>
              <Otherwise>
                <CloseIcon
                  // icon={faArrowLeft}
                  className="c-black-light rtl-reverse d-block"
                />
              </Otherwise>
            </Choose>
          </a>
        </div>
      </If>
      {/* <h1 className="flex-grow-1 fs-15 m-0 ta-c txt-overflow-ellipsis fw-6"> */}
      <MobileView>
        <h1
          className={classnames(
            `flex-grow-1 txt-overflow-ellipsis fw-6 ${titleClassName}`,
            isBrowser ? 'fs-18 lh-24 ls-032 ta-l' : 'm-0 ta-c ',
            {
              'fs-15': !(titleClassName.indexOf('fs') > -1)
            }
          )}
        >
          {title}
        </h1>
      </MobileView>
      <If condition={showHeaderRight}>
        <div
          className={classnames('r-ta-r', {
            w100: !(headerRightClassName.indexOf('w') > -1)
          })}
        >
          {HeaderRight}
        </div>
      </If>
      <If condition={isFilterModal}>
        <div className="r-ta-r">
          <a
            onClick={onBackButtonPress}
            onKeyPress={onBackButtonPress}
            role="link"
            className="d-block"
          >
            {' '}
            <CloseIcon className="c-black-light rtl-reverse d-block" />
          </a>
        </div>
      </If>
    </header>
  );
}
