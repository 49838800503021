import firebase from '@firebase/app';
import momentTimeZone from 'moment-timezone';

import * as Sentry from '@sentry/browser';
import {
  DEFAULT_LOCALE,
  DEFAULT_CURRENCY,
  MADA_CARD_BINS,
  JWT_COOKIE_NAME,
  ARABIC_STORE_ID,
  ENGLISH_STORE_ID,
  // API_STYLI_UTILS_DOMAIN,
  IS_ARABIC,
  BASE_PATH,
  CURRENT_LANG_PREFIX,
  COUNTRY,
  UAE_ARABIC_STORE_ID,
  UAE_ENGLISH_STORE_ID,
  KW_ARABIC_STORE_ID,
  KW_ENGLISH_STORE_ID,
  COUNTRIES_NAMES,
  COUNTRIES,
  BH_ARABIC_STORE_ID,
  BH_ENGLISH_STORE_ID,
  QA_ARABIC_STORE_ID,
  QA_ENGLISH_STORE_ID,
  IN_ENGLISH_STORE_ID
} from '../constants';

import { translate } from '../constants/language';
// import { isValidName } from './validators';
import logError from './logError';
import { isValidEmail, isValidPhone } from './validators';
import { JWT_MSG } from '../constants/jwtError';

export const setSentryCustomer = ({ email }) => {
  try {
    Sentry.configureScope(scope => {
      scope.setUser({ email });
    });
    if (window.newrelic) {
      window.newrelic.setCustomAttribute('email', email);
    }
  } catch (e) {
    logError(e);
  }
};

export const path = (p, o, ifnull = null) => {
  const reducerFunction = (xs, x) => {
    return xs && xs[x] ? xs[x] : ifnull;
  };
  return p.reduce(reducerFunction, o);
};
export function getCookieValue(a) {
  const b = document.cookie.match(`(^|[^;]+)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}
export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires};path=/;domain=${
    window.location.hostname
  }`;
}

export function deleteCookie(name) {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${window.location.hostname}`;
}

export function getCardType(number = '') {
  let re = new RegExp('^4');
  if (number.match(re) != null) return 'visa';

  re = new RegExp('^(34|37)');
  if (number.match(re) != null) return 'amex';

  const bin = (number.replace(/\s/g, '') || '').substr(0, 6);
  if (MADA_CARD_BINS.indexOf(bin) > -1) {
    return 'mada';
  }

  re = new RegExp('^5[1-5]');
  if (number.match(re) != null) return 'mastercard';

  re = new RegExp('^6011');
  if (number.match(re) != null) return 'discover';

  return '';
}
export function getCard() {}

export function formatPrice(price = 0, locale, options = {}) {
  const l = locale || DEFAULT_LOCALE;
  const result = Number(price).toLocaleString(l, {
    ...options,
    currency: options.currency || DEFAULT_CURRENCY,
    style: 'currency'
  });
  return result.indexOf(' ') > -1
    ? result
    : result.replace(DEFAULT_CURRENCY, `${DEFAULT_CURRENCY}`);
}

export const truncatePhoneNumber = (phone = '') => {
  return (String(phone) || '')
    .replace('+966', '')
    .replace('+971', '')
    .replace('+965', '')
    .replace('+973', '')
    .replace('+974', '')
    .replace('+91', '')
    .replace(/\s/g, '');
};
export function getDefaultLanguage(language) {
  let lang = language ? 'ar' : undefined;
  const cookieLanguage = getCookieValue('ln');
  if (!lang && ['ar', 'en'].indexOf(cookieLanguage) > -1) {
    lang = cookieLanguage;
  } else lang = 'ar';
  return lang;
}
export function setDefaultLanguage(lang, { urlLang, isHomepage }) {
  const hasLang = getCookieValue('ln');
  if (!hasLang && !urlLang && isHomepage) {
    setCookie('slp', 1, 3);
  }
  if (urlLang) {
    setCookie('slp', undefined, -1);
  }
  setCookie('ln', lang, 365);
}

export function setDefaultCountry(country) {
  setCookie('cn', country, 365);
}
export function getCurrentLanguage() {
  const { pathname } = window.location;
  const [, countryLang, language] = pathname.split('/');
  let lang = language === 'en' ? 'en' : getDefaultLanguage(language);
  if (['en', 'ar'].indexOf(countryLang) > -1) {
    lang = countryLang;
  }
  const isHomepage = pathname.split('/').join('').length === 0;
  setDefaultLanguage(lang, { urlLang: language, isHomepage });
  return lang;
}

export function getDefaultCountry(countries) {
  const country = getCookieValue('cn');
  return countries.indexOf(country) > -1 ? country : 'sa';
}

export function getWebsiteCountry(countries) {
  const { pathname = '' } = window.location;
  const [, country] = pathname.split('/');
  const isHomepage = pathname.split('/').join('').length === 0;

  const selectedCountry =
    countries.indexOf(country) > -1 ? country : getDefaultCountry(countries);
  const hasCountry = !!getCookieValue('cn');
  if (!hasCountry && !country && isHomepage) {
    setCookie('scp', 1, 3);
  } else if (country) {
    setCookie('scp', undefined, -1);
  }
  setCookie('cn', selectedCountry, 365);
  return selectedCountry;
}

export function getJWT() {
  const jwt = getCookieValue(JWT_COOKIE_NAME);
  const token = `KEY ${jwt}`;
  if (jwt) {
    return token;
  }
  return false;
}

export function getStoreId() {
  switch (COUNTRY) {
    case 'ae': {
      if (IS_ARABIC) {
        return UAE_ARABIC_STORE_ID;
      }
      return UAE_ENGLISH_STORE_ID;
    }
    case 'kw': {
      if (IS_ARABIC) {
        return KW_ARABIC_STORE_ID;
      }
      return KW_ENGLISH_STORE_ID;
    }
    case 'bh': {
      if (IS_ARABIC) {
        return BH_ARABIC_STORE_ID;
      }
      return BH_ENGLISH_STORE_ID;
    }
    case 'qa': {
      if (IS_ARABIC) {
        return QA_ARABIC_STORE_ID;
      }
      return QA_ENGLISH_STORE_ID;
    }
    case 'in': {
      return IN_ENGLISH_STORE_ID;
    }
    default: {
      if (IS_ARABIC) {
        return ARABIC_STORE_ID;
      }
      return ENGLISH_STORE_ID;
    }
  }
  // return IS_ARABIC ? ARABIC_STORE_ID : ENGLISH_STORE_ID;
}

const requiredError = value =>
  !String(value || '').replace(/\s/g, '')
    ? translate(`This is required field`)
    : false;
const alphaValidation = (value = '') => {
  return !/^[a-zA-Z .]+$/i.test(value) &&
    !/[\u0600-\u06FF\u0750-\u077F]/i.test(value)
    ? translate(`No Special Characters allowed`)
    : false;
};
const validPhoneNumber = (value = '', storeId) => {
  const arabic = /[\u0600-\u06FF]/;
  return !(
    value.replace(/\s/g, '').length > 7 &&
    value.replace(/\s/g, '').length <= 10 &&
    !arabic.test(value) &&
    isValidPhone(value, storeId)
  )
    ? translate(
        `Please use a valid mobile number${
          COUNTRY.toLowerCase() === 'in' ? '' : ' (eg. 501234567)'
        }`
      )
    : false;
};

export function validateAddress(payload = {}, isGuestUser, storeId) {
  const {
    email = '',
    fullname = '',
    mobileNumber = '',
    selectedProvinces = {},
    buildingNumber
    // street = []
  } = payload;
  const errors = {};

  const fullNameError = requiredError(fullname) || alphaValidation(fullname);
  if (fullNameError) {
    errors.fullname = fullNameError;
  }
  if (isGuestUser) {
    const emailError = requiredError(email);
    if (emailError) {
      errors.email = emailError;
    }
    if (!isValidEmail(email)) {
      errors.email = translate('Invalid email. Can you check the format?');
    }
  }

  const telephoneError =
    requiredError(mobileNumber) ||
    validPhoneNumber(truncatePhoneNumber(mobileNumber), storeId);
  if (telephoneError) {
    errors.mobileNumber = telephoneError;
  }
  const provinceError = requiredError(
    path(['province', 'name'], selectedProvinces)
  );
  if (provinceError) {
    errors.province = provinceError;
  }
  const cityError = requiredError(path(['city', 'name'], selectedProvinces));
  if (cityError) {
    errors.city = cityError;
  }
  const areaError = requiredError(path(['area', 'name'], selectedProvinces));
  if (areaError) {
    errors.area = translate('You have to select the Area');
  }
  const buildingNumberError = requiredError(buildingNumber);
  if (buildingNumberError) {
    errors.buildingNumber = buildingNumberError;
  }

  return errors;
}
export const getMagentoPostAddress = address => {
  return {
    // ...address,
    countryId: address.country_id,
    customerId: address.customer_id,
    customerAddressId: address.id,
    regionId: address.region.region_id,
    regionCode: address.region.region_code,
    telephone: address.telephone,
    street: address.street.split(' '),
    city: address.city,
    firstname: address.firstname,
    lastname: address.lastname,
    customAttributes: address.custom_attributes
  };
};

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export const getSelectedAddressId = ({ selectedAddressId } = {}) => {
  return Number(selectedAddressId);
};

export const ifNotNumber = (value, def = 0) => {
  return Number(value) || def;
};
export const getBalanceApplied = orderSummary => {
  const {
    total,
    shippingAmount,
    discount = 0,
    couponDiscount = 0,
    autoCouponDiscount = 0,
    importFeesAmount = 0,
    // tax = 0,
    grandTotal,
    codCharges = 0,
    storeCreditBalance,
    storeCreditApplied
  } = orderSummary;
  const sum =
    ifNotNumber(total) +
    ifNotNumber(shippingAmount) +
    ifNotNumber(codCharges) +
    ifNotNumber(importFeesAmount) -
    ifNotNumber(discount) -
    ifNotNumber(couponDiscount) -
    ifNotNumber(autoCouponDiscount);

  const cleanGrandTotal =
    orderSummary.grandTotal -
    orderSummary.codCharges +
    orderSummary.storeCreditApplied;

  const remaining =
    Number(storeCreditBalance || 0) - Number(cleanGrandTotal || 0);

  return {
    remaining,
    sum,
    grandTotal,
    storeCreditApplied,
    cleanGrandTotal,
    storeCreditBalance
  };
};

export function logUserActivities() {
  try {
    // const newData = { uid: getCustomerIdCookie(), ...data };
    // axios.post(`${API_STYLI_UTILS_DOMAIN}log`, newData);
  } catch (e) {
    logError(e);
  }
}

export function getProductGalleryImg({ imgURL }) {
  let newURL = '';
  if (imgURL && imgURL.indexOf('cdn-cgi') === -1) {
    newURL = imgURL.replace(/cache\/(.*?)\//g, '');
    // if (process.env.NODE_ENV === 'production') {
    //   newURL = newURL
    //     .replace('/pub/media/', `/media/`)
    //     .replace(
    //       '/media/',
    //       `/cdn-cgi/image/width=${reqWidth},quality=100/pub/media/`
    //     )
    //     .replace('/product//', '/product/');
    // }
  }
  return newURL || imgURL;
  // return imgURL;
}

export function getCeilingNum(cNum) {
  let newNum = 100;
  if (cNum > 0) {
    newNum = Math.ceil(cNum / 100) * 100;
  }
  return newNum || 100;
}

export function getPLPImg({ imgURL, isBrowser, plpcompress, reqWidth }) {
  const screenWidth = window.innerWidth;
  let width = isBrowser ? screenWidth / 2 : screenWidth;
  if (reqWidth && reqWidth > 0) {
    width = getCeilingNum(reqWidth);
  }
  let newURL = '';
  if (imgURL) {
    if (plpcompress) {
      newURL = imgURL.replace(
        `/media/`,
        `/cdn-cgi/image/width=${width},quality=100/media/`
      );
    } else {
      newURL = imgURL.replace(/cache\/(.*?)\//g, '');
    }
  }
  return newURL;
}

export function getRandomNum(min, max, inclusive = true) {
  const minNum = Math.ceil(min);
  const maxNum = Math.floor(max);
  if (inclusive) {
    return Math.floor(Math.random() * (maxNum - minNum + 1) + minNum);
  }
  return Math.floor(Math.random() * (maxNum - minNum) + minNum);
}

export function arrayUnique(arr) {
  return Array.from(new Set(arr));
}

export function getNewWidth(reqWidth) {
  const params = new URLSearchParams(window.location.search);
  const widthInPercentage = params.get('width-percentage')
    ? params.get('width-percentage')
    : 30;
  const newWidth = reqWidth + Math.round(reqWidth * (widthInPercentage / 100));
  return newWidth;
}

export function getResizedImg({ imgURL, reqWidth }) {
  let newURL = '';
  // const newWidth = getNewWidth(reqWidth);
  const newWidth = getCeilingNum(reqWidth);
  if (
    imgURL &&
    imgURL.indexOf('cdn-cgi') === -1 &&
    !(imgURL.toLowerCase().indexOf('.gif') > -1)
  ) {
    if (imgURL.indexOf('m.stylishop.com') > -1) {
      newURL = imgURL.replace(
        'm.stylishop.com/',
        `m.stylishop.com/cdn-cgi/image/width=${newWidth},quality=100/`
      );
    } else if (imgURL.indexOf('images.stylishop.com') > -1) {
      newURL = imgURL.replace(
        'images.stylishop.com/',
        `images.stylishop.com/cdn-cgi/image/width=${newWidth},quality=100/`
      );
    }
  }
  return newURL || imgURL;
}

export function setGenderPreference(gender) {
  if (!getCookieValue('gn')) {
    setCookie('sgp', 1, 3);
    setCookie('sagp', 1, 3);
  }
  setCookie('gn', gender === 'men' ? 'm' : 'f');
}

export function setTabCookie(value) {
  setCookie('selTab', value, 365);
}

export function getTabCookie() {
  const cVal = getCookieValue('selTab');
  return cVal === '' ? 'women' : cVal;
}
/* @params men/women/empty string
   Output: men/women
*/
export function getGenderPreference(gender) {
  const cookieGender = getCookieValue('gn') === 'm' ? 'men' : 'women';
  // (COUNTRY === COUNTRY_KSA) ? []:[]
  return ['women', 'men', 'kids', 'beauty'].indexOf(gender || cookieGender) > -1
    ? gender || cookieGender
    : 'women';
}

export function goBack(history) {
  const currentLocation = window.location.href;
  history.goBack();
  setTimeout(() => {
    if (currentLocation === window.location.href) {
      history.push('/');
    }
  }, 1000);
}

export function initializeFirebase() {
  if (!firebase.apps.length) {
    try {
      firebase.initializeApp(JSON.parse(process.env.STYLI_APP_FIREBASE_INIT));
    } catch (e) {
      logError(e);
    }
  }
}

export function getNavigationBasePath() {
  return `${BASE_PATH}/${CURRENT_LANG_PREFIX}`;
}

export function arrayToObj(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
}

export function isGoogleBot() {
  const agent = navigator.userAgent;
  return agent.toLowerCase().indexOf('googlebot') > -1;
}

export function isIncognito(callback) {
  const fs = window.RequestFileSystem || window.webkitRequestFileSystem;

  if (!fs) {
    callback(false);
  } else {
    fs(
      window.TEMPORARY,
      100,
      callback.bind(undefined, false),
      callback.bind(undefined, true)
    );
  }
}

export function getDeliveryTextOnAmount({ amount, deliveryThresholds }) {
  if (deliveryThresholds && amount) {
    const selectedThreshHold = deliveryThresholds.find(element => {
      return amount >= element.threshHoldLess;
    });
    if (selectedThreshHold) {
      return selectedThreshHold[`message_${CURRENT_LANG_PREFIX}`];
    }
  }
  return '';
}

export function getUtcTimeStamp() {
  const now = new Date();
  const utcTimestamp = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );
  return utcTimestamp;
}

export function sortArrayOfObj(arr, objectKey, sortOrder) {
  return arr.sort((object1, object2) => {
    return sortOrder === 'asc'
      ? object1[objectKey] - object2[objectKey]
      : object2[objectKey] - object1[objectKey];
  });
}

export function getAddressString({ addressData = {}, hideAERegion = false }) {
  let addressStr = '';
  if (addressData.buildingNumber) {
    addressStr += `${addressData.buildingNumber}, `;
  }
  if (addressData.streetAddress) {
    addressStr += `${addressData.streetAddress}, `;
  }
  if (addressData.landmark) {
    addressStr += `${addressData.landmark}, `;
  }
  if (addressData.area) {
    addressStr += `${addressData.area}, `;
  }
  if (addressData.city) {
    addressStr += `${addressData.city} `;
  }
  if (addressData.region) {
    if (hideAERegion && ['ae', 'bh', 'qa'].includes(COUNTRY.toLowerCase())) {
      addressStr += '';
    } else {
      addressStr += `,${addressData.region} `;
    }
  }

  if (addressData.country && addressData.country !== 'KW') {
    const countryCodeLower =
      addressData.country && addressData.country.toLowerCase();
    const countryIndex = COUNTRIES.indexOf(countryCodeLower);
    const countryName = countryIndex > -1 ? COUNTRIES_NAMES[countryIndex] : '';

    addressStr += countryName;
  }
  return addressStr;
}

export function changeDecimalTo(value, digitsAfterDecimal) {
  const stringVal = String(value);
  const decimalIndex =
    stringVal.indexOf('.') > -1 ? stringVal.indexOf('.') : stringVal.length;
  const wholeNum = stringVal.substr(0, decimalIndex);
  const decimals = stringVal
    .substr(decimalIndex + 1, digitsAfterDecimal)
    .padEnd(digitsAfterDecimal, '0');
  return `${wholeNum}.${decimals}`;
}

export function fixUrl(url) {
  const fixedUrl = url
    ? url.replace(/["~!@#$%^&*\(\)_+=`{}\[\]\|\\:;'<>,.\/?"\- \t\r\n]+/g, '-') //eslint-disable-line
    : '';
  return fixedUrl;
}

export function isWhiteSpace(str) {
  return /^\s*$/.test(str);
}

export function cmToInch(val) {
  const value = Number(val) / 2.54;
  return value.toFixed(1);
}
export function cmToInchRange(val) {
  let finalValue = '';
  if (val) {
    const valAry = val.toString().split('-');
    valAry.forEach(valItem => {
      if (finalValue.length) {
        finalValue += '-';
      }
      finalValue += (Number(valItem.trim()) / 2.54).toFixed(1).toString();
    });
  }
  return finalValue;
}

export function camalize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

const defaultContactDetails = {
  phone: '8001111090',
  email: 'hello.ksa@stylishop.com',
  whatsapp: '+966115208333'
};
export function openWhatsAppClick(whatsapp) {
  const href = `https://wa.me/${
    whatsapp !== '' ? whatsapp : defaultContactDetails.whatsapp
  }`;
  window.open(href, '_blank');
}

export function getStoreCurrentTimeDiff(cutOffTime, timeZone) {
  let hrsDiff = 0;
  let minDiff = 0;

  const cutOffTimeArr = cutOffTime ? cutOffTime.split(':') : [];
  const cutOffTimeHrs = Number(cutOffTimeArr[0] || 0);
  const cutOffTimeMin = Number(cutOffTimeArr[1] || 0);
  const totalCutOffMinutes = cutOffTimeHrs * 60 + cutOffTimeMin;

  const currentTimeArray = momentTimeZone()
    .tz(timeZone)
    .format('HH:mm')
    .split(':');
  const currentTimeHrs = Number(currentTimeArray[0] || 0);
  const currentTimeMin = Number(currentTimeArray[1] || 0);
  const totalCurrentMinutes = currentTimeHrs * 60 + currentTimeMin;

  const timeDiff = totalCutOffMinutes - totalCurrentMinutes;

  // const currentDayDiff = totalCutOffMinutes - totalCurrentMinutes;
  // const timeDiff = currentDayDiff > 0 ? currentDayDiff : currentDayDiff + 1440; // 1440 is number of minutes in 24 hrs

  if (timeDiff > 0) {
    hrsDiff = Math.floor(timeDiff / 60);
    minDiff = timeDiff % 60;
  }
  return { hrsDiff, minDiff };
}

export function handleJwterror(statusCode, statusMsg) {
  const baseUrl = window.location.origin;
  if (
    JWT_MSG.includes(statusMsg) &&
    window.location.href !==
      `${baseUrl}/${COUNTRY}/${CURRENT_LANG_PREFIX}/logout`
  ) {
    window.location.href = `${baseUrl}/${COUNTRY}/${CURRENT_LANG_PREFIX}/logout`;
  }
}
const isNumb = num => /^\d+$/.test(num) || /^\d+\.\d+$/.test(num);
export function validatePincode(areaCode) {
  if (areaCode.length === 6 && isNumb(areaCode)) {
    return true;
  }
  return 'Incorrect Pincode.';
}

export function getMicroServiceURL(country, msDomain) {
  let fMsDomain = msDomain;
  if (country.toLowerCase() === 'in') {
    const msDomainAry = msDomain.split('//');
    fMsDomain = `${msDomainAry[0]}//in-${msDomainAry[1]}`;
  }
  return fMsDomain;
}

export function getStoreURL(rURL) {
  let fMsDomain = rURL;
  if (
    COUNTRY &&
    COUNTRY.toLowerCase() === 'in' &&
    rURL.indexOf('//in-') === -1
  ) {
    const msDomainAry = rURL.split('//');
    fMsDomain = `${msDomainAry[0]}//in-${msDomainAry[1]}`;
  }
  return fMsDomain;
}

export function categoryTrim(value, selectedFacet) {
  if (selectedFacet.indexOf('categories') > -1) {
    const categories = value.split(' /// ');
    return categories[categories.length - 1];
  }
  return value;
}
